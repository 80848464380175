
// <---------- This is  Old Animation before the 28-10-2024-------------------->

// TypewriterHtmlAnimation.js
// import React, { useState, useEffect, useRef } from "react";
// import ReactHtmlParser from "react-html-parser";

// const TypewriterHtmlAnimation = ({ htmlContent, onAnimationComplete }) => {
//   const [displayText, setDisplayText] = useState("");
//   const scrollRef = useRef(null);
//   const typingSpeed = 50; // Adjust typing speed as needed

//   useEffect(() => {
//     if (!htmlContent) return;

//     let index = 0;

//     const interval = setInterval(() => {
//       setDisplayText((prev) => prev + htmlContent[index]);
//       index++;

//       if (scrollRef.current) {
//         scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
//       }

//       if (index === htmlContent.length) {
//         clearInterval(interval);
//         if (onAnimationComplete) onAnimationComplete(); // Notify the parent
//       }
//     }, typingSpeed);

//     return () => clearInterval(interval); // Cleanup interval on component unmount
//   }, [htmlContent, onAnimationComplete]);

//   return (
//     <div ref={scrollRef} style={{ maxHeight: "400px", overflowY: "auto", fontSize: "1em" }}>
//       <div>{ReactHtmlParser(displayText)}</div> {/* Incremental content */}
//     </div>
//   );
// };

// export default TypewriterHtmlAnimation;





// <--------------- This is New Animation Code 29-10-2024-------------->
import React, { useState, useEffect, useRef } from "react";

const TypewriterHtmlAnimation = ({ htmlContent, onAnimationComplete }) => {
  const [displayText, setDisplayText] = useState("");
  const scrollRef = useRef(null);
  const typingSpeed = 100;

  useEffect(() => {
    if (!htmlContent) return;

    // Split content by tags or individual words with spaces
    const htmlChunks = htmlContent.match(/<[^>]+>|[^<\s]+|\s+/g);
    let index = 0;

    // Typewriter interval function
    const interval = setInterval(() => {
      if (index < htmlChunks.length) {
        setDisplayText((prev) => prev + htmlChunks[index]);
        index++;

        // Auto-scroll functionality
        if (scrollRef.current) {
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
      } else {
        clearInterval(interval);
        if (onAnimationComplete) onAnimationComplete(); // Callback when complete
      }
    }, typingSpeed);

    return () => clearInterval(interval); // Clean up on unmount
  }, [htmlContent, onAnimationComplete]);

  return (
    <div
      ref={scrollRef}
      style={{
        maxHeight: "400px",
        overflowY: "auto",
        fontSize: "1em",
        lineHeight: "1.4", // Refined line height for controlled spacing
        wordSpacing: "0.1em", // Optional: Adjust word spacing for clarity
        whiteSpace: "pre-wrap", // Preserve spaces without extra gaps
      }}
      dangerouslySetInnerHTML={{ __html: displayText }}
    />
  );
};

export default TypewriterHtmlAnimation;

