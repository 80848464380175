import React, { useState, useEffect } from 'react';
// import Footer from '../../../common/footer/Footer';
import Mentor_header from '../mentor_header/Mentor_Header';
import CommonImageTransport from '../../../common/images/CommonImages';
import { Bar, Line } from 'react-chartjs-2';
import { MentorDashboardData } from '../../../../api/Mentor';
import { Link } from 'react-router-dom';
import 'chart.js/auto';
import { MdAttachMoney } from "react-icons/md";
import Loader from '../../../../loader/Loader';
import Mentor_Footer from '../mentor_footer/Mentor_Footer';

const MentorDashboard = () => {
    const [is_Loading, setIs_Loading] = useState();
    const [MentorDashBoardList, setMentorDashBoardList] = useState([]);
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };
    // <------------- Get Mentor dashBoard List -------------->
    const GetMentorDashBoardList = async () => {
        setIs_Loading(true)
        try {
            const response = await MentorDashboardData(headers)
            if (response?.data?.statusCode == "200") {
                setMentorDashBoardList(response?.data?.data)
                setIs_Loading(false)
            }

        } catch (error) {
            setIs_Loading(false)
        }
    }
    // <-------- Complete Sessions -------------->
    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', "Dec"],
        datasets: [
            {
                label: 'Complete Sessions',
                data: MentorDashBoardList?.monthlyCounts,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };
    // <-------- Up Coming  Sessions -------------->
    const linechartdata = {
        labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        datasets: [
            {
                label: 'Upcoming Sessions',
                data: MentorDashBoardList?.weeklyCounts,
                fill: false,
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
                tension: 0.4,
            },
        ],
    };

    //<-------- Calculate the sum of monthlyCounts ------------->
    const calculateSum = (counts) => {
        return counts.reduce((acc, count) => acc + count, 0);
    };
    // <----------- Render the Get Mentor DashBoard List ----------->
    useEffect(() => {
        GetMentorDashBoardList();
    }, [])

    return (
        <div>
            {
                is_Loading ? (
                    <>
                    <Loader/>
                    </>

                ) : (
                    <>
                        <div className="bg_nav-slid bg-white">
                            <Mentor_header />
                        </div>
                        <div className="enrolled-area-two py-5 pt-3 bg-light">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="Dashboard1 col-md-12">
                                        <h3>Dashboard</h3>
                                    </div>
                                    <Link to="/mentor_pages/CoachingMentor" className='col-sm-6 col-md-3 col-xlg-3'>
                                        <div className="mb-3">
                                            <div className="card btn-gradient-121 widget-flat">
                                                <div className="bg-layerbg">
                                                    <img src={CommonImageTransport?.circle} alt="" /></div>
                                                <div className="card-body">
                                                    <div className="float-end">
                                                        <i className="mdi ri-profile-line widget-icon"></i>
                                                    </div>
                                                    <h5 className="text-muted fw-normal mt-0" style={{ fontSize: "2.1rem" }}
                                                        title="Number of Customers">Completed session</h5>
                                                    <h3 className="mt-2 mb-0">
                                                        {MentorDashBoardList?.monthlyCounts ? calculateSum(MentorDashBoardList?.monthlyCounts) : 'Loading...'}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/mentor_pages/Mentor_UpComing_Session" className='col-sm-6 col-md-3 col-xlg-3'>
                                        <div className="mb-3">
                                            <div className="card widget-flat btn-gradient-success">
                                                <div className="bg-layerbg"><img
                                                    src={CommonImageTransport?.circle} alt="" /></div>
                                                <div className="card-body">
                                                    <div className="float-end">
                                                        <i className="mdi widget-icon bg-success-lighten ri-eye-line"></i>
                                                    </div>
                                                    <h5 className="text-muted fw-normal mt-0" style={{ fontSize: "2.2rem" }} title="Number of Orders">Upcoming session</h5>
                                                    <h3 className="mt-2 mb-0">{MentorDashBoardList?.weeklyCounts ? calculateSum(MentorDashBoardList?.weeklyCounts) : 'Loading...'}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>



                                    <div className='col-sm-6 col-md-3 col-xlg-3'>
                                        <div className="mb-3">
                                            <div className="card widget-flat btn-gradient-warning">
                                                <div className="card-body">
                                                    <div className="float-end">
                                                        <MdAttachMoney style={{ fontSize: "3.2rem", color: "white" }} />
                                                    </div>
                                                    <div>
                                                        <h5 className="text-muted fw-normal mt-0" style={{ fontSize: "2.5rem" }}
                                                            title="Number of Orders">Fees Per Hour</h5>
                                                        <h3 className="mt-2 mb-0">${MentorDashBoardList?.per_hour_price} USD</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-6 col-md-3 col-xlg-3'>
                                        <div className="mb-3">
                                            <div className="card widget-flat btn-gradient-danger">
                                                <div className="card-body">
                                                    <div className="float-end">
                                                        <MdAttachMoney style={{ fontSize: "3.2rem", color: "white" }} />
                                                    </div>
                                                    <div>
                                                        <h5 className="text-muted fw-normal mt-0" style={{ fontSize: "2.5rem" }}
                                                            title="Number of Orders">Total Earning</h5>
                                                        <h3 className="mt-2 mb-0">Total: {MentorDashBoardList?.mentor_income}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <div className="card widget-flat">
                                                <h4 className="graphtitles">Completed session</h4>
                                                <div className="charthhe" id="chartContainer">
                                                    <Bar data={data} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="card widget-flat">
                                                <h4 className="graphtitles">Upcoming session</h4>
                                                <div className="charthhe" id="chartContainer2">
                                                    <Line data={linechartdata} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            <Mentor_Footer />
        </div>
    )
}

export default MentorDashboard