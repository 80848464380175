import React, { useState, useEffect } from 'react'
import Header from '../../components/common/header/Header'
import '../../assets/css/iconplugins.css'
import '../../assets/css/style.css'
import '../../assets/css/responsive.css'
import '../../assets/images/favicon.png'
import CommonImageTransport from '../../components/common/images/CommonImages'
import 'react-phone-number-input/style.css'
import StudentForm from '../student/Student_SignUp'
import Recruiter from '../recruiter/Recruiter_SignUp'
import Mentor from '../mentor/Mentor_SignUp'

const Signup = () => {
    const getRecruiter = localStorage.getItem("HandleRecruiter")
    const getMentorship = localStorage.getItem("HandleMentorship")
    useEffect(() => {
        if (getRecruiter) {
            setChangeTab(2); 
        } else if (getMentorship) {
            setChangeTab(3); 
        }
        else{
            setChangeTab(1)
        }
    }, []);

    const Student = getRecruiter && getMentorship
    useEffect(() => {
        document.title = '::Signup::';
      }, []);
    const [ChangeTab, setChangeTab] = useState(1)

    const RemoveLocalStorage = () => {
        localStorage.removeItem("HandleMentorship");
        localStorage.removeItem("HandleRecruiter");
      }
  return (
    <div className="login-signup-bg">
            <Header />
            <div className="user-area p-2 p-md-4 p-lg-5 pt-lg-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 p-0 d-none d-md-none d-lg-block">
                            <div className="user_login">
                                <img src={CommonImageTransport.loginbg} className="imgk-fluid w-100" alt="login-banner" />
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex p-0">
                            <div className="user-all-form userlogin position-relative">
                                <div className="login_bottom">
                                    <img src={CommonImageTransport.loginBottom} alt="" />
                                </div>
                                <div className="contact-form m-0 py-0">
                                   
                                    <h3 className="user-title"> Sign up for an account</h3>

                                    <ul className="nav tabs_signup justify-content-start gap-2">
                                        
                                        <li className="nav-item">
                                            <a className={ ChangeTab === 1  ? "nav-link active" : "nav-link"}
                                             aria-current="page" onClick={()=>{setChangeTab(1);
                                              RemoveLocalStorage();
                                              
                                              }}>Student</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={ (ChangeTab === 2 || getRecruiter) ? "nav-link active" : "nav-link"} 
                                             onClick={()=>{setChangeTab(2)}}>Recruiter</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={( ChangeTab === 3 || getMentorship) ? "nav-link active" : "nav-link"} 
                                             onClick={()=>{setChangeTab(3)}}>Mentor</a>
                                        </li>

                                    </ul>
                                    {/* {ChangeTab === 1 && <StudentForm />}  */}
                                    {ChangeTab === 2 && <Recruiter />} 
                                    {ChangeTab === 3 && <Mentor />} 
                                   

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Signup