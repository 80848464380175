import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import backgroundImage from "../../../../assets/images/inner-banner/banner2.png";
import Header from "../../../common/header/Header";
import Footer from "../../../common/footer/Footer";
import BackToTop from "../../../common/back_to_top/Back_To_Top";
import Accordion from "react-bootstrap/Accordion";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Loader from "../../../../loader/Loader";
import {
  GetSetings,
  GetStudentFCQustion,
  UserContactForm,
} from "../../../../api/Public";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { isValidPhoneNumber } from "libphonenumber-js";
import Modal from "react-bootstrap/Modal";

const Contact = () => {
  const contactFormRef = useRef(null);
  const [SettingList, setSettingList] = useState({});
  const [ActiveTabs, setActiveTabs] = useState(0);
  const [FCQList, setFCQList] = useState([]);
  const [MentorFCQList, setMentorFCQList] = useState([]);
  const [RecuriterFCQList, setRecuriterFCQList] = useState([]);
  const [AccordionTitleActive, setAccordionTitleActive] = useState(0);

  // <-------- Contact Form Parameters --------------->
  const [first_name, setFirst_Name] = useState("");
  const [last_name, setLast_Name] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [mobile, setMobile] = useState("");
  const [subject, setSubject] = useState("Support");
  const [message, setMessage] = useState("");

  // <--------- Submit the Data after validated all data ---------------->
  const [isSubmitting, setIsSubmitting] = useState(false);

  // <-------------- Validation's for the parameters -------------->
  const [firstNameError, setFirstNameError] = useState("");
  const [LastNameError, setLastNameError] = useState("");
  const [EmailError, setEmailError] = useState(true);
  const [mobileError, setMobileError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [characterCount, setCharacterCount] = useState(0);

  // <--------- This is UseState for Loader Pages-------->
  // const [isLoading, setIsLoading] = useState(true);

  // <---------- Show the Popup T&C ------------->
  const [ShowTermsCondition, setShowTermsCondition] = useState(false);

  // <----------- Check the T & C -------------->
  const [isTACChecked, setIsTACChecked] = useState(false);

  // <----------- Handle Active Conditions ------------------>
  const HandleActive = (index) => {
    setActiveTabs(index);
  };

  // <---------------- Handle Active Accordition Title Section ------------>
  const HandleAccordionTitleActive = (index) => {
    setAccordionTitleActive(AccordionTitleActive === index ? null : index);
  };

  // <--------- Check the Name is Valid or not Vaild here --------------->
  const handleNameInputChange = (event, setter, setError) => {
    const value = event.target.value;
    setter(value);
    const regex = /^[a-zA-Z\s]+$/;
    if (!regex.test(value)) {
      setError("Name should contain only letters and spaces");
    } else {
      setError("");
    }
  };

  /*----------------------Validate Email-------------------*/
  const handleEmailInputChange = (event, setter, setError) => {
    const value = event.target.value;
    setter(value);
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(value)) {
      setError("Please enter a valid email address.");
    } else {
      setError("");
    }
  };

  // <----------- validation for the message section's ----------->
  const handleMessageChange = (e) => {
    const value = e.target.value;
    const characterCountWithoutSpaces = value.replace(/\s/g, "").length;
    setMessage(value);
    setCharacterCount(characterCountWithoutSpaces);
    if (characterCountWithoutSpaces < 100) {
      setMessageError(
        "Message must be at least 100 characters (excluding spaces)."
      );
    } else if (value.length > 1500) {
      setMessageError("Message cannot exceed 1500 characters.");
    } else {
      setMessageError("");
    }
  };

  // Phone Number Validation
  // const validatePhoneNumber = (phoneNumber) => {
  //   if (isValidPhoneNumber(phoneNumber)) {
  //     setMobileError("");
  //     return true;
  //   } else {
  //     setMobileError("Please enter a valid phone number.");
  //     return false;
  //   }
  // };

  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    setIsTACChecked(e.target.checked);
  };

  // <---------- Submit the Contact form Data -------------->
  const handleContactSubmit = async (e) => {
    e.preventDefault();
  
    // Validate first and last names
    if (firstNameError || LastNameError || !first_name || !last_name) {
      toast.error("Name should contain only letters and spaces");
      return;
    }
  
    // Validate email
    if (EmailError || !email) {
      toast.error("Please enter a correct email");
      return;
    }
  
    // Validate phone number before submitting
    if (!validatePhoneNumber(mobile)) {
      toast.error("Please enter a valid phone number.");
      return;
    }
  
    // Validate message length (without spaces)
    const characterCountWithoutSpaces = message.replace(/\s/g, "").length;
    if (characterCountWithoutSpaces < 100) {
      toast.error("Message must be at least 100 characters (excluding spaces).");
      return;
    } else if (message.length > 1500) {
      toast.error("Message cannot exceed 1500 characters.");
      return;
    }
  
    // Check if the terms and conditions checkbox is checked
    if (!isTACChecked) {
      toast.error("Please accept the Terms & Conditions and Privacy Policy.");
      return;
    }
  
    setIsSubmitting(true); // Disable form and show loading state
  
    // Prepare data for submission
    const data = {
      fname: first_name,
      lname: last_name,
      organization: organization,
      email_id: email,
      mobile_no: mobile,  // Ensure this is formatted with country code
      contact_subject: subject,
      user_message: message,
      tac: 1,  // Mark terms and conditions as accepted
    };
  
    console.log("handleContactSubmit_data", data);
  
    try {
      const response = await UserContactForm(data);  // Assuming `UserContactForm` is the API call
  
      if (response?.data?.statusCode === "200") {
        toast.success("Your enquiry has been successfully submitted!");
        
        // Reset form fields after successful submission
        setFirst_Name("");
        setLast_Name("");
        setEmail("");
        setOrganization("");
        setSubject("");
        setMessage("");
        setMobile("");
      } else if (response?.data?.msg === "User already exists") {
        toast.warn("User already exists. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting contact form:", error);
      toast.error("An error occurred while submitting the form. Please try again.");
    } finally {
      setIsSubmitting(false); // Re-enable form
    }
  };
  

  const HandleGetFCQustion = async () => {
    const data = {
      user_type: "student",
    };
    try {
      const response = await GetStudentFCQustion(data);
      setFCQList(response?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  const HandleGetMentorFCQustion = async () => {
    const data = {
      user_type: "trainer",
    };
    try {
      const response = await GetStudentFCQustion(data);
      setMentorFCQList(response?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  const HandleGetRecuriterFCQustion = async () => {
    const data = {
      user_type: "recruiter",
    };
    try {
      const response = await GetStudentFCQustion(data);
      setRecuriterFCQList(response?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  console.log("HandleGetFCQustion", FCQList);

  const HandleGetSettingList = async () => {
    const response = await GetSetings();
    setSettingList(response?.data?.data);
  };

   // Validate the phone number
   const validatePhoneNumber = (phoneNumber) => {
    // Ensure phoneNumber is not null or undefined before validating
    if (!phoneNumber) {
      setMobileError("Phone number cannot be empty.");
      return false;
    }
  
    // Validate the phone number if it's not empty
    if (isValidPhoneNumber(phoneNumber)) {
      setMobileError(""); // Clear error if valid
      return true;
    } else {
      setMobileError("Please enter a valid phone number.");
      return false;
    }
  };
  

  // Handle phone number changes and validation
  const handlePhoneNumberChange = (value) => {
    setMobile(value || "");  // Ensure mobile is always a string, even when cleared
    validatePhoneNumber(value);  // Validate the number when changed
  };
  


  useEffect(() => {
    HandleGetSettingList();
  }, []);

  // <--------- This UseEffect for Loader Pages-------->
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2200);
  //   return () => clearTimeout(timeout);
  // }, []);

  // <--------------
  useEffect(() => {
    const timeout = setTimeout(() => {
      document.title = "Contact";
      document
        .querySelector(".accordion-item")
        ?.classList?.add("animate-from-bottom");
      // setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    HandleGetFCQustion();
    HandleGetRecuriterFCQustion();
    HandleGetMentorFCQustion();
  }, []);

  // This useEffect will scroll to the form after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      if (contactFormRef.current) {
        contactFormRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 5000);

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Scroll to the top of the page when the component is rendered
    window.scrollTo(0, 0);
  }, []);
  return (
    <>

      <Header />
      <div>
        <div className="" />
        <div
          className="inner-banner contact_bgus inner-banner-bg ptb-100"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <div className="container">
            <div className="row">
              <ToastContainer style={{ marginTop: "120px" }} />
              <div className="col-md-8 my-auto">
                <div className="inner-title py-2">
                  <a
                    href="#contactus"
                    className="default-btn bg-white text-dark fw-bold rounded"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-area bg-light pt-4">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 mx-auto">
                <div className="faq-accordion">
                  <div className="courses-details-contact">
                    <div className="tab courselfaq courses-details-tab">
                      <Tabs
                        selectedIndex={ActiveTabs}
                        onSelect={(index) => HandleActive(index)}
                      >
                        <TabList className="tabs justify-content-center">
                          <Tab className={ActiveTabs === 0 ? "active" : ""}>
                            Student
                          </Tab>
                          <Tab className={ActiveTabs === 1 ? "active" : ""}>
                            Mentor
                          </Tab>
                          <Tab className={ActiveTabs === 2 ? "active" : ""}>
                            Recruiter
                          </Tab>
                        </TabList>

                        <div className="section-title mb-45">
                          <h2>Frequently asked questions</h2>
                        </div>

                        <TabPanel>
                          <div className="tabs_item current">
                            <Accordion className="accordion">
                              {FCQList?.map((item, index) => (
                                <Accordion.Item
                                  eventKey={index.toString()}
                                  key={index}
                                >
                                  <Accordion.Header>
                                    <a
                                      className={`accordion-title ${AccordionTitleActive === index
                                          ? "active"
                                          : ""
                                        }`}
                                      onClick={() =>
                                        HandleAccordionTitleActive(index)
                                      }
                                      href="javascript:void(0)"
                                    >
                                      <i className="ri-add-fill" />
                                      {item?.question}
                                    </a>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className="accordion-content show">
                                      <p>{item?.answer}</p>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              ))}
                            </Accordion>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="tabs_item current">
                            <Accordion className="accordion">
                              {MentorFCQList?.map((item, index) => (
                                <Accordion.Item
                                  eventKey={index.toString()}
                                  key={index}
                                >
                                  <Accordion.Header>
                                    <a
                                      className={`accordion-title ${AccordionTitleActive === index
                                          ? "active"
                                          : ""
                                        }`}
                                      onClick={() =>
                                        HandleAccordionTitleActive(index)
                                      }
                                      href="javascript:void(0)"
                                    >
                                      <i className="ri-add-fill" />
                                      {item?.question}
                                    </a>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className="accordion-content show">
                                      <p>{item?.answer}</p>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              ))}
                            </Accordion>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="tabs_item current">
                            <Accordion className="accordion">
                              {RecuriterFCQList?.map((item, index) => (
                                <Accordion.Item
                                  eventKey={index.toString()}
                                  key={index}
                                >
                                  <Accordion.Header>
                                    <a
                                      className={`accordion-title ${AccordionTitleActive === index
                                          ? "active"
                                          : ""
                                        }`}
                                      onClick={() =>
                                        HandleAccordionTitleActive(index)
                                      }
                                      href="javascript:void(0)"
                                    >
                                      <i className="ri-add-fill" />
                                      {item?.question}
                                    </a>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className="accordion-content show">
                                      <p>{item?.answer}</p>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              ))}
                            </Accordion>
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={contactFormRef}>
          <div className="contact-widget-area">
            <div className="container">
              <div className="row py-5" id="contactus">
                <div className="col-md-4">
                  <div className="contact-info-card">
                    <i className="ri-map-pin-fill" />
                    <h3>Our location </h3>
                    <p>{SettingList?.officeaddress}</p>
                  </div>
                  <div className="contact-info-card">
                    <i className="ri-mail-fill" />
                    <h3>Email us</h3>
                    <p>
                      <a href="#">
                        <span className="__cf_email__">
                          {SettingList?.email}
                        </span>
                      </a>
                    </p>
                  </div>
                  <div className="contact-info-card">
                    <i className="ri-phone-fill" />
                    <h3>Phone</h3>
                    <p>
                      <a href="tel:+44587154756">
                        {SettingList?.countrycode} {SettingList?.mobileno}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-md-12 col-lg-8" id="#contactus">
                  <div className="section-title mb-45">
                    <h2>Get In Touch With Us</h2>
                  </div>
                  <div className="contact-form p-0">
                    <form
                      id="contactForms"
                      onSubmit={handleContactSubmit}
                      className="formcontact"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-floating form-group">
                            <input
                              type="text"
                              name="first_name"
                              value={first_name}
                              id="name"
                              className="form-control"
                              onChange={(event) =>
                                handleNameInputChange(
                                  event,
                                  setFirst_Name,
                                  setFirstNameError
                                )
                              }
                              required
                              data-error="Please Enter Your Name"
                              placeholder=""
                            />
                            <label className="mb-2" for="floatingInput">
                              First Name
                            </label>
                            {firstNameError && (
                              <p style={{ color: "red", fontSize: "14px" }}>
                                {firstNameError}
                              </p>
                            )}
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-floating form-group">
                            <input
                              type="text"
                              name="last_name"
                              id="name"
                              value={last_name}
                              onChange={(event) =>
                                handleNameInputChange(
                                  event,
                                  setLast_Name,
                                  setLastNameError
                                )
                              }
                              className="form-control"
                              required
                              data-error="Please Enter Your Name"
                              placeholder=""
                            />
                            <label className="mb-2" for="floatingInput">
                              Last Name
                            </label>
                            {LastNameError && (
                              <p style={{ color: "red", fontSize: "14px" }}>
                                {LastNameError}
                              </p>
                            )}
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-floating form-group">
                            <input
                              type="email"
                              name="email"
                              value={email}
                              onChange={(event) =>
                                handleEmailInputChange(
                                  event,
                                  setEmail,
                                  setEmailError
                                )
                              }
                              id="email"
                              className="form-control"
                              required
                              data-error="Please Enter Your Email"
                              placeholder=""
                            />
                            <label className="mb-2" for="floatingInput">
                              Email ID
                            </label>
                            {EmailError && (
                              <p style={{ color: "red", fontSize: "14px" }}>
                                {EmailError}
                              </p>
                            )}
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div className="col-lg-6 ">
                          <div className="form-floating form-group">
                            <PhoneInput
                              className="phoneinput form-control"
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="US"
                              placeholder="Enter phone number"
                              value={mobile || ""}
                              onChange={handlePhoneNumberChange}
                            />
                            {mobileError && (
                              <p style={{ color: "red", fontSize: "14px" }}>
                                {mobileError}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-floating form-group">
                            <input
                              type="text"
                              name="organization"
                              value={organization}
                              onChange={(e) =>
                                setOrganization(e.target.value)
                              }
                              id="msg_subject"
                              className="form-control"
                              required
                              data-error="Please Enter Your Organization"
                              placeholder=""
                            />
                            <label className="mb-2" for="floatingInput">
                              Organization
                            </label>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-lg-12 mb-3">
                          <div className="d">
                            <label className="mb-1 fw-bold">Subject</label>
                            <div className="d-flex gap-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="subject"
                                  value="Support"
                                  onChange={(e) =>
                                    setSubject(e.target.value)
                                  }
                                  checked={subject === "Support"}
                                  id="flexRadioDefault1"
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  Support
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="subject"
                                  value="partnership"
                                  onChange={(e) =>
                                    setSubject(e.target.value)
                                  }
                                  id="flexRadioDefault2"
                                  checked={subject === "partnership"}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault2"
                                >
                                  Partnership
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="subject"
                                  value="Investor"
                                  onChange={(e) =>
                                    setSubject(e.target.value)
                                  }
                                  checked={subject === "Investor"}
                                  id="flexRadioDefault3"
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault3"
                                >
                                  Investor
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="subject"
                                  value="Feedback"
                                  onChange={(e) =>
                                    setSubject(e.target.value)
                                  }
                                  id="flexRadioDefault4"
                                  checked={subject === "Feedback"}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault4"
                                >
                                  Feedback
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-floating form-group">
                            <textarea
                              id="text_area"
                              maxlength="1500"
                              name="message"
                              value={message}
                              onChange={handleMessageChange}
                              className="form-control"
                              cols="30"
                              rows="7"
                              required
                              data-error="Write your message"
                              placeholder=""
                            ></textarea>
                            <label className="mb-2" for="floatingInput">
                              Message
                            </label>
                            {messageError && (
                              <p style={{ color: "red" }}>{messageError}</p>
                            )}
                            <span id="count">{characterCount}/1500</span>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="agree-label">
                            <input type="checkbox" id="chb1" onChange={handleCheckboxChange}
                              checked={isTACChecked} />
                            <label for="chb1" onClick={() => setShowTermsCondition(true)}>
                              Accept <a href="#">Terms & Conditions</a> And{" "}
                              <a href="#">Privacy Policy.</a>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <button type="submit" className="default-btn">
                            Send Message
                          </button>
                          <div
                            id="msgSubmit"
                            className="h3 text-center hidden"
                          ></div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <--------- T & C Modal section's ------------> */}
      <Modal size="lg" show={ShowTermsCondition} onHide={() => setShowTermsCondition(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row pt-1">
              <div className="col-lg-12">
                <div className="single-content">
                  <h3> User Agreement:</h3>
                  <ul>
                    <li>
                      The user agrees to provide accurate information in the
                      contact form.
                    </li>
                    <li>
                      The website owner is not responsible for the accuracy
                      or security of the information provided by the user.
                    </li>
                  </ul>
                  <h3> Restrictions:</h3>
                  <ul>
                    <li>
                      Users should not use the contact form for any
                      unlawful purposes, including spam, abuse, or fraudulent activities.
                    </li>

                  </ul>
                  <h3> Communication Policy:</h3>
                  <ul>
                    <li>
                      By submitting the contact form, users
                      agree to receive communication via email, phone, or other methods for follow-up.
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <BackToTop />
      <Footer />

    </>
  );
};

export default Contact;
