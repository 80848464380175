import React, { useState } from 'react';
import CommonImageTransport from '../../../common/images/CommonImages';
import Student_Header from '../student_header/Student_Header';
import Typewriter from 'typewriter-effect';
const Student_Coaching_Center = () => {
    const [showSearchFields, setShowSearchFields] = useState(false);
    const [ActiveTab, setActiveTab] = useState(0);
    const [MentorList, setMentorList] = useState([0]);

    const HandleActiveTab = (tabs) => {
        setActiveTab(tabs)
    }
    return (
        <div>
            <div className="bg_nav-slid bg-white">
                <Student_Header />
            </div>
            <div className="header_space"></div>
            <div className="recruiter_list pb-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <form>
                                <div className="searchlocation">
                                    <div className="iconfield">
                                        <div className="icon_search">
                                            <img src={CommonImageTransport?.searchicon} alt="" />
                                        </div>

                                        {!showSearchFields ? (
                                            <div
                                                onClick={() => setShowSearchFields(true)}>
                                                <Typewriter
                                                    options={{
                                                        loop: true,
                                                    }}
                                                    onInit={(typewriter) => {
                                                        typewriter.typeString('Search by title')
                                                            .callFunction(() => {
                                                            })
                                                            .pauseFor(2500)
                                                            .deleteAll()
                                                            .callFunction(() => {
                                                            })
                                                            .typeString('Search by location')
                                                            .pauseFor(2500)
                                                            .start();
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <div>
                                                <input type="text" placeholder="" style={{ border: "none", outline: "none" }} />
                                            </div>
                                        )}


                                    </div>
                                    <div className="iconfield">
                                        <div className="icon_search">
                                            <img src={CommonImageTransport?.locationicon} alt="" />
                                        </div>
                                        <div className="input-field">
                                            <input type="text" placeholder="USA, Florida" className="form-control"
                                                required="" />
                                        </div>
                                    </div>
                                    <div className="input-field ad121">
                                        <input type="submit" value="Search" className="submit_btn" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="left_recruiterlists row">
                        <div className="col-md-4 col-lg-4">
                            <div className="sticky-header">
                                <div className="nav flex-column recruiter_all_lists nav-pills" id="v-pills-tab" role="tablist"
                                    aria-orientation="vertical">
                                    {
                                        MentorList?.map((MentorListResult, index) => {
                                            return (
                                                <>
                                                    <div className={`nav-link ${ActiveTab === 0 ? "active" : ""}`}
                                                        onClick={() => HandleActiveTab(0)} id="v-pills-home-tab" data-bs-toggle="pill"
                                                        data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home"
                                                        aria-selected="true">
                                                        <div className="card_rec position-relative">
                                                            <div className="mentersvies0121">
                                                                <div className="position-relative">
                                                                    <img src={CommonImageTransport?.instructorDetails}
                                                                        className="img-fluid rounded" alt="Instructor" />
                                                                </div>
                                                                <div className="instructors-details-contents">
                                                                    <div className="">
                                                                        <div className="comnam">
                                                                            <h3>Kannav </h3>
                                                                            <h6 className="sub-title">Web designer</h6>
                                                                        </div>
                                                                    </div>
                                                                    <p className="perographsv">
                                                                        AVP of Revenue at Emeritus | Former Management Consultant | IIT
                                                                        Delhi |
                                                                        IIM
                                                                        Lucknow
                                                                        | ESCP Paris
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="skillstopic">
                                                                <div>product Management</div>
                                                                <div>Business Development</div>
                                                                <div>Career Advice</div>
                                                                <div>+3</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`nav-link ${ActiveTab === 1 ? "active" : ""}`}
                                                        onClick={() => HandleActiveTab(1)} id="v-pills-home-tab" data-bs-toggle="pill"
                                                        data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home"
                                                        aria-selected="true">
                                                        <div className="card_rec position-relative">
                                                            <div className="mentersvies0121">
                                                                <div className="position-relative">
                                                                    <img src={CommonImageTransport?.instructorDetails}
                                                                        className="img-fluid rounded" alt="Instructor" />
                                                                </div>
                                                                <div className="instructors-details-contents">
                                                                    <div className="">
                                                                        <div className="comnam">
                                                                            <h3>Kannav </h3>
                                                                            <h6 className="sub-title">Web designer</h6>
                                                                        </div>
                                                                    </div>
                                                                    <p className="perographsv">
                                                                        AVP of Revenue at Emeritus | Former Management Consultant | IIT
                                                                        Delhi |
                                                                        IIM
                                                                        Lucknow
                                                                        | ESCP Paris
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="skillstopic">
                                                                <div>product Management</div>
                                                                <div>Business Development</div>
                                                                <div>Career Advice</div>
                                                                <div>+3</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`nav-link ${ActiveTab === 2 ? "active" : ""}`}
                                                        onClick={() => HandleActiveTab(2)} id="v-pills-home-tab" data-bs-toggle="pill"
                                                        data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home"
                                                        aria-selected="true">
                                                        <div className="card_rec position-relative">
                                                            <div className="mentersvies0121">
                                                                <div className="position-relative">
                                                                    <img src={CommonImageTransport?.instructorDetails}
                                                                        className="img-fluid rounded" alt="Instructor" />
                                                                </div>
                                                                <div className="instructors-details-contents">
                                                                    <div className="">
                                                                        <div className="comnam">
                                                                            <h3>Kannav </h3>
                                                                            <h6 className="sub-title">Web designer</h6>
                                                                        </div>
                                                                    </div>
                                                                    <p className="perographsv">
                                                                        AVP of Revenue at Emeritus | Former Management Consultant | IIT
                                                                        Delhi |
                                                                        IIM
                                                                        Lucknow
                                                                        | ESCP Paris
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="skillstopic">
                                                                <div>product Management</div>
                                                                <div>Business Development</div>
                                                                <div>Career Advice</div>
                                                                <div>+3</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-8">
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className={`tab-pane fade ${ActiveTab === 0 ? "show active" : ""}`} id="v-pills-home" role="tabpanel"
                                    aria-labelledby="v-pills-home-tab">
                                    <div className="instructors-details-img m-0">
                                        <div className="card-instrutors shadow position-relative bg-white p-4">

                                            <div className="mentersvies0121">
                                                <div className="position-relative menter_pro">
                                                    <img src={CommonImageTransport?.instructorDetails}
                                                        className="rounded_01top" alt="Instructor" />
                                                </div>

                                                <div className="instructors-details-contents mt-3">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <h3>Kannav </h3>
                                                            <h6 className="sub-title mb-2">Web designer</h6>
                                                        </div>
                                                    </div>
                                                    <p>
                                                        AVP of Revenue at Emeritus | Former Management Consultant | IIT
                                                        Delhi | IIM
                                                        Lucknow
                                                        | ESCP Paris
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="instructors-details-contents mt-3">
                                                <div className="businessmen">
                                                    <div><i className="ri-briefcase-4-fill"></i> 9 years</div>
                                                    <div><i className="ri-shield-user-fill"></i> Business And Management
                                                    </div>
                                                    <div><i className="ri-chat-voice-fill"></i>45 Bookings</div>
                                                    <div><i className="ri-time-fill"></i>12.3 Hrs</div>
                                                    <a href="#" className="default-btn rounded" data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal">Session</a>
                                                </div>

                                            </div>
                                        </div>

                                        {/* <!-- Modal --> */}
                                        <div className="modal fade" id="exampleModal" tabindex="-1"
                                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">Metting Session
                                                            Date
                                                            and Time</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="calendar" id="calendar-2" data-mdb-monday-first="true"></div>
                                                        {/* <!-- <div id="demo"></div>
                                                <div id="demo-timegrid"></div> --> */}
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="default-btn rounded">Book
                                                            Session</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <h5>About</h5>
                                                <p>
                                                    Sed porttitor lectus nibh. Donec rutrum congue leo eget malesuada.
                                                    Praesent sapien
                                                    massa,
                                                    convallis a
                                                    pellentesque egestas Curabitur arcu erat, accumsan id imperdiet et,
                                                    porttitor at
                                                    sem.
                                                    Cras
                                                    ultricies ligula sed
                                                    magna dictum porta. Vestibulum ante ipsum primis in faucibus orci
                                                    luctus
                                                    et ultrices
                                                    posuere
                                                    cubilia.
                                                </p>
                                                <p>
                                                    Vestibulum ac diam sit amet quam vehicula elementum sed sit amet
                                                    dui.
                                                    Vestibulum ac
                                                    diam
                                                    sit
                                                    amet quam
                                                    vehicula elementum sed sit amet dui. Curabitur non nulla sit amet
                                                    nisl
                                                    tempus
                                                    convallis
                                                    quis
                                                    ac lectus.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <h5>Topics</h5>
                                                <div className="skillstopic">
                                                    <div>#Build a team</div>
                                                    <div>#Change jobs</div>
                                                    <div>#Foster team culture</div>
                                                    <div>#Get a raise</div>
                                                    <div>#Get your Resume/CV reviewed</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <h5>Skills</h5>
                                                <div className="skillstopic">
                                                    <div> #Product Consulting</div>
                                                    <div>#Product Management</div>
                                                    <div>#How to get high-impact jobs - VCs/Strategy</div>
                                                    <div>#Career Advice</div>
                                                    <div>#CAT Preparation</div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <h5>Education</h5>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>ESCP Business School, Paris, France</h6>
                                                        <span>MIM</span>
                                                        <p>2016 - 2017</p>
                                                    </div>
                                                </div>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>ESCP Business School, Paris, France</h6>
                                                        <span>MIM</span>
                                                        <p>2016 - 2017</p>
                                                    </div>
                                                </div>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>ESCP Business School, Paris, France</h6>
                                                        <span>MIM</span>
                                                        <p>2016 - 2017</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <h5>Work Experience</h5>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>Head of Marketing</h6>
                                                        <span>Sunstone Eduversity</span>
                                                        <p>2021 - 2022</p>
                                                    </div>
                                                </div>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>Head of Marketing</h6>
                                                        <span>Sunstone Eduversity</span>
                                                        <p>2021 - 2022</p>
                                                    </div>
                                                </div>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>Head of Marketing</h6>
                                                        <span>Sunstone Eduversity</span>
                                                        <p>2021 - 2022</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${ActiveTab === 1 ? "show active" : ""}`} id="v-pills-profile" role="tabpanel"
                                    aria-labelledby="v-pills-profile-tab">
                                    <div className="instructors-details-img m-0">
                                        <div className="card-instrutors shadow position-relative bg-white p-4">

                                            <div className="mentersvies0121">
                                                <div className="position-relative menter_pro">
                                                    <img src={CommonImageTransport?.instructorDetails}
                                                        className="rounded_01top" alt="Instructor" />

                                                </div>

                                                <div className="instructors-details-contents mt-3">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <h3>Kannav </h3>
                                                            <h6 className="sub-title mb-2">Web designer</h6>
                                                        </div>

                                                    </div>
                                                    <p>
                                                        AVP of Revenue at Emeritus | Former Management Consultant | IIT
                                                        Delhi | IIM
                                                        Lucknow
                                                        | ESCP Paris
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="instructors-details-contents mt-3">


                                                <div className="businessmen">
                                                    <div><i className="ri-briefcase-4-fill"></i> 9 years</div>
                                                    <div><i className="ri-shield-user-fill"></i> Business And Management
                                                    </div>
                                                    <div><i className="ri-chat-voice-fill"></i>45 Bookings</div>
                                                    <div><i className="ri-time-fill"></i>12.3 Hrs</div>
                                                    <a href="#" className="default-btn rounded" data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal">Book Now</a>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <h5>About</h5>
                                                <p>
                                                    Sed porttitor lectus nibh. Donec rutrum congue leo eget malesuada.
                                                    Praesent sapien
                                                    massa,
                                                    convallis a
                                                    pellentesque egestas Curabitur arcu erat, accumsan id imperdiet et,
                                                    porttitor at
                                                    sem.
                                                    Cras
                                                    ultricies ligula sed
                                                    magna dictum porta. Vestibulum ante ipsum primis in faucibus orci
                                                    luctus
                                                    et ultrices
                                                    posuere
                                                    cubilia.
                                                </p>
                                                <p>
                                                    Vestibulum ac diam sit amet quam vehicula elementum sed sit amet
                                                    dui.
                                                    Vestibulum ac
                                                    diam
                                                    sit
                                                    amet quam
                                                    vehicula elementum sed sit amet dui. Curabitur non nulla sit amet
                                                    nisl
                                                    tempus
                                                    convallis
                                                    quis
                                                    ac lectus.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <h5>Topics</h5>
                                                <div className="skillstopic">
                                                    <div>#Build a team</div>
                                                    <div>#Change jobs</div>
                                                    <div>#Foster team culture</div>
                                                    <div>#Get a raise</div>
                                                    <div>#Get your Resume/CV reviewed</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <h5>Skills</h5>
                                                <div className="skillstopic">
                                                    <div> #Product Consulting</div>
                                                    <div>#Product Management</div>
                                                    <div>#How to get high-impact jobs - VCs/Strategy</div>
                                                    <div>#Career Advice</div>
                                                    <div>#CAT Preparation</div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <h5>Education</h5>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>ESCP Business School, Paris, France</h6>
                                                        <span>MIM</span>
                                                        <p>2016 - 2017</p>
                                                    </div>
                                                </div>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>ESCP Business School, Paris, France</h6>
                                                        <span>MIM</span>
                                                        <p>2016 - 2017</p>
                                                    </div>
                                                </div>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>ESCP Business School, Paris, France</h6>
                                                        <span>MIM</span>
                                                        <p>2016 - 2017</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <h5>Work Experience</h5>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>Head of Marketing</h6>
                                                        <span>Sunstone Eduversity</span>
                                                        <p>2021 - 2022</p>
                                                    </div>
                                                </div>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>Head of Marketing</h6>
                                                        <span>Sunstone Eduversity</span>
                                                        <p>2021 - 2022</p>
                                                    </div>
                                                </div>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>Head of Marketing</h6>
                                                        <span>Sunstone Eduversity</span>
                                                        <p>2021 - 2022</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${ActiveTab === 2 ? "show active" : ""}`} id="v-pills-messages" role="tabpanel"
                                    aria-labelledby="v-pills-messages-tab">
                                    <div className="instructors-details-img m-0">
                                        <div className="card-instrutors shadow position-relative bg-white p-4">

                                            <div className="mentersvies0121">
                                                <div className="position-relative menter_pro">
                                                    <img src={CommonImageTransport?.instructorDetails}
                                                        className="rounded_01top" alt="Instructor" />

                                                </div>

                                                <div className="instructors-details-contents mt-3">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <h3>Kannav </h3>
                                                            <h6 className="sub-title mb-2">Web designer</h6>
                                                        </div>

                                                    </div>
                                                    <p>
                                                        AVP of Revenue at Emeritus | Former Management Consultant | IIT
                                                        Delhi | IIM
                                                        Lucknow
                                                        | ESCP Paris
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="instructors-details-contents mt-3">


                                                <div className="businessmen">
                                                    <div><i className="ri-briefcase-4-fill"></i> 9 years</div>
                                                    <div><i className="ri-shield-user-fill"></i> Business And Management
                                                    </div>
                                                    <div><i className="ri-chat-voice-fill"></i>45 Bookings</div>
                                                    <div><i className="ri-time-fill"></i>12.3 Hrs</div>
                                                    <a href="#" className="default-btn rounded" data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal">Book Now</a>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <h5>About</h5>
                                                <p>
                                                    Sed porttitor lectus nibh. Donec rutrum congue leo eget malesuada.
                                                    Praesent sapien
                                                    massa,
                                                    convallis a
                                                    pellentesque egestas Curabitur arcu erat, accumsan id imperdiet et,
                                                    porttitor at
                                                    sem.
                                                    Cras
                                                    ultricies ligula sed
                                                    magna dictum porta. Vestibulum ante ipsum primis in faucibus orci
                                                    luctus
                                                    et ultrices
                                                    posuere
                                                    cubilia.
                                                </p>
                                                <p>
                                                    Vestibulum ac diam sit amet quam vehicula elementum sed sit amet
                                                    dui.
                                                    Vestibulum ac
                                                    diam
                                                    sit
                                                    amet quam
                                                    vehicula elementum sed sit amet dui. Curabitur non nulla sit amet
                                                    nisl
                                                    tempus
                                                    convallis
                                                    quis
                                                    ac lectus.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <h5>Topics</h5>
                                                <div className="skillstopic">
                                                    <div>#Build a team</div>
                                                    <div>#Change jobs</div>
                                                    <div>#Foster team culture</div>
                                                    <div>#Get a raise</div>
                                                    <div>#Get your Resume/CV reviewed</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <h5>Skills</h5>
                                                <div className="skillstopic">
                                                    <div> #Product Consulting</div>
                                                    <div>#Product Management</div>
                                                    <div>#How to get high-impact jobs - VCs/Strategy</div>
                                                    <div>#Career Advice</div>
                                                    <div>#CAT Preparation</div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <h5>Education</h5>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>ESCP Business School, Paris, France</h6>
                                                        <span>MIM</span>
                                                        <p>2016 - 2017</p>
                                                    </div>
                                                </div>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>ESCP Business School, Paris, France</h6>
                                                        <span>MIM</span>
                                                        <p>2016 - 2017</p>
                                                    </div>
                                                </div>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>ESCP Business School, Paris, France</h6>
                                                        <span>MIM</span>
                                                        <p>2016 - 2017</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <h5>Work Experience</h5>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>Head of Marketing</h6>
                                                        <span>Sunstone Eduversity</span>
                                                        <p>2021 - 2022</p>
                                                    </div>
                                                </div>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>Head of Marketing</h6>
                                                        <span>Sunstone Eduversity</span>
                                                        <p>2021 - 2022</p>
                                                    </div>
                                                </div>
                                                <div className="skillstopic_education">
                                                    <div className="educationicon">
                                                        <img src={CommonImageTransport?.education} alt="" />
                                                    </div>
                                                    <div>
                                                        <h6>Head of Marketing</h6>
                                                        <span>Sunstone Eduversity</span>
                                                        <p>2021 - 2022</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Student_Coaching_Center