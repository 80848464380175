// import React,{useEffect} from 'react'
// import { useNavigate } from 'react-router-dom';

// const Recruiter_Protected = (props) => {
//     const {Component} = props
//     const navigate = useNavigate();
//     useEffect(() => {
//         const role_id = localStorage.getItem("role_id");
//         if (role_id == "4") {
//             console.log("/recruiter-hiring/RecruiterJobs")
//         } else {
//                 navigate("/recruiter_pages/RecruiterDashboard");
//         }
//     }, [navigate])
    
   
//   return (
//     <div>
//  <Component/>
//     </div>
//   )
// }

// export default Recruiter_Protected
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Recruiter_Protected = (props) => {
    const { Component } = props;
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const role_id = localStorage.getItem('role_id');

        // Redirect to Signin if role_id is not present
        if (!role_id) {
            navigate('/Signin', { replace: true });
            return;
        }

        // Check if the user is a recruiter (role_id === 4)
        if (role_id === '4') {
            // List of authorized recruiter paths
            const allowedRecruiterPaths = [
                '/recruiter_pages/AllProfile',
                '/recruiter_pages/RecruiterJobs',
                '/recruiter_pages/Recruiter_JobPosts',
                '/recruiter_pages/NewProfile',
                '/recruiter_pages/Recuriter_Applied_Profile',
                '/recruiter_pages/ProfileRequest',
                '/recruiter_pages/ProfileResume',
                '/recruiter_pages/RecruiterInterviewing',
                '/recruiter_pages/RecruiterDashboard',
                '/recruiter_pages/RecuriterMyTeam',
                '/recruiter_pages/RecruiterList',
                '/recruiter_pages/Recuriter_Rejected',
                '/recruiter_pages/RecruiterStep',
                '/recruiter_pages/Interview_Guide',
                '/recruiter_pages/recruiter-Profile',
                '/recruiter_pages/Recuriter_Edit_Profile',
                '/recruiter_pages/Recuriter_Reset_Password',
                '/recruiter_pages/Recuriter_Support',
            ];

            // If the current path isn't in the allowed recruiter paths,
            // check for dynamic Interview_Guide_id path
            const isDynamicPath = /^\/recruiter_pages\/\d+$/; // Regex for dynamic path
            const isAllowedPath = allowedRecruiterPaths.includes(location.pathname) || 
                                  isDynamicPath.test(location.pathname);

            if (!isAllowedPath) {
                navigate('/recruiter_pages/RecruiterDashboard');
            }
        } else {
            // If role_id is not 4 (e.g., role 2 or 3), redirect to the respective dashboard
            if (role_id === '2') {
                navigate('/Student/Student_dashBoard');
            } else if (role_id === '3') {
                navigate('/mentor_pages/MentorDashboard');
            }
        }
    }, [navigate, location.pathname]);

    return <Component />;
};

export default Recruiter_Protected;

