import React, { useEffect } from "react";
import Mentor_header from "../mentor_header/Mentor_Header";
import CommonImageTransport from "../../../common/images/CommonImages";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Typewriter from "typewriter-effect";
import {
  MentorBookInprocessComplete,
  MentorSeenStudentList,
} from "../../../../api/Mentor";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Loader from "../../../../loader/Loader";
import { TimeZoneMappings } from "../../../common/timezone/TimeZone";
import Mentor_Footer from "../mentor_footer/Mentor_Footer";

const Mentor_UpComing_Session = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState([]);
  const [recorded_link, setRecorded_link] = useState("");
  const [metting_file, setMetting_File] = useState("");
  const [comment, setComment] = useState("");
  const [is_Loading, setIs_Loading] = useState(false);
  const [meeting_link, setMeeting_link] = useState("");
  const [Changed_meeting_link, setChanged_Meeting_link] = useState(
    meeting_link?.meeting_link
  );
  const [MentorStudentList, setMentorStudentList] = useState([]);
  const [booking_id, setBooking_id] = useState("");
  const [MentorStudentDetails, setMentorStudentDetails] = useState({});
  const [ActiveTabs, setActiveTabs] = useState(MentorStudentList[0]?.id);
  const [showSearchFields, setShowSearchFeilds] = useState(false);
  const mentor_Login_Data = localStorage.getItem("user_Login_Data");
  const mentor_login_parse_data = JSON.parse(mentor_Login_Data);
  const token = localStorage.getItem("User_Token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const HandleActiveTabs = (tabs) => {
    setActiveTabs(tabs);
  };

  // <--------- Complete Popup Modal Function --------->
  const [Completeshow, setCompleteshow] = useState(false);
  const handleCompleteshow = () => setCompleteshow(true);
  const handleCompleteClose = () => setCompleteshow(false);

  // <--------- Complete Popup Modal Function --------->
  const [EditMeetingShow, setEditMeetingShow] = useState(false);
  const handleEditMeetingShow = () => setEditMeetingShow(true);
  const handleEditMeetingClose = () => setEditMeetingShow(false);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredList = MentorStudentList?.filter(
    (item) =>
      item?.fname?.toLowerCase().includes(searchTerm) ||
      item?.lname?.toLowerCase().includes(searchTerm) ||
      item?.email?.toLowerCase().includes(searchTerm) ||
      item?.country?.toLowerCase().includes(searchTerm) ||
      item?.state?.toLowerCase().includes(searchTerm) ||
      item?.city?.toLowerCase().includes(searchTerm)
  );

  // <----------  the Complete Slot ------------->
  const handleCompleteSlot = async () => {
    const commentLengthWithoutSpaces = comment.replace(/\s/g, "").length;

    if (!recorded_link) {
      toast.error("Please provide the recorded link.");
      return;
    }

    // if (!metting_file) {
    //   toast.error("Please upload the meeting file.");
    //   return;
    // }

    if (commentLengthWithoutSpaces < 300) {
      toast.error("Notes must be at least 300 characters (excluding spaces).");
      return;
    }

    const fromData = new FormData();
    fromData.append("recorded_link", recorded_link);
    fromData.append("comment", comment);
    fromData.append("status", 3);
    fromData.append("id", booking_id);
    fromData.append("metting_file", metting_file);

    const response = await MentorBookInprocessComplete(fromData, headers);
    if (response?.data?.msg === "Booking status updated successfully.") {
      toast.success("Booking status updated successfully.");
      setTimeout(() => {
        handleCompleteClose();
        handleMentorSeenStudentList();
      }, 500);

      setTimeout(() => {
        navigate("/mentor_pages/CoachingMentor");
      }, 1000);
    } else if (response?.data?.msg === "Invalid Booking.") {
      toast.error("Invalid Booking !!!");
      setTimeout(() => {
        handleCompleteClose();
      }, 2000);
    }
  };

  // <------------- Edit Meeting Link ----------->
  const handleEditMeetingSlot = async () => {
    const MentorBookCompletedata = {
      meeting_link: Changed_meeting_link,
      status: 2,
      id: meeting_link?.id,
    };
    const response = await MentorBookInprocessComplete(
      MentorBookCompletedata,
      headers
    );
    if (response?.data?.msg === "Booking status updated successfully.") {
      toast.success("Booking status updated successfully.");
      setTimeout(() => {
        handleEditMeetingClose();
      }, 1000);
      setTimeout(() => {
        handleMentorSeenStudentList();
      }, 2000);
    }
  };

  // <--------------- Mentor Upcoming Sessions ----------------->
  const handleMentorSeenStudentList = async () => {
    setIs_Loading(true);
    try {
      const MentorSeenStudentListData = {
        status: 2,
      };
      const response = await MentorSeenStudentList(
        MentorSeenStudentListData,
        headers
      );
      setMentorStudentList(response?.data?.data?.booking);
      handleMentorSeenStudentDetails(response?.data?.data?.booking[0]?.id);
      setMeeting_link(response?.data?.data?.booking[0]);
      setChanged_Meeting_link(response?.data?.data?.booking[0]?.meeting_link);
      setIs_Loading(false);
    } catch (error) {
      setIs_Loading(true);
    }
  };

  const getFullTimeZoneName = (abbreviation) => {
    return TimeZoneMappings[abbreviation] || abbreviation;
  };
  // <----------- Student Profile Details & Mentor Booking details ----------->
  const handleMentorSeenStudentDetails = async (id) => {
    try {
      const MentorSeenStudentListData = {
        status: 2,
        id: id ? id : ActiveTabs,
      };
      const response = await MentorSeenStudentList(
        MentorSeenStudentListData,
        headers
      );
      const studentData = response?.data?.data;
      if (!studentData) {
        throw new Error("No student data received");
      }
      setBooking_id(studentData?.booking?.id);

      // Retrieve dynamic time zones
      const mentorTimeZone = getFullTimeZoneName(
        mentor_login_parse_data?.time_zone
      );
      console.log("mentorTimeZone", mentorTimeZone);

      // Assuming studentTimeZone could be 'IST' or another valid timezone
      let studentTimeZone = getFullTimeZoneName(
        studentData?.booking?.slot_time_zone
      );
      console.log("studentTimeZone", studentTimeZone);

      if (
        !mentorTimeZone ||
        !studentTimeZone ||
        !studentData?.booking?.slot_time
      ) {
        throw new Error("Missing time zone or slot time data");
      }
      const slotStrings = studentData.booking.slot_time
        .split(",")
        ?.map((slot) => slot.trim());
      const referenceDate = moment().format("YYYY-MM-DD");

      const convertedSlots = slotStrings?.map((slot) => {
        const [startTimeStr, endTimeStr] = slot.split(" To ");

        try {
          const startTime = moment.tz(
            `${referenceDate} ${startTimeStr}`,
            "YYYY-MM-DD h:mm A",
            studentTimeZone
          );
          const endTime = moment.tz(
            `${referenceDate} ${endTimeStr}`,
            "YYYY-MM-DD h:mm A",
            studentTimeZone
          );
          const startTimeMentor = startTime
            .clone()
            .tz(mentorTimeZone)
            .format("h:mm A");
          const endTimeMentor = endTime
            .clone()
            .tz(mentorTimeZone)
            .format("h:mm A");

          return `${startTimeMentor} To ${endTimeMentor}`;
        } catch (error) {
          console.error(`Error converting time slot ${slot}:`, error);
          return slot;
        }
      });
      const updatedStudentData = {
        ...studentData,
        booking: {
          ...studentData.booking,
          slot_time: convertedSlots,
        },
      };
      setMentorStudentDetails(updatedStudentData);
    } catch (error) {
      console.error("Error fetching mentor details:", error);
    }
  };
  console.log("djfgsdjgfjsgfjsdg", MentorStudentDetails?.booking?.slot_time)

  const handleMentorSeenStudentById = (id) => {
    setActiveTabs(id);
    handleMentorSeenStudentDetails(id);
  };

  useEffect(() => {
    handleMentorSeenStudentList();
    handleMentorSeenStudentById();
  }, []);

  useEffect(() => {
    if (
      filteredList?.length > 0 &&
      (!ActiveTabs || !filteredList?.some((job) => job.id === ActiveTabs))
    ) {
      const firstActiveTab = filteredList[0];
      setActiveTabs(firstActiveTab?.id);
      handleMentorSeenStudentDetails(firstActiveTab?.id);
      setMentorStudentDetails();
    }
  }, [filteredList, ActiveTabs]);

  const [isSlotTimePast, setIsSlotTimePast] = useState(false);

  useEffect(() => {
    const checkIfSlotTimePast = () => {
      if (MentorStudentDetails?.booking?.bookig_date && MentorStudentDetails?.booking?.slot_time) {
        // Parse booking date
        const bookingDate = moment(MentorStudentDetails.booking.bookig_date);
  
        // Check if slot_time is a string and parse it, otherwise assume it's already an array
        let slotTimes;
        if (typeof MentorStudentDetails.booking.slot_time === "string") {
          try {
            slotTimes = JSON.parse(MentorStudentDetails.booking.slot_time.replace(/\\/g, ''));
          } catch (error) {
            console.error("Failed to parse slot_time as JSON:", error);
            return;
          }
        } else if (Array.isArray(MentorStudentDetails.booking.slot_time)) {
          slotTimes = MentorStudentDetails.booking.slot_time;
        } else {
          console.error("Unexpected format for slot_time");
          return;
        }
        const latestSlotTimeStr = slotTimes[slotTimes.length - 1].split(" To ")[1]; 
        const latestSlotDateTime = moment(`${bookingDate.format("YYYY-MM-DD")} ${latestSlotTimeStr}`, "YYYY-MM-DD h:mm A");
        setIsSlotTimePast(moment().isAfter(latestSlotDateTime));
      }
    };
  
    checkIfSlotTimePast();
  }, [MentorStudentDetails]);
  

  return (
    <div>
      <div className="bg_nav-slid bg-white">
        <Mentor_header />
      </div>
      <div className="header_space"></div>
      <div className="recruiter_list pb-5 pt-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ul className="nav tabs_signup hiredpppp justify-content-start gap-2 mb-3">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/mentor_pages/Mentor_Student_Profile"
                  >
                    All Profile
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    to="/mentor_pages/Mentor_UpComing_Session"
                  >
                    Upcoming Sessions
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    aria-current="page"
                    to="/mentor_pages/CoachingMentor"
                  >
                    Completed Sessions
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {is_Loading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              {MentorStudentList?.length > 0 ? (
                <>
                  <div className="col-12">
                    <form>
                      <div className="searchlocation">
                        <div className="iconfield">
                          <div className="icon_search">
                            <img
                              src={CommonImageTransport?.searchicon}
                              alt=""
                            />
                          </div>
                          {!showSearchFields ? (
                            <div onClick={() => setShowSearchFeilds(true)}>
                              <Typewriter
                                options={{
                                  loop: true,
                                }}
                                onInit={(typewriter) => {
                                  typewriter
                                    .typeString("Search by title")
                                    .callFunction(() => { })
                                    .pauseFor(2500)
                                    .deleteAll()
                                    .callFunction(() => { })
                                    .typeString("Search by location")
                                    .pauseFor(2500)
                                    .start();
                                }}
                              />
                            </div>
                          ) : (
                            <div>
                              <input
                                type="text"
                                placeholder=""
                                onChange={handleSearchChange}
                                style={{ border: "none", outline: "none" }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="iconfield">
                          <div className="icon_search">
                            <img
                              src={CommonImageTransport?.locationicon}
                              alt=""
                            />
                          </div>
                          <div className="input-field">
                            <input
                              type="text"
                              placeholder="USA, Florida"
                              onChange={handleSearchChange}
                              className="form-control"
                              required=""
                            />
                          </div>
                        </div>
                        <div className="input-field ad121">
                          <button type="submit" className="submit_btn">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="left_recruiterlists row">
                    <div className="col-md-4 col-lg-4">
                      <div className="sticky-header">
                        <div
                          className="nav flex-column recruiter_all_lists nav-pills"
                          id="v-pills-tab"
                          role="tablist"
                          aria-orientation="vertical"
                        >
                          {filteredList?.map(
                            (MentorStudentListResult, index) => {
                              return (
                                <>
                                  <div
                                    key={index}
                                    className={`nav-link ${ActiveTabs === MentorStudentListResult?.id
                                      ? "active"
                                      : ""
                                      }`}
                                    onClick={() => {
                                      HandleActiveTabs(
                                        MentorStudentListResult?.id
                                      );
                                      handleMentorSeenStudentById(
                                        MentorStudentListResult?.id
                                      );
                                    }}
                                    id="v-pills-home-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-home"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-home"
                                    aria-selected="true"
                                  >
                                    <div className="card_rec position-relative">
                                      <div className="mentersvies0121">
                                        <div className="position-relative">
                                          {MentorStudentListResult?.profileimg !=
                                            null ? (
                                            <>
                                              <img
                                                src={
                                                  MentorStudentListResult?.profileimg
                                                }
                                                className="img-fluid rounded"
                                                alt="Instructor"
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                src={
                                                  CommonImageTransport?.userLogo
                                                }
                                                className="img-fluid rounded"
                                                alt="Instructor"
                                              />
                                            </>
                                          )}

                                          <span className="live_user bg-secondary newuser">
                                            Upcoming
                                          </span>
                                        </div>
                                        <div className="instructors-details-contents">
                                          <div className="d-flex justify-content-between gap-2">
                                            <div className="comnam">
                                              <h3>
                                                {MentorStudentListResult?.fname}
                                              </h3>
                                              <h6 className="sub-title ">
                                                Email :
                                                {MentorStudentListResult?.email}
                                              </h6>
                                              {/* <h6 className="sub-title">Phone :{MentorStudentListResult?.countrycode} {MentorStudentListResult?.mobile}</h6> */}
                                              <p className="sub-title">
                                                {
                                                  MentorStudentListResult?.p_title
                                                }
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                    {filteredList?.find((job) => job.id === ActiveTabs) && (
                      <>
                        <div className="col-md-8 col-lg-8">
                          {MentorStudentDetails && (
                            <div
                              className="tab-content"
                              id="v-pills-tabContent"
                            >
                              <div
                                className={`tab-pane fade ${ActiveTabs ? "show active" : ""
                                  }`}
                                id={`v-pills-${ActiveTabs}`}
                                role="tabpanel"
                                aria-labelledby={`v-pills-${ActiveTabs}-tab`}
                              >
                                <div className="instructors-details-img m-0">
                                  <div className="card-instrutors shadow position-relative bg-white p-4">
                                    <div className="mentersvies0121">
                                      <div className="position-relative menter_pro">
                                        {MentorStudentDetails?.student_info
                                          ?.profileimg != null ? (
                                          <>
                                            <img
                                              src={
                                                MentorStudentDetails
                                                  ?.student_info?.profileimg
                                              }
                                              className="rounded_01top"
                                              alt="Instructor"
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              src={
                                                CommonImageTransport?.userLogo
                                              }
                                              className="rounded_01top"
                                              alt="Instructor"
                                            />
                                          </>
                                        )}

                                        <span className="live_user bg-secondary newuser">
                                          Upcoming
                                        </span>
                                      </div>
                                      <div className="instructors-details-contents mt-3">
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <h3>
                                              {
                                                MentorStudentDetails
                                                  ?.student_info?.fname
                                              }
                                            </h3>
                                            <h6 className="sub-title mb-2">
                                              Email:{" "}
                                              {
                                                MentorStudentDetails
                                                  ?.student_info?.email
                                              }
                                            </h6>
                                            {/* <h6 className="sub-title mb-2">Phone: {MentorStudentDetails?.student_info?.countrycode} {MentorStudentDetails?.student_info?.mobile}</h6> */}
                                            <h6 className="sub-title mb-2">
                                              {
                                                MentorStudentDetails
                                                  ?.student_info?.p_title
                                              }
                                            </h6>
                                          </div>
                                          <div className="bookbtnas d-flex align-items-center gap-2">
                                            <a
                                              href="#"
                                              className="btn btn-success btn-sm rounded"
                                              data-bs-toggle="modal"
                                              data-bs-target="#feedback"
                                            >
                                              Accepted ✓
                                            </a>

                                            <div>
                                              {/* Conditional rendering for the Complete button */}
                                              {isSlotTimePast ? (
                                                <a
                                                  href="#"
                                                  className="btn btn-primary btn-sm rounded"
                                                  onClick={() => {
                                                    handleCompleteshow();
                                                    handleMentorSeenStudentById(MentorStudentDetails?.student_detail?.id);
                                                  }}
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#completedmodal"
                                                >
                                                  Complete
                                                </a>
                                              ) : null}
                                            </div>

                                            {/* <!-- Complete modal --> */}
                                            <Modal
                                              size="lg"
                                              className="modal fade"
                                              id="feedback"
                                              tabindex="-1"
                                              aria-labelledby="exampleModalLabel"
                                              aria-hidden="true"
                                              show={Completeshow}
                                              onHide={handleCompleteClose}
                                            >
                                              <ToastContainer
                                                style={{ marginTop: "50px" }}
                                              />
                                              <Modal.Body>
                                                <div className="modal-header">
                                                  <h5
                                                    className="modal-title"
                                                    id="exampleModalLabel"
                                                  >
                                                    Meeting
                                                  </h5>
                                                  <button
                                                    type="button"
                                                    onClick={
                                                      handleCompleteClose
                                                    }
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                  ></button>
                                                </div>
                                                <form method="post">
                                                  <div className="modal-body">
                                                    <div className="row">
                                                      <div className="col-12 col-md-12">
                                                        <div className="form-group mb-2 myteamform">
                                                          <lable>
                                                            Recorded link
                                                          </lable>
                                                          <input
                                                            type="text"
                                                            name="recorded_link"
                                                            placeholder="https://meet.google.com/odp-nvra-ycf"
                                                            value={
                                                              recorded_link
                                                            }
                                                            onChange={(e) =>
                                                              setRecorded_link(
                                                                e.target.value
                                                              )
                                                            }
                                                            className="form-control"
                                                            required
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="col-12 col-md-12">
                                                        <div className="form-group mb-2 myteamform">
                                                          <lable>
                                                            Upload file
                                                          </lable>
                                                          <input
                                                            type="file"
                                                            name="metting_file"
                                                            placeholder=""
                                                            onChange={(e) =>
                                                              setMetting_File(
                                                                e.target
                                                                  .files[0]
                                                              )
                                                            }
                                                            className="form-control"
                                                            required
                                                          />
                                                        </div>
                                                      </div>

                                                      <div className="col-12 col-md-12">
                                                        <div className="form-group mb-2 myteamform">
                                                          <lable>Notes</lable>
                                                          <textarea
                                                            type="file"
                                                            name="comment"
                                                            value={comment}
                                                            onChange={(e) =>
                                                              setComment(
                                                                e.target.value
                                                              )
                                                            }
                                                            placeholder=""
                                                            className="form-control custom-textarea" // Apply custom class here
                                                            required
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="dd-footer d-flex gap-3 p-3 border-top">
                                                    <button
                                                      type="button"
                                                      onClick={
                                                        handleCompleteSlot
                                                      }
                                                      className="btn default-btn w-100"
                                                    >
                                                      Submit
                                                    </button>
                                                  </div>
                                                </form>
                                              </Modal.Body>
                                            </Modal>
                                          </div>
                                        </div>
                                        <p>
                                          {
                                            MentorStudentDetails?.student_detail
                                              ?.company
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="card-instrutors rounded shadow position-relative mt-3 bg-white p-4">
                                    <div className="card-body p-0">
                                      <div className="d-flex justify-content-between align-items-start gap-5">
                                        <div>
                                          <h6>Avaliable Time</h6>
                                          <div className="skillstopic">
                                            <div className="fw-bold bg-light text-primary">
                                              Date:
                                            </div>
                                            <div>
                                              {moment(
                                                MentorStudentDetails?.booking
                                                  ?.bookig_date
                                              ).format("YYYY-MM-DD")}
                                            </div>
                                            <div className="fw-bold bg-light text-primary">
                                              Day:
                                            </div>
                                            <div>
                                              {
                                                MentorStudentDetails?.booking
                                                  ?.booking_day
                                              }
                                            </div>
                                            <div className="fw-bold bg-light text-primary">
                                              Time:
                                            </div>
                                            <div>
                                              {" "}
                                              {MentorStudentDetails?.booking?.slot_time?.map(
                                                (slot, index, array) => (
                                                  <span key={index}>
                                                    {slot}
                                                    {index < array.length - 1 &&
                                                      ", "}
                                                  </span>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-instrutors rounded shadow position-relative mt-3 bg-white p-4">
                                    <div className="card-body p-0">
                                      <div className="d-flex justify-content-between align-items-start gap-5">
                                        <div>
                                          <h6>Meeting Link:</h6>
                                          <div className="fw-bold bg-light text-primary">
                                            <a
                                              className="text-primary"
                                              target="_blank"
                                              href={
                                                MentorStudentDetails?.booking
                                                  ?.meeting_link
                                              }
                                            >
                                              {
                                                MentorStudentDetails?.booking
                                                  ?.meeting_link
                                              }
                                            </a>
                                          </div>
                                        </div>
                                        <div className="bookbtnas">
                                          <Link
                                            to="#"
                                            className="btn btn-primary rounded"
                                            data-bs-toggle="modal"
                                            data-bs-target="#bookinterview"
                                            onClick={() =>
                                              handleEditMeetingShow(
                                                MentorStudentList[ActiveTabs]
                                                  ?.id
                                              )
                                            }
                                          >
                                            Edit
                                          </Link>
                                        </div>

                                        {/* <----------- Edit Meeting ----------------> */}
                                        <Modal
                                          size="lg"
                                          className="modal fade mt-5"
                                          id="feedback"
                                          tabindex="-1"
                                          aria-labelledby="exampleModalLabel"
                                          aria-hidden="true"
                                          show={EditMeetingShow}
                                          onHide={handleEditMeetingClose}
                                        >
                                          <ToastContainer
                                            style={{ marginTop: "50px" }}
                                          />
                                          <Modal.Body>
                                            <div className="modal-header">
                                              <h5
                                                className="modal-title"
                                                id="exampleModalLabel"
                                              >
                                                Meeting
                                              </h5>
                                              <button
                                                type="button"
                                                onClick={handleEditMeetingClose}
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                              ></button>
                                            </div>
                                            <form method="post">
                                              <div className="modal-body">
                                                <div className="row">
                                                  <div className="col-12 col-md-12">
                                                    <div className="form-group mb-2 myteamform">
                                                      <lable>
                                                        Meeting link
                                                      </lable>
                                                      <input
                                                        type="text"
                                                        name="meeting_link"
                                                        placeholder="https://meet.google.com/odp-nvra-ycf"
                                                        value={
                                                          Changed_meeting_link
                                                        }
                                                        onChange={(e) =>
                                                          setChanged_Meeting_link(
                                                            e.target.value
                                                          )
                                                        }
                                                        className="form-control"
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="dd-footer d-flex gap-3 p-3 border-top">
                                                <button
                                                  type="button"
                                                  onClick={
                                                    handleEditMeetingSlot
                                                  }
                                                  className="btn default-btn w-100"
                                                >
                                                  Submit
                                                </button>
                                              </div>
                                            </form>
                                          </Modal.Body>
                                        </Modal>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <------- Skills sections ------------> */}
                                <div className="instructors-details-contents mt-3">
                                  <div className="card-instrutors shadow bg-white p-4">
                                    <h5>Skills</h5>
                                    <div className="skillstopic">
                                      {MentorStudentDetails?.student_skills?.map(
                                        (StudentSkillsResult) => {
                                          return (
                                            <>
                                              <div>
                                                {StudentSkillsResult?.skill}
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {/* <------- Education sections ------------> */}
                                <div className="instructors-details-contents mt-3">
                                  <div className="card-instrutors shadow bg-white p-4">
                                    <h5>Education</h5>
                                    {MentorStudentDetails?.student_educations?.map(
                                      (StudentEductionResult) => {
                                        return (
                                          <>
                                            <div className="skillstopic_education">
                                              <div className="educationicon">
                                                <img
                                                  src={
                                                    CommonImageTransport?.education
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                              <div>
                                                <h6>
                                                  {
                                                    StudentEductionResult?.university
                                                  }
                                                </h6>
                                                <span>
                                                  {
                                                    StudentEductionResult?.degreetype
                                                  }
                                                </span>
                                                <p>
                                                  {moment(
                                                    StudentEductionResult?.startdate
                                                  ).format("YYYY")}{" "}
                                                  -{" "}
                                                  {moment(
                                                    StudentEductionResult?.enddate
                                                  ).format("YYYY")}
                                                </p>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                                {/* <---- Work Experience Sections -------------> */}
                                {MentorStudentDetails?.student_detail
                                  ?.p_title != null && (
                                    <div className="instructors-details-contents mt-3">
                                      <div className="card-instrutors shadow bg-white p-4">
                                        <h5>Work Experience</h5>
                                        <div className="skillstopic_education">
                                          <div className="educationicon">
                                            <img
                                              src={
                                                CommonImageTransport?.education
                                              }
                                              alt=""
                                            />
                                          </div>
                                          <div>
                                            <h6>
                                              {
                                                MentorStudentDetails
                                                  ?.student_detail?.p_title
                                              }
                                            </h6>
                                            <span>
                                              {
                                                MentorStudentDetails
                                                  ?.student_detail?.company
                                              }
                                            </span>
                                            <p>
                                              {moment(
                                                MentorStudentDetails
                                                  ?.student_detail?.start_date
                                              ).format("YYYY")}{" "}
                                              -{" "}
                                              {moment(
                                                MentorStudentDetails
                                                  ?.student_detail?.end_date
                                              ).format("YYYY")}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <div className="text-center mt-5">
                  <h2>Not Avaliable Upcoming session here...</h2>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Mentor_Footer />
    </div>
  );
};

export default Mentor_UpComing_Session;
