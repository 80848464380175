import React,{useState} from 'react'
import CommonImageTransport from '../../components/common/images/CommonImages'
import Header from '../../components/common/header/Header'
import {ChangePassword} from "../../api/Auth"
import { useNavigate } from 'react-router-dom'
const NewPassword = () => {
    const navigate = useNavigate()
const [old_password,setOld_password] = useState("")
const [password,setPassword] = useState("")
const PasswordChange = async (e) => {

    e.preventDefault();
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };
    const passwordData = {
        password: password,
        old_password: old_password
    };
    try {
        const response = await ChangePassword(passwordData, headers);
        if(response.data.statusCode == "200"){
            alert("Password Update Successfully !!!")
            navigate("/Signin")
        }
    } catch (error) {
        console.log("error",error)
    }
};


  return (
    <div className='login-signup-bg' >
        <Header/>
      <div className="user-area p-2 p-md-4 p-lg-5 pt-lg-3">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 p-0">
                    <div className="user_login">
                        <img src={CommonImageTransport.loginbg} className="imgk-fluid w-100" alt="login-banner" />
                    </div>
                </div>
                <div className="col-lg-6 d-flex p-0">
                    <div className="user-all-form userlogin position-relative">
                        <div className="login_bottom">
                            <img src={CommonImageTransport.loginBottom} alt="" />
                        </div>
                        <div className="contact-form m-0 py-0">
                            <h3 className="user-title"> Create New Password</h3>

                            <form id="contactForms" className="mt-2" onSubmit={PasswordChange}>
                                <div className="row">
                                  
                                    <div className="col-12 col-lg-12">
                                        <div className="form-floating form-group">
                                            <input type="password" className="form-control"
                                            name="old_password" value={old_password} 
                                            onChange={(e)=>setOld_password(e.target.value)}
                                             id="floatingPassword"
                                                placeholder="xxxxxxxx" />
                                            <label htmlFor="floatingPassword">Current Password</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-12">
                                        <div className="form-floating form-group">
                                            <input type="password" className="form-control" 
                                            name="password" value={password} 
                                            onChange={(e)=>setPassword(e.target.value)}
                                            id="floatingPassword"
                                                placeholder="xxxxxxxx" />
                                            <label htmlFor="floatingPassword">New Password</label>
                                        </div>
                                    </div>
                                   
                                  
                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn w-100 rounded">
                                            SAVE
                                        </button>

                                      
                                    </div>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default NewPassword