import React from 'react'
import { Link } from 'react-router-dom'
import Student_Header from '../student_header/Student_Header';
import CommonImageTransport from '../../../common/images/CommonImages';
import Student_Common_Profile_section from '../student_common_profile_section/Student_Common_Profile_section';
const MyCourses = () => {
  return (
    <div>
      <Student_Header />
      <div className="stepbg">
        <div className="client_filter-job pt-2 pb-5">
          <div className="stepsForm">
            <form method="post" id="msform">
              <div className="container">
                <div className="row">
                <div className='col-md-4'>
                <Student_Common_Profile_section/>
                </div>
                  <div className="col-md-8 d-flex">
                    <div className="flexdr position-relative p-3 p-md-4 shadow-lg">
                      <div className="personal_user_info">
                        <div className="border-bottom">
                          <h4>My Courses </h4>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="videoviews">
                              <Link to="/MyCoursesDetails">
                                <img src={CommonImageTransport?.video1} className="img-fluid" alt="" />
                                <h6>Web Programming</h6>
                              </Link>
                              <ul class="course-list nav">
                                <li><i class="ri-time-fill"></i> 10 hr 07 min</li>
                                <li><i class="ri-calendar-fill"></i> 18/04/2024</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="videoviews">
                              <Link to="/MyCoursesDetails">
                                <img src={CommonImageTransport?.video2} className="img-fluid" alt="" />
                                <h6>Building Successful Business</h6>
                              </Link>
                              <ul class="course-list nav">
                                <li><i class="ri-time-fill"></i> 10 hr 07 min</li>
                                <li><i class="ri-calendar-fill"></i> 18/04/2024</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="videoviews">
                              <Link to="/MyCoursesDetails">
                                <img src={CommonImageTransport?.video3} className="img-fluid" alt="" />
                                <h6>English Pro Course</h6>
                              </Link>
                              <ul class="course-list nav">
                                <li><i class="ri-time-fill"></i> 10 hr 07 min</li>
                                <li><i class="ri-calendar-fill"></i> 18/04/2024</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>

  )
}

export default MyCourses