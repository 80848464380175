import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Student_Protected = (props) => {
    const { Component } = props;
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const role_id = localStorage.getItem('role_id');

        if (!role_id) {
            // Navigate to Signin if role_id is not present
            navigate('/Signin', { replace: true });
            return;  // Stop further execution
        }

        if (role_id === '2') {
            // Allowed student paths (without dynamic params)
            const allowedStudentPaths = [
                '/Student_step',
                '/Student/Student_jobs',
                '/Student/Student_Resume',
                '/Student/Student_dashBoard',
                '/Student/Student_Traning_Program',
                '/Student/Student_Coaching_Center',
                '/Student/Student_Mentor_List',
                '/Student/Student_Upcoming_Mentor_list',
                '/Student/Student_Mentor_Complate_session',
                '/Student/Student_Coaching_Session_Complete', // Base path without dynamic ID
                '/Student/Student_Profile',
                '/Student/Student_Edit_Profile',
                '/Student/Student_Change_Password',
                '/Student/Student_Applied_Job',
                '/Student/Student_Support',
                '/Student/MyCourses',
                '/MyCoursesDetails',
                '/CourseVideoInfo',
            ];

            const currentPath = location.pathname;
            
            // Regex to match dynamic paths like '/Student/Student_Coaching_Session_Complete/:course_id'
            const dynamicPathRegex = new RegExp('^/Student/Student_Coaching_Session_Complete/\\d+$');

            // Check if current path is allowed or matches a dynamic route
            const isAllowed = allowedStudentPaths.some((allowedPath) => {
                return currentPath === allowedPath || dynamicPathRegex.test(currentPath);
            });

            if (!isAllowed) {
                navigate('/Student/Student_dashBoard', { replace: true });
            }
        } else {
            // Handle non-student roles
            if (role_id === '3') {
                navigate('/mentor_pages/MentorDashboard', { replace: true });
            } else if (role_id === '4') {
                navigate('/recruiter_pages/RecruiterDashboard', { replace: true });
            } else {
                // Fallback to Signin if the role_id is invalid
                navigate('/Signin', { replace: true });
            }
        }
    }, [navigate, location.pathname]);

    return <Component />;
};

export default Student_Protected;
