import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GetSetings } from "../../../../api/Public";
import "react-toastify/dist/ReactToastify.css";

const Mentor_Footer = () => {
  const [SettingList, setSettingList] = useState({});

  const HandleGetSettingList = async () => {
    const response = await GetSetings();
    setSettingList(response?.data?.data);
  };

  useEffect(() => {
    HandleGetSettingList();
  }, []);
  console.log("HandleGetSettingList", SettingList);

  return (
    <footer class="footer-area">
      <div class="container pt-5">
        <div class="row">
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget">
              <p>{SettingList?.shortdesc}</p>
              <ul class="social-link">
                <li class="social-title">Follow Us:</li>
                <li>
                  <Link to={SettingList?.facebook} target="_blank">
                    <i class="ri-facebook-fill"></i>
                  </Link>
                </li>
                <li>
                  <Link to={SettingList?.twitter} target="_blank">
                    <i class="ri-twitter-fill"></i>
                  </Link>
                </li>
                <li>
                  <Link to={SettingList?.instagram} target="_blank">
                    <i class="ri-instagram-line"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget ps-5">
              <h3>About us</h3>
              <ul class="footer-list">
                <li>
                  <Link to="/mentor_pages/MentorDashboard">Dashboard</Link>
                </li>

                <li>
                  <Link to="/mentor_pages/Mentor_Resume_Profile">Profile</Link>
                </li>
                <li>
                  <Link to="/mentor_pages/CoachingMentor">Coaching Center</Link>
                </li>
             

              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget ps-5">
              <h3>Resources</h3>
              <ul class="footer-list">
                <li style={{ zIndex: 99 }}>
                  <Link to="/mentor_pages/Mentor_Booking">Availability</Link>
                </li>
                <li>
                  <Link to="/mentor_pages/Mentor_Support">Support</Link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget ps-5">
              <h3>Official Info</h3>
              <ul class="footer-contact">
                <li>
                  <i class="ri-map-pin-2-fill"></i>
                  <div class="content">
                    <h4>Location:</h4>
                    <span>{SettingList?.officeaddress}</span>
                  </div>
                </li>
                <li>
                  <i class="ri-mail-fill"></i>
                  <div class="content">
                    <h4>Email:</h4>
                    <span>
                      <Link
                        to={`mailto:${SettingList?.email}?subject=Application%20for%20Job&body=I%20am%20interested%20in%20applying%20for%20a%20job.`}>
                        <span className="__cf_email__">
                          {SettingList?.email}
                        </span>
                      </Link>
                    </span>
                  </div>
                </li>
                <li>
                  <i class="ri-phone-fill"></i>
                  <div class="content">
                    <h4>Phone:</h4>
                    <span>
                      <a href="tel:+11234567890">
                        {SettingList?.countrycode} {SettingList?.mobileno}
                      </a>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright-area">
        <div class="container">
          <div class="copy-right-text text-center">
            <p>
              Copyright @
              <script>document.write(new Date().getFullYear())</script>{" "}
              <b>FreshGard</b> All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Mentor_Footer;
