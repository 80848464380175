import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment';
import { ProfileSections } from "../../../../api/Auth"
import Recruiter_Common_Profile from '../recruiter_common_profile/Recruiter_Common_Profile';
import Recruiter_Header from '../recruiter_header/Recruiter_Header';

const Recruiter_Profile = () => {
    const [GetUserProfile, SetGetUserProfile] = useState([])
    const user_id = localStorage.getItem("User_id")
    // <----------- User's token ----------------->
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    }
    // <----------- Recuriter Profile sections ------------->
    const ProfileSections1 = async () => {
        const response = await ProfileSections({ user_id, headers })
        SetGetUserProfile(response?.data?.data?.profile)
    }
    //  <-----------Render the Recuriter Profile sections ------------->
    useEffect(() => {
        ProfileSections1();
    }, [])


    useEffect(() => {
        const isFirstRender = sessionStorage.getItem('isFirstRender');
      
        if (!isFirstRender) {
          // Set flag in sessionStorage to indicate that the page has been loaded once
          sessionStorage.setItem('isFirstRender', 'true');
      
          // Reload the page once
          window.location.reload();
        }
      }, []);
    return (
        <div>
            <div className="stepbg">
                {/* <--------- Recuriter Header ----------> */}
                <Recruiter_Header />
                <div className="client_filter-job py-5 pt-2">
                    <div className="stepsForm">
                        <form method="post" id="msform">
                            <div className="container">
                                <div className="row">
                                    <div className='col-md-4'>
                                        {/* <--------- Recruiter Common Profile ----------> */}
                                        <Recruiter_Common_Profile />
                                    </div>
                                    <div className="col-md-8 d-flex">
                                        <div className="flexdr position-relative p-3 p-md-4 shadow-lg">
                                            <div className="personal_user_info border p-4 rounded">
                                                <div className="d-flex justify-content-between">
                                                    <h4>Personal Information</h4>
                                                    <Link to="/recruiter_pages/Recuriter_Edit_Profile"
                                                        className="default-btn rounded p-2">Edit <i className="ri-edit-2-fill" /></Link>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group info__profile">
                                                            <p>First Name</p>
                                                            <h6>{GetUserProfile?.fname}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group info__profile">
                                                            <p>Last Name</p>
                                                            <h6>{GetUserProfile?.lname}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group info__profile">
                                                            <p>Email ID</p>
                                                            <h6>{GetUserProfile?.email}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group info__profile">
                                                            <p>Phone Number</p>
                                                            <h6>{GetUserProfile?.mobile?.replace(/null/g, '')}</h6>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-6">
                                                        <div className="form-group info__profile">
                                                            <p>Dob</p>
                                                            <h6>
                                                                {moment(GetUserProfile?.dob).format("DD-MM-YYYY")}
                                                            </h6>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="personal_user_info mt-4 border p-4 rounded">
                                                <div className="d-flex justify-content-between">
                                                    <h4>Address</h4>
                                                    <Link to="/recruiter_pages/Recuriter_Edit_Profile" className="default-btn rounded p-2">Edit <i className="ri-edit-2-fill" /></Link>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group info__profile">
                                                            <p>Country</p>
                                                            <h6>{GetUserProfile?.country}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group info__profile">
                                                            <p>State</p>
                                                            <h6>{GetUserProfile?.state}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group info__profile">
                                                            <p>City</p>
                                                            <h6>{GetUserProfile?.city}</h6>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-6">
                                                        <div className="form-group info__profile">
                                                            <p>Nationlity</p>
                                                            <h6>{GetUserProfile?.citizen}</h6>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Recruiter_Profile