import React, { useState } from 'react'
import CommonImageTransport from '../../components/common/images/CommonImages'
import { Link, useNavigate } from 'react-router-dom'
import OtpInput from 'react-otp-input';
import Header from '../../components/common/header/Header'
import { OtpVerify, SendOtp } from '../../api/Auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';

const ForgotOtp = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const Reset_password_User_Email = localStorage.getItem("Reset_password_User_Email");

// <-------- Otp verify function ------------->
    const OtpVerify1 = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            const SendOtpVerifyData = {
                type: "resetpassword",
                email: Reset_password_User_Email,
                otp
            };
            const response = await OtpVerify(SendOtpVerifyData)
            console.log("OtpVerify1",response?.data?.ms)
            if (response.data.data.isOtpMatch == true) {
                setIsLoading(false)
                setTimeout(()=>{
                    toast.success("Otp Verify Successfully !!!")
                },1000)
                setTimeout(() => {
                    navigate("/ForgotPassword")
                }, 2000)
            }
            if (response.data.statusCode == '400') {
                setIsLoading(false);
                setTimeout(()=>{
                    toast.error(response.data.msg);
                },1000)
            }
        } catch (error) {
            setIsLoading(false)
            console.log("Error:", error);
        }
    };

     // <----------- Resend Otp ------------>
     const ResendOTP = async () => {
        const ResndOtpData = {
            type: "resetpassword",             //login,signup,resetpassword
            email: Reset_password_User_Email
        }
        try {
            const response = await SendOtp(ResndOtpData)
            console.log("ResendOTP", response)
            if (response?.data?.statusCode == "200") {
                toast.success("Otp send on your email, please check.")
            }
        } catch (error) {
            console.log("error", error)
        }
    }
    return (

        <div className='login-signup-bg'>
            <Header />
            {
                isLoading ? (

                    <>
                      <div className="chat-window text-center">
            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                margin: "auto",
              }}
              className="spinner-border"
              role="status"
            >
              <span className="sr-only"></span>
            </div>
          </div>
                    </>
                ):(
                    <>
                     <div className="user-area p-2 p-md-4 p-lg-5 pt-lg-3">
                <div className="container">
                    <div className="row">
                        <ToastContainer style={{ marginTop: "120px" }} />
                        <div className="col-lg-6 p-0">
                            <div className="user_login">
                                <img src={CommonImageTransport.loginbg} className="imgk-fluid w-100" alt="login-banner" />
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex p-0">
                            <div className="user-all-form userlogin position-relative">
                                <div className="login_bottom">
                                    <img src={CommonImageTransport.loginBottom} alt="" />
                                </div>
                                <div className="contact-form">

                                    <h3 className="user-title">Enter OTP</h3>
                                    <p>Code Just Sent to <span className="fw-bold">{Reset_password_User_Email}</span></p>
                                    <form id="contactForms" className="mt-5" onSubmit={OtpVerify1}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-floating form-group">
                                                    <div className='otp-input-fields'>
                                                        <OtpInput className="otp__digit otp__field__1"
                                                            value={otp}
                                                            onChange={(newOtp) => { setOtp(newOtp) }}
                                                            numInputs={6}
                                                            renderInput={(props) =>
                                                                <input   {...props} />}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <button type="submit"
                                                    
                                                    className="default-btn w-100 rounded">
                                                    Verify
                                                </button>

                                                <h6 className="mt-4 text-center fw-normal text-muted">Didn't get the otp?
                                                    <Link className="fw-bold" href="#" onClick={ResendOTP}>Resend</Link></h6>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                    </>
                    
                )
            }
           
        </div>

    )
}

export default ForgotOtp