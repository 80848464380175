import React, { useState, useEffect } from 'react'
import CommonImageTransport from '../../../../components/common/images/CommonImages';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ProfileSections } from "../../../../api/Auth"
const Recruiter_Common_Profile = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [GetUserProfile, SetGetUserProfile] = useState([]);
    const user_id = localStorage.getItem("User_id");
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    }
    // <------------- Get User Profile Sections ---------------->
    const GetuserProfileSection = async () => {
        const response = await ProfileSections({ user_id, headers });
        SetGetUserProfile(response?.data?.data?.profile);
    }
    // <------------- Render The User Profile Sections ---------------->
    useEffect(() => {
        GetuserProfileSection();
    }, [])
    const LogoutUser = () => {
        const User_Token = localStorage.getItem("User_Token");
        if (User_Token) {
            const confirmLogout = window.confirm("Are you sure you want to log out?");
            if (confirmLogout) {
                localStorage.removeItem("User_Token");
                localStorage.removeItem("Is_LoggedIn");
                localStorage.removeItem("user_Login_Data");
                localStorage.removeItem("user_Email");
                localStorage.removeItem("User_id");
                localStorage.removeItem("rememberedUser");
                localStorage.removeItem("role_id");
            }

        }
        navigate('/signin');
        window.location.reload();
    }
    return (
        <div>
            <div>
                <div>
                    <div className="sf-steps profileview border shadow-lg">
                        <div className="profile_avtar pt-3 pt-md-5">
                            {
                            GetUserProfile?.profileimg != null ?
                                    <>
                                        <img src={GetUserProfile?.profileimg} height={100} width={100}
                                            className="rounded-pill" alt="Profile" />
                                    </> :
                                    <>
                                        <img src={CommonImageTransport.userLogo} height={100} width={100}
                                            className="rounded-pill" alt="Profile" />
                                    </>
                            }
                            <span />
                        </div>
                        <h5 className="mt-3 mb-1">{GetUserProfile?.fname} {GetUserProfile?.lname}</h5>
                        <p>({GetUserProfile?.role_name})</p>
                        <ul className="list-group profilemenu">
                            <li className="list-group-item" >
                                <Link to="/recruiter_pages/recruiter-Profile" className={location.pathname === '/Profile' ? 'active' : ''}>
                                    <i className="ri-shield-user-fill" /> My Profile</Link></li>

                            <li className="list-group-item"><Link className={location.pathname === '/ProfileChangePassword' ? 'active' : ''}
                                to="/recruiter_pages/Recuriter_Reset_Password">
                                <i className="ri-lock-password-fill" />
                                Reset Password</Link>
                            </li>

                            <li className="list-group-item" onClick={LogoutUser}><Link to="#" className="logoutbtn2"> <i className="ri-logout-box-fill" />
                                LOGOUT</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Recruiter_Common_Profile