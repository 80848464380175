// import React, { useEffect, useRef, useState } from 'react';

// const YouTubePlayer = ({ videoId, onVideoFullyPlayed, onError }) => {
//   const [player, setPlayer] = useState(null);
//   const [lastTime, setLastTime] = useState(0);
//   const [showNext, setShowNext] = useState(false);
//   const playerRef = useRef(null);
//   const intervalRef = useRef(null);
//   const playerInstanceRef = useRef(null);

//   // Maximum allowed playback time in seconds
//   const MAX_ALLOWED_TIME = 10;

//   useEffect(() => {
//     // Load YouTube Iframe API script only once
//     if (!window.YT) {
//       const script = document.createElement('script');
//       script.src = "https://www.youtube.com/iframe_api";
//       document.body.appendChild(script);

//       script.onload = () => {
//         window.onYouTubeIframeAPIReady = () => {
//           initializePlayer();
//         };
//       };

//       return () => {
//         document.body.removeChild(script);
//       };
//     } else {
//       initializePlayer();
//     }

//     return () => {
//       if (playerInstanceRef.current) {
//         playerInstanceRef.current.destroy();
//         playerInstanceRef.current = null;
//       }
//       if (intervalRef.current) {
//         clearInterval(intervalRef.current);
//       }
//     };
//   }, [videoId]);

//   const initializePlayer = () => {
//     if (window.YT) {
//       const newPlayer = new window.YT.Player(playerRef.current, {
//         height: '380',
//         width: '100%',
//         videoId: videoId,
//         playerVars: {
//           controls: 0,
//           modestbranding: 1,
//           rel: 0,
//           fs: 0,
//           autoplay: 0,
//         },
//         events: {
//           'onReady': onPlayerReady,
//           'onStateChange': onPlayerStateChange,
//           'onError': onPlayerError,
//         },
//       });

//       playerInstanceRef.current = newPlayer;
//       setPlayer(newPlayer);
//     }
//   };

//   const onPlayerReady = () => {
//     // Player is ready
//   };

//   const onPlayerStateChange = (event) => {
//     if (event.data === window.YT.PlayerState.PLAYING) {
//       intervalRef.current = setInterval(() => {
//         if (player) {
//           const currentTime = player.getCurrentTime();
//           const duration = player.getDuration();

//           if (currentTime >= duration - 1) {
//             setShowNext(true);
//             if (onVideoFullyPlayed) onVideoFullyPlayed();
//             clearInterval(intervalRef.current);
//           }

//           if (Math.abs(currentTime - lastTime) > 2) {
//             player.seekTo(lastTime);
//           } else {
//             setLastTime(currentTime);
//           }
//         }
//       }, 1000);
//     } else if (event.data === window.YT.PlayerState.ENDED) {
//       if (onVideoFullyPlayed) onVideoFullyPlayed();
//     }
//   };

//   const onPlayerError = (event) => {
//     console.error("YouTube Player Error: ", event.data);
//     if (onError) onError(event.data);
//   };

//   return (
//     <div>
//       <div ref={playerRef}></div>
//       {showNext && (
//         <div style={{ marginTop: '20px' }}>
//           <a
//             style={{ cursor: "pointer" }}
//             onClick={() => console.log("Next button clicked")} // Handle the next button action here
//             className="nxt"
//           >
//             Next
//           </a>
//         </div>
//       )}
//     </div>
//   );
// };

// export default YouTubePlayer;



import React, { useEffect, useRef, useState } from 'react';

const YouTubePlayer = ({ videoId, onVideoFullyPlayed, onError }) => {
  const [player, setPlayer] = useState(null);
  const [showNext, setShowNext] = useState(false);
  const playerRef = useRef(null);
  const intervalRef = useRef(null);
  const playerInstanceRef = useRef(null);

  useEffect(() => {
    // Load YouTube Iframe API script only once
    if (!window.YT) {
      const script = document.createElement('script');
      script.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(script);

      script.onload = () => {
        window.onYouTubeIframeAPIReady = () => {
          initializePlayer();
        };
      };

      return () => {
        document.body.removeChild(script);
      };
    } else {
      initializePlayer();
    }

    return () => {
      if (playerInstanceRef.current) {
        playerInstanceRef.current.destroy();
        playerInstanceRef.current = null;
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [videoId]);

  const initializePlayer = () => {
    if (window.YT) {
      const newPlayer = new window.YT.Player(playerRef.current, {
        height: '320',
        width: '100%',
        videoId: videoId,
        playerVars: {
          controls: 1, // Enable user controls
          modestbranding: 1,
          rel: 0,
          fs: 1, // Allow fullscreen
          autoplay: 0,
        },
        events: {
          'onReady': onPlayerReady,
          'onStateChange': onPlayerStateChange,
          'onError': onPlayerError,
        },
      });

      playerInstanceRef.current = newPlayer;
      setPlayer(newPlayer);
    }
  };

  const onPlayerReady = () => {
    // Player is ready
  };

  const onPlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      intervalRef.current = setInterval(() => {
        if (player) {
          const currentTime = player.getCurrentTime();
          const duration = player.getDuration();

          // Trigger "Next" when video reaches near the end
          if (currentTime >= duration - 1) {
            setShowNext(true);
            if (onVideoFullyPlayed) onVideoFullyPlayed();
            clearInterval(intervalRef.current);
          }
        }
      }, 1000);
    } else if (event.data === window.YT.PlayerState.ENDED) {
      if (onVideoFullyPlayed) onVideoFullyPlayed();
    }
  };

  const onPlayerError = (event) => {
    console.error("YouTube Player Error: ", event.data);
    if (onError) onError(event.data);
  };

  return (
    <div>
      <div ref={playerRef}></div>
      {showNext && (
        <div style={{ marginTop: '20px' }}>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => console.log("Next button clicked")} // Handle the next button action here
            className="nxt"
          >
            Next
          </a>
        </div>
      )}
    </div>
  );
};

export default YouTubePlayer;

