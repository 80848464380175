import axios from "axios";
import { API_BASE_URL } from "../base_url/Base_URL";

// <-------------- Mentor Step -------------------------->
export const MentorStepProfile = async(MentorStepData,headers)=>{
  
    try{
        const response = await axios.post(`${API_BASE_URL}/update-mentor-profile`,MentorStepData,{headers})
        return response
    }catch(error){
        return error
    }
}
export const MentorStepProfile1 = async(headers)=>{
  
    try{
        const response = await axios.post(`${API_BASE_URL}/update-mentor-profile`,{},{headers})
        return response
    }catch(error){
        return error
    }
}

// <---------------- Student Register Api's Function--------------->
export const MentorSlotBook = async (MentorSlotBookData) => {
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };
    try {
       const response =  await axios.post(`${API_BASE_URL}/mentor-slots`, MentorSlotBookData,{headers})
            return response;
        }catch(error){
            return error
        }  
}

// <----------------- Get Mentor List ---------------------->
export const GetMentorList = async (headers)=>{
    try {
        const response = await axios.get(`${API_BASE_URL}/mentor-list`, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <-------------- Get Mentor Slot ---------------------->
export const GetMentorSlot = async (GetMentorSlotData,headers)=>{
    try {
        const response = await axios.post(`${API_BASE_URL}/get-mentor-slots`, GetMentorSlotData, {headers})
        return response
    } catch (error) {
        return error
    }
}

// <--------------- Student Book Mentor Slot ----------------->
export const BookMentorSlot = async (BookMentorSlotData,headers)=>{
    try {
        const response = await axios.post(`${API_BASE_URL}/book-mentor`, BookMentorSlotData, {headers})
        return response
    } catch (error) {
        return error
    }
}

// <-------------- Get Mentor Slot List ---------------------->
export const GetSloatMentorList = async (headers)=>{
    try {
        const response = await axios.get(`${API_BASE_URL}/sloat-mentor-list`, {headers})
        return response
    } catch (error) {
        return error
    }
}


// <-------------- student-mentor-bookinglist --------------------->
export const GetStudentmentorbookinglist = async(HandleStudentmentorbookinglist,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/student-mentor-bookinglist`,HandleStudentmentorbookinglist,{headers})
        return response
    }catch(error){
     return error
    }
}
// <-------------- mentor-Seen Student List --------------------->
export const MentorSeenStudentList = async(MentorSeenStudentListData,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/mentor-booking`,MentorSeenStudentListData,{headers})
        return response
    }catch(error){
     return error
    }
}

// <-------------- book-inprocess-complete --------------------->
export const MentorBookInprocessComplete = async(MentorBookInprocessCompleteData,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/book-inprocess-complete`,MentorBookInprocessCompleteData,{headers})
        return response
    }catch(error){
        return error
    }
}

// <-------------- book-inprocess-complete --------------------->
export const MentorBookReject = async(MentorBookInprocessCompleteData,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/cancel-book`,MentorBookInprocessCompleteData,{headers})
        return response
    }catch(error){
        return error
    }
}

// <-------------- Mentor Dashboard --------------------->
export const MentorDashboardData = async(headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/mentor-dashboard`,{},{headers})
        return response
    }catch(error){
        return error
    }
}

