import React from 'react'

const Loader = () => {
  return (
    <div>
      <div id="preloader">
      <div id="preloader-area">
        <div className="spinner"></div>
        <div className="spinner"></div>
        <div className="spinner"></div>
        <div className="spinner"></div>
        <div className="spinner"></div>
        <div className="spinner"></div>
        <div className="spinner"></div>
        <div className="spinner"></div>
      </div>
      <div className="preloader-section preloader-left"></div>
      <div className="preloader-section preloader-right"></div>
    </div>
    </div>
  )
}

export default Loader
