import axios from "axios";
import { API_BASE_URL } from "../base_url/Base_URL";


// <----------------- Global Course List ----------------------->
export const GetCourseList = async (CourseListData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/course-list`, CourseListData)
        return response
    } catch (error) {
        return error
    }
}
// <--------  Home slider --------------->
export const GetImgSlidersList = async (CourseListData) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/sliders-list`, CourseListData)
        return response
    } catch (error) {
        return error
    }
}

// <------------- Privacy & Ploicy --------------->
export const PrivacyPolicy = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/privacy-policy`)
        return response
    } catch (error) {
        return error
    }
}
// <------------- Privacy & Ploicy --------------->
export const TermsCondtion = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/terms-condition`)
        return response
    } catch (error) {
        return error
    }
}