import React, { useState, useEffect } from 'react';
import CommonImageTransport from '../../../common/images/CommonImages';
import "../../../../assets/css/style.css";
import Modal from 'react-bootstrap/Modal';
import Loader from '../../../../loader/Loader';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import Recruiter_Header from '../recruiter_header/Recruiter_Header';
import moment from 'moment';
import { AddRecuriterTeamMemeber, GetRecuriterTeamMemeberList, TeamUpcomingInterviews } from '../../../../api/Recuriter';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Recruiter_Footer from '../recruiter_footer/Recruiter_Footer';

const RecuriterMyTeam = () => {
    const [profile_img, setProfile_Img] = useState("")
    const [first_name, setFirst_Name] = useState("");
    const [Loading, setLoading] = useState(false)
    const [last_name, setLast_Name] = useState("");
    const [email, setEmail] = useState([]);
    const [invite_link, setInvite_Link] = useState("");
    // <--------- This is UseState for Loader Pages-------->

    const [isLoading, setIsLoading] = useState(true);
    // <------- Active Tabs ------>
    const [AddTeamshow, setAddTeamshow] = useState(false);
    const [InterviewShow, setInterviewShow] = useState(false);
    const [selectedDates, setSelectedDates] = useState([]);
    const [TeamMemeberList, setTeamMemeberList] = useState([]);
    const [ActiveTabs, setActiveTabs] = useState(TeamMemeberList?.id);
    const [TeamUpcomingInterviewsList, setTeamUpcomingInterviewsList] = useState([]);
    const [selectedTimes, setSelectedTimes] = useState({});
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleAddTeamClose = () => setAddTeamshow(false);
    const handleAddTeamShow = () => setAddTeamshow(true);
    const handleInterviewClose = () => setInterviewShow(false);

    const [imagePreviewUrl, setImagePreviewUrl] = useState(""); // To store the preview URL

    // <---------- Valiadation for the Parameters ------------>
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    /*------------------validate name------------*/
    const handleInputChange = (event, setter, setError) => {
        const value = event.target.value;
        setter(value);
        const regex = /^[a-zA-Z\s]+$/;
        if (!regex.test(value)) {
            setError("Name should contain only alphabetical letters");
        } else {
            setError("");
        }
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setProfile_Img(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreviewUrl(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    // Email validation function
    const validateEmail = (email) => {
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (!regex.test(email)) {
            setIsValidEmail(false);
            setEmailError("please enter the vaild email address");
        } else {
            setIsValidEmail(true);
            setEmailError("");
        }
    };

    const handleEmailChange = (newEmails) => {
        setEmail(newEmails);
        // Validate the last entered email (if multiple, take the last one)
        if (newEmails.length > 0) {
            validateEmail(newEmails[newEmails.length - 1]); // Validate the last email
        }
    };
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };

    //   <------ Handle Active Tabs Sections ------------->
    const HandleActiveTabs = (index) => {
        setActiveTabs(index)
    }

    const handleTimeClick = (date, time) => {
        setSelectedTimes((prevSelectedTimes) => {
            const newSelectedTimes = { ...prevSelectedTimes };
            if (!newSelectedTimes[date]) {
                newSelectedTimes[date] = [];
            }
            if (!newSelectedTimes[date].includes(time)) {
                newSelectedTimes[date].push(time);
            }
            return newSelectedTimes;
        });
    };


    // Function to generate time options for each hour and minute interval
    const generateTimeOptions = () => {
        const times = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const timeString = new Date(0, 0, 0, hour, minute).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                times.push(timeString);
            }
        }
        return times;
    };

    const validateURL = (url) => {
        const urlRegex = /^https:\/\/meet\.google\.com\/[a-z]{3}-[a-z]{4}-[a-z]{3}$/;
        return urlRegex.test(url);
    };

    // <----------- Handle Add Team Member form ----------------->
    const AddTeamMembers = async (e) => {
        e.preventDefault();

        // Validation checks
        if (!profile_img) {
            toast.error("Please upload the profile image");
            return;
        } else if (!first_name) {
            toast.error("Please fill in the first name");
            return;
        } else if (firstNameError) {
            toast.error("First name should contain only alphabetical letters");
            return;
        } else if (!last_name) {
            toast.error("Please fill in the last name");
            return;
        } else if (lastNameError) {
            toast.error("Last name should contain only alphabetical letters");
            return;
        } else if (!email?.length) {
            toast.error("Please press the button on the email to submit");
            return;
        }

        // Validate the last email in the array
        const lastEmail = email[email.length - 1];
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/; // General email regex
        if (!emailRegex.test(lastEmail)) {
            toast.error("Please enter a valid email address");
            return;
        }

        // Invite link validation
        if (!invite_link) {
            toast.error("Please fill in the invite link");
            return;
        }

        // General URL validation regex (for any valid URL)
        const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/[\w-]*)*$/;

        if (!urlRegex.test(invite_link)) {
            toast.error("Please enter a valid invite link (must be a proper URL).");
            return;
        }


        // If all validations pass, prepare the form data
        const formData = new FormData();
        formData.append("profile_img", profile_img);
        formData.append("groupLink", invite_link);
        formData.append("email", email);
        formData.append("lname", last_name);
        formData.append("fname", first_name);

        try {
            // API call to submit the form
            const response = await AddRecuriterTeamMemeber(formData, headers);

            if (response?.data?.statusCode === "200") {
                // Success: Clear all the form fields and reset state
                console.log("AddTeamMembers", response);
                GetTeamMemeberList();  // Update team member list
                handleAddTeamClose();  // Close modal or form

                // Reset form fields
                setFirst_Name("");
                setLast_Name("");
                setInvite_Link("");
                setEmail([]);  // Reset the email input
                setProfile_Img(null);  // Reset the profile image input
                setImagePreviewUrl(null);  // Reset the image preview
                toast.success("Team member added successfully!");  // Show success toast
            }
        } catch (error) {
            console.error("Error adding team member:", error);
            toast.error("An error occurred while adding the team member. Please try again.");
        }
    };



    // <-------- get the Team Member -------------->
    const GetTeamMemeberList = async () => {
        setLoading(true)
        try {
            const response = await GetRecuriterTeamMemeberList(headers)
            if (response?.data?.statusCode == "200") {
                setLoading(false)
            }
            setTeamMemeberList(response?.data?.data)
        } catch (error) {
            setLoading(true)
            console.log("error", error)
        }
    }
    console.log("TeamMemeberList", TeamMemeberList)

    // <------------ get the Upcoming Interview List here ------------>
    const GetUpcomingInterviewList = async (id) => {
        const TeamUpcomingInterviewsData = {
            team_member_id: id
        }
        console.log("TeamUpcomingInterviewsData", TeamUpcomingInterviewsData)
        const response = await TeamUpcomingInterviews(TeamUpcomingInterviewsData, headers)
        console.log("", response)
        setTeamUpcomingInterviewsList(response?.data?.data)
    }
    console.log("GetUpcomingInterviewList", TeamUpcomingInterviewsList)

    // <--------- This UseEffect for Loader Pages-------->
    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 2200);
        return () => clearTimeout(timeout);
    }, []);
    // <------ Get Team Member List ----------->
    useEffect(() => {
        GetTeamMemeberList();
        GetUpcomingInterviewList();
    }, [])


    // <---------- Show the bydefault First Jobs  --------->
    useEffect(() => {
        if (TeamMemeberList?.length > 0) {
            HandleActiveTabs(TeamMemeberList[0]?.id);
            GetUpcomingInterviewList(TeamMemeberList[0]?.id);
        }
    }, [TeamMemeberList]);

    const parseEmailString = (emailString) => {

        return emailString?.replace(/[{}]/g, ' ').split(',');
    };
    return (
        <div>
            {
                isLoading ? <Loader /> : <>
                    <div className="bg_nav-slid bg-white">
                        <Recruiter_Header />
                    </div>
                    <div className="header_space"></div>

                    {
                        Loading ? (
                            <>
                                <div className="chat-window text-center">
                                    <div style={{
                                        position: "absolute",
                                        top: 0,
                                        bottom: 0,
                                        margin: "auto",
                                    }} className="spinner-border" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="recruiter_list pb-5 pt-4">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <ToastContainer style={{ marginTop: "100px" }} />
                                            <div className="col-12">
                                                <div className="d-flex mb-3 justify-content-between gap-2">
                                                    {
                                                        TeamMemeberList?.length > 0 && (
                                                            <div>
                                                                <h5>Team List</h5>
                                                            </div>
                                                        )
                                                    }

                                                    <div><a href="#" onClick={handleAddTeamShow} className="addtambtn" data-bs-toggle="modal"
                                                        data-bs-target="#addtame">+ Add Team
                                                        Member</a></div>
                                                </div>
                                            </div>
                                            {
                                                TeamMemeberList?.length > 0 ? (
                                                    <>
                                                        <div className="left_recruiterlist">
                                                            <div className="sticky-header">
                                                                <div className="nav flex-column recruiter_all_lists nav-pills" id="v-pills-tab" role="tablist"
                                                                    aria-orientation="vertical">
                                                                    {
                                                                        TeamMemeberList?.map((TeamMemeberListResult, index) => {
                                                                            return (
                                                                                <>
                                                                                    <div key={index} className={`nav-link ${ActiveTabs === TeamMemeberListResult?.id ?
                                                                                        "active" : ""}`}
                                                                                        onClick={() => {
                                                                                            HandleActiveTabs(TeamMemeberListResult?.id)
                                                                                            GetUpcomingInterviewList(TeamMemeberListResult?.id)
                                                                                        }}
                                                                                        id="v-pills-home-tab" data-bs-toggle="pill"
                                                                                        data-bs-target="v-pills-home" type="button"
                                                                                        role="tab" aria-controls="v-pills-home"
                                                                                        aria-selected="true">
                                                                                        <div className="card_rec position-relative">

                                                                                            <div className="mentersvies0121">
                                                                                                <div className="position-relative">
                                                                                                    <img src={TeamMemeberListResult?.profile_img} style={{ width: "100px", height: "100px", borderRadius: "50%" }}
                                                                                                        className="img-fluid rounded" alt="Instructor" />
                                                                                                    {/* <span className="live_user newuser">Available</span> */}
                                                                                                </div>
                                                                                                <div className="instructors-details-contents">
                                                                                                    <div className="d-flex justify-content-between gap-2">
                                                                                                        <div className="comnam">
                                                                                                            <h3>{TeamMemeberListResult?.fname} {TeamMemeberListResult?.lname}</h3>
                                                                                                            <h6 className="sub-title">{TeamMemeberListResult?.title}</h6>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                    <p className="perographsv">{TeamMemeberListResult?.grouplink}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="skillstopic">
                                                                                                <div>{parseEmailString(TeamMemeberListResult?.email)}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>


                                                            <div className="tab-content" id="v-pills-tabContent">
                                                                <div
                                                                    className={`tab-pane fade ${ActiveTabs ? "show active" : ""}`}
                                                                    id={`v-pills-${ActiveTabs}`}
                                                                    role="tabpanel"
                                                                    aria-labelledby={`v-pills-${ActiveTabs}-tab`}>
                                                                    <div className="instructors-details-img m-0">
                                                                        <div className="instructors-details-contents mt-3">
                                                                            <div className="card-instrutors upinterviews shadow bg-white p-4">
                                                                                <h5>Upcoming Interview</h5>
                                                                                <div className="row">
                                                                                    {
                                                                                        TeamUpcomingInterviewsList?.length > 0 ? (
                                                                                            <>
                                                                                                {
                                                                                                    TeamUpcomingInterviewsList?.map((TeamUpcomingInterviewsListResult) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <div className="col-md-6 col-lg-6">
                                                                                                                    <div className="card-body inuplis border rounded">
                                                                                                                        <div className="d-flex gap-3">
                                                                                                                            <div>
                                                                                                                                <img src={CommonImageTransport?.profile1} width="50"
                                                                                                                                    height="50" className="rounded" alt="" />
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <h5>{TeamUpcomingInterviewsListResult?.job?.title}</h5>
                                                                                                                                <ul className="list-group list-group-flush nav">
                                                                                                                                    <li><b>Location:</b>{TeamUpcomingInterviewsListResult?.job?.location}</li>
                                                                                                                                    <li><b>Student:</b>{TeamUpcomingInterviewsListResult?.student?.fname} {TeamUpcomingInterviewsListResult?.student?.lname}</li>
                                                                                                                                    <li><b>InterView:</b>{TeamUpcomingInterviewsListResult?.roundtype}</li>
                                                                                                                                    <li><b>Date:</b>{moment(TeamUpcomingInterviewsListResult?.scheduledat).format("DD-MM-YYYY")}</li>
                                                                                                                                    <li><b>Time:</b> {moment(TeamUpcomingInterviewsListResult?.scheduledat).format("HH:MM A")}</li>
                                                                                                                                </ul>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                Not Any Upcoming Interview
                                                                                            </>

                                                                                        )
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <h1>Not Any Team List Here</h1>
                                                    </>
                                                )
                                            }


                                        </div>
                                    </div>
                                </div>
                            </>

                        )
                    }

                </>
            }
            {/* <----------- Add Team Member's -------------> */}
            <Modal className="modal fade" show={AddTeamshow} onHide={handleAddTeamClose}
                id="addtame" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel2">Add Team Member</h5>
                        <button type="button" className="btn-close" onClick={handleAddTeamClose} data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <form method="post" onSubmit={AddTeamMembers}>
                        <div className="modal-body">

                            <div className="row">
                                <div className="col-12 mb-3 text-center border-bottom pb-3">
                                    <div className="form-group">
                                        <label className="fieldlabels">Upload Profile image</label>
                                        {/* <div id='profile-upload'>
                                                    <div className="hvr-profile-img">
                                                        <input type="file" name="profile_img"
                                                            onChange={(e) => setProfile_Img(e.target.files[0])} id='getval'
                                                            className="upload w180" title="Dimensions 180 X 180" /></div>
                                                    <i className="fa ri-camera-2-fill"></i>
                                                </div> */}
                                        <div id="profile-upload">
                                            <div className="hvr-profile-img">
                                                <input
                                                    type="file"
                                                    name="profile_img"
                                                    onChange={handleImageUpload}
                                                    id="getval"
                                                    className="upload w180"
                                                    title="Dimensions 180 X 180"
                                                    accept=".jpg,.jpeg,.png,.gif"
                                                />
                                            </div>
                                            <i className="fa ri-camera-2-fill"></i>

                                            {/* Display the uploaded image preview if available */}
                                            {imagePreviewUrl && (
                                                <div className="uploaded-image-preview" style={{ marginTop: '10px' }}>
                                                    <img
                                                        src={imagePreviewUrl}
                                                        alt="Profile Preview"
                                                        style={{
                                                            width: "180px",
                                                            height: "180px",
                                                            objectFit: "cover",
                                                            borderRadius: "5px",
                                                            border: "1px solid #ddd",
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group mb-2 myteamform">
                                        <label>First Name</label>
                                        <input type="text" name="first_name" value={first_name}
                                            // onChange={(e) => setFirst_Name(e.target.value)}
                                            onChange={(event) =>
                                                handleInputChange(
                                                    event,
                                                    setFirst_Name,
                                                    setFirstNameError
                                                )
                                            }
                                            placeholder="first name" className="form-control"
                                        />
                                        {firstNameError && (
                                            <p style={{ color: "red", fontSize: "14px" }}>
                                                {firstNameError}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group mb-2 myteamform">
                                        <label>Last Name</label>
                                        <input type="text" name="last_name" value={last_name}
                                            // onChange={(e) => setLast_Name(e.target.value)}
                                            onChange={(event) =>
                                                handleInputChange(
                                                    event,
                                                    setLast_Name,
                                                    setLastNameError
                                                )
                                            }
                                            placeholder="last name" className="form-control"
                                        />
                                        {lastNameError && (
                                            <p style={{ color: "red", fontSize: "14px" }}>
                                                {lastNameError}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <ul id="tagList"></ul>
                                    <div className="form-group myteamform">
                                        <label>Email ID</label>
                                        <TagsInput
                                            className="form-control"
                                            value={email}
                                            name="email"
                                            onChange={handleEmailChange}
                                            inputProps={{
                                                placeholder: 'Type your skills and press Enter.', // Full placeholder text
                                            }}
                                            style={{
                                                width: '100%', // Ensure input spans full width
                                                minWidth: '250px', // Ensure minimum width
                                                padding: '10px', // Add padding if needed
                                            }}
                                        />
                                        {!isValidEmail && <small className="text-danger">{emailError}</small>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="form-group mb-2 myteamform">
                                        <h6>Invite Group Link</h6>
                                        <input type="text" name="invite_link"
                                            value={invite_link}
                                            onChange={(e) => setInvite_Link(e.target.value)}
                                            placeholder="https://meet.google.com/odp-nvra-ycf"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="dd-footer d-flex gap-3 p-3 border-top">
                            <button type="button" className="btn btn-secondary w-100" data-bs-dismiss="modal" onClick={handleAddTeamClose}>Close</button>
                            <button type="submit" className="btn default-btn w-100">Submit</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Recruiter_Footer />
        </div>
    )
}

export default RecuriterMyTeam