import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import CommonImageTransport from '../../../common/images/CommonImages'
import Loader from '../../../../loader/Loader'
import Typewriter from 'typewriter-effect';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';
// import Select from 'react-select';
import { IoClose } from "react-icons/io5";
import Recruiter_Header from '../recruiter_header/Recruiter_Header';
import { GetRecuriterAllAppliedJobs } from '../../../../api/Recuriter'
import ReactHtmlParser from "react-html-parser";
import { Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel, OutlinedInput } from '@mui/material';

const Recuriter_Applied_Profile = () => {
    const [AppliedJobs, setAppliedJobs] = useState([]);
    // <--------- This is UseState for Loader Pages-------->
    const [isLoading, setIsLoading] = useState(true);
    const [ActiveTab, setActiveTab] = useState(0);
    const [showSearchFields, setShowSearchFields] = useState(false);
    const [InterviewShow, setInterviewShow] = useState(false);
    const [TechnicalInterview, setTechnicalInterview] = useState([{ id: 1 }]);
    const [BehavioralInterview, setBehavioralInterview] = useState([{ id: 1 }]);
    const [selectedOptions, setselectedOptions] = useState([]);
    const [TechSelectOption, setTechSelectOption] = useState([]);
    const [BehavSelectOption, setBehavSelectOption] = useState([]);
    const [TechInterviewDate, setTechInterviewDate] = useState([]);
    const [BehavInterviewDate, setBehavInterviewDate] = useState([]);
    const [TechInterviewLink, setTechInterviewLink] = useState([]);
    const [BehavInterviewLink, setBehavInterviewLink] = useState([]);
    const [TechnicalformattedDateWithTimeZone, setTechnicalformattedDateWithTimeZone] = useState('');
    const [BehavioralformattedDateWithTimeZone, setBehavioralformattedDateWithTimeZone] = useState('');
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };



    const handleChange = (event) => {
        setselectedOptions(event.target.value);
    };

    const options = ['Rahul Sharma', 'Ravi Sharma', 'Sunil Kumar', 'Amit Singh'];




    const TechnicalformatDateTimeWithTimeZone = (e) => {
        const selectedDateTime = e.target.value;
        const dateObj = moment(selectedDateTime).tz(moment.tz.guess());

        // Format for display
        const formattedDisplay = dateObj.format('ddd MMM DD YYYY HH:mm:ss [GMT]Z (zz)');
        setTechnicalformattedDateWithTimeZone(formattedDisplay);

        // Format for input value
        const formattedForInput = dateObj.format('YYYY-MM-DDTHH:mm:ss');
        setTechInterviewDate(formattedForInput);
    };


    const BehavioralformatDateTimeWithTimeZone = (date) => {
        const dateObj = moment(date).tz(moment.tz.guess());
        console.log("dateObj", dateObj.format('ddd MMM DD YYYY HH:mm:ss [GMT]Z (zz)'));
        const formatted = dateObj.format('ddd MMM DD YYYY HH:mm:ss [GMT]Z (zz)');
        setBehavioralformattedDateWithTimeZone(formatted);
        const formattedForInput = dateObj.format('YYYY-MM-DDTHH:mm:ssZ');
        setBehavInterviewDate(formattedForInput);
    };
    const handleInterviewClose = () => setInterviewShow(false);
    const handleInterviewShow = () => setInterviewShow(true);
    // <--------- Handle the Interview scheduled ------->
    const HandleInterviewScheduled = (e) => {
        e.preventDefault();
        const daat1 = {
            selectedOptions,
            TechnicalformattedDateWithTimeZone,
            TechInterviewLink
        }
        console.log("dlkfjkg", daat1)
    }
    // <-------------- Get the All Applied Job List here ---------------->
    const GetStudentAppliedJobs = async () => {
        const GetRecuriterAllAppliedJobsData = {
            status: 2
        }
        const response = await GetRecuriterAllAppliedJobs(GetRecuriterAllAppliedJobsData, headers);
        setAppliedJobs(response?.data?.data)
    }

    // <--------- This UseEffect for Loader Pages-------->
    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 2200);

        return () => clearTimeout(timeout);
    }, []);
    // <------------- Handle the Active Functionlity ---------->
    const HandleActiveTab = (tabs) => {
        setActiveTab(tabs)
    }
    // <--------- Render the Get student Applied Jobs ---------->
    useEffect(() => {
        GetStudentAppliedJobs();
    }, [])

    // <---------- Show the bydefault First Jobs  --------->
    useEffect(() => {
        if (AppliedJobs?.length > 0) {
            HandleActiveTab(AppliedJobs[0]?.id);
        }
    }, [AppliedJobs]);


    return (
        <div>
            {
                isLoading ? <Loader /> : (
                    <>
                        <div className="bg_nav-slid bg-white">
                            <Recruiter_Header />
                        </div>
                        <Modal
                            size="lg"
                            show={InterviewShow}
                            onHide={() => setInterviewShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Interview</h5>
                                <button type="button" className="btn-close" onClick={handleInterviewClose}
                                    data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form method="post">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12 col-md-12">
                                            <div className="form-group mb-2 myteamform">
                                                <h6>Technical Interview</h6>
                                                <FormControl sx={{ m: 1, width: 750 }}>
                                                    <InputLabel id="demo-multiple-checkbox-label">Select Interviewer</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="demo-multiple-checkbox"
                                                        multiple
                                                        value={selectedOptions}
                                                        onChange={handleChange}
                                                        input={<OutlinedInput label="Tech Select Option" />}
                                                        renderValue={(selected) => selected.join(', ')}
                                                    >
                                                        {options.map((name) => (
                                                            <MenuItem key={name} value={name}>
                                                                <Checkbox checked={selectedOptions.indexOf(name) > -1} />
                                                                <ListItemText primary={name} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <div className="form-group mb-2 myteamform">
                                                <h6>Interview Date</h6>
                                                <div className="datetime-timezone-input form-group">
                                                    <input
                                                        type="datetime-local"
                                                        name="TechnicalformattedDateWithTimeZone"
                                                        placeholder="date"
                                                        className="form-control datetime-input"
                                                        required
                                                        value={TechInterviewDate}
                                                        onChange={TechnicalformatDateTimeWithTimeZone}
                                                        min={moment().format('YYYY-MM-DDTHH:mm')}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <div className="form-group mb-2 myteamform">
                                                <h6>Interview  Link</h6>
                                                <input type="text" name="TechInterviewLink"
                                                    value={TechInterviewLink}
                                                    onChange={(e) => setTechInterviewLink(e.target.value)}
                                                    placeholder="https://meet.google.com/odp-nvra-ycf" className="form-control"
                                                    required />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dd-footer d-flex gap-3 p-3 border-top">
                                    <button type="button" className="btn btn-secondary w-100" data-bs-dismiss="modal">Close</button>
                                    <button type="button" onClick={HandleInterviewScheduled} className="btn default-btn w-100">Submit</button>
                                </div>
                            </form>


                        </Modal>
                        <div className="recruiter_list pb-5 pt-4">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <ul className="nav tabs_signup justify-content-start gap-2 mb-3">
                                            <li className="nav-item">
                                                <Link className="nav-link" aria-current="page" to="/recruiter_pages/NewProfile">New Profile</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/recruiter_pages/RecruiterInterviewing">Interviewing</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/recruiter_pages/RecruiterList">Hired</Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link className="nav-link" to="/recruiter_pages/AllProfile">All</Link>
                                            </li>

                                        </ul>
                                    </div>
                                    {
                                        AppliedJobs?.length > 0 ? (
                                            <>
                                                <div className='col-md-8'>
                                                    <form>
                                                        <div className="searchlocation">
                                                            <div className="iconfield">
                                                                <div className="icon_search">
                                                                    <img src={CommonImageTransport?.searchicon} alt="" />
                                                                </div>

                                                                {!showSearchFields ? (
                                                                    <div
                                                                        onClick={() => setShowSearchFields(true)}>
                                                                        <Typewriter
                                                                            options={{
                                                                                loop: true,
                                                                            }}
                                                                            onInit={(typewriter) => {
                                                                                typewriter.typeString('Search by title')
                                                                                    .callFunction(() => {
                                                                                    })
                                                                                    .pauseFor(2500)
                                                                                    .deleteAll()
                                                                                    .callFunction(() => {
                                                                                    })
                                                                                    .typeString('Search by location')
                                                                                    .pauseFor(2500)
                                                                                    .start();
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <input type="text"
                                                                            placeholder=""
                                                                            style={{ border: "none", outline: "none" }} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="iconfield">
                                                                <div className="icon_search">
                                                                    <img src={CommonImageTransport?.locationicon} alt="" />
                                                                </div>
                                                                <div className="input-field">
                                                                    <input type="text" placeholder="USA, Florida" className="form-control"
                                                                        required="" />
                                                                </div>
                                                            </div>
                                                            <div className="input-field ad121">
                                                                <input type="submit" value="Search" className="submit_btn" />
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                                {/* <div class="col-md-4 my-3 pt-1 text-end">
                                        <a href="#" class="default-btn rounded">Request More Profile</a>
                                    </div> */}
                                                <div className='col-12'>
                                                    <div className="left_recruiterlist">
                                                        <div className="sticky-header">
                                                            <div className="nav flex-column recruiter_all_lists nav-pills" id="v-pills-tab" role="tablist"
                                                                aria-orientation="vertical">
                                                                {
                                                                    AppliedJobs?.map((AppliedJobsResult, index) => {
                                                                        return (
                                                                            <>
                                                                                <div key={index} className={`nav-link ${ActiveTab === AppliedJobsResult?.id ?
                                                                                    "active" : ""}`}
                                                                                    onClick={() => HandleActiveTab(AppliedJobsResult?.id)}
                                                                                    id="v-pills-home-tab" data-bs-toggle="pill"
                                                                                    data-bs-target="#v-pills-home" type="button"
                                                                                    role="tab" aria-controls="v-pills-home"
                                                                                    aria-selected="true">
                                                                                    <div className="card_rec position-relative">
                                                                                        <div className="mentersvies0121">
                                                                                            <div className="position-relative">
                                                                                                <img src={CommonImageTransport.instructorDetails}
                                                                                                    className="img-fluid rounded" alt="Instructor" />
                                                                                                <span className="live_user">Available</span>
                                                                                            </div>
                                                                                            <div className="instructors-details-contents">
                                                                                                <div className="d-flex justify-content-between gap-2">
                                                                                                    <div className="comnam">
                                                                                                        <h3>{AppliedJobsResult?.student_name} </h3>
                                                                                                        <h6 className="sub-title">{AppliedJobsResult?.title}</h6>
                                                                                                    </div>
                                                                                                    <div className="reviewsreat">
                                                                                                        <span className="reviewd"><i
                                                                                                            className="ri-star-fill text-warning"></i>5.0
                                                                                                            (18
                                                                                                            Reviews)</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <p className="perographsv">{AppliedJobsResult?.organization}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="skillstopic text-dark">
                                                                                            {AppliedJobsResult?.skills
                                                                                                ?.replace(/[{}"\\]/g, '')
                                                                                                ?.split(/,\s*/)
                                                                                                ?.map((skill, index, array) => (
                                                                                                    <span key={index}>
                                                                                                        {skill.trim()}
                                                                                                        {index < array.length - 1 && ', '}
                                                                                                    </span>
                                                                                                ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        </div>

                                                        <div className="tab-content" id="v-pills-tabContent">
                                                            <div className={`tab-pane fade ${ActiveTab ? "show active" : ""}`}
                                                                id={`v-pills-${ActiveTab}`}
                                                                role="tabpanel"
                                                                aria-labelledby={`v-pills-${ActiveTab}-tab`}>
                                                                <div className="instructors-details-img m-0">
                                                                    <div className="card-instrutors shadow position-relative bg-white p-4">
                                                                        <a href="#" className="rejectbtn2"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#exampleModal3">Applied</a>
                                                                        <div className="mentersvies0121">
                                                                            <div className="position-relative menter_pro">
                                                                                <img src={CommonImageTransport.instructorDetails}
                                                                                    className="rounded_01top" alt="Instructor" />
                                                                                <span className="live_user newuser">Available</span>
                                                                            </div>

                                                                            <div className="instructors-details-contents mt-3">
                                                                                <div className="d-flex justify-content-between">
                                                                                    <div>
                                                                                        <h3>{AppliedJobs?.find(job => job.id === ActiveTab)?.student_name} </h3>
                                                                                        <h6 className="sub-title mb-2">{AppliedJobs?.find(job => job.id === ActiveTab)?.title}</h6>
                                                                                    </div>
                                                                                    <div>
                                                                                        <a href="#"
                                                                                            className="default-btn rounded"
                                                                                            onClick={handleInterviewShow}>Arrange Interview</a>
                                                                                    </div>
                                                                                </div>
                                                                                <p>{AppliedJobs?.find(job => job.id === ActiveTab)?.organization}</p>
                                                                                <p><strong>Job Id:</strong>{AppliedJobs?.find(job => job.id === ActiveTab)?.unique_id}</p>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="instructors-details-contents mt-3">
                                                                            <div className="businessmen">
                                                                                <div><i className="ri-briefcase-4-fill"></i> 9 years</div>
                                                                                <div><i className="ri-shield-user-fill"></i> Business And Management
                                                                                </div>
                                                                                <div><i className="ri-chat-voice-fill"></i>45 Bookings</div>
                                                                                <div><i className="ri-time-fill"></i>12.3 Hrs</div>
                                                                            </div>
                                                                        </div> */}
                                                                    </div>


                                                                    <div className="instructors-details-contents mt-3">
                                                                        <div className="card-instrutors shadow bg-white p-4">
                                                                            <h5>About Student</h5>
                                                                            <p> {ReactHtmlParser(AppliedJobs?.find(job => job.id === ActiveTab)?.aboutjob)}</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="instructors-details-contents mt-3">
                                                                        <div className="card-instrutors shadow bg-white p-4">
                                                                            <h5>Skills</h5>
                                                                            <div>
                                                                                {AppliedJobs?.find(job => job.id === ActiveTab)?.skills
                                                                                    ?.replace(/[{}"\\]/g, '')
                                                                                    ?.split(/,\s*/)
                                                                                    ?.map((skill, index, array) => (
                                                                                        <span key={index}>
                                                                                            {skill.trim()}
                                                                                            {index < array.length - 1 && ', '}
                                                                                        </span>
                                                                                    ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="instructors-details-contents mt-3">
                                                                        <div className="card-instrutors shadow bg-white p-4">
                                                                            <h5>Education</h5>
                                                                            <div className="skillstopic_education">
                                                                                <div className="educationicon">
                                                                                    <img src={CommonImageTransport.education} alt="" />
                                                                                </div>
                                                                                <div>
                                                                                    <h6>ESCP Business School, Paris, France</h6>
                                                                                    <span>MIM</span>
                                                                                    <p>2016 - 2017</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="skillstopic_education">
                                                                                <div className="educationicon">
                                                                                    <img src={CommonImageTransport.education} alt="" />
                                                                                </div>
                                                                                <div>
                                                                                    <h6>ESCP Business School, Paris, France</h6>
                                                                                    <span>MIM</span>
                                                                                    <p>2016 - 2017</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="skillstopic_education">
                                                                                <div className="educationicon">
                                                                                    <img src={CommonImageTransport.education} alt="" />
                                                                                </div>
                                                                                <div>
                                                                                    <h6>ESCP Business School, Paris, France</h6>
                                                                                    <span>MIM</span>
                                                                                    <p>2016 - 2017</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className='text-center mt-5'>
                                                    <h2>Not Any Applied Jobs Here ...</h2>
                                                </div>
                                            </>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </>
                )
            }

        </div>
    )
}

export default Recuriter_Applied_Profile