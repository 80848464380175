import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Mentor_Protected = (props) => {
    const { Component } = props;
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const role_id = localStorage.getItem('role_id');

        // Redirect to Signin if role_id is not present
        if (!role_id) {
            navigate('/Signin', { replace: true });
            return;
        }

        // Check if the user is a mentor (role_id === 3)
        if (role_id === '3') {
            // List of authorized mentor paths
            const allowedMentorPaths = [
                '/mentor_pages/CoachingMentor',
                '/mentor_pages/MentorDashboard',
                '/mentor_pages/Mentor-Step',
                '/mentor_pages/Mentor_UpComing_Session',
                '/mentor_pages/Mentor_Student_Profile',
                '/mentor_pages/Mentor_Booking',
                '/mentor_pages/Mentor_Resume_Profile',
                '/mentor_pages/Mentor_Profile',
                '/mentor_pages/Mentor_Edit_Profile',
                '/mentor_pages/Mentor_Change_Password',
                '/mentor_pages/Mentor_Support',
            ];

            // If the current path isn't in the allowed mentor paths, redirect to the mentor dashboard
            if (!allowedMentorPaths.includes(location.pathname)) {
                navigate('/mentor_pages/MentorDashboard');
            }
        } else {
            // If role_id is not 3 (e.g., role 2 or 4), redirect to the respective dashboard
            if (role_id === '2') {
                navigate('/Student/Student_dashBoard');
            } else if (role_id === '4') {
                navigate('/recruiter_pages/RecruiterDashboard');
            }
        }
    }, [navigate, location.pathname]);

    return <Component />;
};

export default Mentor_Protected;
