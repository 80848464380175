import React from 'react'

export const PhoneValidLenght =  {

        'AF': 9,   // Afghanistan
        'US': 10,   // United State America
        'AL': 8,   // Albania
        'DZ': 9,   // Algeria
        'AD': 6,   // Andorra
        'AO': 9,   // Angola
        'AG': 10,  // Antigua and Barbuda
        'AR': 10,  // Argentina
        'AM': 8,   // Armenia
        'AU': 9,   // Australia
        'AT': 4,   // Austria
        'AZ': 9,   // Azerbaijan
        'BS': 10,  // Bahamas
        'BH': 8,   // Bahrain
        'BD': 10,  // Bangladesh
        'BB': 7,   // Barbados
        'BY': 9,   // Belarus
        'BE': 8,   // Belgium
        'BZ': 7,   // Belize
        'BJ': 10,  // Benin
        'BT': 8,   // Bhutan
        'BO': 8,   // Bolivia
        'BA': 8,   // Bosnia and Herzegovina
        'BW': 7,   // Botswana
        'BR': 11,  // Brazil
        'BN': 7,   // Brunei
        'BG': 7,   // Bulgaria
        'BF': 10,  // Burkina Faso
        'BI': 7,   // Burundi
        'CV': 7,   // Cape Verde
        'KH': 8,   // Cambodia
        'CM': 9,   // Cameroon
        'CA': 10,  // Canada
        'CL': 9,   // Chile
        'CN': 11,  // China
        'CO': 10,  // Colombia
        'KM': 7,   // Comoros
        'CG': 8,   // Congo
        'CD': 9,   // Democratic Republic of the Congo
        'CK': 7,   // Cook Islands
        'CR': 8,   // Costa Rica
        'HR': 8,   // Croatia
        'CU': 8,   // Cuba
        'CY': 8,   // Cyprus
        'CZ': 9,   // Czech Republic
        'DK': 8,   // Denmark
        'DJ': 7,   // Djibouti
        'DM': 10,  // Dominica
        'DO': 10,  // Dominican Republic
        'EC': 9,   // Ecuador
        'EG': 11,  // Egypt
        'SV': 8,   // El Salvador
        'GQ': 9,   // Equatorial Guinea
        'ER': 7,   // Eritrea
        'EE': 7,   // Estonia
        'SZ': 9,   // Eswatini
        'ET': 10,  // Ethiopia
        'FJ': 7,   // Fiji
        'FI': 8,   // Finland
        'FR': 9,   // France
        'GA': 7,   // Gabon
        'GM': 7,   // Gambia
        'GE': 9,   // Georgia
        'DE': 10,  // Germany
        'GH': 10,  // Ghana
        'GR': 10,  // Greece
        'GD': 10,  // Grenada
        'GT': 8,   // Guatemala
        'GN': 9,   // Guinea
        'GW': 9,   // Guinea-Bissau
        'GY': 7,   // Guyana
        'HT': 8,   // Haiti
        'HN': 8,   // Honduras
        'HK': 8,   // Hong Kong
        'HU': 9,   // Hungary
        'IS': 7,   // Iceland
        'IN': 10,  // India
        'ID': 10,  // Indonesia
        'IR': 10,  // Iran
        'IQ': 10,  // Iraq
        'IE': 9,   // Ireland
        'IL': 9,   // Israel
        'IT': 10,  // Italy
        'JM': 10,  // Jamaica
        'JP': 10,  // Japan
        'JE': 7,   // Jersey
        'JO': 8,   // Jordan
        'KZ': 10,  // Kazakhstan
        'KE': 10,  // Kenya
        'KI': 7,   // Kiribati
        'KP': 8,   // North Korea
        'KR': 10,  // South Korea
        'KW': 8,   // Kuwait
        'KG': 9,   // Kyrgyzstan
        'LA': 8,   // Laos
        'LV': 8,   // Latvia
        'LB': 8,   // Lebanon
        'LS': 8,   // Lesotho
        'LR': 7,   // Liberia
        'LY': 9,   // Libya
        'LI': 9,   // Liechtenstein
        'LT': 8,   // Lithuania
        'LU': 6,   // Luxembourg
        'MG': 10,  // Madagascar
        'MW': 10,  // Malawi
        'MY': 10,  // Malaysia
        'MV': 7,   // Maldives
        'ML': 8,   // Mali
        'MT': 8,   // Malta
        'MH': 7,   // Marshall Islands
        'MR': 8,   // Mauritania
        'MU': 10,  // Mauritius
        'MX': 10,  // Mexico
        'FM': 7,   // Micronesia
        'MD': 8,   // Moldova
        'MC': 7,   // Monaco
        'MN': 8,   // Mongolia
        'ME': 8,   // Montenegro
        'MA': 10,  // Morocco
        'MZ': 9,   // Mozambique
        'MM': 9,   // Myanmar
        'NA': 10,  // Namibia
        'NP': 10,  // Nepal
        'NL': 9,   // Netherlands
        'NZ': 9,   // New Zealand
        'NI': 8,   // Nicaragua
        'NE': 10,  // Niger
        'NG': 10,  // Nigeria
        'MK': 8,   // North Macedonia
        'NO': 8,   // Norway
        'OM': 8,   // Oman
        'PK': 10,  // Pakistan
        'PW': 7,   // Palau
        'PA': 7,   // Panama
        'PG': 9,   // Papua New Guinea
        'PY': 7,   // Paraguay
        'PE': 9,   // Peru
        'PH': 10,  // Philippines
        'PL': 9,   // Poland
        'PT': 9,   // Portugal
        'QA': 8,   // Qatar
        'RE': 10,  // Réunion
        'RO': 10,  // Romania
        'RU': 10,  // Russia
        'RW': 10,  // Rwanda
        'LC': 10,  // Saint Lucia
        'WS': 7,   // Samoa
        'SM': 10,  // San Marino
        'ST': 9,   // São Tomé and Príncipe
        'SA': 9,   // Saudi Arabia
        'SN': 9,   // Senegal
        'RS': 9,   // Serbia
        'SC': 10,  // Seychelles
        'SL': 8,   // Sierra Leone
        'SG': 8,   // Singapore
        'SK': 9,   // Slovakia
        'SI': 8,   // Slovenia
        'SB': 7,   // Solomon Islands
        'ZA': 10,  // South Africa
        'ES': 9,   // Spain
        'LK': 10,  // Sri Lanka
        'SD': 10,  // Sudan
        'SR': 7,   // Suriname
        'SZ': 9,   // Swaziland
        'SE': 10,  // Sweden
        'CH': 9,   // Switzerland
        'SY': 8,   // Syria
        'TW': 10,  // Taiwan
        'TJ': 9,   // Tajikistan
        'TZ': 10,  // Tanzania
        'TH': 9,   // Thailand
        'TL': 9,   // Timor-Leste
        'TG': 8,   // Togo
        'TO': 7,   // Tonga
        
    
}


