import React, { useState, useEffect, useRef } from 'react';
import Student_Header from '../student_header/Student_Header';
import CommonImageTransport from '../../../common/images/CommonImages';
import "../../../../assets/css/style.css";
import { Link, useNavigate } from 'react-router-dom';
import { PostCourseReview, StudentCourseContinue, StudentCourseList } from '../../../../api/Student';
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
import { Rating } from 'react-simple-star-rating';
import Student_Footer from '../student_footer/Student_Footer';
import { Modal } from 'react-bootstrap';
import { ProfileSections } from '../../../../api/Auth';
import ReactHtmlParser from "react-html-parser";
const Student_Traning_Program = () => {
    const navigate = useNavigate()
    // const [is_Loading,setIs_Loading] = useState(false)
    const [ActiveTabs, setActiveTabs] = useState(0);
    const contentRef = useRef(null);
    const [CourseList, setCourseList] = useState([])
    const [rating, setRating] = useState(0);
    const [feedback_title, setFeedback_Title] = useState("");
    const [review, setReview] = useState("");
    const [CourseDeatils, setCourseDeatils] = useState({})
    console.log("dnfkjvhdfjhdfjhfj", CourseDeatils)
    const [CourseIs_Booking, setCourseIs_Booking] = useState({})
    const [course_id, setCourseid] = useState("")
    const [course_id1, setCourseid1] = useState("")
    const [ShowBookNow, setShowBookNow] = useState(false)
    const [GetUserProfile, SetGetUserProfile] = useState([])
    const user_id = localStorage.getItem("User_id")


    // <--------------- Get the Active Traning Program ------------->
    const Get_Active_Traning_Program = localStorage.getItem("Active_Traning_Program")
    const [ActiveCourse, setActiveCourse] = useState(Get_Active_Traning_Program || 0);
    console.log("course_idd,mvnfdkbnfcgnb", ActiveCourse)

    // Refs for each section
    const overviewRef = useRef(null);
    const curriculumRef = useRef(null);
    const instructorRef = useRef(null);
    const reviewsRef = useRef(null);

    // Scroll into view when tab is clicked
    const scrollToSection = (ref, index) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
            setActiveTabs(index); // Set active tab when clicked
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const sections = [
                { ref: overviewRef, index: 0 },
                { ref: curriculumRef, index: 1 },
                { ref: instructorRef, index: 2 },
                { ref: reviewsRef, index: 3 },
            ];

            sections.forEach((section) => {
                const rect = section?.ref?.current?.getBoundingClientRect();
                if (rect?.top >= 0 && rect?.top < window.innerHeight / 2) {
                    setActiveTabs(section.index);
                }
            });
        };

        // Attach scroll listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // <------------- User Autnetication's ------------------>
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };


    const CourseDetailsId = (course_id) => {
        setCourseid(course_id)
        HandleCourseDetails(course_id)
        // HandleStudentCourseContinue(course_id)
    }
    console.log("CourseDetailsId", course_id)

    const CourseDetailsId1 = (course_id) => {
        setCourseid1(course_id)
        HandleCourseDetails(course_id)
        HandleStudentCourseContinue(course_id)
        setShowBookNow(false)
        // HandleGetCourseList()

    }
    console.log("CourseDetailsId1", CourseDeatils)
    const HandleCourseDetails = async (course_id) => {
        const data = {
            course_id: course_id
        }
        console.log("data", data)
        try {
            const response = await StudentCourseList(data, headers);
            setCourseDeatils(response?.data?.data[0])
            setCourseIs_Booking(response?.data?.data[0])
        } catch (error) {
            // setIs_Loading(false)
        }
    }
    console.log("HandleCourseDetails", CourseDeatils)
    const HandleStudentCourseContinue = async (course_id) => {
        const data = {
            course_id: course_id,
            amount: 1500,
            paymentId: "PAYMENT0001",
            payment_status: "SUCCESS"
        }
        console.log("data", data)
        try {
            const response = await StudentCourseContinue(data, headers);
            if (response?.data?.statusCode == "400") {
                toast.warn(response?.data?.msg)
                // alert("you have already one course active first complete this after purchases new course.")
            }
            else {
                navigate(`/Student/Student_Coaching_Session_Complete/${course_id}`)
            }
            console.log("HandleStudentCourseContinue", response)
            // HandleCourseDetails();
        } catch (error) {
            console.log("error", error)
        }
    }

    console.log("CourseIs_Booking", CourseIs_Booking)

    const HandleGetCourseList = async () => {
        try {
            const response = await StudentCourseList({}, headers);
            setCourseList(response?.data?.data)
        } catch (error) {
            console.log("error", error)
        }
    }

    // <--------- Get the Student Profile section's ----------->
    const ProfileSections1 = async () => {
        const response = await ProfileSections({ user_id, headers })
        SetGetUserProfile(response?.data?.data?.profile)
    }

    // <--------- Get the Student Profile section's ----------->
    useEffect(() => {
        ProfileSections1();
    }, [])


    const HandlepostWriteReview = async (e) => {
        e.preventDefault()
        const data = {
            course_id: ActiveCourse,
            rating,
            review,
            feedback_title,
        }
        try {
            const response = await PostCourseReview(data, headers);
            console.log("HandlepostWriteReview", response)
            if (response?.data?.statusCode == "200") {
                toast.success(response?.data?.msg)
                setRating("")
                setReview("")
                setFeedback_Title("")
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    // <------------ Fetch the Active Course and Course Details ------------->
    useEffect(() => {
        if (ActiveCourse) {
            HandleCourseDetails(ActiveCourse);
        } else if (CourseList?.length > 0) {
            const firstCourseId = CourseList[0]?.id;
            setActiveCourse(firstCourseId);
        }
    }, [CourseList, ActiveCourse]);

    // <------------- Fetch the course List ------------>
    useEffect(() => {
        HandleGetCourseList();
    }, [])

    // <----------- Handle the Tabs Active & unActive ----------->
    const HandleActiveTabs = (index) => {
        setActiveTabs(index);
        if (index === 0) {
            overviewRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (index === 1) {
            curriculumRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (index === 2) {
            instructorRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (index === 3) {
            reviewsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Effect to switch tabs when scrolled to the bottom of the content
    useEffect(() => {
        const handleScroll = () => {
            if (contentRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
                if (scrollTop + clientHeight >= scrollHeight - 10) {
                    setActiveTabs((prev) => (prev + 1) % 4);
                }
            }
        };

        const contentElement = contentRef.current;

        // Add scroll event listener
        if (contentElement) {
            contentElement.addEventListener('scroll', handleScroll);
        }

        // Cleanup function to remove the event listener
        return () => {
            if (contentElement) {
                contentElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [ActiveTabs]);
    return (
        <div>
            {

                <>
                    <div className="bg_nav-slid bg-white">
                        <Student_Header />
                    </div>
                    <div className="header_space"></div>
                    <ToastContainer style={{ marginTop: "120px" }} />
                    {
                        CourseList?.length > 0 ? (
                            <>
                                <div className="courses-area py-4">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-4 col-lg-3">
                                                <div className="bg_gradient p-3 rounded mb-3 gap-3 align-items-center">
                                                    <div className="text-center">
                                                        <img src={CourseDeatils?.instructor_img} width="155" height="140" class="rounded-pill"
                                                            alt="" />
                                                    </div>
                                                    <div className="instructors-details-contents w-100 text-center">
                                                        <h4 className="mb-0">{CourseDeatils?.instructor_name}</h4>
                                                        <h5 className="mb-0">{CourseDeatils?.instructor_designation}</h5>
                                                        <hr />

                                                    </div>
                                                </div>
                                                <div className="courses-page-sidebar pr-20">
                                                    <div className="courses-page-side-bar-widget">
                                                        <h3 className="title">Categories List</h3>
                                                        <ul className="courses-page-content">
                                                            {
                                                                CourseList?.sort((a, b) => {
                                                                    // First priority: isCompleted == false and isBooking == true
                                                                    if (a.isCompleted === false && a.isBooking === true) return -1;
                                                                    if (b.isCompleted === false && b.isBooking === true) return 1;

                                                                    // Second priority: isCompleted == true and isBooking == true
                                                                    if (a.isCompleted === true && a.isBooking === true) return -1;
                                                                    if (b.isCompleted === true && b.isBooking === true) return 1;

                                                                    // Third priority: isCompleted == false and isBooking === false
                                                                    if (a.isCompleted === false && a.isBooking === false) return -1;
                                                                    if (b.isCompleted === false && b.isBooking === false) return 1;

                                                                    return 0;
                                                                }).map((CourseListResult) => {
                                                                    let className = "";
                                                                    if (ActiveCourse == CourseListResult?.id) {
                                                                        className += "text-inf fw-bold ";
                                                                    } else {
                                                                        className += "text-dark fw-bold ";
                                                                    }

                                                                    if (CourseListResult?.isCompleted === false && CourseListResult?.isBooking === true) {
                                                                        className += "alert alert-primary px-3 py-2 btn-sm w-100 m-0";
                                                                    } else if (CourseListResult?.isCompleted === true && CourseListResult?.isBooking === true) {
                                                                        className += "alert text-secondary alert-success px-3 py-2 btn-sm w-100 m-0";
                                                                    } else if (CourseListResult?.isCompleted === false && CourseListResult?.isBooking === false) {
                                                                        className += "alert alert-secondary px-3 py-2 btn-sm w-100 m-0";
                                                                    }

                                                                    return (
                                                                        <li
                                                                            key={CourseListResult?.id}
                                                                            onClick={() => {
                                                                                CourseDetailsId(CourseListResult?.id);
                                                                                setActiveCourse(CourseListResult?.id);
                                                                                localStorage.setItem("Active_Traning_Program", CourseListResult?.id);
                                                                            }}
                                                                        >
                                                                            <a href="#" className={`${className}`}>
                                                                                {CourseListResult?.title}
                                                                            </a>
                                                                        </li>
                                                                    );
                                                                })
                                                            }




                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-8 col-lg-9">
                                                <div className="inner-banner inner-banner-bg p-4">
                                                    <div className="inner-title">
                                                        <h3>{CourseDeatils?.title}</h3>
                                                        {/* <p className="text-white">{CourseDeatils?.short_desc}</p> */}
                                                    </div>
                                                </div>

                                                <div className="courses-details-area pt-20 pb-70">
                                                    <div className="row">
                                                        <div className="col-lg-8">
                                                            <div className="scourse_meta mt-2">
                                                                <div className="smeta">
                                                                    <img src={CourseDeatils?.instructor_img} height="70" width="50"
                                                                        className="rounded-pill" alt="author" />
                                                                    <div className="smeta_text">
                                                                        <span>Instructor:</span>
                                                                        <p>
                                                                            <a href="#">{CourseDeatils?.instructor_name}</a>
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="smeta">
                                                                    <span>Category:</span>
                                                                    <p>{CourseDeatils?.category}</p>
                                                                </div>

                                                                <div className="smeta">
                                                                    <span>Last Update:</span>
                                                                    <p>{moment(CourseDeatils?.updated_at).format("DD-MM-YYYY")}</p>
                                                                </div>
                                                            </div>
                                                            <div className="courses-details-contact">
                                                                <div className="tab courses-details-tab">
                                                                    <div className='global-nav fixed-menu'>
                                                                        <ul className="tabs">
                                                                            <li className={`${ActiveTabs === 0 ? "active" : ""}`}
                                                                                onClick={() => scrollToSection(overviewRef, 0)}>
                                                                                Overview
                                                                            </li>
                                                                            <li className={`${ActiveTabs === 1 ? "active" : ""}`}
                                                                                onClick={() => scrollToSection(curriculumRef, 1)}>
                                                                                Curriculum
                                                                            </li>
                                                                            <li className={`${ActiveTabs === 2 ? "active" : ""}`}
                                                                                onClick={() => scrollToSection(instructorRef, 2)}>
                                                                                Instructor
                                                                            </li>
                                                                            <li className={`${ActiveTabs === 3 ? "active" : ""}`}
                                                                                onClick={() => scrollToSection(reviewsRef, 3)}>
                                                                                Reviews
                                                                            </li>
                                                                        </ul>
                                                                    </div>

                                                                    <div className="tab_content current active">
                                                                        <div id="overview" ref={overviewRef} >

                                                                            <div className="tabs_item current">
                                                                                <div className="courses-details-tab-content">
                                                                                    <div className="courses-details-into">
                                                                                        <h3>Description</h3>
                                                                                        <p>{ReactHtmlParser(CourseDeatils?.course_description)}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div id="curriculum" ref={curriculumRef} >

                                                                            <div className="tabs_item">
                                                                                <div className="courses-details-tab-content">
                                                                                    <div className="courses-details-accordion">
                                                                                        <ul className="accordion">
                                                                                            <li className="accordion-item">
                                                                                                <a className="accordion-title active" href="javascript:void(0)">
                                                                                                    <i className="ri-add-fill"></i>
                                                                                                    Introduction
                                                                                                </a>
                                                                                                <div className="accordion-content show">
                                                                                                    <div className="accordion-content-list">
                                                                                                        <div className="accordion-content-left">
                                                                                                            <i className="ri-file-text-line"></i>
                                                                                                            Introduction to the course
                                                                                                        </div>

                                                                                                    </div>
                                                                                                    <div className="accordion-content-list">
                                                                                                        <div className="accordion-content-left">
                                                                                                            <i className="ri-profile-line"></i>
                                                                                                            Number of classes
                                                                                                        </div>
                                                                                                        <div className="accordion-content-right">

                                                                                                            <div className="tag"> {CourseDeatils?.total_classes} Class</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="accordion-content-list">
                                                                                                        <div className="accordion-content-left">
                                                                                                            <i className="ri-play-circle-line"></i>
                                                                                                            Total Videos
                                                                                                        </div>
                                                                                                        <div className="accordion-content-right">

                                                                                                            <div className="tag"> {CourseDeatils?.total_vedios} Video</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="accordion-content-list">
                                                                                                        <div className="accordion-content-left">
                                                                                                            <i className="ri-file-text-line"></i>
                                                                                                            Number of Notes
                                                                                                        </div>
                                                                                                        <div className="accordion-content-right">
                                                                                                            <div className="tag"> {CourseDeatils?.total_classes} Notes</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="accordion-content-list">
                                                                                                        <div className="accordion-content-left">
                                                                                                            <i className="ri-time-line"></i>
                                                                                                            Duration
                                                                                                        </div>
                                                                                                        <div className="accordion-content-right">
                                                                                                            <div className="tag"> {CourseDeatils?.duration} Days</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>

                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div id="instructor" ref={instructorRef} >

                                                                            <div className="tabs_item">
                                                                                <div className="courses-details-tab-content">
                                                                                    <div className="courses-details-instructor">
                                                                                        <h3>About the instructor</h3>
                                                                                        <div className="details-instructor">
                                                                                            <img src={CourseDeatils?.instructor_img}
                                                                                                alt="instructor" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
                                                                                            <h3>{CourseDeatils?.instructor_name}</h3>
                                                                                            <span>{CourseDeatils?.instructor_designation}</span>
                                                                                        </div>
                                                                                        <p>{CourseDeatils?.instructor_description}</p>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                        <div id="reviews" ref={reviewsRef}>

                                                                            <>
                                                                                <div className="tabs_item">
                                                                                    <div className="courses-details-tab-content">
                                                                                        <div className="courses-details-review-form">
                                                                                            <div className="review-comments">
                                                                                                {
                                                                                                    CourseDeatils?.coursereview?.map((coursereviewResult) => {
                                                                                                        if(coursereviewResult?.status == "0"){
                                                                                                            return;
                                                                                                        }
                                                                                                        return (
                                                                                                            <>
                                                                                                                <div className="review-item">
                                                                                                                    <div className="content">
                                                                                                                        {
                                                                                                                            coursereviewResult?.student_profileImg != null ? (
                                                                                                                                <>
                                                                                                                                    <img src={coursereviewResult?.student_profileImg}
                                                                                                                                        alt="Images" />
                                                                                                                                </>

                                                                                                                            ) : (
                                                                                                                                <>
                                                                                                                                    <img src={CommonImageTransport?.userLogo}
                                                                                                                                        alt="Images" />
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        }

                                                                                                                        <div className="content-dtls">
                                                                                                                            <h4>{coursereviewResult?.student_fname} {coursereviewResult?.student_lname}</h4>
                                                                                                                            <span>{moment(coursereviewResult?.created_at).format("DD MMM, YYYY")}</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="rating">
                                                                                                                        <Rating readonly={true} initialValue={coursereviewResult?.rating} />
                                                                                                                    </div>
                                                                                                                    <h3>{coursereviewResult?.feedback_title}</h3>
                                                                                                                    <p>{coursereviewResult?.review}</p>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    CourseIs_Booking?.isCompleted === true && (
                                                                                        <div class="review-form mt-5">
                                                                                            <div class="contact-wrap-form">
                                                                                                <div class="contact-form">
                                                                                                    <h4>Write a Review</h4>
                                                                                                    <form id="contactForm" onSubmit={HandlepostWriteReview}>
                                                                                                        <div class="row">
                                                                                                            <div class="col-lg-6 col-sm-6">
                                                                                                                <div class="form-group">
                                                                                                                    <input type="text" name="name" value={`${GetUserProfile?.fname} ${GetUserProfile?.lname}`}
                                                                                                                        id="name" class="form-control"
                                                                                                                        required
                                                                                                                        disabled
                                                                                                                        data-error="Please enter your name"
                                                                                                                        placeholder="Your Name" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="col-lg-6 col-sm-6">
                                                                                                                <div class="form-group">
                                                                                                                    <input type="email" name="email" value={GetUserProfile?.email}
                                                                                                                        id="email" class="form-control"
                                                                                                                        required
                                                                                                                        disabled
                                                                                                                        data-error="Please enter your email"
                                                                                                                        placeholder="Your Email" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-12 col-md-6">
                                                                                                                <div className="form-group d-flex align-items-center gap-4">
                                                                                                                    <label>Star Ratting</label>
                                                                                                                    <div className="rate">
                                                                                                                        {[...Array(5)].map((_, index) => {
                                                                                                                            const starValue = 5 - index;
                                                                                                                            return (
                                                                                                                                <label key={index}
                                                                                                                                    title={`${starValue} rating`}
                                                                                                                                    style={{ fontSize: "2.5rem" }}
                                                                                                                                    className={starValue <= rating ? 'star filled' : 'star'}
                                                                                                                                    onClick={() => setRating(starValue)}>
                                                                                                                                    ★
                                                                                                                                </label>
                                                                                                                            );
                                                                                                                        })}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="col-lg-6 col-sm-12">
                                                                                                                <div class="form-group">
                                                                                                                    <input type="text" name="feedback_title" value={feedback_title}
                                                                                                                        class="form-control" required
                                                                                                                        data-error="Your website"
                                                                                                                        onChange={(e) => setFeedback_Title(e.target.value)}
                                                                                                                        placeholder="title" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="col-lg-12 col-md-12">
                                                                                                                <div class="form-group">
                                                                                                                    <textarea name="review" value={review}
                                                                                                                        onChange={(e) => setReview(e.target.value)}
                                                                                                                        class="form-control"
                                                                                                                        id="message" cols="30" rows="8"
                                                                                                                        required
                                                                                                                        data-error="Write your message"
                                                                                                                        placeholder="Your Message.."></textarea>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="col-lg-12 col-md-12">
                                                                                                                <button type="submit"
                                                                                                                    class="default-btn">
                                                                                                                    Post A Comment
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </form>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }


                                                                            </>


                                                                        </div>




                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="courses-details-sidebar m-0">
                                                                <img src={CourseDeatils?.course_img} className="w-100" height="200"
                                                                    alt="Courses" />
                                                                <div className="content">
                                                                    <div className="course-sidebar">
                                                                        <h3>Course Features</h3>
                                                                        <ul className="scourse_list">
                                                                            <li>
                                                                                <span className="cside-label">
                                                                                    <i className="ri-time-fill"></i> Duration
                                                                                </span>

                                                                                <span className="cside-value">
                                                                                    {CourseDeatils?.duration}
                                                                                </span>
                                                                            </li>

                                                                            <li>
                                                                                <span className="cside-label">
                                                                                    <i className="ri-profile-line"></i> Class
                                                                                </span>

                                                                                <span className="cside-value">
                                                                                    {CourseDeatils?.total_classes}
                                                                                </span>
                                                                            </li>
                                                                            <li>
                                                                                <span className="cside-label">
                                                                                    <i className="ri-video-fill"></i> Video
                                                                                </span>

                                                                                <span className="cside-value">
                                                                                    {CourseDeatils?.total_vedios}
                                                                                </span>
                                                                            </li>

                                                                            <li>
                                                                                <span className="cside-label">
                                                                                    <i className="ri-bar-chart-grouped-fill"></i> Skill Level
                                                                                </span>

                                                                                <span className="cside-value">
                                                                                    {CourseDeatils?.skill_level}
                                                                                </span>
                                                                            </li>

                                                                            <li className="m-0">
                                                                                <span className="cside-label">
                                                                                    <i className="ri-global-line"></i> Language
                                                                                </span>

                                                                                <span className="cside-value">
                                                                                    {CourseDeatils?.course_language}
                                                                                </span>
                                                                            </li>
                                                                        </ul>
                                                                        {
                                                                            CourseIs_Booking?.isCompleted === true ? (
                                                                                <div className="text-center" onClick={() => navigate(`/Student/Student_Coaching_Session_Complete/${CourseDeatils?.id}`)}>
                                                                                    <Link to="#" className="default-btn" >Completed</Link>
                                                                                </div>
                                                                            ) : CourseIs_Booking?.isBooking === true ? (
                                                                                <div className="text-center" onClick={() => navigate(`/Student/Student_Coaching_Session_Complete/${CourseDeatils?.id}`)}>
                                                                                    <Link to="#" className="default-btn">Continue</Link>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="text-center">
                                                                                    <Link to="#" onClick={() => setShowBookNow(true)} className="default-btn">Book Now</Link>
                                                                                </div>
                                                                            )
                                                                        }



                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>

                        ) : (
                            <div className='text-center mt-5'>
                                <h3 style={{ marginTop: "220px" }}>Not Any Traning Program Available Here</h3>
                            </div>
                        )
                    }
                </>

            }

            <Student_Footer />
            <Modal show={ShowBookNow} >
                <Modal.Header closeButton onClick={() => setShowBookNow(false)}>
                    <Modal.Title>Continue The Training Program</Modal.Title>
                </Modal.Header>
                <Modal.Body> "Students are allowed to  enroll one training program at a time.
                    So please continue the training program which you
                    are most interested in completing the training program.
                    You can enrol second training program after successfully completion of first enrolled  training program. </Modal.Body>

                <Modal.Footer>
                    <div className="text-center">
                        <Link to="#" onClick={() => setShowBookNow(false)}
                            className="default-btn">Cancel</Link>
                    </div>
                    <div className="text-center">
                        <Link to="#" onClick={() => CourseDetailsId1(CourseDeatils?.id)}
                            className="default-btn">Continue</Link>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Student_Traning_Program