import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ProfileSections, ProfileUpdate } from '../../../../api/Auth';
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { CitySelect, CountrySelect, StateSelect, } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import 'react-tagsinput/react-tagsinput.css';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import 'react-tagsinput/react-tagsinput.css';
import Header from '../../../common/header/Header';
import Common_Profile_Section from '../common_profile_section/Common_Profile_Section';

const Edit_Profile = () => {
    const navigate = useNavigate();
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [countryCode, setCountryCode] = useState("");
    const [defaultCountryCode, setDefaultCountryCode] = useState("");
    const [defaultState, setDefaultState] = useState("");
    const [defaultCity, setDefaultCity] = useState("");
    const [profileImg, setProfileImg] = useState("");
    const [dob, setDob] = useState("");
    const [countryid, setCountryid] = useState(0);
    const [stateid, setstateid] = useState(0);
    const [cityid, setcityid] = useState(0);
    const [error, setError] = useState('')
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [phoneNumber, setPhoneNumber] = useState()
    const [value, setValue] = useState(mobile)
    // <---------- Change the DOB Formatted here -------------->
    const [formattedDob, setFormattedDob] = useState('');

    useEffect(() => {
        let [firstIndex, ...remaining] = formatPhoneNumberIntl(value).split(" ");
        let joinedRemaining = remaining.join('');

        setCountryCode(firstIndex)
        setPhoneNumber(joinedRemaining)
        console.log("country+++", value);
    }, [value])

    // <---------- For the Validation for the name & last name ------------------>
    const handleInputChange = (event, setter, setError) => {
        const value = event.target.value;
        setter(value);
        const regex = /^[a-zA-Z]+$/;
        if (!regex.test(value)) {
            setError('Name should contain only characters');
        } else {
            setError('');
        }
    };

    const funCountryId = (e) => {
        setDefaultCountryCode(e.iso2)
        setCountryid(e.id);
    }

    const funStateId = (e) => {
        setDefaultState(e.iso2)
        setstateid(e)
    }

    const funCityId = (e) => {
        setDefaultCity(e.iso2)
        setcityid(e)
    }

    const user_id = localStorage.getItem("User_id")
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    }

    // <----------- Get the User Profile --------------->
    const ProfileSections1 = async () => {
        const response = await ProfileSections({ user_id, headers })
        setFname(response?.data?.data?.profile?.fname)
        setLname(response?.data?.data?.profile?.lname)
        setEmail(response?.data?.data?.profile?.email)
        setMobile(response?.data?.data?.profile?.mobile)
        setCountry(response?.data?.data?.profile?.country)
        setState(response?.data?.data?.profile?.state)
        setCity(response?.data?.data?.profile?.city)
        setDob(response?.data?.data?.profile?.dob)
        setCountryCode(response?.data?.data?.profile?.countrycode)
    }

    // <----------- Edit Profile Sections ------------------>
    const EditProfile = async () => {
        const formdata = new FormData();
        formdata.append("countryCode", value)
        formdata.append("dob", dob)
        formdata.append("city", city)
        formdata.append("state", state)
        formdata.append("country", defaultCountryCode)
        formdata.append("mobile", mobile)
        formdata.append("email", email)
        formdata.append("lname", lname)
        formdata.append("fname", fname)
        formdata.append("profileImg", profileImg)
        const headers = {
            "Authorization": `Bearer ${token}`
        }
        const response = await ProfileUpdate(formdata, headers);
        console.log("EditProfile", response)
        if (response?.data?.statusCode === "200") {
            toast.success("Profile Updated Successfully !!!")
            setTimeout(() => {
                navigate("/Profile")
            }, 3000)
        }
    }

    useEffect(() => {
        ProfileSections1();
    }, [])


    // <-------- show the ByDefault Country code here ------------->
    useEffect(() => {
        console.log("++++++++++++++++++countryCode", defaultCountryCode);
        setValue(defaultCountryCode);
    }, [defaultCountryCode]);

    // <------------ Show the DOB bydeafult Here ---------------->
    useEffect(() => {
        setFormattedDob(formatDateForDisplay(dob));
    }, [dob]);

    // <------------ Show the DOB format Date ---------------->
    const formatDateForDisplay = (dateString) => {
        if (!dateString) return '';
        return moment(dateString, 'YYYY-MM-DD').format('YYYY-MM-DD');
    };



    return (
        <div>
            <div className="bg_nav-slid bg-white">
                <Header />
            </div>
            <div className="stepbg">
                <div className="header_space"></div>
                <div className="client_filter-job pt-2 pb-5">
                    <div className="stepsForm">
                        <form method="post" id="msform">
                            <div className="container">
                                <div className="row">
                                    <ToastContainer style={{ marginTop: "100px" }} />
                                    <div className="col-md-4">
                                        <Common_Profile_Section />
                                    </div>
                                    <div className="col-md-8 d-flex">
                                        <div className="flexdr position-relative p-3 p-md-4 shadow-lg">
                                            <div className="professional-sp row">
                                                <div className="col-12 col-md-12 mb-3 text-center border-bottom pb-3">
                                                    <div className="form-group">
                                                        <label className="fieldlabels">Upload Profile image</label>
                                                        <div id='profile-upload'>
                                                            <div className="hvr-profile-img"><input type="file" name="profileImg" onChange={(e) => setProfileImg(e.target.files[0])} id='getval'
                                                                className="upload w180"
                                                                title="Dimensions 180 X 180" /></div>
                                                            <i className="fa ri-camera-2-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label className="fieldlabels">First Name</label>
                                                        <input
                                                            type="text"
                                                            name="fname"
                                                            value={fname}
                                                            onChange={(event) => handleInputChange(event, setFname, setFirstNameError)}
                                                            placeholder="First Name"
                                                        />
                                                        {firstNameError && <p style={{ color: 'red', fontSize: '14px' }}>{firstNameError}</p>}

                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label className="fieldlabels">Last Name</label>
                                                        <input type="text" name="uname" placeholder="Last Name"
                                                            value={lname}
                                                            onChange={(event) => handleInputChange(event, setLname, setLastNameError)}
                                                        />
                                                        {lastNameError && <p style={{ color: 'red', fontSize: '14px' }}>{lastNameError}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="fieldlabels">Verify email ID *</label>
                                                        <input type="email" name="uname" placeholder="Email ID"
                                                            value={email}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12">
                                                    <div className="form-group mb-3">
                                                        <label className="fieldlabels">Verify Phone Number *</label>
                                                        <div className="combined-input-container ">
                                                            <div className="phoneinput">
                                                                <PhoneInput
                                                                    international
                                                                    countryCallingCodeEditable={false}
                                                                    defaultCountry={defaultCountryCode}
                                                                    placeholder="Code"
                                                                    value={countryCode}
                                                                    onChange={setCountryCode} />
                                                            </div>

                                                            <div className='EditMobile'>
                                                                <input name='mobile' value={mobile}
                                                                    onChange={(e) => setMobile(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="fieldlabels">Linkedin Profile link </label>
                                                        <input type="text" name="uname"

                                                            placeholder="https://in.linkedin.com/"
                                                            value="https://in.linkedin.com/op34w" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <h5>Location</h5>
                                                </div>

                                                <div className='col-md-4'>
                                                    <div className="form-floating form-group">
                                                        <h6>Country</h6>
                                                        <CountrySelect
                                                            name="defaultCountryCode"
                                                            onChange={(e) => {
                                                                setCountry(e?.target?.value);
                                                                funCountryId(e)
                                                            }}
                                                            placeHolder="Select Country"

                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <h6>State</h6>
                                                    <StateSelect
                                                        countryid={countryid}
                                                        onChange={(selectedState) => {

                                                            funStateId(selectedState.id)
                                                            setState(selectedState.name);
                                                        }}
                                                        placeHolder="Select State"
                                                    />
                                                </div>
                                                <div className='col-md-4'>
                                                    <h6>City</h6>
                                                    <CitySelect
                                                        countryid={countryid}
                                                        stateid={stateid}
                                                        cityid={cityid}
                                                        onChange={(selectedCity) => {
                                                            console.log("Selected city:", selectedCity);
                                                            funCityId(selectedCity.id)
                                                            setCity(selectedCity.name);
                                                        }}
                                                        placeHolder="Select City"
                                                    />

                                                </div>
                                                <div className="col-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="fieldlabels">DOB</label>
                                                        <input type="date" name="dob"
                                                            placeholder="DOB"
                                                            onChange={(e) => setDob(e.target.value)}
                                                            value={formattedDob} />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="col-12 col-md-12">
                                                    <div className="form-group">
                                                        <Link to="#" type='button' onClick={EditProfile}
                                                            className="default-btn rounded px-5">Submit</Link>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Edit_Profile