import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment';
import { ProfileSections } from '../../../../api/Auth';
import Student_Header from '../student_header/Student_Header';
import Student_Common_Profile_section from '../student_common_profile_section/Student_Common_Profile_section';

const Student_Profile = () => {
  const [GetUserProfile, SetGetUserProfile] = useState([])
  const user_id = localStorage.getItem("User_id")

  // <---------- User Authentciation token's ------------>
  const token = localStorage.getItem("User_Token");
  const headers = {
    "Authorization": `Bearer ${token}`
  }

  // <--------- Get the Student Profile section's ----------->
  const ProfileSections1 = async () => {
    const response = await ProfileSections({ user_id, headers })
    SetGetUserProfile(response?.data?.data)
  }

  // <--------- Get the Student Profile section's ----------->
  useEffect(() => {
    ProfileSections1();
  }, [])

  
  

  return (
    <div className="stepbg">
      {/* <------ Student Header's -----------> */}
      <Student_Header />
      <div className="client_filter-job py-5 pt-2">
        <div className="stepsForm">
          <form method="post" id="msform">
            <div className="container">
              <div className="row">
                <div className='col-md-4'>
      {/* <------ Student Common Profile Section -----------> */}
                  <Student_Common_Profile_section />
                </div>
                <div className="col-md-8 d-flex">
                  <div className="flexdr position-relative p-3 p-md-4 shadow-lg">
                    {/* <--------- Personal Information Sections -------------> */}
                    <div className="personal_user_info border p-4 rounded">
                      <div className="d-flex justify-content-between">
                        <h4>Personal Information</h4>
                        <Link to="/Student/Student_Edit_Profile" className="default-btn rounded p-2">Edit 
                        <i className="ri-edit-2-fill" /></Link>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group info__profile">
                            <p>First Name</p>
                            <h6>{GetUserProfile?.profile?.fname}</h6>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group info__profile">
                            <p>Last Name</p>
                            <h6>{GetUserProfile?.profile?.lname}</h6>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group info__profile">
                            <p>Email ID</p>
                            <h6>{GetUserProfile?.profile?.email}</h6>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group info__profile">
                            <p>Phone Number</p>
                            <h6>{GetUserProfile?.profile?.mobile?.replace(/null/g, '')}</h6>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group info__profile">
                            <p>Dob</p>
                            <h6>
                              {moment(GetUserProfile?.student_detail?.dob).format("DD-MM-YYYY")}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="personal_user_info mt-4 border p-4 rounded">
                      <div className="d-flex justify-content-between">
                        <h4>Address</h4>
                        <Link to="/Student/Student_Edit_Profile" className="default-btn rounded p-2">Edit <i className="ri-edit-2-fill" /></Link>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group info__profile">
                            <p>Country</p>
                            <h6>{GetUserProfile?.profile?.country}</h6>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group info__profile">
                            <p>State</p>
                            <h6>{GetUserProfile?.profile?.state}</h6>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group info__profile">
                            <p>City</p>
                            <h6>{GetUserProfile?.profile?.city}</h6>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group info__profile">
                            <p>Nationlity</p>
                            <h6>{GetUserProfile?.student_detail?.nationality}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Student_Profile;
