import React,{useEffect,useState} from 'react'
import Header from '../../../common/header/Header'
import Footer from '../../../common/footer/Footer'
import { PrivacyPolicy } from '../../../../api/Global'
import ReactHtmlParser from "react-html-parser";

const PrivacyPolicy1 = () => {
      // <------------ P & P ----------------->
  const [PrivacyPolicyList, setPrivacyPolicyList] = useState({});
//   const [TermsConditionList, setTermsConditionList] = useState({});

// <---------- Get Privacy & ploicy ------------->
const GetPrivacyPolicy = async()=>{
    try{
  const response = await PrivacyPolicy();
  setPrivacyPolicyList(response?.data?.data)
    }catch(error){
       console.log("error",error)
    }
  }
  useEffect(()=>{
    GetPrivacyPolicy();
  },[])
    useEffect(() => {
        // Scroll to the top of the page when the component is rendered
        window.scrollTo(0, 0);
      }, []);
  return (
    <div>
        <div className="bg_nav-slid bg-white">
       <Header/>
    </div>



    <div className="privacy-policy-area pt-70 pb-70">
        <div className="container">
            <div className="section-title">
                <h3>Privacy Policy</h3>
            </div>
            <div className="row pt-45">
                <div className="col-lg-12">
                    <div className="single-content">
                        <h3>{PrivacyPolicyList?.title}</h3>
                        <p>{ReactHtmlParser(PrivacyPolicyList?.desciption)}</p>
                        
                    </div>
                  
                </div>
            </div>
        </div>
    </div>


    {/* <div className="newsletter-area section-bg ptb-100">
        <div className="container">
            <div className="row">
                <div className="col-lg-5">
                    <div className="section-title mt-rs-20">
                        <span>ARE YOU IMPRESSED FOR AMAZING SERVICES?</span>
                        <h2>Subscribe our newsletter</h2>
                    </div>
                </div>
                <div className="col-lg-7">
                    <form className="newsletter-form" data-toggle="validator" method="POST">
                        <input type="email" className="form-control" 
                        placeholder="Enter Your Email Address" name="EMAIL"
                            required autocomplete="off"/>
                        <button className="subscribe-btn" type="submit">
                            Subscribe Now <i className="flaticon-paper-plane"></i>
                        </button>
                        <div id="validator-newsletter" className="form-result"></div>
                    </form>
                </div>
            </div>
        </div>
    </div> */}
    <Footer/>
    </div>
  )
}

export default PrivacyPolicy1