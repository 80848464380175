import Mentor_header from '../mentor_header/Mentor_Header';
import React, { useState, useEffect } from 'react';
import "../../../../assets/css/style.css";
import { MentorSlotBook, GetMentorSlot } from '../../../../api/Mentor';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import moment from 'moment-timezone';
import Mentor_Footer from '../mentor_footer/Mentor_Footer';

const Mentor_Booking = () => {
    const [ActiveDay, setActiveDay] = useState("Sunday");
    const [bookingDays, setBookingDays] = useState({});
    const [bookig_day, setBooking_day] = useState([]);
    const [booking_time, setBooking_time] = useState([]);
    const [BookingTimeSlot, setBookingTimeSlot] = useState({});
    const token = localStorage.getItem("User_Token");
    const mentor_userid = localStorage.getItem("User_id");
    const DateDataFirst = new Date();
    const timeZone = moment.tz.guess(); 
    const timeZoneAbbr = moment.tz(DateDataFirst, timeZone).format('z'); 
    
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const timeSlots = [
        `12:00 AM To 01:00 AM`, `01:00 AM To 02:00 AM`, `02:00 AM To 03:00 AM`,
        `03:00 AM To 04:00 AM`, `04:00 AM To 05:00 AM`, `05:00 AM To 06:00 AM`,
        `06:00 AM To 07:00 AM`, `07:00 AM To 08:00 AM`, `08:00 AM To 09:00 AM`,
        `09:00 AM To 10:00 AM`, `10:00 AM To 11:00 AM`, `11:00 AM To 12:00 PM`,
        `12:00 PM To 01:00 PM`, `01:00 PM To 02:00 PM`, `02:00 PM To 03:00 PM`,
        `03:00 PM To 04:00 PM`, `04:00 PM To 05:00 PM`, `05:00 PM To 06:00 PM`,
        `06:00 PM To 07:00 PM`, `07:00 PM To 08:00 PM`, `08:00 PM To 09:00 PM`,
        `09:00 PM To 10:00 PM`, `10:00 PM To 11:00 PM`, `11:00 PM To 12:00 PM`
    ];
   

    // <--------- Time Slot Active & DisActive ------------>
    const HandleTimeSlotClick = (slot, day) => {
        setBookingTimeSlot(prevBookingTime => {
            const updatedDaySlots = prevBookingTime[day] ? [...prevBookingTime[day]] : [];
            const slotIndex = updatedDaySlots.indexOf(slot);
            if (slotIndex !== -1) {
                updatedDaySlots.splice(slotIndex, 1);
            } else {
                updatedDaySlots.push(slot);
            }

            return {
                ...prevBookingTime,
                [day]: updatedDaySlots,
            };
        });


    };
    console.log("BookingTimeSlot", BookingTimeSlot)

    // <--------- Handle the Day Accroding to the Date --------------->
    const handleDayClick = (day) => {
        setActiveDay(day);
        setBooking_time(bookingDays[day] || []);
        setBooking_day(prevSelectedDays => {
            if (prevSelectedDays.includes(day)) {
                return prevSelectedDays.filter(selectedDay => selectedDay !== day);
            } else {
                return [...prevSelectedDays, day];
            }
        });
    };

    // <---------- Create the Time Slot  --------------->
    const HandleTimeSlot = async (e) => {
        e.preventDefault();
        const headers = {
            "Authorization": `Bearer ${token}`
        }
        const timeSlotArray = {
            time_sloat: { ...BookingTimeSlot }
        }
        await MentorSlotBook(timeSlotArray, headers);
        toast.success("Sloats updated successfully !!!")
    }
    
    // <------------ Change the Response in the Day Formated here ----------->
    const transformResponse = (response) => {
        const transformedData = {};
        for (const day in response) {
            transformedData[day] = response[day].map(slot => slot.booking_time);
        }
        return transformedData;
    };

    // <------------ Get the Our Booked slots ----------->
    const GetHandleGetSlots = async () => {
        const headers = {
            "Authorization": `Bearer ${token}`
        };
        const GetSlotdata = {
            mentor_userid,
            day: null,
            is_student:false
        }
        const response = await GetMentorSlot(GetSlotdata, headers);
        const transformedData = transformResponse(response?.data?.data);
        setBookingTimeSlot(transformedData);
    }
    useEffect(() => {
        GetHandleGetSlots();
    }, [])
    console.log("GetHandleGetSlots", BookingTimeSlot)
    useEffect(() => {
        setBooking_time(bookingDays[ActiveDay] || []);
    }, [ActiveDay, BookingTimeSlot]);

    useEffect(() => {
        // Scroll to the top of the page when the component is rendered
        window.scrollTo(0, 0);
      }, []);

    return (
        <div>
            <div className="bg_nav-slid bg-white">
                <Mentor_header />
            </div>
            <div className="header_space"></div>
            <div className="recruiter_list pb-5 pt-4">
                <div className="container-fluid">
                    <div className="row">
                        <ToastContainer style={{ marginTop: "120px" }} />
                        <div className='mt-4 text-center'>
                        <h3>Please choose your available timeslots for the week and save your selections.</h3>
                        </div>
                        <div className="shadow bg-white mt-4">
                            <div className="card">
                                <div className="card-body1">
                                    {daysOfWeek?.map((day, index) => (
                                        <div key={index} className="mb1-4">
                                            <ul className="nav nav-pills tabs_signup hiredpppp justify-content-start gap-2 mb-3"
                                                id="pills-tab" role="tablist">
                                                <li className="nav-item"><a className='nav-link active'>{day}</a></li>
                                            </ul>

                                            <div className="timeslotbook1 skillstopic addtimes" style={{ width: "200px" }}>
                                                {timeSlots.map((slot, slotIndex) => (
                                                    <div
                                                        key={slotIndex}
                                                        className={BookingTimeSlot[day]?.includes(slot) ? "active1" : ""}
                                                        onClick={() => HandleTimeSlotClick(slot, day)}
                                                        style={{ cursor: 'pointer' }}>
                                                        {slot}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                    <div className="text-end pe-3 pb-3">
                                        <a href="#" onClick={(e) => HandleTimeSlot(e)} className="default-btn rounded-pill">Save Time</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Mentor_Footer/>
        </div>
    )
}

export default Mentor_Booking