import React from 'react'

export const TimeZoneMappings = {
    // General Time Zones
    'UTC': 'UTC',
    'GMT': 'GMT',
    'BST': 'Europe/London', // British Summer Time
    'CET': 'Europe/Paris', // Central European Time
    'CEST': 'Europe/Paris', // Central European Summer Time
    'EET': 'Europe/Athens', // Eastern European Time
    'EEST': 'Europe/Athens', // Eastern European Summer Time
    'MSK': 'Europe/Moscow', // Moscow Time

    // North American Time Zones
    'EST': 'America/New_York', // Eastern Standard Time
    'EDT': 'America/New_York', // Eastern Daylight Time
    'CST': 'America/Chicago', // Central Standard Time
    'CDT': 'America/Chicago', // Central Daylight Time
    'MST': 'America/Denver', // Mountain Standard Time
    'MDT': 'America/Denver', // Mountain Daylight Time
    'PST': 'America/Los_Angeles', // Pacific Standard Time
    'PDT': 'America/Los_Angeles', // Pacific Daylight Time

    // South American Time Zones
    'ART': 'America/Argentina/Buenos_Aires', // Argentina Time
    'BRT': 'America/Sao_Paulo', // Brasilia Time

    // Australian Time Zones
    'AEST': 'Australia/Sydney', // Australian Eastern Standard Time
    'AEDT': 'Australia/Sydney', // Australian Eastern Daylight Time
    'ACST': 'Australia/Adelaide', // Australian Central Standard Time
    'ACDT': 'Australia/Adelaide', // Australian Central Daylight Time
    'AWST': 'Australia/Perth', // Australian Western Standard Time

    // Asian Time Zones
    'IST': 'Asia/Kolkata', // Indian Standard Time
    'JST': 'Asia/Tokyo', // Japan Standard Time
    'CST': 'Asia/Shanghai', // China Standard Time (Note: 'CST' can be ambiguous)
    'HKT': 'Asia/Hong_Kong', // Hong Kong Time
    'SGT': 'Asia/Singapore', // Singapore Time
    'PHT': 'Asia/Manila', // Philippine Time

    // Middle Eastern Time Zones
    'AST': 'Asia/Riyadh', // Arabian Standard Time
    'IDT': 'Asia/Jerusalem', // Israel Daylight Time

    // Oceanic Time Zones
    'NZST': 'Pacific/Auckland', // New Zealand Standard Time
    'NZDT': 'Pacific/Auckland', // New Zealand Daylight Time
    'FJT': 'Pacific/Fiji', // Fiji Time
    'SBT': 'Pacific/Guadalcanal', // Solomon Islands Time

    // Time Zones with Offsets
    'UTC-4': 'America/Caracas', // Venezuela Time
    'UTC-5': 'America/Bogota', // Colombia Time
    'UTC+3': 'Europe/Moscow', // Moscow Time (sometimes used)
    'UTC+4': 'Asia/Dubai', // Gulf Standard Time
    'UTC+5': 'Asia/Yekaterinburg', // Yekaterinburg Time
    'UTC+8': 'Asia/Singapore', // Singapore Time
    'UTC+9': 'Asia/Tokyo', // Japan Standard Time
    'UTC+10': 'Australia/Sydney', // Australian Eastern Standard Time
    'UTC+11': 'Pacific/Noumea', // New Caledonia Time
    'UTC+12': 'Pacific/Fiji' // Fiji Time
};