import React, { useEffect } from "react";
import Mentor_header from "../mentor_header/Mentor_Header";
import CommonImageTransport from "../../../common/images/CommonImages";
import { Link } from "react-router-dom";
import { useState } from "react";
import Typewriter from "typewriter-effect";
import { MentorSeenStudentList } from "../../../../api/Mentor";
import moment from "moment";
import Loader from "../../../../loader/Loader";
import Mentor_Footer from "../mentor_footer/Mentor_Footer";
import { TimeZoneMappings } from "../../../common/timezone/TimeZone";
const CoachingMentor = () => {
  const [is_Loading, setIs_Loading] = useState(false);
  const [MentorCompleteList, setMentorCompleteList] = useState([]);
  const [booking_id, setBooking_id] = useState("");
  const [MentorStudentDetails, setMentorStudentDetails] = useState({});
  const [ActiveTabs, setActiveTabs] = useState(MentorCompleteList[0]?.id);
  const [showSearchFields, setShowSearchFields] = useState(false);
  const [filteredCompleteStudentList, setFilteredCompleteStudentList] =
    useState([]);
  const [searchQuery, setSearchQuery] = useState("");

//   <----------- Login Mentor's data -------------->
  const mentor_Login_Data = localStorage.getItem("user_Login_Data");
  const mentor_login_parse_data = JSON.parse(mentor_Login_Data);
//   <---------- User Authentication token's ------------------>
  const token = localStorage.getItem("User_Token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const HandleActiveTabs = (tabs) => {
    setActiveTabs(tabs);
  };

  // <-------------- Handle the seach Filter data ---------->
  const handlesearchinputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // <------- Student Complete Session List  ---------->
  const handleMentorSeenStudentList = async () => {
    setIs_Loading(true);
    try {
      const MentorSeenStudentListData = {
        status: 3,
      };
      const response = await MentorSeenStudentList(
        MentorSeenStudentListData,
        headers
      );
      setMentorCompleteList(response?.data?.data?.booking);
      handleMentorSeenStudentDetails(response?.data?.data?.booking[0]?.id);
      setIs_Loading(false);
    } catch (error) {
      setIs_Loading(true);
    }
  };

  const getFullTimeZoneName = (abbreviation) => {
    return TimeZoneMappings[abbreviation] || abbreviation;
  };

  // <----------- Student Profile Details & Mentor Booking details ----------->
  const handleMentorSeenStudentDetails = async (id) => {
    try {
      const MentorSeenStudentListData = {
        status: 3,
        id: id ? id : ActiveTabs,
      };
      const response = await MentorSeenStudentList(
        MentorSeenStudentListData,
        headers
      );
    //   setMentorStudentDetails(response?.data?.data);
      const studentData = response?.data?.data;
      if (!studentData) {
        throw new Error("No student data received");
      }
      setBooking_id(studentData?.booking?.id);

      // Retrieve dynamic time zones
      const mentorTimeZone = getFullTimeZoneName(mentor_login_parse_data?.time_zone); 
      console.log("mentorTimeZone", mentorTimeZone);

      // Assuming studentTimeZone could be 'IST' or another valid timezone
      let studentTimeZone = getFullTimeZoneName(studentData?.booking?.slot_time_zone); 
      console.log("studentTimeZone", studentTimeZone);

      if (
        !mentorTimeZone ||
        !studentTimeZone ||
        !studentData?.booking?.slot_time) {
        throw new Error("Missing time zone or slot time data");
      }
      const slotStrings = studentData.booking.slot_time
        .split(",")
        ?.map((slot) => slot.trim());
      const referenceDate = moment().format("YYYY-MM-DD");

      const convertedSlots = slotStrings?.map((slot) => {
        const [startTimeStr, endTimeStr] = slot.split(" To ");

        try {
          const startTime = moment.tz(
            `${referenceDate} ${startTimeStr}`,
            "YYYY-MM-DD h:mm A",
            studentTimeZone
          );
          const endTime = moment.tz(
            `${referenceDate} ${endTimeStr}`,
            "YYYY-MM-DD h:mm A",
            studentTimeZone
          );
          const startTimeMentor = startTime
            .clone()
            .tz(mentorTimeZone)
            .format("h:mm A");
          const endTimeMentor = endTime
            .clone()
            .tz(mentorTimeZone)
            .format("h:mm A");

          return `${startTimeMentor} To ${endTimeMentor}`;
        } catch (error) {
          console.error(`Error converting time slot ${slot}:`, error);
          return slot;
        }
      });
      const updatedStudentData = {
        ...studentData,
        booking: {
          ...studentData.booking,
          slot_time: convertedSlots,
        },
      };
      setMentorStudentDetails(updatedStudentData);
    }  catch (error) {
        console.error("Error fetching mentor details:", error);
      }
  };
  // <--------- Get Student Details By Id -------------------->
  const handleMentorSeenStudentById = (id) => {
    setActiveTabs(id);
    handleMentorSeenStudentDetails(id);
  };

  // <--------- get the Filterd data when we render on this page ------------>
  useEffect(() => {
    const searchTerms = searchQuery.toLowerCase().trim().split(/\s+/); // Split search query by spaces (trim to avoid leading/trailing spaces)

    const filtered = MentorCompleteList?.filter((MentorListResult) => {
      const fullName =
        `${MentorListResult?.fname} ${MentorListResult?.lname}`.toLowerCase(); // Combine fname and lname with a space

      // Check if the search query matches the full name (with space between fname and lname)
      const matchesFullName = fullName.includes(searchQuery.toLowerCase());

      // Check if the search terms match fname or lname individually
      const matchesFnameLname = searchTerms.some(
        (term) =>
          MentorListResult?.fname?.toLowerCase().includes(term) ||
          MentorListResult?.lname?.toLowerCase().includes(term)
      );

      // Check if any of the search terms match city, state, or country
      const matchesCityStateCountry = searchTerms.some(
        (term) =>
          MentorListResult?.city?.toLowerCase().includes(term) ||
          MentorListResult?.state?.toLowerCase().includes(term) ||
          MentorListResult?.country?.toLowerCase().includes(term)
      );

      return (
        matchesFullName ||
        matchesFnameLname || // Check fname or lname individually
        matchesCityStateCountry || // Check city, state, country
        MentorListResult?.profession
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        MentorListResult?.email
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    });

    setFilteredCompleteStudentList(filtered);
  }, [MentorCompleteList, searchQuery]);

  // <---------- Render the Complete Student List &  Mentor Seen Student By Id -------------->
  useEffect(() => {
    handleMentorSeenStudentList();
    handleMentorSeenStudentById();
  }, []);
  // <--------- Show the First Student --------------->
  useEffect(() => {
    setActiveTabs(MentorCompleteList[0]?.id);
  }, [MentorCompleteList]);
  return (
    <div>
      {is_Loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <div className="bg_nav-slid bg-white">
            <Mentor_header />
          </div>
          <div className="recruiter_list pb-5 pt-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <ul className="nav tabs_signup hiredpppp justify-content-start gap-2 mb-3">
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        aria-current="page"
                        to="/mentor_pages/Mentor_Student_Profile"
                      >
                        All Profile
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/mentor_pages/Mentor_UpComing_Session"
                      >
                        Upcoming Sessions
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        aria-current="page"
                        to="/mentor_pages/CoachingMentor"
                      >
                        Completed Sessions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {MentorCompleteList && MentorCompleteList?.length > 0 ? (
                <>
                  <div className="col-12">
                    <form>
                      <div className="searchlocation">
                        <div className="iconfield">
                          <div className="icon_search">
                            <img
                              src={CommonImageTransport?.searchicon}
                              alt=""
                            />
                          </div>
                          {!showSearchFields ? (
                            <div onClick={() => setShowSearchFields(true)}>
                              <Typewriter
                                options={{
                                  loop: true,
                                }}
                                onInit={(typewriter) => {
                                  typewriter
                                    .typeString("Search by title")
                                    .callFunction(() => {})
                                    .pauseFor(2500)
                                    .deleteAll()
                                    .callFunction(() => {})
                                    .typeString("Search by location")
                                    .pauseFor(2500)
                                    .start();
                                }}
                              />
                            </div>
                          ) : (
                            <div>
                              <input
                                type="text"
                                placeholder=""
                                onChange={handlesearchinputChange}
                                style={{ border: "none", outline: "none" }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="iconfield">
                          <div className="icon_search">
                            <img
                              src={CommonImageTransport?.locationicon}
                              alt=""
                            />
                          </div>
                          <div className="input-field">
                            <input
                              type="text"
                              placeholder="USA, Florida"
                              className="form-control"
                              onChange={handlesearchinputChange}
                              required=""
                            />
                          </div>
                        </div>
                        <div className="input-field ad121">
                          <button type="submit" className="submit_btn">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {is_Loading ? (
                    <>
                      {/* <div className="chat-window text-center">
                                                <div style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    bottom: 0,
                                                    margin: "auto",
                                                }} className="spinner-border" role="status">
                                                    <span className="sr-only"></span>
                                                </div>
                                            </div> */}
                      <Loader />
                    </>
                  ) : (
                    <div className="left_recruiterlists row">
                      <div className="col-md-4 col-lg-4">
                        <div className="sticky-header">
                          <div
                            className="nav flex-column recruiter_all_lists nav-pills"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            {filteredCompleteStudentList?.map(
                              (MentorStudentListResult, index) => {
                                return (
                                  <>
                                    <div
                                      key={index}
                                      className={`nav-link ${
                                        ActiveTabs ===
                                        MentorStudentListResult?.id
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        HandleActiveTabs(
                                          MentorStudentListResult?.id
                                        );
                                        handleMentorSeenStudentById(
                                          MentorStudentListResult?.id
                                        );
                                      }}
                                      id="v-pills-home-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#v-pills-home"
                                      type="button"
                                      role="tab"
                                      aria-controls="v-pills-home"
                                      aria-selected="true"
                                    >
                                      <div className="card_rec position-relative">
                                        <div className="mentersvies0121">
                                          <div className="position-relative">
                                            {MentorStudentListResult?.profileimg !=
                                            null ? (
                                              <>
                                                <img
                                                  src={
                                                    MentorStudentListResult?.profileimg
                                                  }
                                                  className="img-fluid rounded"
                                                  alt="Instructor"
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  src={
                                                    CommonImageTransport?.userLogo
                                                  }
                                                  className="img-fluid rounded"
                                                  alt="Instructor"
                                                />
                                              </>
                                            )}

                                            <span className="live_user bg-success newuser">
                                              Completed
                                            </span>
                                          </div>
                                          <div className="instructors-details-contents">
                                            <div className="d-flex justify-content-between gap-2">
                                              <div className="comnam">
                                                <h3>
                                                  {
                                                    MentorStudentListResult?.fname
                                                  }
                                                </h3>
                                                <h6 className="sub-title ">
                                                  Email :
                                                  {
                                                    MentorStudentListResult?.email
                                                  }
                                                </h6>
                                                <h6 className="sub-title ">
                                                  {
                                                    MentorStudentListResult?.p_title
                                                  }
                                                </h6>
                                                <p>
                                                  {
                                                    MentorStudentDetails
                                                      ?.student_detail?.company
                                                  }
                                                </p>
                                                <div className="skillstopic">
                                                  {MentorStudentDetails?.student_skills?.map(
                                                    (StudentDetailsSkills) => {
                                                      return (
                                                        <>
                                                          <div>
                                                            {
                                                              StudentDetailsSkills?.skill
                                                            }
                                                          </div>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                                {/* <h6 className="sub-title">Phone :{MentorStudentListResult?.mobile}</h6> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      {filteredCompleteStudentList?.find(
                        (job) => job.id === ActiveTabs
                      ) && (
                        <div className="col-md-8 col-lg-8">
                          <div className="tab-content" id="v-pills-tabContent">
                            {MentorStudentDetails && (
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                <div
                                  className={`tab-pane fade ${
                                    ActiveTabs ? "show active" : ""
                                  }`}
                                  id={`v-pills-${ActiveTabs}`}
                                  role="tabpanel"
                                  aria-labelledby={`v-pills-${ActiveTabs}-tab`}
                                >
                                  <div className="card-instrutors shadow position-relative bg-white p-4">
                                    <div className="mentersvies0121">
                                      <div className="position-relative menter_pro">
                                        {MentorStudentDetails?.student_info
                                          ?.profileimg != null ? (
                                          <>
                                            <img
                                              src={
                                                MentorStudentDetails
                                                  ?.student_info?.profileimg
                                              }
                                              className="rounded_01top"
                                              alt="Instructor"
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              src={
                                                CommonImageTransport?.userLogo
                                              }
                                              className="rounded_01top"
                                              alt="Instructor"
                                            />
                                          </>
                                        )}

                                        <span className="live_user bg-success newuser">
                                          Completed
                                        </span>
                                      </div>
                                      <div className="instructors-details-contents mt-3">
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <h3>
                                              {
                                                MentorStudentDetails
                                                  ?.student_info?.fname
                                              }{" "}
                                              {/* {
                                                MentorStudentDetails
                                                  ?.student_info?.lname
                                              } */}
                                            </h3>
                                            <h6 className="sub-title mb-2">
                                              {
                                                MentorStudentDetails
                                                  ?.student_info?.email
                                              }
                                            </h6>
                                            {/* <h6 className="sub-title ">
                                                  {
                                                    MentorStudentListResult?.p_title
                                                  }
                                                </h6> */}
                                            {/* <h6 className="sub-title mb-2">
                                              Phone:
                                              {
                                                MentorStudentDetails
                                                  ?.student_info?.mobile
                                              }
                                            </h6> */}
                                          </div>
                                          <div className="bookbtnas d-flex align-items-center gap-2">
                                            <a
                                              href="#"
                                              className="btn btn-success btn-sm rounded"
                                              data-bs-toggle="modal"
                                              data-bs-target="#feedback"
                                            >
                                              Completed ✓
                                            </a>
                                          </div>
                                        </div>
                                        <p>
                                          {
                                            MentorStudentDetails?.student_detail
                                              ?.company
                                          }
                                        </p>
                                      </div>
                                    </div>
                                    {/* <div className="instructors-details-contents mt-3">
                                                                            <div className="businessmen">
                                                                                <div><i className="ri-briefcase-4-fill"></i> 9 years</div>
                                                                                <div><i className="ri-shield-user-fill"></i> Business And Management
                                                                                </div>
                                                                                <div><i className="ri-chat-voice-fill"></i>45 Bookings</div>
                                                                                <div><i className="ri-time-fill"></i>12.3 Hrs</div>
                                                                            </div>
    
                                                                        </div> */}
                                  </div>

                                  <div className="card-instrutors rounded shadow position-relative mt-3 bg-white p-4">
                                    <div className="card-body p-0">
                                      <div className="d-flex justify-content-between align-items-start gap-5">
                                        <div>
                                          <h6>Recorded Link:</h6>
                                          <div className="skillstopic">
                                            <div className="fw-bold bg-light text-primary">
                                              <a
                                                className="text-primary"
                                                target="_blank"
                                                href={
                                                  MentorStudentDetails?.booking
                                                    ?.recorded_link
                                                }
                                              >
                                                {
                                                  MentorStudentDetails?.booking
                                                    ?.recorded_link
                                                }
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-instrutors rounded shadow position-relative mt-3 bg-white p-4">
                                    <div className="card-body p-0">
                                      <div className="d-flex justify-content-between align-items-start gap-5">
                                        <div>
                                          <h6>Avaliable Time</h6>
                                          <div className="skillstopic">
                                            <div className="fw-bold bg-light text-primary">
                                              Date:
                                            </div>
                                            <div>
                                              {moment(
                                                MentorStudentDetails?.booking
                                                  ?.bookig_date
                                              ).format("YYYY-MM-DD")}
                                            </div>
                                            <div className="fw-bold bg-light text-primary">
                                              Day:
                                            </div>
                                            <div>
                                              {
                                                MentorStudentDetails?.booking
                                                  ?.booking_day
                                              }
                                            </div>
                                            <div className="fw-bold bg-light text-primary">
                                              Time:
                                            </div>
                                            <div>
                                              {MentorStudentDetails?.booking?.slot_time?.map(
                                                (slot, index, array) => (
                                                  <span key={index}>
                                                    {slot}
                                                    {index < array.length - 1 &&
                                                      ", "}
                                                  </span>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <------ Skills Sections ----------------> */}
                                <div className="instructors-details-contents mt-3">
                                  <div className="card-instrutors shadow bg-white p-4">
                                    <h5>Skills</h5>
                                    <div className="skillstopic">
                                      {MentorStudentDetails?.student_skills?.map(
                                        (StudentDetailsSkills) => {
                                          return (
                                            <>
                                              <div>
                                                {StudentDetailsSkills?.skill}
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {/* <-------- Eduction Sections --------------> */}
                                <div className="instructors-details-contents mt-3">
                                  <div className="card-instrutors shadow bg-white p-4">
                                    <h5>Education</h5>
                                    {MentorStudentDetails?.student_educations?.map(
                                      (StudentEductionResult) => {
                                        return (
                                          <>
                                            <div className="skillstopic_education">
                                              <div className="educationicon">
                                                <img
                                                  src={
                                                    CommonImageTransport?.education
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                              <div>
                                                <h6>
                                                  {
                                                    StudentEductionResult?.university
                                                  }
                                                </h6>
                                                <span>
                                                  {
                                                    StudentEductionResult?.degreetype
                                                  }
                                                </span>
                                                <p>
                                                  {moment(
                                                    StudentEductionResult?.startdate
                                                  ).format("YYYY")}{" "}
                                                  -{" "}
                                                  {moment(
                                                    StudentEductionResult?.enddate
                                                  ).format("YYYY")}
                                                </p>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                                {/* <-------- Work Experience Sections -------------> */}
                                <div className="instructors-details-contents mt-3">
                                  <div className="card-instrutors shadow bg-white p-4">
                                    <h5>Work Experience</h5>
                                    <div className="skillstopic_education">
                                      <div className="educationicon">
                                        <img
                                          src={CommonImageTransport?.education}
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <h6>
                                          {
                                            MentorStudentDetails?.student_detail
                                              ?.p_title
                                          }
                                        </h6>
                                        <span>
                                          {
                                            MentorStudentDetails?.student_detail
                                              ?.company
                                          }
                                        </span>
                                        <p>
                                          {moment(
                                            MentorStudentDetails?.student_detail
                                              ?.start_date
                                          ).format("YYYY")}{" "}
                                          -{" "}
                                          {moment(
                                            MentorStudentDetails?.student_detail
                                              ?.end_date
                                          ).format("YYYY")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <---------------- Study Materaile --------------> */}
                                <div className="instructors-details-contents mt-3">
                                  <div className="card-instrutors shadow bg-white p-4">
                                    <h5>Notes</h5>
                                    <div className="skillstopic_education">
                                    <p>{MentorStudentDetails?.booking?.comment}</p>
                                    </div>
                                    {
                                        MentorStudentDetails?.booking?.metting_file != null && (
                                            <button className="btn btn-gray">PDF:<a className='text-primary' target="_blank" href={MentorStudentDetails?.booking?.metting_file}>{MentorStudentDetails?.booking?.metting_file}</a></button>
                                        )
                                    }
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="text-center mt-5">
                    <h2>Not Avaliable Student Complete Session here...</h2>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      <Mentor_Footer />
    </div>
  );
};

export default CoachingMentor;
