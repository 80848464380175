import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PayPalPaymentButton = ({ amount }) => {
  const onApprove = (data, actions) => {
    // Capture the funds from the transaction
    return actions.order.capture().then((details) => {
      alert(`Transaction completed by ${details.payer.name.given}`);
      console.log("Transaction details:", details);
      // You can call a function here to handle your business logic, e.g., saving the transaction
    });
  };

  return (
    <PayPalScriptProvider
      options={{
        "client-id": "AZK7ThaEqZsIHn3GaOBxhg5kjPfyct5IPDtsn0KPuXShIOqFX1nBftrofa4frTLOgQmwlto4f2ntsdNJ", // Replace with your client ID
        currency: "USD", // Specify the currency you want to use
      }}
    >
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: amount, // The amount to charge
                },
                shipping: {
                  // Optional: provide a default shipping address
                  address: {
                    address_line_1: "1234 Test St", // Optional address
                    admin_area_2: "San Jose", // Optional city
                    postal_code: "95131", // Optional postal code
                    country_code: "US", // Optional country
                  },
                },
              },
            ],
            application_context: {
              shipping_preference: "NO_SHIPPING", // Skip shipping address collection
            },
          });
        }}
        onApprove={onApprove}
        onError={(err) => {
          console.error("PayPal Checkout onError", err);
          alert("An error occurred during the transaction. Please try again.");
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalPaymentButton;
