import React from 'react';
import video1 from "../../../../assets/images/courses/video1.png";
import CommonImageTransport from '../../../common/images/CommonImages';
import { Link } from 'react-router-dom';
import "../../../../assets/css/style.css";
import Student_Common_Profile_section from '../student_common_profile_section/Student_Common_Profile_section';
import Student_Header from '../student_header/Student_Header';

const MyCoursesDetails = () => {
  return (
    <div>
      <Student_Header />
      <div className="stepbg">
        <div className="header_space"></div>
        <div className="client_filter-job pt-2 pb-5">
          <div className="stepsForm">
            <form method="post" id="msform">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                  <Student_Common_Profile_section/>
                  </div>
                  <div className="col-md-8 d-flex">
                    <div className="flexdr position-relative p-3 p-md-4 shadow-lg">
                      <div className="personal_user_info">
                        <div className="border-bottom">
                          <h4>Web Programming</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <table className="table videotab table-hover">
                              <thead>
                                <tr className='table_tr'>
                                  <th scope="col">Class</th>
                                  <th scope="col">Video</th>
                                  <th scope="col">Nots</th>
                                  <th scope="col">Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th scope="row">Class 1</th>
                                  <td>
                                    <div className="coursetab10">
                                      <Link to="#"><img src={video1} className="img-fluid"
                                        alt="" />
                                      </Link>
                                    </div>
                                  </td>
                                  <td><div className="coursetab11"><Link to="#">
                                    <img src={CommonImageTransport?.PDF} className="img-fluid"
                                      alt="" /></Link>
                                  </div>
                                  </td>
                                  <td>10/04/2024</td>
                                  <td><Link to="/CourseVideoInfo" className="default-btn rounded">View</Link></td>
                                </tr>
                                <tr>
                                  <th scope="row">Class 2</th>
                                  <td>
                                    <div className="coursetab10">
                                      <Link to="#"><img src={video1} className="img-fluid"
                                        alt="" />
                                      </Link>
                                    </div>
                                  </td>
                                  <td><div className="coursetab11"><Link to="#">
                                    <img src={CommonImageTransport?.PDF} className="img-fluid"
                                      alt="" /></Link>
                                  </div>
                                  </td>
                                  <td>10/04/2024</td>
                                  <td><Link to="/CourseVideoInfo" className="default-btn rounded">View</Link></td>
                                </tr>
                                <tr>
                                  <th scope="row">Class 3</th>
                                  <td>
                                    <div className="coursetab10">
                                      <Link to="#"><img src={video1} className="img-fluid"
                                        alt="" />
                                      </Link>
                                    </div>
                                  </td>
                                  <td><div className="coursetab11"><Link to="#">
                                    <img src={CommonImageTransport?.PDF} className="img-fluid"
                                      alt="" /></Link>
                                  </div>
                                  </td>
                                  <td>10/04/2024</td>
                                  <td><Link to="/CourseVideoInfo" className="default-btn rounded">View</Link></td>
                                </tr>
                                <tr>
                                  <th scope="row">Class 4</th>
                                  <td>
                                    <div className="coursetab10">
                                      <Link to="#"><img src={video1} className="img-fluid"
                                        alt="" />
                                      </Link>
                                    </div>
                                  </td>
                                  <td><div className="coursetab11">
                                    <Link to="#"><img src={CommonImageTransport?.PDF} className="img-fluid"
                                      alt="" /></Link>
                                  </div>
                                  </td>
                                  <td>10/04/2024</td>
                                  <td><Link to="/CourseVideoInfo" className="default-btn rounded">View</Link></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyCoursesDetails