import React from 'react'
const Page_404 = () => {
  return (
    <div className='text-center mt-5'>
        <h1>404</h1>
        <h4> Page Not Found !!</h4>
    </div>
  )
}

export default Page_404