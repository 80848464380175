import React, { useState, useEffect } from 'react'
import Header from '../../../common/header/Header'
import CommonImageTransport from '../../../common/images/CommonImages'
import Footer from '../../../common/footer/Footer'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../../../../loader/Loader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
import { GetPublicJob } from '../../../../api/Public';
import { FaLocationDot } from "react-icons/fa6";
import { IoIosSettings } from "react-icons/io";

const Jobs = () => {
    const [JobList, seJobList] = useState([])
    // <--------- This is UseState for Loader Pages-------->
    // const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const GetTheJobLists = async () => {
        try {
            const response = await GetPublicJob()
            seJobList(response?.data.data)
        } catch (error) {
            console.log("error", error)
        }
    }
    console.log("JobList", JobList)

    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         setIsLoading(false);
    //     }, 2000);
    //     return () => clearTimeout(timeout);
    // }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            document.title = 'Training Program';
            document.querySelector('.banner-img').classList.add('animate-from-bottom');
            document.querySelector('.banner-content').classList.add('animate-from-top');
            // setIsLoading(false);
        }, 1000);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        GetTheJobLists()
    }, [])

    const AddAllMentors = async (e) => {
        e.preventDefault();
        const role_id = localStorage.getItem("role_id");
        if (role_id === "2") {
            navigate("/Student/Student_jobs");
        } else if (role_id === "3" || role_id === "4") {
            toast.error("You Can't access !!!");
            setTimeout(() => {
                navigate("/");
            }, 2000);
        }
        else {
            toast.error("User not sign in here !!!");
            setTimeout(() => {
                navigate("/Signin");
            }, 2000);
        }
    }

    useEffect(() => {
        // Scroll to the top of the page when the component is rendered
        window.scrollTo(0, 0);
      }, []);
    return (
        <div>
          
                    <>
                        <div className="bg_nav-slid bg-white">
                            <Header />
                        </div>
                        <div className="recruiterbgme jobbgre py-4">
                            <div className="container">
                                <div className="row align-items-center">
                                    <ToastContainer style={{ marginTop: "100px" }} />
                                    <div className="col-lg-6">
                                        <div className="banner-content">
                                            <span data-aos="fade-up" data-aos-delay="900" data-aos-duration="1000"
                                                data-aos-once="true">Welcome to Jobs manager</span>
                                            <h1 data-aos="fade-down" data-aos-delay="900" data-aos-duration="1000" data-aos-once="true">
                                                Easy way to get your dream jobs </h1>
                                            <p data-aos="fade-up" data-aos-delay="900" data-aos-duration="1000" data-aos-once="true">
                                                Fill your job in hours, not weeks. Search for free.</p>
                                            <div className="banner-form-area" data-aos="fade-down" data-aos-delay="900" data-aos-duration="1000"
                                                data-aos-once="true">
                                                <Link onClick={AddAllMentors}
                                                    className="default-btn rounded-pill" type="submit">
                                                    Search Jobs
                                                </Link>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="banner-img" data-speed="0.05" data-revert="true">
                                            <img src={CommonImageTransport?.jobbanner} data-aos="fade-up"
                                                data-aos-delay="900"
                                                data-aos-duration="1000" data-aos-once="true" alt="Man" />


                                            <div className="right-content kkkkkdd job__qui" data-aos="fade-down" data-aos-delay="900"
                                                data-aos-duration="1000" data-aos-once="true">

                                                <div className="content">
                                                    <img src={CommonImageTransport?.job2} alt="" />
                                                </div>
                                            </div>
                                            <div className="left-content kkkkkddsadf applicantsuser" data-aos="fade-up" data-aos-delay="900"
                                                data-aos-duration="1000" data-aos-once="true">

                                                <img src={CommonImageTransport.job1} alt="" />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="banner-shape">
                                <div className="banner-shape1">
                                    <img src={CommonImageTransport.shape1} alt="Shape" />
                                </div>
                            </div>
                        </div>

                        {/* <div className="instructors-area py-5">
                            <div className="container">
                                <div className="row align-items-center mb-45">
                                    <div className="col-lg-8 col-md-9">
                                        <div className="section-title mt-rs-20">
                                            <h2>Browse by category</h2>
                                            <p>
                                                Find the job that’s perfect for you. about 800+ new jobs everyday
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-3 col-md-6">
                                        <div className="categoryjobs">
                                            <div className="image-left d-inline-flex">
                                                <img decoding="async" alt="Human Resource"
                                                    src={CommonImageTransport.customerservice}
                                                    width="42" height="42" />
                                            </div>
                                            <div className="text-info-right">
                                                <h4>
                                                    Human Resource </h4>
                                                <p className="font-xs mb-0">
                                                    No Job Available </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="categoryjobs">
                                            <div className="image-left d-inline-flex">
                                                <img loading="lazy" decoding="async" alt="Market Research"
                                                    src={CommonImageTransport.research} width="42" height="42" />
                                            </div>
                                            <div className="text-info-right">
                                                <h4>
                                                    Market Research </h4>
                                                <p className="font-xs mb-0">
                                                    1 Job Available </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="categoryjobs">
                                            <div className="image-left d-inline-flex">
                                                <img loading="lazy" decoding="async" alt="Software"
                                                    src={CommonImageTransport.marketing}
                                                    width="42" height="42" />
                                            </div>
                                            <div className="text-info-right">
                                                <h4>
                                                    Software </h4>
                                                <p className="font-xs mb-0">
                                                    No Job Available </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="categoryjobs">
                                            <div className="image-left d-inline-flex">
                                                <img loading="lazy" decoding="async" alt="Marketing &amp; Sale"
                                                    src={CommonImageTransport.sale} width="42" height="42" />
                                            </div>
                                            <div className="text-info-right">
                                                <h4>
                                                    Marketing &amp; Sale </h4>
                                                <p className="font-xs mb-0">
                                                    No Job Available </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="categoryjobs">
                                            <div className="image-left d-inline-flex">
                                                <img decoding="async" alt="Content Writer"
                                                    src={CommonImageTransport.contentwriter}
                                                    width="42" height="42" />
                                            </div>
                                            <div className="text-info-right">
                                                <h4>
                                                    Content Writer </h4>
                                                <p className="font-xs mb-0">
                                                    4 Jobs Available </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="hiringbanner mt-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-13">
                                        <div className="hiringviedessd">
                                            <div>
                                                <span>WE ARE</span>
                                                <h1>HIRING</h1>
                                            </div>
                                            <div>
                                                <p>Let’s Work Together & Explore Opportunities
                                                </p>
                                            </div>
                                            <Link onClick={AddAllMentors} className="default-btn">Apply Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="instructors-area py-5">
                            <div className="container">
                                <div className="row align-items-center mb-45">
                                    <div className="col-lg-8 col-md-9">
                                        <div className="section-title mt-rs-20">
                                            <h2>Jobs of the day</h2>
                                            <p>
                                                Search and connect with the right candidates faster
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-3 text-end">
                                        <Link onClick={AddAllMentors}
                                            className="default-btn">All Jobs</Link>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    {
                                        JobList?.map((JobListResult) => {
                                            return (
                                                <>
                                                    <div className="col-lg-4 col-md-4">
                                                        <div className="categoryjobs joblist010">
                                                            <div className="card_rec position-relative">
                                                                <div className="mentersvies0121">
                                                                    <div>
                                                                        {
                                                                            JobListResult?.job_image != null ? (
                                                                                <>
                                                                                 <img src={JobListResult.job_image} style={{width:"100px",height:"100px"}}
                                                                            className="img-fluid rounded"
                                                                            alt="Instructor" />
                                                                                </>

                                                                            ):(
                                                                                <>
                                                                                 <img src={CommonImageTransport?.companybuilding} style={{width:"100px",height:"100px"}}
                                                                            className="img-fluid rounded"
                                                                            alt="Instructor" />
                                                                                </>
                                                                            )
                                                                        }
                                                                       
                                                                    </div>
                                                                    <div className="instructors-details-contents">
                                                                        <div className="comnam">
                                                                            <h3>{JobListResult?.title}</h3>
                                                                            <h6 className="sub-title">{JobListResult?.organization}</h6>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                {/* <p className="perographsv" dangerouslySetInnerHTML={{ __html: JobListResult?.aboutjob }}></p> */}
                                                                {/* <p className="perographsv">{JobListResult?.location}</p> */}

                                                                <div className="businessmen footer_skills">
                                                                    <div><FaLocationDot /><strong>Location:</strong> {JobListResult?.location}</div>
                                                                    <div><IoIosSettings size="19"/><strong>Skills:</strong>{JobListResult?.skills}</div>
                                                                </div>

                                                                <Link to="#" onClick={AddAllMentors} className="rightareee"><i className="ri-arrow-right-up-line"></i></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }



                                </div>
                            </div>
                        </div>
                        <div className="enrolled-areas py-5">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div className="enrolled-img" data-speed="0.05" data-revert="true">
                                            <img src={CommonImageTransport?.Innovatively} alt="Enrolled" />

                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="enrolled-content">
                                            <div className="section-title ps-0 px-lg-5">
                                                <h2>Innovatively built for recruiters and applicants</h2>
                                                <p>
                                                    We create engaging experience with our radicitted tool, post on application and let your
                                                    condide-checkout these way towards on new
                                                </p>
                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <Footer />
                    </>
            

        </div>
    )
}

export default Jobs