import logoFull from '../../../assets/images/logos/logo-full.png'
import profile1 from "../../../assets/images/profile1.jpg"
import enrolled from "../../../assets/images/enrolled/enrolled-img3.jpg"
import menter from "../../../assets/images/menter.png"
import testimonials1 from "../../../assets/images/testimonials/testimonials-img1.jpg"
import testimonials2 from "../../../assets/images/testimonials/testimonials-img2.jpg"
import testimonials3 from "../../../assets/images/testimonials/testimonials-img3.jpg"
import users from "../../../assets/images/users.png"
import trainer from "../../../assets/images/trainer.png"
import jobs from "../../../assets/images/jobs.png"
import learn from "../../../assets/images/Learn.png"
import mentorship2 from "../../../assets/images/mentorship2.png"
import compete from "../../../assets/images/compete.png"
import instructors2 from "../../../assets/images/courses/courses-instructors2.jpg"
import Cybersecurity from "../../../assets/images/Cybersecurity.png"
import instructors1 from '../../../assets/images/courses/courses-instructors1.jpg'
import networking from '../../../assets/images/networking.png'
import cloud from '../../../assets/images/cloud.png'
import instructors3 from '../../../assets/images/courses/courses-instructors3.jpg'
import instructors4 from '../../../assets/images/courses/courses-instructors4.jpg'
import instructorsI1 from '../../../assets/images/instructors/instructors-img1.jpg'
import instructorsI2 from '../../../assets/images/instructors/instructors-img2.jpg'
import instructorsI3 from '../../../assets/images/instructors/instructors-img3.jpg'
import instructorsI4 from '../../../assets/images/instructors/instructors-img4.jpg'
import instructorsI5 from '../../../assets/images/instructors/instructors-img5.jpg'
import instructorsI6 from '../../../assets/images/instructors/instructors-img6.jpg'
import instructorsI7 from '../../../assets/images/instructors/instructors-img7.jpg'
import instructorsI8 from '../../../assets/images/instructors/instructors-img8.jpg'
import login2 from '../../../assets/images/login_2.gif'
import loginBottom from '../../../assets/images/login-bottom.png'
import PDF from '../../../assets/images/courses/PDF.png'
import shape1 from '../../../assets/images/home-one/shape1.png'
import shape2 from '../../../assets/images/home-one/shape2.png'
import shape3 from '../../../assets/images/home-one/shape3.png'
import HiringManager from '../../../assets/images/hiring-manager.png'
import JobMe from '../../../assets/images/job-me.png'
import client1 from '../../../assets/images/client-logo/client1.png'
import client2 from '../../../assets/images/client-logo/client2.png'
import client3 from '../../../assets/images/client-logo/client3.png'
import client4 from '../../../assets/images/client-logo/client4.png'
import client5 from '../../../assets/images/client-logo/client5.png'
import client6 from '../../../assets/images/client-logo/client6.png'
import hire1 from '../../../assets/images/hire1.jpg'
import hire2 from '../../../assets/images/hire2.jpg'
import hire3 from '../../../assets/images/hire3.jpg'
import Innovatively from '../../../assets/images/Innovatively.png'
import backgroundImage from '../../../assets/images/hiring-process.png'
import instructorDetails from '../../../assets/images/instructors/instructors-details.jpg'
import education from '../../../assets/images/education.png'
import courseI1 from '../../../assets/images/courses/courses-img1.jpg'
import courseI2 from '../../../assets/images/courses/courses-img2.jpg'
import courseI3 from '../../../assets/images/courses/courses-img3.jpg'
import video1 from '../../../assets/images/courses/video1.png'
import video2 from '../../../assets/images/courses/video2.jpg'
import video3 from '../../../assets/images/courses/video3.jpg'
import video4 from '../../../assets/images/courses/video4.jpg'
import trainerBanner from '../../../assets/images/trainer-banner.png'
import userImage from '../../../assets/images/home-one/user-img.jpg'
import enrolledShape from '../../../assets/images/enrolled/enrolled-shape.png'
import mentorBanner from '../../../assets/images/mentor-banner.png'
import enrolledShape2 from '../../../assets/images/enrolled/enrolled-shape2.png'
import instructorsdetails from '../../../assets/images/instructors/instructors-details.jpg'
import instructorsimg6 from '../../../assets/images/instructors/instructors-img6.jpg'
import instructorsimg3 from '../../../assets/images/instructors/instructors-img3.jpg'
import instructorsimg4 from '../../../assets/images/courses/instructors4.jpg'
import pdfpdf from '../../../assets/images/pdf.pdf'
import jobbanner from '../../../assets/images/job-banner.webp'
import job2 from '../../../assets/images/job-2.webp'
import job1 from '../../../assets/images/job-1.webp'
import customerservice from '../../../assets/images/icon/customer-service.svg'
import companybuilding from '../../../assets/images/company-building.png'
import contentwriter from '../../../assets/images/icon/content-writer.svg'
import sale from '../../../assets/images/icon/sale.svg'
import marketing from '../../../assets/images/icon/marketing.svg'
import research from '../../../assets/images/icon/research.svg'
import locationicon from '../../../assets/images/icon/location-icon.svg'
import searchicon from '../../../assets/images/icon/search-icon.svg'
import userLogo from '../../../assets/images/userLogo.png'
import careerbanner from '../../../assets/images/career-banner.png'
import careerbanner2 from '../../../assets/images/career-banner2.png'
import careerbanner3 from '../../../assets/images/career-banner3.png'
import careerbanner4 from '../../../assets/images/career-banner4.png'
import careerbanner5 from '../../../assets/images/career-banner5.png'
import circle from '../../../assets/images/circle.svg'
import award from "../../../assets/images/award.png"
import checked from "../../../assets/images/checked.png"
import clock from "../../../assets/images/expired.png"
import expired_new from "../../../assets/images/expired_new.png"
import loginbg from "../../../assets/images/loginbg.png"

const CommonImageTransport = {
      award,
      loginbg,
      expired_new,
      checked,
      clock,
      logoFull,
      profile1,
      enrolled,
      menter,
      testimonials1,
      testimonials2,
      testimonials3,
      compete,
      mentorship2,
      learn,
      jobs,
      trainer,
      users,
      instructors3,
      cloud,
      networking,
      instructors1,
      Cybersecurity,
      instructors2,
      instructors3,
      instructors4,
      login2,
      loginBottom,
      PDF,
      backgroundImage,
      Innovatively,
      hire3,
      hire2,
      hire1,
      client6,
      client5,
      client4,
      client3,
      client2,
      client1,
      JobMe,
      HiringManager,
      shape3,
      shape2,
      shape1,
      courseI3,
      courseI2,
      courseI1,
      education,
      instructorDetails,
      instructorsI8,
      instructorsI7,
      instructorsI6,
      instructorsI5,
      instructorsI4,
      instructorsI3,
      instructorsI2,
      instructorsI1,
      enrolledShape2,
      mentorBanner,
      enrolledShape,
      userImage,
      trainerBanner,
      instructorsdetails,
      instructorsimg6,
      instructorsimg3,
      video1,
      video2,
      video3,
      video4,
      pdfpdf,
      jobbanner,
      job2,
      job1,
      customerservice,
      research,
      marketing,
      sale,
      contentwriter,
      companybuilding,
      userLogo,
      locationicon,
      searchicon,
      careerbanner,
      careerbanner2,
      careerbanner3,
      careerbanner4,
      careerbanner5,
      circle,
      instructorsimg4


};

export default CommonImageTransport;
