import React, { useState, useEffect } from 'react';
import Mentor_header from '../mentor_header/Mentor_Header';
import CommonImageTransport from '../../../common/images/CommonImages';
import { MentorStepProfile, MentorStepProfile1 } from "../../../../api/Mentor";
import ReactHtmlParser from "react-html-parser";
import { GetStudentMentorFeedback } from '../../../../api/Student';
import { Rating } from 'react-simple-star-rating';
import moment from 'moment';
import { Pagination, PaginationItem, Stack } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Loader from '../../../../loader/Loader';
import { ProfileSections } from '../../../../api/Auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Mentor_Footer from '../mentor_footer/Mentor_Footer';

const Mentor_Profile = () => {
    const [is_loading, setIs_loading] = useState(false)
    const [EditAboutMode, setEditAboutMode] = useState(false);
    const [EditPerHrsCharge, setEditPerHrsCharge] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [skills, setSkills] = useState([]);
    const [Mentor_ProfileList, setMentor_ProfileList] = useState([]);
    console.log("Mentor_ProfileList", Mentor_ProfileList?.profile?.profession)
    const [description, setDescription] = useState("");
    const [per_hour_price, setPerHourPrice] = useState("");
    const itemsPerPage = 3;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const token = localStorage.getItem("User_Token");
    const mentor_id = localStorage.getItem("User_id");
    const [FeedbackList, setFeedbackList] = useState([])
    const headers = {
        "Authorization": `Bearer ${token}`
    }
    // <----------- show the Skills Edit Sections --------------->
    const handleEditClick = () => {
        setEditMode(true);
    };
    // <------ Chnage in the Skills Sections -------------->
    // const handleSkillChange = (index, value) => {
    //     const newSkills = [...skills];
    //     newSkills[index] = value;
    //     setSkills(newSkills);
    // };
    useEffect(() => {
        if (Mentor_ProfileList?.profile?.profession) {
            const professionArray = Mentor_ProfileList.profile.profession
                .replace(/[{}"]/g, '')
                .split(", ");
            setSkills(professionArray);
        }
    }, [Mentor_ProfileList]);

    // Function to handle change in skill input fields
    const handleSkillChange = (index, value) => {
        const newSkills = [...skills];
        newSkills[index] = value;
        setSkills(newSkills);
    };
    const handlePerHrsCharge = () => {
        setEditPerHrsCharge(true);
    };
    // <----------- get the Mentor Profile Here  ------------>
    const GetMentorProfile = async () => {
        setIs_loading(true)
        const headers = {
            "Authorization": `Bearer ${token}`
        }
        try {
            const response = await ProfileSections(headers)
            setMentor_ProfileList(response?.data?.data);
            setDescription(response?.data?.data?.profile?.userdesc)
            setPerHourPrice(response?.data?.data?.profile?.per_hour_price)
            setIs_loading(false)
            console.log("GetMentorProfile", response)
        }
        catch (error) {
            console.log("error", error)
            setIs_loading(true)
        }
    }
    // <----------- get the Feeback List here ------------>
    const HandleGetFeedbackform = async () => {
        const Feedbackdata = {
            mentor_id: mentor_id
        }
        const response = await GetStudentMentorFeedback(Feedbackdata, headers);
        setFeedbackList(response?.data?.data?.mentorFeedback)
    }

    // <------------- Edit The Mentor Profile sections Here ----------------->
    const HandelEditSkillsSections = async () => {
        // Check if all skills are filled
        const areSkillsFilled = skills?.every(skill => skill.trim() !== '');

        if (!areSkillsFilled) {
            toast.error("Please fill in all skill fields before saving.");
            return; // Stop execution if any skill is empty
        }

        // Proceed if all skills are filled
        const headers = {
            "Authorization": `Bearer ${token}`
        }
        const MentorStepData = {
            profession: skills,
            is_mentorstep: 1
        }

        try {
            const response = await MentorStepProfile(MentorStepData, headers);
            if (response?.data?.statusCode === "200") {
                toast.success("Skills updated successfully!");
                setEditMode(false);
                GetMentorProfile();  // Fetch the updated profile after saving
            } else {
                toast.error("Failed to update skills. Please try again.");
            }
        } catch (error) {
            console.error("Error updating skills", error);
            toast.error("An error occurred while updating skills.");
        }
    };


    // <------------- Edit The Mentor Profile sections Here ----------------->
    const HandleEditAboutSections = async () => {
        const headers = {
            "Authorization": `Bearer ${token}`
        }
        const MentorStepData = {
            userDesc: description,
            is_mentorstep: 1
        }
        const response = await MentorStepProfile(MentorStepData, headers);
        setEditAboutMode(false);
        GetMentorProfile();
    }

    // <------------- Edit The Mentor Profile sections Here ----------------->
    const HandleEditperHrsChargeSections = async () => {
        const headers = {
            "Authorization": `Bearer ${token}`
        }
        const MentorStepData = {
            per_hour_price: per_hour_price,
            is_mentorstep: 1
        }
        const response = await MentorStepProfile(MentorStepData, headers);
        setEditPerHrsCharge(false);
        GetMentorProfile();
    }

    // <----------- Pagination sections ------------->
    useEffect(() => {
        setTotalPages(Math.ceil(FeedbackList.length / itemsPerPage));
    }, [FeedbackList]);
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // <------- Render  the feeback and Mentor Profile sections --------------->
    useEffect(() => {
        HandleGetFeedbackform();
        GetMentorProfile();
    }, [])


    useEffect(() => {
        if (Mentor_ProfileList?.profession) {
            setSkills(Mentor_ProfileList?.profession?.replace(/[{}"]/g, '').split(", "));
        }
    }, [Mentor_ProfileList]);
    console.log("Skills", skills)
    return (
        <div>
            <div className="bg_nav-slid bg-white">
                <Mentor_header />
            </div>
            <div className="header_space"></div>
            {
                is_loading ? (
                    <Loader />
                ) : (
                    <div className="mentorprofile121 ptb-70 position-relative o-grid o-grid--wide js-stickyWrapper">
                        <div className="container">
                            <ToastContainer style={{ marginTop: "50px" }} />
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="instructors-details-img m-0">
                                        <div className="card-instrutors shadow position-relative bg-white p-4">
                                            <div className="mentersvies0121">
                                                <div className="position-relative menter_pro">
                                                    {
                                                        Mentor_ProfileList?.profile?.profileimg != null ? (
                                                            <>
                                                                <img src={Mentor_ProfileList?.profile?.profileimg}
                                                                    className="rounded_01top" alt="Instructor" />
                                                            </>


                                                        ) : (
                                                            <>
                                                                <img src={CommonImageTransport?.userLogo}
                                                                    className="rounded_01top" alt="Instructor" />
                                                            </>
                                                        )
                                                    }

                                                </div>
                                                <div className="instructors-details-contents">
                                                    <div className="">
                                                        <div className="comnam">
                                                            <h3>{Mentor_ProfileList?.profile?.fname} {Mentor_ProfileList?.profile?.lname} </h3>
                                                            <ul className="nav my-2 hkddda justify-content-between text-dark align-items-center">
                                                                <li className="me-2"><i className="ri-mail-line"></i> {Mentor_ProfileList?.profile?.email}
                                                                </li>
                                                                <li className="me-2"><i className="ri-phone-line">
                                                                    </i>{Mentor_ProfileList?.profile?.mobile.replace(/null/g, '')}</li>
                                                                <li className="me-2"><i className="ri-map-pin-fill"></i> {Mentor_ProfileList?.profile?.city}, {Mentor_ProfileList?.profile?.state},
                                                                    {Mentor_ProfileList?.profile?.country}</li>
                                                                {/* <li className="me-2 mt-3">
                                                                   <strong> DOB:</strong>({moment(
                                                                       Mentor_ProfileList?.profile?.dob
                                                                    ).format("DD-MM-YYYY")})</li> */}
                                                            </ul>
                                                            {/* <h6>({Mentor_ProfileList?.profile?.nationality})</h6> */}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <--------- Per Hours Charges Sections ---------------->*/}
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <div className="d-flex justify-content-between">
                                                    <h5>USD Per Hour Fees</h5>
                                                    {/* {EditPerHrsCharge ? (
                                                <div>
                                                    <button className="btn btn-primary m-1"
                                                        onClick={HandleEditperHrsChargeSections}>Save</button>
                                                    <button className="btn btn-secondary m-1"
                                                        onClick={() => setEditPerHrsCharge(false)}>Cancel</button>
                                                </div>
                                            ) : (
                                                <button className="btn btn-primary m-1"
                                                    onClick={() => setEditPerHrsCharge(true)}>Edit</button>
                                            )} */}
                                                </div>
                                                <p className="bolded white">
                                                    {/* {EditPerHrsCharge ? (
                                              <input
                                                type="number"
                                                name='per_hour_price'
                                                value={per_hour_price}
                                                onChange={(e) => setPerHourPrice(e.target.value)}
                                                style={{ border: "none", borderBottom: "1px solid grey" }}
                                              />
                                            ) : ( */}
                                                    ${per_hour_price} USD
                                                    {/* )} */}
                                                </p>
                                            </div>
                                        </div>
                                        {/* <---------- Skills Sections -----------> */}
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <div className='d-flex justify-content-between'>
                                                    <h5>Skills</h5>
                                                    <div className='text-end'>
                                                        {editMode ? (
                                                            <div>
                                                                <button className='btn btn-primary m-1' onClick={HandelEditSkillsSections}>Save</button>
                                                                <button className='btn btn-secondary m-1' onClick={() => setEditMode(false)}>Cancel</button>
                                                            </div>
                                                        ) : (
                                                            <button className='btn btn-primary m-1' onClick={handleEditClick}>Edit</button>
                                                        )}
                                                    </div>
                                                </div>
                                                <div>
                                                    {editMode ? (
                                                        <>
                                                            {skills?.map((skill, index) => (
                                                                <div key={index} className="d-inline">
                                                                    <input
                                                                        type="text"
                                                                        value={skill}
                                                                        name='profession'
                                                                        onChange={(e) => handleSkillChange(index, e.target.value)}
                                                                        style={{
                                                                            border: "none", outline: "none", borderBottom: "1px solid grey", width: "100%",
                                                                            marginRight: '5px'
                                                                        }}
                                                                    />
                                                                </div>
                                                            ))}

                                                        </>
                                                    ) : (
                                                        Mentor_ProfileList?.profile?.profession?.replace(/[{}"]/g, '')?.split(", ").map((skill, index) => (
                                                            <div className='d-inline' key={index}>{skill}</div>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <--------- About Sections ---------------->*/}
                                        <div className="instructors-details-contents mt-3">
                                            <div className="card-instrutors shadow bg-white p-4">
                                                <div className="d-flex justify-content-between">
                                                    <h5>About Mentor</h5>
                                                    {EditAboutMode ? (
                                                        <div>
                                                            <button className="btn btn-primary m-1"
                                                                onClick={HandleEditAboutSections}>Save</button>
                                                            <button className="btn btn-secondary m-1"
                                                                onClick={() => setEditAboutMode(false)}>Cancel</button>
                                                        </div>
                                                    ) : (
                                                        <button className="btn btn-primary m-1"
                                                            onClick={() => setEditAboutMode(true)}>Edit</button>
                                                    )}
                                                </div>
                                                {EditAboutMode ? (
                                                    <ReactQuill className="aboutPageEditorContainer"
                                                        value={description}
                                                        name="description"
                                                        onChange={(contant) => setDescription(contant)} />
                                                ) : (
                                                    <p>{ReactHtmlParser(description)}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    FeedbackList?.length > 0 ? (
                                        <>
                                            <div className="col-lg-4">
                                                <div className="courses-details-tab-content shadow p-3 sticky2 js-sticky">
                                                    <div className="courses-details-review-form">
                                                        <h3 className="m-0">What Student say</h3>
                                                        <div className="review-comments m-0">
                                                            {
                                                                FeedbackList?.slice(startIndex, endIndex)?.map((FeedbackResult) => {
                                                                    return (
                                                                        <>
                                                                            <div className="review-item mt-3 pe-0">
                                                                                <div className="content">
                                                                                    {
                                                                                        FeedbackResult?.profileimg != null ? (
                                                                                            <>
                                                                                                <img src={FeedbackResult?.profileimg} alt="Images" />

                                                                                            </>

                                                                                        ) : (
                                                                                            <>
                                                                                                <img src={CommonImageTransport?.userLogo} alt="Images" />
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                    <div className="content-dtls">
                                                                                        <h4>{FeedbackResult?.fname} {FeedbackResult?.lname}</h4>
                                                                                        <span>{moment(FeedbackResult?.created_at).format("DD/MM/YYYY")}</span>
                                                                                    </div>
                                                                                    <div className="rating">
                                                                                        <Rating readonly={true} initialValue={FeedbackResult?.rating} />
                                                                                    </div>
                                                                                </div>
                                                                                <p>
                                                                                    {FeedbackResult?.comment}
                                                                                </p>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        <div className="pagination-area mb-0">
                                                            <Stack spacing={2}>
                                                                <Pagination
                                                                    count={totalPages}
                                                                    page={currentPage}
                                                                    onChange={handlePageChange}
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    renderItem={(item) => (
                                                                        <PaginationItem component="div" {...item} />
                                                                    )}
                                                                />
                                                            </Stack>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        null
                                    )
                                }

                            </div>
                        </div>
                    </div>
                )
            }
            <Mentor_Footer />
        </div>
    )
}

export default Mentor_Profile