import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "react-country-state-city/dist/react-country-state-city.css";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { Link, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { MentorStepProfile } from "../../../../api/Mentor";
import { UserLogin } from "../../../../api/Auth";
import { countries } from "../../../common/countrylist/CountryList";

const MentorStep = () => {
  const navigate = useNavigate();
  const [Profession, setProfession] = useState([]);
  const [Interested, setInterested] = useState("");
  const [job_title, setJob_title] = useState("");
  const [Description, setDescription] = useState("");
  const [per_hour_price, setPer_hour_price] = useState(null);
  const [Linkedin_Profile, setLinkedin_Profile] = useState("");
  const [selectNationlity, setSelectNationlity] = useState("");
  const [DOB, setDOB] = useState("");
  const [isChecked_Linkedin_Profile, setisChecked_Linkedin_Profile] = useState(false);
  const [MentorDetails, setMentorDetails] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [activeTab, setActiveTab] = useState("Mentor");
  const tabs = ["Mentor", "ApplicationDetails"];
  const token = localStorage.getItem("User_Token");
  const user_Login_Data = JSON.parse(localStorage.getItem("user_Login_Data"));
  const [is_mentorstep, setIs_mentor_step] = useState(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Get today's date
  const today = new Date();

  // Calculate the date 18 years ago
  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear() - 21);

  // Convert the max date to ISO format for the input
  const maxDateString = maxDate.toISOString().split("T")[0];


  // <----------- Page Previous Function ----------->
  const handlePrevious = () => {
    const currentIndex = tabs.indexOf(activeTab);
    const previousIndex = (currentIndex - 1 + tabs.length) % tabs.length;
    setActiveTab(tabs[previousIndex]);
  };

  // <----------- Page Next Function ----------->
  const handleNext = () => {
    const currentIndex = tabs.indexOf(activeTab);
    const nextIndex = (currentIndex + 1) % tabs.length;
    setActiveTab(tabs[nextIndex]);
  };

  // <------ Handle Linkdin Url Form ----------------->
  const HandleLinkdinUrl = (e) => {
    e.preventDefault();
  };

  const handleNationlityChange = (e) => {
    setSelectNationlity(e.target.value);
  };


  // <---------- Handle Applications form ------------>
  const HandleApplicationDetails = async () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const MentorStepData = {
      profession: Profession,
      user_type: Interested,
      userDesc: Description,
      per_hour_price: per_hour_price,
      job_title: job_title,
      nationality: selectNationlity,
      dob: DOB,
      is_mentorstep: 1,
    };
    try {
      const response = await MentorStepProfile(MentorStepData, headers);
      setMentorDetails(response?.data?.data?.status);
      if (response?.data?.statusCode == "200") {
        toast.success("Mentor Profile Successfully Updated !!!");
        setTimeout(() => {
          navigate("/mentor_pages/MentorDashboard");
        }, 2000);
      } else if (response?.data?.statusCode == "400") {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  // <------------- Render the Fetch Login details -------------->
  useEffect(() => {
    const FetchLoginDetails = async () => {
      const response = await UserLogin(user_Login_Data);
      setIs_mentor_step(response?.data?.data?.user?.is_mentorstep);
    };
    FetchLoginDetails();
  }, []);
  // <------- Navigate the Mentor dashboard Page ---------------->
  useEffect(() => {
    if (is_mentorstep === 1) {
      navigate("/mentor_pages/MentorDashboard");
    }
  }, [is_mentorstep, navigate]);
  return (
    <div>
      <div className="bg_nav-slid bg-white"></div>
      <div className="stepbg">
        <div className="client_filter-job pt-1 pb-0">
          <div className="stepsForm">
            <form method="post" id="msform">
              <div className="container">
                <div className="row">
                  <ToastContainer style={{ marginTop: "120px" }} />
                  <div className="col-md-4">
                    <div className="sf-steps">
                      <h5 className="default-btn w-100 text-white m-0 py-3 px-4">
                        Mentors
                      </h5>
                      <div className="left-slid-filter space-box-two shadow-none">
                        <div className="sf-steps-content">
                          <div
                            className={`joblisttitle ${activeTab === "ApplicationDetails"
                              ? "sf-active"
                              : ""
                              }`}
                          // onClick={() => handleTabClick("ApplicationDetails")}
                          >
                            <span>1</span>
                            <aside className="list-icons">
                              <h5>Linkdin Profile</h5>
                            </aside>
                          </div>
                          <div
                            className={`joblisttitle ${activeTab === "Mentor" ? "sf-active" : ""
                              }`}
                          // onClick={() => handleTabClick("Mentor")}
                          >
                            <span>2</span>
                            <aside className="list-icons">
                              <h5>Application Details!</h5>
                            </aside>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {activeTab === "Mentor" && (
                    <div className="col-md-8 mx-auto">
                      <div className="flexdr position-relative">
                        <div className="sf-steps-form sf-radius">
                          <ul className="sf-content m-0">
                            <div className="find-com-box p-0">
                              <div className="professional-sp">
                                <h4>Become an FreshGrad Mentor!</h4>
                                <h6>Fill all form field to go to next step</h6>
                              </div>
                              <hr />
                              <div className="professional-sp row">
                                <div className="col-12 col-md-12 mt-3">
                                  <label className="fieldlabels">Job Title:</label>
                                  <input
                                    className="form-check-input"
                                    type="text"
                                    name="job_title"
                                    id="flexRadioDefault1"
                                    placeholder="Enter job title"
                                    value={job_title}
                                    onChange={(e) =>
                                      setJob_title(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="col-12 col-md-12">
                                  <label className="fieldlabels">
                                    Skills:
                                  </label>
                                  <div>
                                    <TagsInput
                                      value={Profession}
                                      name="Profession"
                                      onChange={(skill) => setProfession(skill)}
                                      inputProps={{ placeholder: 'Add your Skills and press here' }}
                                    />
                                  </div>
                                  <div>
                                    <ul id="tagList"></ul>
                                  </div>
                                </div>
                                <div className="col-12 col-md-12 mt-3">
                                  <p>Interested:</p>
                                  <div className="form-group d-flex gap-4">
                                    <div className="form-check radiobtnin0111">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="Interested"
                                        id="flexRadioDefault1"
                                        value="interviewer"
                                        onClick={(e) =>
                                          setInterested(e.target.value)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        for="flexRadioDefault1"
                                      >
                                        Interviewer
                                      </label>
                                    </div>
                                    <div className="form-check radiobtnin0111">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="both"
                                        id="flexRadioDefault1"
                                        value="trainer"
                                        onClick={(e) =>
                                          setInterested(e.target.value)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        for="flexRadioDefault2"
                                      >
                                        Trainer
                                      </label>
                                    </div>
                                    <div className="form-check radiobtnin0111">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="Interested"
                                        id="flexRadioDefault1"
                                        value="both"
                                        onClick={(e) =>
                                          setInterested(e.target.value)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        for="flexRadioDefault2"
                                      >
                                        Both
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-md-12 mt-3">
                                  <label className="fieldlabels">Payment($)</label>
                                  <input
                                    className="form-check-input"
                                    type="number"
                                    name="per_hour_price"
                                    id="flexRadioDefault1"
                                    placeholder="100$"
                                    value={per_hour_price}
                                    onChange={(e) =>
                                      setPer_hour_price(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="col-12 col-md-6 mt-3">
                                  <label className="fieldlabels">DOB</label>
                                  <input
                                    type="date" className="form-control"
                                    value={
                                      DOB
                                        ? new Date(DOB)
                                          .toISOString()
                                          .split("T")[0]
                                        : ""
                                    }
                                    onChange={(e) =>
                                      setDOB(e.target.value)
                                    }
                                    name="DOB"
                                    placeholder="dob"
                                    max={maxDateString}
                                    required
                                  />
                                  {DOB && new Date(DOB) > maxDate && (
                                    <p style={{ color: "red" }}>
                                      You must be at least 18 years old.
                                    </p>
                                  )}
                                </div>
                                <div className="col-md-6 col-12 mt-3">
                                  <label className="fieldlabels">Select a Nationality</label>
                                  <select id="country-select" value={selectNationlity} onChange={handleNationlityChange} className="form-control">
                                    <option value="">--Select a Nationality--</option>
                                    {countries?.map((country, index) => (
                                      <option key={index} value={country}>
                                        {country}
                                      </option>
                                    ))}
                                  </select>

                                </div>

                                <div className="col-12 col-md-12">
                                  <div className="form-group">
                                    <label className="fieldlabels">
                                      Summary *
                                    </label>
                                    <ReactQuill
                                      className="aboutPageEditorContainer"
                                      value={Description}
                                      name="Description"
                                      onChange={(contant) =>
                                        setDescription(contant)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <div className="agree-label d-flex gap-2 mt-5">
                                      <input
                                        type="checkbox"
                                        id="Consent"
                                        className="termcheck"
                                        checked={isChecked} // Bind checked property to isChecked_Other
                                        onChange={() => setIsChecked(!isChecked)} // Toggle state on change
                                      />
                                      <label htmlFor="Consent" className="small text-muted">
                                        make your profile visible publicly on freshgrad.com
                                      </label>
                                    </div>
                                    {!isChecked && (
                                      <p style={{ color: "red" }}>
                                        Please accept the terms and conditions before proceeding to the next page.
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ul>
                          <div className="sf-steps-navigation d-flex justify-content-between">
                            <button
                              id="sf-prev"
                              onClick={handlePrevious}
                              type="button"
                              className="btn btn-light"
                            >
                              Previous
                            </button>
                            <span id="sf-msg" className="sf-msg-error"></span>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                if (!job_title) {
                                  toast.error("Please enter the job title.");
                                }

                                else if (Profession.length === 0) {
                                  toast.error(
                                    "Please add at least one Skills."
                                  );
                                }

                                else if (!Interested) {
                                  toast.error("Please select an interest.");
                                }
                                else if (!per_hour_price) {
                                  toast.error("Please enter a price.");
                                }
                                else if (!DOB) {
                                  toast.error("Please enter your DOB.");
                                }

                                else if (!selectNationlity) {
                                  toast.error("Please select Your Nationlity.");
                                }
                                else if (!Description) {
                                  toast.error("Summary cannot be empty.");
                                }



                                else {
                                  HandleApplicationDetails(e);
                                }
                              }}
                              id="sf-next"
                              type="submit"
                              className="btn default-btn"
                            // disabled={
                            //     Profession.length === 0 || // Disable if no professions
                            //     !Description ||
                            //     !Interested ||
                            //     !per_hour_price
                            // }
                            >
                              Submit Request
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {activeTab === "ApplicationDetails" && (
                    <div className="col-md-8 mx-auto">
                      <div className="flexdr position-relative">
                        <div className="sf-steps-form sf-radius">
                          <ul className="sf-content m-0">
                            <div className="find-com-box p-0">
                              <div className="professional-sp">
                                <h4>Linkedin Profile </h4>
                                <h6>Fill all form field to go to next step</h6>
                              </div>
                              <hr />
                              <div className="professional-sp row">
                                <div className="col-12 col-md-12">
                                  <form className="form-group">
                                    <label className="fieldlabels">
                                      Linkedin Profile Url
                                    </label>
                                    <input
                                      type="text"
                                      name="Linkedin_Profile"
                                      value={Linkedin_Profile}
                                      onChange={(e) =>
                                        setLinkedin_Profile(e.target.value)
                                      }
                                      required
                                      placeholder="https://www.linkedin.com/?trk=guest_homepage-basic_nav-header-logo"
                                    />
                                    <div className="col-lg-12 form-condition">
                                      <div className="agree-label d-flex gap-2">
                                        <input
                                          type="checkbox"
                                          id="chb1"
                                          className="termcheck"
                                          onChange={() =>
                                            setisChecked_Linkedin_Profile(
                                              !isChecked_Linkedin_Profile
                                            )
                                          }
                                        />
                                        <label
                                          htmlFor="chb1"
                                          className="small text-muted"
                                        >
                                          All your information is collected,
                                          stored and processed as per our data
                                          processing guidelines. By signing up
                                          on FreshGard, you agree to our{" "}
                                          <Link to="#">Privacy Policy</Link> and{" "}
                                          <Link to="#">Terms of Use</Link>
                                        </label>
                                      </div>
                                      {!isChecked_Linkedin_Profile && (
                                        <p style={{ color: "red" }}>
                                          Please accept the terms and conditions
                                          before Next Page.
                                        </p>
                                      )}
                                    </div>
                                    <div className="sf-steps-navigation d-flex justify-content-between">
                                      <button
                                        onClick={handlePrevious}
                                        id="sf-prev"
                                        type="button"
                                        className="btn btn-light"
                                      >
                                        Previous
                                      </button>
                                      <span
                                        id="sf-msg"
                                        className="sf-msg-error"
                                      ></span>
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (
                                            isChecked_Linkedin_Profile &&
                                            Linkedin_Profile
                                          ) {
                                            handleNext();
                                            HandleLinkdinUrl(e);
                                          }
                                        }}
                                        id="sf-next"
                                        type="submit"
                                        className="btn default-btn"
                                        disabled={
                                          !isChecked_Linkedin_Profile ||
                                          !Linkedin_Profile
                                        }
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </ul>
                          <div className="sf-steps-navigation d-flex justify-content-between">
                            <button
                              id="sf-prev"
                              onClick={handlePrevious}
                              type="button"
                              className="btn btn-light"
                            >
                              Previous
                            </button>
                            <span id="sf-msg" className="sf-msg-error"></span>
                            <button
                              id="sf-next"
                              type="button"
                              onClick={handleNext}
                              className="btn default-btn"
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* {
                                        activeTab === "SalaryRange" && (
                                            <div className="col-md-8 mx-auto">
                                                <div className="flexdr position-relative">
                                                    <div className="sf-steps-form sf-radius">
                                                        <ul className="sf-content m-0">
                                                            <h1>Not Data Here</h1>
                                                        </ul>

                                                        <div className="sf-steps-navigation d-flex justify-content-between">

                                                            <button id="sf-prev" onClick={handlePrevious} type="button"
                                                                className="btn btn-light">Previous</button>
                                                            <span id="sf-msg" className="sf-msg-error"></span>
                                                            <button id="sf-next" type="button" onClick={handleNext}
                                                                className="btn default-btn">Next</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorStep;
