import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../../../loader/Loader';
import Recruiter_Header from '../recruiter_header/Recruiter_Header';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, TextField, Button } from '@mui/material';
import { GetRecuriterAllAppliedJobs } from '../../../../api/Recuriter';
import Modal from 'react-bootstrap/Modal';
import { GetStudentResume } from '../../../../api/Student';
import moment from 'moment';
import CommonImageTransport from '../../../common/images/CommonImages';
import Recruiter_Footer from '../recruiter_footer/Recruiter_Footer';
// <----------- Table header sections ---------->
const columns = [
    { id: 'unique_id', label: 'Job ID', minWidth: 50 },
    { id: 'applied_status', label: 'Job Status', minWidth: 150 },
    { id: 'title', label: 'Job Title', minWidth: 150 },
    { id: 'student_firstname', label: 'First Name', minWidth: 150 },
    { id: 'student_lastname', label: 'Last Name', minWidth: 150 },
    { id: 'mobile', label: 'Mobile', minWidth: 100 },
    { id: 'student_email', label: 'Email', minWidth: 100 },
    { id: 'location', label: 'Location', minWidth: 200 },
    { id: 'student_userid', label: 'Profile', minWidth: 150 },
];


const AllProfile = () => {
    // <--------- This is UseState for Loader Pages-------->
    const [isLoading, setIsLoading] = useState(true);
    const [StudnetId, setStudentId] = useState("")
    const [StudentProfile, setStudentProfile] = useState([]);

    // <--------- Filter the Data from the Table section's ------------>
    const [filters, setFilters] = useState({
        unique_id: '',
        applied_status: '',
        student_name: '',
        title: '',
        mobile: '',
        expertise: '',
        organization: '',
        employmenttype: '',
        location: '',
        skills: '',
    });

    // <------- Student Profile Show Section's -------------->
    const [StudentProfileShow, setStudentProfileShow] = useState(false);



    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // <------------ User Authentication token's ------------>
    const token = localStorage.getItem("User_Token");
    const headers = {
        Authorization: `Bearer ${token}`
    };

    // <---------- Change the page -------------->
    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    // <-------- Handle the How many Data Show in the Row ---------->
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // <------------ Filter the data By the column -------------->
    const handleFilterChange = (e, columnId) => {
        const newFilters = { ...filters, [columnId]: e.target.value };
        setFilters(newFilters);
    };

    // <---------- show the Button behalf of the status of the job ------------>
    const renderStatusButton = (status) => {
        switch (status) {
            case 1:
                return <Button color="warning">Pending</Button>;
            case 2:
                return <Button color="success">Accepted</Button>;
            case 0:
                return <Button color="error">Rejected</Button>;
            case 3:
                return <Button className="btn btn-info">Processing</Button>;
            default:
                return null;
        }
    };

    const getStatusLabel = (status) => {
        switch (status) {
            case 1:
                return "Pending";
            case 2:
                return "Accepted";
            case 0:
                return "Rejected";
            case 3:
                return "Processing";
            default:
                return "";
        }
    };

    const filteredRows = rows.filter((row) =>
        columns.every((column) => {
            const columnValue = column.id === 'applied_status' ? getStatusLabel(row[column.id]) : row[column.id];
            return String(columnValue)
                .toLowerCase()
                .includes(filters[column.id]?.toLowerCase() || "");
        })
    );
    const GetStudentDetailsById = (student_userid) => {
        setStudentId(student_userid)
        GetStudentProfile(student_userid)
    }
    //    <---------- Get the Student Profile here ------------>
    const GetStudentProfile = async (StudnetId) => {
        const StudentResumeData = {
            id: StudnetId
        };
        console.log("StudentResumeData", StudentResumeData)
        const response = await GetStudentResume(StudentResumeData, headers)
        setStudentProfile(response?.data?.data)
    }
    console.log("GetStudentProfile", StudentProfile)
    // <--------- Get the All Applied Jobs Profile ------------->
    useEffect(() => {
        const GetAllAppliedJobsProfile = async () => {
            setIsLoading(true);
            try {
                const GetRecuriterAllAppliedJobsData = {
                    status: null
                };

                const response = await GetRecuriterAllAppliedJobs(GetRecuriterAllAppliedJobsData, headers);
                if (response?.data?.statusCode == "200") {
                    setIsLoading(false);
                }
                setRows(response?.data?.data || []);
            } catch (error) {
                setIsLoading(true);
                console.log(error);
            }
        };

        GetAllAppliedJobsProfile();
    }, []);
    return (
        <div>
            {
                isLoading ? <Loader /> : (
                    <>
                        <div class="bg_nav-slid bg-white">
                            <Recruiter_Header />
                        </div>
                        <div class="recruiter_list pb-5 pt-4">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-12">
                                        <ul class="nav tabs_signup justify-content-start gap-2 mb-3">
                                            <li class="nav-item">
                                                <Link class="nav-link" aria-current="page" to="/recruiter_pages/NewProfile">New Profile</Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link" to="/recruiter_pages/RecruiterInterviewing">Interviewing</Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link" to="/recruiter_pages/RecruiterList">Hired</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/recruiter_pages/Recuriter_Rejected">Rejected</Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link active" to="/recruiter_pages/AllProfile">All</Link>
                                            </li>
                                        </ul>
                                        {
                                            rows?.length > 0 ? (
                                                <>
                                                    {/* <-------- Table sections ------> */}
                                                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                                        <TableContainer sx={{ maxHeight: 440 }}>
                                                            <Table stickyHeader aria-label="sticky table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {columns.map((column) => (
                                                                            <TableCell
                                                                                key={column.id}
                                                                                align={column.align}
                                                                                style={{ minWidth: column.minWidth, backgroundColor: '#f1f1f1' }}>
                                                                                {column.label}
                                                                                <TextField
                                                                                    variant="standard"
                                                                                    value={filters[column.id] || ""}
                                                                                    onChange={(e) => handleFilterChange(e, column.id)}
                                                                                    placeholder={`Filter ${column.label}`}
                                                                                    size="small" />
                                                                            </TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {filteredRows
                                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                        .map((row) => {
                                                                            return (
                                                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                                    {columns.map((column) => {
                                                                                        const value = row[column.id];
                                                                                        return (
                                                                                            <TableCell key={column.id} align={column.align}>
                                                                                                {column.id === 'applied_status' ? (
                                                                                                    renderStatusButton(value)
                                                                                                ) : column.id === 'student_userid' ? (
                                                                                                    <button onClick={() => {
                                                                                                        setStudentProfileShow(true)
                                                                                                        GetStudentDetailsById(row?.student_userid)
                                                                                                    }} className='btn btn-primary'>View More</button>
                                                                                                ) : (
                                                                                                    value
                                                                                                )}
                                                                                            </TableCell>
                                                                                        );
                                                                                    })}
                                                                                </TableRow>
                                                                            );
                                                                        })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        <TablePagination
                                                            rowsPerPageOptions={[10, 25, 100]}
                                                            component="div"
                                                            count={filteredRows.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                        />
                                                    </Paper>
                                                </>

                                            ) : (
                                                <div className='text-center mt-5'>
                                                    <h4>Not Any Student Profile Available here</h4>
                                                </div>
                                            )
                                        }


                                        {/* <------ Student Profile section's -------------> */}
                                        <Modal size='lg' show={StudentProfileShow} onHide={() => setStudentProfileShow(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Student Profile</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div class="resumefile">
                                                    <page size="A4">
                                                        <div class="d-md-flex">
                                                            <div class="leftPanel">
                                                                {
                                                                    StudentProfile?.student_info?.profileimg != null ? (
                                                                        <>
                                                                            <img src={StudentProfile?.student_info?.profileimg} />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <img src={CommonImageTransport?.userLogo} />
                                                                        </>
                                                                    )
                                                                }
                                                                <div class="details">
                                                                    <div class="item bottomLineSeparator">
                                                                        <h2>
                                                                            CONTACT
                                                                        </h2>
                                                                        <div class="smallText">
                                                                            <p>
                                                                                <i class="fa fa-phone contactIcon" aria-hidden="true"></i>
                                                                                ({StudentProfile?.student_info?.countrycode}) {StudentProfile?.student_info?.mobile}
                                                                            </p>
                                                                            <p>
                                                                                <i class="fa fa-envelope contactIcon" aria-hidden="true"></i>
                                                                                <a href="mailto:lorem@ipsum.com@gmail.com">{StudentProfile?.student_info?.email}</a>
                                                                            </p>
                                                                            <p>
                                                                                <i class="fa fa-map-marker contactIcon" aria-hidden="true"></i>
                                                                                {StudentProfile?.student_detail?.location}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="item bottomLineSeparator">
                                                                        <h2>
                                                                            SKILLS
                                                                        </h2>
                                                                        <div class="smallText">
                                                                            {
                                                                                StudentProfile?.student_skills?.map((StudentSkillsResult) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div class="skill">
                                                                                                <div>
                                                                                                    <span>{StudentSkillsResult?.skill}</span>
                                                                                                </div>
                                                                                                <div class="yearsOfExperience">
                                                                                                    <span class="alignright">{StudentSkillsResult?.expertise}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div class="item">
                                                                        <h2>
                                                                            EDUCATION
                                                                        </h2>
                                                                        {
                                                                            StudentProfile?.student_educations?.map((StudentEdutionResult) => {
                                                                                return (
                                                                                    <>
                                                                                        <div class="smallText">
                                                                                            <p class="bolded white">
                                                                                                <strong>Degree:</strong>{StudentEdutionResult?.degreename}
                                                                                            </p>
                                                                                            <p>
                                                                                                <strong>University:</strong>{StudentEdutionResult?.university}
                                                                                            </p>
                                                                                            <p>
                                                                                                {moment(StudentEdutionResult?.startdate).format("YYYY")}  -  {moment(StudentEdutionResult?.enddate).format("YYYY")}
                                                                                            </p>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })

                                                                        }

                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="rightPanel">
                                                                <div>
                                                                    <h3>
                                                                        {StudentProfile?.student_info?.fname} {StudentProfile?.student_info?.lname}
                                                                    </h3>
                                                                    <div class="smallText">
                                                                        <h6>
                                                                            ({StudentProfile?.student_detail?.p_title})
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="workExperience mt-5">
                                                                    <h4>
                                                                        Work experience
                                                                    </h4>
                                                                    <ul>
                                                                        <li>
                                                                            <div class="jobPosition">
                                                                                <span class="bolded">
                                                                                    <h3>{StudentProfile?.student_detail?.industry}</h3>
                                                                                    <h6>{StudentProfile?.student_detail?.p_title}</h6>
                                                                                </span>
                                                                                <span>{moment(StudentProfile?.student_detail?.startdate).format("YYYY")} - {moment(StudentProfile?.student_detail?.enddate).format("YYYY")}</span>
                                                                            </div>
                                                                            <div class="smallText">
                                                                                <p>{StudentProfile?.student_detail?.emp_type}</p>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div class="workExperience">
                                                                    <h4>
                                                                        Projects
                                                                    </h4>
                                                                    <ul>
                                                                        {
                                                                            StudentProfile?.university_projects?.map((StudentWorkExpResult) => {
                                                                                return (
                                                                                    <>
                                                                                        <li>
                                                                                            <div class="jobPosition">
                                                                                                <span class="bolded">
                                                                                                    {StudentWorkExpResult?.title}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div class="smallText">
                                                                                                <p>{StudentWorkExpResult?.yourrole}</p>
                                                                                            </div>
                                                                                        </li>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                                <div class="workExperience">
                                                                    <h4>
                                                                        Certificate
                                                                    </h4>
                                                                    <ul>
                                                                        {
                                                                            StudentProfile?.student_certification?.length > 0 && (
                                                                                <>
                                                                                    {
                                                                                        StudentProfile?.student_certification?.map((StudentCertificateResult) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <li>
                                                                                                        <div class="jobPosition">
                                                                                                            <span class="bolded">
                                                                                                                {StudentCertificateResult?.certificate}
                                                                                                            </span>
                                                                                                            <span>
                                                                                                                {moment(StudentCertificateResult?.certification_date).format("DD-MM-YYYY")}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div class="projectName bolded">
                                                                                                            <span>
                                                                                                                {StudentCertificateResult?.organication}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </page>

                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
       <Recruiter_Footer/>
        </div>
    )
}

export default AllProfile