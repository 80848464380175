import React, { useState, useEffect, useRef } from 'react'
import { Accordion } from 'react-bootstrap';
import CommonImageTransport from '../../../common/images/CommonImages';
import Modal from 'react-bootstrap/Modal';
import TagsInput from 'react-tagsinput';
import moment from 'moment-timezone';
import { Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { CompleteInterviewRound, GetRecuriterTeamMemeberList, PostInterviewRounds, Postinterviewroundfeedback } from '../../../../api/Recuriter'
import 'react-tagsinput/react-tagsinput.css';
import { Rating } from 'react-simple-star-rating';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const Recuriter_Technical_Interviewing = ({ GetInterviewWithRounds, onGetInterviewWithRounds }) => {

    // <-------------------------- Modal sections Open and Close ---------------------------->
    const [FinishTechnicalInterviewShow, setFinishTechnicalInterviewShow] = useState(false);
    const [TechnicalInterviewFeedbackShow, setTechnicalInterviewFeedbackShow] = useState(false);
    const [AddMoreTechnicalInterviewShow, setAddMoreTechnicalInterviewShow] = useState(false);
    const [ScheduleTechnicalInterviewShow, setScheduleTechnicalInterviewShow] = useState(false);
    const [showEditTechnicalInterviewFeedback, setShowEditTechnicalInterviewFeedback] = useState(false);

    // <---------- Technical Interview Feedback & edit Feedback sections ------------------------->
    const [FeedbackRating, setFeedbackRating] = useState(0);
    const [TechnicalInterviewFeedbackSkills, setTechnicalInterviewFeedbackSkills] = useState([])
    const [technicalinterviewfeedbackstatus, setTechnicalinterviewFeedbackStatus] = useState("")
    const [TechnicalInterviewFeedback, setTechnicalInterviewFeedback] = useState("")
    const [technicalFeedbackrecordedLink, settechnicalFeedbackrecordedLink] = useState('');

    // <--------------------- schedule Technical Interview sections -------------------------------->
    const [scheduleTechnicalInterviewselectedOptions, setScheduleTechnicalInterviewselectedOptions] = useState([]);
    const [ScheduleTechnicalInterviewFromScheduledAt, setTecnicalInterviewFromScheduledAt] = useState('');
    const [ScheduleTechnicalInterviewToScheduledAt, setTecnicalInterviewToScheduledAt] = useState('');
    const [ScheduleTechIntervieFromDate, setTechInterviewFromDate] = useState([]);
    const [ScheduleTechIntervieToDate, setTechInterviewToDate] = useState([]);
    const [TechnicalInterviewSeduleInterviewLink, setTechnicalInterviewScheduleInterviewLink] = useState([]);

    // <--------------------- Add More Technical Interview sections ----------------------------->
    const [addMoreTechnicalselectedOptions, setaddMoreTechnicalselectedOptions] = useState([]);
    const [addMoreFromTechInterviewDate, setaddMoreFromTechInterviewDate] = useState([]);
    const [addMoreToTechInterviewDate, setaddMoreToTechInterviewDate] = useState([]);
    const [addMoreToScheduledAt, setaddMoreToScheduledAt] = useState('');
    const [addMoreFromScheduledAt, setaddMoreFromScheduledAt] = useState('');
    const [AddMoreInterviewLink, setAddMoreTechInterviewLink] = useState([]);

    // <----------------------- get the Last id from the feedback form --------------------------->
    const [specificInterviewId, setSpecificInterviewId] = useState();
    const [GetFeedbackById, setGetFeedbackById] = useState('')
    const [GetStatusFeedbackById, setGetStatusFeedbackById] = useState('');
    const [GetStarRattingFeedbackById, setGetStarRattingFeedbackById] = useState('');
    const [GetSkillsFeedbackById, setGetSkillsFeedbackById] = useState([]);
    const [GetRecordedFeedbackById, setGetRecordedFeedbackById] = useState("");
    const [GetGiveFeedbackById, setGetGiveFeedbackById] = useState("");

    // <------------------- Show More & Less Feedback form-------------------------> 
    const [showMore, setShowMore] = useState(false);

    const [openTechnicalAccordions, setOpenTechnicalAccordions] = useState({});
    const [showMoreTechnicalContent, setShowMoreTechnicalContent] = useState({});

    // <---------- Toggle Functionlity on the technical interview Accordtion section's ------------->
    const technicaltoggleAccordion = (key) => {
        setOpenTechnicalAccordions((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    // <---------- Toggle For Show More & Less Functionlity on the technical interview section's ------------->
    const toggleTechShowMore = (key) => {
        setShowMoreTechnicalContent((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));

    };

    // <-------------------- get the Interviwer List --------------------->
    const [GetInterviewer, setGetInterviewer] = useState([]);

    // <-------------- get the Interview Details  with Rounds ------------->
    const [interviewWithRounds, setInterviewRounds] = useState([]);
    // const [LastIdinterviewWithRounds, setLastIdInterviewRounds] = useState("");

    // <------------- Get the Interview Id from the Last data ------------------->
    const [InterviewIdinterviewWithRounds, setInterviewIdInterviewRounds] = useState("");
    const [AddMoreTechnicalInterviewIdSections, setAddMoreTechnicalInterviewIdSections] = useState("");
    const token = localStorage.getItem("User_Token");
    const id = localStorage.getItem("GetInterviewWithRounds_id");

    // <--------------- get the User Authentication token ------------------>
    const headers = {
        "Authorization": `Bearer ${token}`
    };

    const contentRef = useRef(null);
    // <--------- Rating on the Feedback & Edit Feedback sections ----------------->
    const handleTechnicalInterviewFeedbackStarClick = (value) => {
        setFeedbackRating(value);
    };
    // <--------- Rating on the Feedback & Edit Feedback sections ----------------->
    const handleEditTechnicalInterviewFeedbackStarClick = (value) => {
        setGetStarRattingFeedbackById(value);
    };

    // <--------- Show the More Content -------------->
    const toggleShowMore = (index) => {
        setShowMore(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    }

    // <------------------ Select the Multiple Interview sections ------------------>
    const handleChangeScheduleTechnicalInterviewselectedOptions = (event) => {
        const {
            target: { value },
        } = event;
        setScheduleTechnicalInterviewselectedOptions(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    // <------------ get the Last Id and Interview Id from the Last Feedback (Add More sections ) ------------>
    const HandleAddMoreTechnicalInterviewIdSections = (details) => {
        const technicalDetails = details?.filter(detail => detail?.roundtype === "Technical");
        const technicalIds = technicalDetails?.map(detail => detail.id);
        const interviewid = technicalDetails?.map(detail => detail.interviewid);

        if (technicalIds.length > 0) {
            const lastId = technicalIds[technicalIds.length - 1];
            const interview = interviewid[interviewid.length - 1];

            setAddMoreTechnicalInterviewIdSections(lastId);
            // setLastIdInterviewRounds(lastId);
            setInterviewIdInterviewRounds(interview);
        }

    };

    // <--------- Get the Team Member List sections ----------->
    useEffect(() => {
        GetTeamMemeberList();
    }, [])


    // <--------- Schedule Technical format Date & Time From Time Zone  --------------->
    const ScheduleTechnicalformatDateTimeWithFromTimeZone = (e) => {
        const selectedDateTime = e.target.value;
        const dateObj = moment(selectedDateTime).tz(moment.tz.guess());
        const formattedDisplay = dateObj.format('ddd MMM DD YYYY HH:mm:ss [GMT]Z (zz)');
        setTecnicalInterviewFromScheduledAt(formattedDisplay);
        const formattedForInput = dateObj.format('YYYY-MM-DDTHH:mm:ss');
        setTechInterviewFromDate(formattedForInput);
    };

    // <--------- Schedule Technical format Date & Time To Time Zone  --------------->
    const ScheduleTechnicalformatDateTimeWithToTimeZone = (e) => {
        const selectedDateTime = e.target.value;
        const dateObj = moment(selectedDateTime).tz(moment.tz.guess());
        const formattedDisplay = dateObj.format('ddd MMM DD YYYY HH:mm:ss [GMT]Z (zz)');
        setTecnicalInterviewToScheduledAt(formattedDisplay);
        const formattedForInput = dateObj.format('YYYY-MM-DDTHH:mm:ss');
        setTechInterviewToDate(formattedForInput);
    };

    // <--------- Add More Technical format Date & Time From Time Zone  --------------->
    const AddMoreTechnicalformatDateTimeWithFromTimeZone = (e) => {
        const selectedDateTime = e.target.value;
        const dateObj = moment(selectedDateTime).tz(moment.tz.guess());
        const formattedDisplay = dateObj.format('ddd MMM DD YYYY HH:mm:ss [GMT]Z (zz)');
        setaddMoreFromScheduledAt(formattedDisplay);
        const formattedForInput = dateObj.format('YYYY-MM-DDTHH:mm:ss');
        setaddMoreFromTechInterviewDate(formattedForInput);
    };

    // <--------- Add More Technical format Date & Time To Time Zone  --------------->
    const AddMoreTechnicalformatDateTimeWithToTimeZone = (e) => {
        const selectedDateTime = e.target.value;
        const dateObj = moment(selectedDateTime).tz(moment.tz.guess());
        const formattedDisplay = dateObj.format('ddd MMM DD YYYY HH:mm:ss [GMT]Z (zz)');
        setaddMoreToScheduledAt(formattedDisplay);
        const formattedForInput = dateObj.format('YYYY-MM-DDTHH:mm:ss');
        setaddMoreToTechInterviewDate(formattedForInput);
    };

    // <---------------- get the Team Member List -----------------------> 
    const GetTeamMemeberList = async () => {
        try {
            const response = await GetRecuriterTeamMemeberList(headers)
            setGetInterviewer(response?.data?.data)
        } catch (error) {
            console.log("error", error)
        }
    }

    // <---------- handle Technical Interview Feedback form -------------------->
    const handleTechnicalInterviewFeedbackForm = async (e) => {
        e.preventDefault();

    // Check if hire status is selected (Hire/Reject)
    if (!technicalinterviewfeedbackstatus) {
        toast.error("Please select a recommendation (Hire/Reject).");
        return;
    }

    // Check if rating is provided
    if (!FeedbackRating || FeedbackRating === 0) {
        toast.error("Please provide a star rating.");
        return;
    }

    // Check if at least one skill is added
    if (!TechnicalInterviewFeedbackSkills || TechnicalInterviewFeedbackSkills.length === 0) {
        toast.error("Please provide at least one skill.");
        return;
    }

    // Check if recorded link is filled
    if (!technicalFeedbackrecordedLink || technicalFeedbackrecordedLink.trim() === "") {
        toast.error("Please provide the recorded link.");
        return;
    }

    // Check if feedback is filled
    if (!TechnicalInterviewFeedback || TechnicalInterviewFeedback.trim() === "") {
        toast.error("Please provide feedback.");
        return;
    }

        const PostinterviewroundfeedbackData = {
            id: specificInterviewId,
            rating: FeedbackRating,
            skills: TechnicalInterviewFeedbackSkills,
            feedback: TechnicalInterviewFeedback,
            is_feedback: 1,
            is_hire_status: technicalinterviewfeedbackstatus,
            recordedLink: technicalFeedbackrecordedLink,
        }
        const response = await Postinterviewroundfeedback(PostinterviewroundfeedbackData, headers)
        if (response?.data?.statusCode == "200") {
            setTechnicalInterviewFeedbackShow(false)
            GetRecuriterInterviewWithRounds(id)
            setShowEditTechnicalInterviewFeedback(false)
            setTechnicalinterviewFeedbackStatus()
            setTechnicalInterviewFeedback()
            settechnicalFeedbackrecordedLink()
            setTechnicalInterviewFeedbackSkills([])
            setFeedbackRating()
        }
    }

    // <---------- handle Edit Technical Interview Feedback form -------------------->
    const handleEditTechnicalInterviewFeedbackForm = async (e) => {
        e.preventDefault();

    // Check if hire status is selected (Hire/Reject)
    if (!GetStatusFeedbackById) {
        toast.error("Please select a recommendation (Hire/Reject).");
        return;
    }

    // Check if rating is provided
    if (!GetStarRattingFeedbackById || GetStarRattingFeedbackById === 0) {
        toast.error("Please provide a star rating.");
        return;
    }

    // Check if at least one skill is provided
    if (!GetSkillsFeedbackById || GetSkillsFeedbackById.length === 0) {
        toast.error("Please provide at least one skill.");
        return;
    }

    // Check if recorded link is filled
    if (!GetRecordedFeedbackById || GetRecordedFeedbackById.trim() === "") {
        toast.error("Please provide the recorded link.");
        return;
    }

    // Check if feedback is filled
    if (!GetGiveFeedbackById || GetGiveFeedbackById.trim() === "") {
        toast.error("Please provide feedback.");
        return;
    }

        const PostinterviewroundfeedbackData = {
            id: specificInterviewId,
            rating: GetStarRattingFeedbackById,
            skills: GetSkillsFeedbackById,
            feedback: GetGiveFeedbackById,
            is_feedback: 1,
            is_hire_status: GetStatusFeedbackById,
            recordedLink: GetRecordedFeedbackById,
        }
        const response = await Postinterviewroundfeedback(PostinterviewroundfeedbackData, headers)
        if (response?.data?.statusCode == "200") {
            setTechnicalInterviewFeedbackShow(false)
            GetRecuriterInterviewWithRounds(id)
            setShowEditTechnicalInterviewFeedback(false)
            setTechnicalinterviewFeedbackStatus()
            setTechnicalInterviewFeedback()
            // settechnicalFeedbackrecordedLink()
            setTechnicalInterviewFeedbackSkills([])
            setFeedbackRating()
        }
    }

    const getIdByName = (objects, names) => {
        const ids = [];
        names.forEach((fullName) => {
            const [fname, lname] = fullName.split(" ");
            objects.forEach((obj) => {
                if (obj.fname === fname && obj.lname === lname) {
                    ids.push(obj.id);
                }
            });
        });
        return ids;
    }

    // <--------- Handle Technical Secdule sections ------------->
    const HandleScheduleTechnicalInterview = async (e) => {
        e.preventDefault();
        const ids = getIdByName(GetInterviewer, scheduleTechnicalInterviewselectedOptions);
        e.preventDefault();

        const PostTechnicalInterviewData = {
            interviewerID: ids,
            interviewId: id,
            roundType: "Technical",               //'Technical', 'Behavioral', 'Decision'
            roundNumber: 1,
            scheduledAt: ScheduleTechnicalInterviewFromScheduledAt,
            scheduledAtTo: ScheduleTechnicalInterviewToScheduledAt,
            interviewLink: TechnicalInterviewSeduleInterviewLink
        }
        const response = await PostInterviewRounds(PostTechnicalInterviewData, headers)
        if (response?.data?.statusCode == "200") {
            GetRecuriterInterviewWithRounds(id)
            setScheduleTechnicalInterviewShow(false)
        }

    }

    // <---------- handle add more form -------------------->
    const HandleAddMoreTechnicalInterview = async (e) => {
        const ids = getIdByName(GetInterviewer, addMoreTechnicalselectedOptions);
        e.preventDefault();
          // Validation checks
    if (addMoreTechnicalselectedOptions.length === 0) {
        toast.error("Please select at least one interviewer for the technical interview.");
        return;
    }

      // 2. Validate if 'Interview From' date is filled
    if (!addMoreFromTechInterviewDate) {
        toast.error("Please provide the 'Interview From' date.");
        return;
    }

    // 3. Validate if 'Interview To' date is filled
    if (!addMoreToTechInterviewDate) {
        toast.error("Please provide the 'Interview To' date.");
        return;
    }

    // 4. Validate if the interview link is filled
     // Ensure interview link is a string and trim it
     if (typeof AddMoreInterviewLink !== 'string' || !AddMoreInterviewLink.trim()) {
        toast.error("Please provide the interview link.");
        return;
    }

        const PostTechnicalInterviewData = {
            lastId: specificInterviewId,
            interviewerID: ids,
            interviewId: InterviewIdinterviewWithRounds,
            roundType: "Technical",               //'Technical', 'Behavioral', 'Decision'
            roundNumber: 1,
            scheduledAt: addMoreFromScheduledAt,
            scheduledAtTo: addMoreToScheduledAt,
            interviewLink: AddMoreInterviewLink
        }
        const response = await PostInterviewRounds(PostTechnicalInterviewData, headers)
        if (response?.data?.statusCode == "200") {
            GetRecuriterInterviewWithRounds(id)
            setAddMoreTechnicalInterviewShow(false);
        }
        setaddMoreToTechInterviewDate([])
        setaddMoreFromTechInterviewDate([])
        setaddMoreTechnicalselectedOptions([])
        setAddMoreTechInterviewLink("");
    }

    // <------ get interview With Round ------------->
    const GetRecuriterInterviewWithRounds = async (id) => {
        const GetInterviewWithRoundsdata = {
            id
        }
        const response = await GetInterviewWithRounds(GetInterviewWithRoundsdata, headers)
        setInterviewRounds(response?.data?.data);
        onGetInterviewWithRounds(response?.data?.data);
    }

    // <------------ complete interview round -------------->
    const Handlecompleteinterviewround = async () => {
        const CompleteInterviewRoundData = {
            id: specificInterviewId
        }
        const response = await CompleteInterviewRound(CompleteInterviewRoundData, headers)
        if (response?.data?.statusCode == "200") {
            GetRecuriterInterviewWithRounds(id)
            setFinishTechnicalInterviewShow(false)
        }
    }


    useEffect(() => {
        const id = localStorage.getItem("GetInterviewWithRounds_id");
        if (id) {
            GetRecuriterInterviewWithRounds(id);
        }
    }, [id]);

    // Filter the interview rounds to only include Behavioral rounds
    const technicalInterviews = interviewWithRounds?.flatMap(interviewWithRoundRes =>
        interviewWithRoundRes?.interviewdetails?.filter(detail => detail?.roundtype === "Technical") || []
    );
    console.log("technicalInterviews", technicalInterviews)
    const PresentDateTime = new Date();

    const handleGetFeedbackById = (id) => {
        const technicalInterviewsById = interviewWithRounds[0]?.interviewdetails?.find(detail => detail?.id === id)
        setGetFeedbackById(technicalInterviewsById)
        setGetStatusFeedbackById(technicalInterviewsById?.is_hire_status);
        setGetRecordedFeedbackById(technicalInterviewsById?.recordedlink);
        const SkillsArray = technicalInterviewsById?.skills.replace(/[{}]/g, '').split(',')
        setGetSkillsFeedbackById(SkillsArray);
        setGetGiveFeedbackById(technicalInterviewsById?.feedback);
        setGetStarRattingFeedbackById(technicalInterviewsById?.rating);
    }
    return (
        <div>

            <div>
            <ToastContainer style={{ marginTop: "120px" }} />
                {
                    // Check if any interview details have roundtype as "Technical"
                    !interviewWithRounds?.some(interview =>
                        interview?.interviewdetails?.some(detail => detail?.roundtype === "Technical")
                    ) && (
                        <div className="firsthire">
                            <div className="card">
                                <a href="#" className="default-btn rounded" data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={() => setScheduleTechnicalInterviewShow(true)}>
                                    Schedule Interview
                                </a>
                            </div>
                        </div>
                    )
                }

                {
                    technicalInterviews?.length > 0 && (
                        technicalInterviews?.map((detail, idx) => {
                            return (
                                <div key={idx}>
                                    {(detail?.rating) && <div className="card mt-3">
                                        <div className="interviewround1">{idx + 1}</div>
                                        <div className="card-body p-0">
                                            <div className="profileinterview p-3 d-flex gap-3">
                                                <img width="50" className="rounded-pill" height="50"
                                                    src={CommonImageTransport?.profile1}
                                                    onError="src='../assets/images/img_avatar.png'"
                                                    alt="" />
                                                <div>Interviewer:-
                                                    {detail?.interviewers?.map((interviewer, idy) => (
                                                        <h6 key={idy} className="m-0">{interviewer?.fname} {interviewer?.lname}</h6>
                                                    ))}
                                                </div>

                                                <div className="form-check text-success">
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                        {detail?.is_hire_status}
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="header0101 p-3 py-2">
                                                <div className="d-flex justify-content-between gap-3">
                                                    <h6 className="m-0 fw-normal">Recommend </h6>
                                                    <div className="reviewsreat">
                                                        <Rating
                                                            readonly={true}
                                                            initialValue={detail?.rating}
                                                            className="custom-star-rating" style={{ fontSize: '5px' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <Accordion activeKey={openTechnicalAccordions[idx] ? '0' : null}>
                                            <Accordion.Item className='pt-0' eventKey="0">
                                                <Accordion.Header onClick={() => technicaltoggleAccordion(idx)} className='btn-sm customebtsns'>
                                                    {openTechnicalAccordions[idx] ? 'View Less' : 'View More'}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="text-end mb-2">
                                                        <a href="#" className="btn btn-outline-primary btn-sm px-3"
                                                            data-bs-toggle="modal" onClick={() => {
                                                                setShowEditTechnicalInterviewFeedback(true)
                                                                setSpecificInterviewId(detail?.id)
                                                                handleGetFeedbackById(detail?.id)
                                                            }}
                                                            data-bs-target="#technicalinterviewfeedback"> Edit Feedback </a>
                                                    </div>

                                                    <h6>Top 5 Skills:-</h6>
                                                    <ol>
                                                        {detail.skills
                                                            ?.replace(/[{}"\\]/g, '')
                                                            ?.split(/,\s*/)
                                                            ?.map((skill, skillIndex, array) => (
                                                                <li key={skillIndex}>
                                                                    {skill.trim()}
                                                                    {skillIndex < array.length - 1 && ', '}
                                                                </li>
                                                            ))}
                                                    </ol>
                                                    <h6 className="mb-0">Recorded Url</h6>
                                                    {/* <p className="text-primary">{detail?.recordedlink}</p> */}
                                                    <a 
  href={detail?.recordedlink} 
  target="_blank" 
  rel="noopener noreferrer" 
  className="text-primary"
>
  {detail?.recordedlink}
</a>


                                                    <div className="showmorecontent">
                                                        <div className="block">
                                                            <div className="content" ref={contentRef}>
                                                                <h6>Feedback:-</h6>
                                                                <p className={showMoreTechnicalContent[idx] ? '' : 'js-excerpt excerpt-hidden'}>{detail?.feedback}</p>
                                                            </div>
                                                            <button role="button" href="#" onClick={() => toggleTechShowMore(idx)}>
                                                                {showMoreTechnicalContent[idx] ? 'Show Less' : 'Show More'}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                    }


                                    {(!detail?.rating) && <div className="firsthire">
                                        <div className="card">
                                            <div className="interviewround1">{idx + 1}</div>
                                            <div className="card-body p-0">
                                                <div className="profileinterview position-relative p-2 d-flex gap-2">
                                                    <img
                                                        width="40"
                                                        className="rounded-pill"
                                                        height="40"
                                                        src={CommonImageTransport?.profile1}
                                                        onError={(e) => (e.target.src = '../assets/images/img_avatar.png')}
                                                        alt=""
                                                    />
                                                    <div>Interviewers:-
                                                        {detail?.interviewers?.map((interviewersResult, idx) => (
                                                            <h6 key={idx} className="m-0">{interviewersResult?.fname} {interviewersResult?.lname}</h6>
                                                        ))}
                                                        <span className="small">Date: {new Date(detail?.scheduledat).toLocaleString()}</span>
                                                    </div>
                                                </div>

                                                <div>
                                                    {(detail?.status === "Completed" && detail?.rating === null && new Date(detail?.scheduledatto) < PresentDateTime) &&
                                                        <div className="header0101 p-2 py-2">
                                                            <a href="#" className="default-btn rounded"
                                                                onClick={() => { setTechnicalInterviewFeedbackShow(true); setSpecificInterviewId(detail.id) }}
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal">
                                                                Interview Feedback
                                                            </a>
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <div className='d-flex justify-content-between mt-2'>
                                        {(idx == technicalInterviews?.length - 1 && detail.status !== "Completed" &&
                                            new Date(detail?.scheduledatto) < PresentDateTime) &&

                                            <span className='btn btn-danger' onClick={() => {
                                                setFinishTechnicalInterviewShow(true)
                                                setSpecificInterviewId(technicalInterviews[technicalInterviews?.length - 1].id)
                                            }}>Finish</span>


                                        }
                                        {(idx == technicalInterviews?.length - 1 && detail.status !== "Completed" &&
                                            new Date(detail?.scheduledatto) < PresentDateTime) &&

                                            <span className='btn btn-success'
                                                onClick={() => {
                                                    setAddMoreTechnicalInterviewShow(true)
                                                    HandleAddMoreTechnicalInterviewIdSections(technicalInterviews || [])
                                                    setSpecificInterviewId(technicalInterviews[technicalInterviews?.length - 1].id)
                                                }}>Add More</span>


                                        }
                                    </div>
                                </div>
                            )

                        })
                    )
                }

                {/* <-------- Add More Technical Interviwer ----------> */}
                <Modal
                    size="lg"
                    show={AddMoreTechnicalInterviewShow}
                    onHide={() => setAddMoreTechnicalInterviewShow(false)}
                    aria-labelledby="example-modal-sizes-title-lg">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Technical Interview</h5>
                        <button type="button" className="btn-close" onClick={() => setAddMoreTechnicalInterviewShow(false)}
                            data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form method="post">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <div className="form-group mb-2 myteamform">
                                        <h6>Select Interviewers</h6>
                                        <FormControl sx={{ m: 1, width: 750 }}>
                                            <InputLabel id="demo-multiple-checkbox-label">Select Interviewer</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={addMoreTechnicalselectedOptions}
                                                onChange={(e) => setaddMoreTechnicalselectedOptions(e.target.value)}
                                                input={<OutlinedInput label="Select Interviewer" />}
                                                renderValue={(selected) => selected.join(', ')}
                                            >
                                                {GetInterviewer?.map((interviewer) => (
                                                    <MenuItem key={interviewer.id} value={`${interviewer.fname} ${interviewer.lname}`}>
                                                        <Checkbox checked={addMoreTechnicalselectedOptions.indexOf(`${interviewer.fname} ${interviewer.lname}`) > -1} />
                                                        <ListItemText primary={`${interviewer.fname} ${interviewer.lname}`} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group mb-2 myteamform">
                                        <h6>Interview From</h6>
                                        <div className="datetime-timezone-input form-group">
                                            <input
                                                type="datetime-local"
                                                name="addMoreFromScheduledAt"
                                                placeholder="date"
                                                className="form-control datetime-input"
                                                required
                                                value={addMoreFromTechInterviewDate}
                                                onChange={AddMoreTechnicalformatDateTimeWithFromTimeZone}
                                                min={moment().format('YYYY-MM-DDTHH:mm')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group mb-2 myteamform">
                                        <h6>Interview To</h6>
                                        <div className="datetime-timezone-input form-group">
                                            <input
                                                type="datetime-local"
                                                name="addMoreToScheduledAt"
                                                placeholder="date"
                                                className="form-control datetime-input"
                                                required
                                                value={addMoreToTechInterviewDate}
                                                onChange={AddMoreTechnicalformatDateTimeWithToTimeZone}
                                                min={moment().format('YYYY-MM-DDTHH:mm')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="form-group mb-2 myteamform">
                                        <h6>Interview Link</h6>
                                        <input
                                            type="text"
                                            name="AddMoreInterviewLink"
                                            value={AddMoreInterviewLink}
                                            onChange={(e) => setAddMoreTechInterviewLink(e.target.value)}
                                            placeholder="https://meet.google.com/odp-nvra-ycf"
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dd-footer d-flex gap-3 p-3 border-top">
                            <button type="button" className="btn btn-secondary w-100" data-bs-dismiss="modal" 
                            onClick={() => setAddMoreTechnicalInterviewShow(false)}>Close</button>
                            <button type="button"
                                className="btn default-btn w-100"
                                onClick={HandleAddMoreTechnicalInterview}>Submit</button>
                        </div>
                    </form>



                </Modal>

                {/* <-------- Schedule Technical Interviwe ----------> */}
                <Modal
                    size="lg"
                    show={ScheduleTechnicalInterviewShow}
                    onHide={() => setScheduleTechnicalInterviewShow(false)}
                    aria-labelledby="example-modal-sizes-title-lg">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Technical Interview</h5>
                        <button type="button" className="btn-close" onClick={() => setScheduleTechnicalInterviewShow(false)}
                            data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form method="post">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <div className="form-group mb-2 myteamform">
                                        <h6>Select Interviewer</h6>
                                        <FormControl sx={{ m: 1, width: 750 }}>
                                            <InputLabel id="demo-multiple-checkbox-label">Select Interviewer</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={scheduleTechnicalInterviewselectedOptions}
                                                onChange={handleChangeScheduleTechnicalInterviewselectedOptions}
                                                input={<OutlinedInput label="Select Interviewer" />}
                                                renderValue={(selected) => selected.join(', ')}>
                                                {GetInterviewer?.map((interviewer) => (
                                                    <MenuItem key={interviewer.id} value={`${interviewer.fname} ${interviewer.lname}`}>
                                                        <Checkbox checked={scheduleTechnicalInterviewselectedOptions.indexOf(`${interviewer.fname} ${interviewer.lname}`) > -1} />
                                                        <ListItemText primary={`${interviewer.fname} ${interviewer.lname}`} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group mb-2 myteamform">
                                        <h6>Interview From</h6>
                                        <div className="datetime-timezone-input form-group">
                                            <input
                                                type="datetime-local"
                                                name="ScheduleTechnicalInterviewFromScheduledAt"
                                                placeholder="date"
                                                className="form-control datetime-input"
                                                required
                                                value={ScheduleTechIntervieFromDate}
                                                onChange={ScheduleTechnicalformatDateTimeWithFromTimeZone}
                                                min={moment().format('YYYY-MM-DDTHH:mm')} />

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group mb-2 myteamform">
                                        <h6>Interview To</h6>
                                        <div className="datetime-timezone-input form-group">
                                            <input
                                                type="datetime-local"
                                                name="ScheduleTechnicalInterviewToScheduledAt"
                                                placeholder="date"
                                                className="form-control datetime-input"
                                                required
                                                value={ScheduleTechIntervieToDate}
                                                onChange={ScheduleTechnicalformatDateTimeWithToTimeZone}
                                                min={moment().format('YYYY-MM-DDTHH:mm')} />

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="form-group mb-2 myteamform">
                                        <h6>Interview  Link</h6>
                                        <input type="text" name="TechnicalInterviewSeduleInterviewLink"
                                            value={TechnicalInterviewSeduleInterviewLink}
                                            onChange={(e) => setTechnicalInterviewScheduleInterviewLink(e.target.value)}
                                            placeholder="https://meet.google.com/odp-nvra-ycf" className="form-control"
                                            required />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dd-footer d-flex gap-3 p-3 border-top">
                            <button type="button" className="btn btn-secondary w-100"  
                            onClick={()=>setScheduleTechnicalInterviewShow(false)} data-bs-dismiss="modal">Close</button>
                            <button type="button"
                                className="btn default-btn w-100" onClick={HandleScheduleTechnicalInterview}>Submit</button>
                        </div>
                    </form>


                </Modal>

                {/* <--------- Technical Feedbacks sections --------------> */}
                <Modal
                    size="lg"
                    show={TechnicalInterviewFeedbackShow}
                    onHide={() => {
                        setTechnicalInterviewFeedbackShow(false);
                        setTechnicalinterviewFeedbackStatus();
                        setTechnicalInterviewFeedback();
                        settechnicalFeedbackrecordedLink();
                        setTechnicalInterviewFeedbackSkills([]);
                        setFeedbackRating();
                    }}
                    aria-labelledby="example-modal-sizes-title-lg"
                    className="modal fade" id="exampleModal" tabIndex="-1"
                    aria-hidden="true"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Interview Feedback</h5>
                            <button type="button" className="btn-close"
                                onClick={() => {
                                    setTechnicalInterviewFeedbackShow(false);
                                    setTechnicalinterviewFeedbackStatus();
                                    setTechnicalInterviewFeedback();
                                    settechnicalFeedbackrecordedLink();
                                    setTechnicalInterviewFeedbackSkills([]);
                                    setFeedbackRating();
                                }}
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form method="post">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div className="form-group">
                                            <div className="d-flex gap-4">
                                                <label className="m-0 fw-normal">Recommend </label>
                                                <div className="form-check text-success">
                                                    <input className="form-check-input text-success" type="radio"
                                                        name="technicalinterviewfeedbackstatus"
                                                        value="Hire"
                                                        onChange={(e) => setTechnicalinterviewFeedbackStatus(e.target.value)}
                                                        id="flexRadioDefault1" />
                                                    <label className="form-check-label text-success"
                                                        htmlFor="flexRadioDefault1">
                                                        Hire
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input text-danger" type="radio"
                                                        name="technicalinterviewfeedbackstatus"
                                                        value="Reject"
                                                        onChange={(e) => setTechnicalinterviewFeedbackStatus(e.target.value)}
                                                        id="flexRadioDefault2" />
                                                    <label className="form-check-label text-danger"
                                                        htmlFor="flexRadioDefault2">
                                                        Reject
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12">
                                        <div className="form-group d-flex align-items-center gap-4">
                                            <label>Star Rating</label>
                                            <div>
                                                <div className="rate">
                                                    {[...Array(5)].map((_, index) => {
                                                        const starValue = 5 - index;
                                                        return (
                                                            <label key={index}
                                                                title={`${starValue} stars`}
                                                                style={{ fontSize: "1.5rem" }}
                                                                className={starValue <= FeedbackRating ? 'star filled' : 'star'}
                                                                onClick={() => handleTechnicalInterviewFeedbackStarClick(starValue)}>
                                                                ★
                                                            </label>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12">
                                        <div className="form-group mb-2">
                                            <label>Top 5 Skills</label>
                                            <TagsInput className='form-control' value={TechnicalInterviewFeedbackSkills} name="BehaviralInterviewSkills"
                                                onChange={(skill) => setTechnicalInterviewFeedbackSkills(skill)}
                                                inputProps={{ placeholder: 'Enter Skills' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12">
                                        <div className="form-group mb-2 myteamform">
                                            <label>Recorded Link</label>
                                            <input type="text" name="technicalFeedbackrecordedLink"
                                                value={technicalFeedbackrecordedLink}
                                                onChange={(e) => settechnicalFeedbackrecordedLink(e.target.value)}
                                                placeholder="https://meet.google.com/odp-nvra-ycf"
                                                className="form-control"
                                                required />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12">
                                        <div className="form-group mb-2 myteamform">
                                            <label>Feedback</label>
                                            <textarea placeholder="Message"
                                                name='TechnicalInterviewFeedback' value={TechnicalInterviewFeedback}
                                                onChange={(e) => setTechnicalInterviewFeedback(e.target.value)}
                                                className="form-control"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dd-footer d-flex gap-3 p-3 border-top">
                                <button type="button" className="btn btn-secondary w-100"
                                    data-bs-dismiss="modal" onClick={()=>setTechnicalInterviewFeedbackShow(false)}>Close</button>
                                <button type="button" onClick={handleTechnicalInterviewFeedbackForm}
                                    // disabled={!FeedbackRating || !technicalinterviewfeedbackstatus}
                                    className="btn default-btn w-100">Submit</button>
                            </div>
                        </form>
                    </div>
                </Modal>

                {/* <!-- Technical Interview Edit technicalinterviewfeedback --> */}
                <Modal size="lg" className="modal fade" id="technicalinterviewfeedback" tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true" show={showEditTechnicalInterviewFeedback}
                    onHide={() => setShowEditTechnicalInterviewFeedback(false)}>

                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Edit Feedback</h5>
                            <button type="button" className="btn-close"
                                onClick={() => setShowEditTechnicalInterviewFeedback(false)}
                                data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <form method="post" >
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div className="form-group">
                                            <div className="d-flex gap-4">
                                                <label className="m-0 fw-normal">Recommend </label>
                                                <div className="form-check text-success">
                                                    <input className="form-check-input text-success" type="radio"
                                                        value="Hire"
                                                        name="GetStatusFeedbackById"
                                                        checked={GetStatusFeedbackById === "Hire"}
                                                        onChange={(e) => setGetStatusFeedbackById(e.target.value)}
                                                        id="flexRadioDefault1" />
                                                    <label className="form-check-label text-success"
                                                        htmlFor="flexRadioDefault1">
                                                        Hire
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input text-danger" type="radio"
                                                        name="GetStatusFeedbackById"
                                                        value="Reject"
                                                        checked={GetStatusFeedbackById === "Reject"}
                                                        onChange={(e) => setGetStatusFeedbackById(e.target.value)}
                                                        id="flexRadioDefault2" />
                                                    <label className="form-check-label text-danger"
                                                        htmlFor="flexRadioDefault2">
                                                        Reject
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12">
                                        <div className="form-group d-flex align-items-center gap-4">
                                            <label>Star Ratting</label>
                                            <div >
                                                <div className="rate">
                                                    {[...Array(5)].map((_, index) => {
                                                        const starValue = 5 - index;
                                                        return (
                                                            <label key={index}
                                                                title={`${starValue} stars`}
                                                                style={{ fontSize: "1.5rem" }}
                                                                className={starValue <= GetStarRattingFeedbackById ? 'star filled' : 'star'}
                                                                onClick={() => handleEditTechnicalInterviewFeedbackStarClick(starValue)}>
                                                                ★
                                                            </label>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12">
                                        <div className="form-group mb-2">
                                            <label>Top 5 Skills</label>
                                            <div>
                                                <TagsInput className='form-control' value={GetSkillsFeedbackById} name="TechnicalInterviewFeedbackSkills"
                                                    onChange={(skill) => setGetSkillsFeedbackById(skill)} 
                                                    inputProps={{ placeholder: 'Add skills' }} />
                                            </div>
                                            <div>
                                                <ul id="tagList">
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12">
                                        <div className="form-group mb-2 myteamform">
                                            <label>Recorded Link</label>
                                            <input type="text" name="GetRecordedFeedbackById"
                                                value={GetRecordedFeedbackById}
                                                placeholder="https://meet.google.com/odp-nvra-ycf"
                                                onChange={(e) => setGetRecordedFeedbackById(e.target.value)}
                                                className="form-control" required />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-12">
                                        <div className="form-group mb-2 myteamform">
                                            <lable>Feedback</lable>
                                            <textarea placeholder="Message"
                                                name='GetGiveFeedbackById'
                                                value={GetGiveFeedbackById}
                                                onChange={(e) => setGetGiveFeedbackById(e.target.value)}
                                                className="form-control"></textarea>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="dd-footer d-flex gap-3 p-3 border-top">
                                <button type="button" className="btn btn-secondary w-100"
                                    data-bs-dismiss="modal" onClick={() => setShowEditTechnicalInterviewFeedback(false)}>Close</button>
                                <button type="button" className="btn default-btn w-100"
                                    // disabled={!GetStarRattingFeedbackById || !GetStatusFeedbackById}
                                    onClick={handleEditTechnicalInterviewFeedbackForm}>Submit</button>
                            </div>
                        </form>
                    </div>
                </Modal>

                {/* <---------- Finish Modal Sections ----------> */}
                <Modal show={FinishTechnicalInterviewShow} onHide={() => setFinishTechnicalInterviewShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are You Sure Finish Interview!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are You Sure Finish Interview!</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setFinishTechnicalInterviewShow(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => Handlecompleteinterviewround()}>
                            Finish
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}

export default Recuriter_Technical_Interviewing