import React, { useState, useEffect } from 'react'
import CommonImageTransport from '../../../common/images/CommonImages'
import Recruiter_Header from '../recruiter_header/Recruiter_Header'
import { useParams } from 'react-router-dom'
import { GetRecuriterTemplateList } from '../../../../api/Recuriter'
import Recruiter_Footer from '../recruiter_footer/Recruiter_Footer'


const Interview_guide_details = () => {

    const { Interview_Guide_id } = useParams();
    const [is_loading,setIs_loading] = useState(false)
    console.log("Interview_guide_details_id", Interview_Guide_id)
    const [Activetabs, setActiveTabs] = useState(0)
    const [TemplateList, setTemplateList] = useState([]);
    const [TemplateDetails, setTemplateDetails] = useState(null);

    const recruiter_id = localStorage.getItem("User_id");
    const Interview_Guilde_Title = localStorage.getItem("Interview_Guilde_Title");


    // <------- User Authentication token's --------------->
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };


    const GetTemplateList = async () => {
        const GetRecuriterTemplateListdata = {
            recruiter_id
        }
        try{
            const response = await GetRecuriterTemplateList(GetRecuriterTemplateListdata, headers);
            setTemplateList(response?.data?.data)

        }catch(error){
        console.log("error",error)
        }
    }
    console.log("GetTemplateList", TemplateList?.id)

    // <--------- Fetch the Teamplate List  ----------->
    useEffect(() => {
        GetTemplateList();
    }, [])

    // <---------- fetch the Data by Teamplate Id ----------->
    useEffect(() => {
        if (TemplateList.length > 0) {
            const FindTemplateDetails = TemplateList.find(template => template.id === parseInt(Interview_Guide_id));
            console.log("FindTemplateDetails", FindTemplateDetails);
            setTemplateDetails(FindTemplateDetails);
        }
    }, [TemplateList, Interview_Guide_id]);
    console.log("TemplateDetails",TemplateDetails?.guide_pdf)

    const HandleActiveTabs = (tabs) => {
        setActiveTabs(tabs)
    }
    return (
        <div>
            <div className="bg_nav-slid bg-white">
                <Recruiter_Header />
            </div>
            <div className="header_space"></div>
            <div className="blog-area pt-1 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center product-topper">
                                <h2>{Interview_Guilde_Title}</h2>
                            </div>

                            <ul className="nav tabs_signup justify-content-center gap-2 pb-4" id="pills-tab" role="tablist">
                                {/* <li className="nav-item flex" role="presentation">
                            <button 
                            className={`nav-link px-5 ${Activetabs=== 0 ? "active":""}`}
                            onClick={()=>HandleActiveTabs(0)}
                             id="pills-home-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                aria-selected="true">Questions and Answers</button>
                        </li> */}

                                {/* <li className="nav-item flex" role="presentation">
                            <button  className={`nav-link px-5 ${Activetabs=== 1 ? "active":""}`}
                            onClick={()=>HandleActiveTabs(1)} id="pills-contact-tab" data-bs-toggle="pill"
                                data-bs-target="#videos" type="button" role="tab" aria-controls="videos"
                                aria-selected="false">Video</button>
                        </li> */}
                                {/* <li className="nav-item flex" role="presentation">
                                    <button className={`nav-link px-5 ${Activetabs === 0 ? "active" : ""}`}
                                        onClick={() => HandleActiveTabs(0)} id="pills-contact-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"
                                        aria-selected="false">PDF</button>
                                </li> */}
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                {/* <div 
                        className={`tab-pane fade ${Activetabs === 0 ? "show active":""}`}
                         id="pills-home" role="tabpanel"
                            aria-labelledby="pills-home-tab">
                            <img src={CommonImageTransport?.video1} alt="" />
                            <article className="mt-4">
                               
                                <h3>1. Are the HTML tags and elements the same thing?</h3>
                                <p>No, HTML tags are used to define the structure of a web page, while HTML elements are
                                    made up
                                    of a set of tags that define a specific part of a web page.</p>
                                <h3>2. What are void elements in HTML?</h3>
                                <p>Void elements in HTML are tags that do not require a closing tag. They are used to
                                    insert
                                    images, line breaks, and other content that does not require additional information.
                                </p>
                                <h3>3. What is the advantage of collapsing white space?</h3>
                                <p>Collapsing white space in HTML can help to reduce the size of web pages and make them
                                    load
                                    faster. It involves removing unnecessary white space between HTML elements.</p>
                                <h3>4. What are HTML Entities?</h3>
                                <p>HTML Entities are special characters used to represent characters that cannot be
                                    typed on a
                                    keyboard. They are often used to display special symbols and foreign characters.</p>
                                <h3>5. How do you display a table in an HTML webpage?</h3>
                                <p>The HTML &lt;table&gt; tag is used to display data in a tabular format. It is also
                                    used to
                                    manage the layout of the page, for example, header section, navigation bar, body
                                    content,
                                    footer section. Given below are the list of HTML tags used for displaying a table in
                                    an HTML
                                    webpage:</p>
                            </article>

                        </div>

                        <div  className={`tab-pane fade ${Activetabs === 1 ? "show active":""}`} id="videos" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <div className="blog-card p-3">
                                <video id="my-video" className="video-js" controls preload="auto"
                                    poster={CommonImageTransport?.video1} data-setup='' loop>
                                    <source
                                        src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-1080p.mp4"
                                        type='video/mp4'/>
                                </video>
                                <div className="content pt-3 pb-0">
                                    <h3>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor
                                        incididunt.
                                    </h3>
                                    <ul className="course-list">
                                        <li><i className="ri-time-fill"></i> 10 hr 07 min</li>
                                        <li><i className="ri-calendar-fill"></i> 18/04/2024</li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                                <div className={`tab-pane fade ${Activetabs === 0 ? "show active" : ""}`} id="pills-contact" role="tabpanel"
                                    aria-labelledby="pills-contact-tab">  <embed
                                    src={TemplateDetails?.guide_pdf}
                                    type="application/pdf"
                                    width="100%"
                                    height="2100px"
                                />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Recruiter_Footer/>
        </div>
    )
}

export default Interview_guide_details