import React from 'react'
import { Route, Routes } from 'react-router-dom';

// <------------ Auth sections here ------------------>
import Signin from '../auth/signin/Signin';
import Signup from '../auth/signup/Signup';
import Otp from '../auth/otp/Otp';
import ForgotPassword from '../auth/forgot_password/Forgot_Password';
import NewPassword from '../auth/new_password/New_Password';
import SendOtpForgotPass from '../auth/send_otp_forgot_pass/Send_Otp_Forgot_Pass';
import ForgotOtp from '../auth/forgot_otp/Forgot_Otp';
import StudentSignUpOtpVerify from '../auth/student/Student_Signup_Otp_Verify';
import RecruiterSignUpOtpVerify from '../auth/recruiter/Recruiter_Signup_Otp_Verify';
import MentorSignUpOtpVerify from '../auth/mentor/MentorSignUpOtpVerify';

// <---------- Public Pages ---------------->
import Home from '../components/pages/public_pages/home/Home';
import Contact from '../components/pages/public_pages/contact/Contact';
import RecruiterHiring from '../components/pages/public_pages/public_recruiter/Public_Recruiter_Hiring';
import TrainingPrograms from '../components/pages/public_pages/training-programs/Training_Programs';
import PrivacyPolicy from '../components/pages/public_pages/privacy_Policy/Privacy_Policy';
import Jobs from '../components/pages/public_pages/public_jobs/Public_Jobs';
import ProtectedRoutes from '../components/common/protected/Protected_Routes';
import Page_404 from '../components/pages/public_pages/page_404/Page_404';
import TrainerMentor from '../components/pages/public_pages/trainer-mentor/Trainer_Mentor';
import TermsCondition from '../components/pages/public_pages/terms_condition/Terms_Condition';
import CoursesDetails from '../components/pages/public_pages/courses_details/Courses_Details';

// <----------------- Recruiter Hiring Pages -------------------------->
import AllProfile from '../components/pages/recruiter_pages/recruiter_all_profile/Recruiter_All_Profile';
import RecruiterJobs from '../components/pages/recruiter_pages/recruiter_jobs/Recruiter_Jobs';
import NewProfile from '../components/pages/recruiter_pages/recruiter_new_profile/Recuriter_New_Profile';
import ProfileRequest from '../components/pages/recruiter_pages/recruiter_profile_request/Recruiter_Profile_Request';
import ProfileResume from '../components/pages/recruiter_pages/recruiter_profile_resume/Recruiter_Profile_Resume';
import RecruiterInterviewing from "../components/pages/recruiter_pages/recuriter_interviewing/Recuriter_Interviewing"
import RecruiterList from '../components/pages/recruiter_pages/recruiter_list/Recruiter_List';
import RecruiterStep from '../components/pages/recruiter_pages/recruiter_step/Recruiter_Step';
import RecruiterDashboard from '../components/pages/recruiter_pages/recruiter_dashboard/Recruiter_Dashboard';
import Recruiter_JobPosts from '../components/pages/recruiter_pages/recruiter_jobPosts/Recruiter_JobPosts';
import RecuriterMyTeam from '../components/pages/recruiter_pages/recruiter_my_team/Recuriter_My_Team';
import Interview_Guide from '../components/pages/recruiter_pages/recruiter_interview_guide/Recruiter_Interview_Guide';
import Interview_guide_details from '../components/pages/recruiter_pages/recruiter_interview_guide_details/Recruiter_Interview_Guide_Details';
import Recruiter_Profile from '../components/pages/recruiter_pages/recruiter_profile/Recruiter_Profile';
import Recuriter_Reset_Password from '../components/pages/recruiter_pages/recuriter_reset_password/Recuriter_Reset_Password';
import Recruiter_Protected from '../components/pages/recruiter_pages/recruiter_protected/Recruiter_Protected';
import Recuriter_Edit_Profile from '../components/pages/recruiter_pages/recuriter_edit_profile/Recuriter_Edit_Profile';
import Recuriter_Rejected from '../components/pages/recruiter_pages/recuriter_rejected/Recuriter_Rejected';
import Recuriter_Support from '../components/pages/recruiter_pages/recuriter_support/Recuriter_Support';
import Recuriter_Applied_Profile from '../components/pages/recruiter_pages/recuriter_applied_profile/Recuriter_Applied_Profile';

// <-------------- Student Sections ------------------>
import MyCourses from '../components/pages/student_pages/student_my_course/Student_My_Courses';
import MyCoursesDetails from '../components/pages/student_pages/student_my_courses_details/Student_My_Courses_Details';
import CourseVideoInfo from '../components/pages/student_pages/student_course_video_info/Student_Course_Video_Info';
import Student_step from '../components/pages/student_pages/student_step/Student_Step';
import Student_Jobs from '../components/pages/student_pages/student_jobs/Student_Jobs';
import Student_Resume from '../components/pages/student_pages/student_resume/Student_Resume';
import Student_DashBoard from "../components/pages/student_pages/student_dashboard/Student_DashBoard"
import Student_Traning_Program from '../components/pages/student_pages/student_traning_program/Student_Traning_Program';
import Student_Coaching_Center from '../components/pages/student_pages/student_coaching_center/Student_Coaching_Center';
import Student_Protected from '../components/pages/student_pages/student_protected/Student_Protected';
import Student_Mentor_List from '../components/pages/student_pages/student_mentor_list/Student_Mentor_List';
import Student_Upcoming_Mentor_list from '../components/pages/student_pages/student_upcoming_mentor_list/Student_Upcoming_Mentor_list';
import Student_Mentor_Complate_Session from '../components/pages/student_pages/student_mentor_complete_session/Student_Mentor_Complete_Session';
import Student_Applied_Job from '../components/pages/student_pages/student_applied_job/Student_Applied_Job';
import Student_Coaching_Session_Complete from '../components/pages/student_pages/student_coaching_session_complete/Student_Coaching_Session_Complete';
import Student_Edit_Profile from '../components/pages/student_pages/student_edit_profile/Student_Edit_Profile';
import Student_Support from '../components/pages/student_pages/student_support/Student_Support';
import Student_Change_Password from '../components/pages/student_pages/student_change_password/Student_Change_Password';
import Student_Profile from '../components/pages/student_pages/student_profile/Student_Profile';

// <----------- Mentors sections --------------------->
import MentorDashboard from '../components/pages/mentor_pages/mentor_dashboaed/Mentor_Dashboard';
import MentorStep from '../components/pages/mentor_pages/mentor_step/Mentor_Step';
import CoachingMentor from '../components/pages/mentor_pages/mentor_complete_session/Mentor_Complete_Session';
import Mentor_UpComing_Session from '../components/pages/mentor_pages/mentor_upcoming_session/Mentor_UpComing_Session'
import Mentor_Protected from '../components/pages/mentor_pages/mentor_protected/Mentor_Protected'
import Mentor_Booking from '../components/pages/mentor_pages/mentor_booking/Mentor_Booking';
import Mentor_Resume_Profile from '../components/pages/mentor_pages/mentor_resume_profile/Mentor_Resume_Profile';
import Mentor_Student_Profile from '../components/pages/mentor_pages/mentor_student_profile/Mentor_Student_Profile';
import Mentor_Profile from '../components/pages/mentor_pages/mentor_profile/Mentor_Profile';
import Mentor_Change_Password from '../components/pages/mentor_pages/mentor_change_password/Mentor_Change_Password';
import Mentor_Support from '../components/pages/mentor_pages/mentor_support/Mentor_Support';
import Mentor_Edit_Profile from '../components/pages/mentor_pages/mentor_edit_profile/Mentor_Edit_Profile';
import Student_SignUp from '../auth/student/Student_SignUp';
import Recruiter_SignUp from '../auth/recruiter/Recruiter_SignUp';
import Mentor_SignUp from '../auth/mentor/Mentor_SignUp';
import Profile from '../components/pages/public_pages/profile/Profile';
import Change_Password from '../auth/change_password/Change_Password';
import Edit_Profile from '../components/pages/public_pages/edit_profile/Edit_Profile';
// import TemHome from '../components/pages/public_pages/home/TemHome';
import Course_List from '../components/pages/public_pages/course_list/Course_List';


const AllRoutes = () => {
  const role = localStorage.getItem("role_id")

  return (
    <div>
      <Routes>




        {/* <----------- Public Pages -------------------> */}
        {
          role == "2" && (
            <>
             {/* <Route path='/' element={< Student_Protected Component={TemHome}/>} /> */}
              <Route path='/' element={<Student_Protected Component={Home} />} />
              <Route path='/Contact' element={<Student_Protected Component={Contact} />} />
              <Route path='/Mentorship' element={<Student_Protected Component={TrainerMentor} />} />
              <Route path='/Recruiter' element={<Student_Protected Component={RecruiterHiring} />} />
              <Route path='/TrainingPrograms' element={<Student_Protected Component={TrainingPrograms} />} />
              <Route path='/privacy-policy' element={<Student_Protected Component={PrivacyPolicy} />} />
              <Route path='/terms-condition' element={<Student_Protected Component={TermsCondition} />} />
              <Route path='/Jobs' element={<Student_Protected Component={Jobs} />} />
              <Route path='/CoursesDetails/:Course_id' element={<Student_Protected Component={CoursesDetails} />} />
              <Route path='/Profile' element={<Student_Protected Component={Profile} />} />
              <Route path='/Edit_Profile' element={<Student_Protected Component={Edit_Profile} />} />
              <Route path='/Course_List' element={<Student_Protected Component={Course_List} />} />

              {/* <-------- Auth sections ---------------> */}
              <Route path='/Signin' element={<Student_Protected Component={Signin} />} />
              <Route path='/Signup' element={<Student_Protected Component={Signup} />} />
              <Route path='/Otp' element={<Student_Protected Component={Otp} />} />
              <Route path='/ForgotPassword' element={<Student_Protected Component={ForgotPassword} />} />
              <Route path='/SendOtpForgotPass' element={<Student_Protected Component={SendOtpForgotPass} />} />
              <Route path='/ForgotOtp' element={<Student_Protected Component={ForgotOtp} />} />
              <Route path='/NewPassword' element={<Student_Protected Component={NewPassword} />} />
              <Route path='/StudentSignUpOtpVerify' element={<Student_Protected Component={StudentSignUpOtpVerify} />} />
              <Route path='/RecruiterSignUpOtpVerify' element={<Student_Protected Component={RecruiterSignUpOtpVerify} />} />
              <Route path='/MentorSignUpOtpVerify' element={<Student_Protected Component={MentorSignUpOtpVerify} />} />
              <Route path='/Student_SignUp' element={<Student_Protected Component={Student_SignUp} />} />
              <Route path='/Recruiter_SignUp' element={<Student_Protected Component={Recruiter_SignUp} />} />
              <Route path='/Mentor_SignUp' element={<Student_Protected Component={Mentor_SignUp} />} />
              <Route path='/Change_Password' element={<Student_Protected Component={Mentor_SignUp} />} />
            </>
          )
        }

        {
          role == "3" && (
            <>
             {/* <Route path='/' element={< Mentor_Protected Component={TemHome}/>} /> */}
               <Route path='/' element={<Mentor_Protected Component={Home} />} />
            <Route path='/Contact' element={<Mentor_Protected Component={Contact} />} />
            <Route path='/Mentorship' element={<Mentor_Protected Component={TrainerMentor} />} />
            <Route path='/Recruiter' element={<Mentor_Protected Component={RecruiterHiring} />} />
            <Route path='/TrainingPrograms' element={<Mentor_Protected Component={TrainingPrograms} />} />
            <Route path='/privacy-policy' element={<Mentor_Protected Component={PrivacyPolicy} />} />
            <Route path='/terms-condition' element={<Mentor_Protected Component={TermsCondition} />} />
            <Route path='/Jobs' element={<Mentor_Protected Component={Jobs} />} />
            <Route path='/CoursesDetails/:Course_id' element={<Mentor_Protected Component={CoursesDetails} />} />
            <Route path='/Profile' element={<Mentor_Protected Component={Profile} />} />
            <Route path='/Edit_Profile' element={<Mentor_Protected Component={Edit_Profile} />} />
            <Route path='/Course_List' element={<Mentor_Protected Component={Course_List} />} />

            {/* <-------- Auth sections ---------------> */}
            <Route path='/Signin' element={<Mentor_Protected Component={Signin} />} />
            <Route path='/Signup' element={<Mentor_Protected Component={Signup} />} />
            <Route path='/Otp' element={<Mentor_Protected Component={Otp} />} />
            <Route path='/ForgotPassword' element={<Mentor_Protected Component={ForgotPassword} />} />
            <Route path='/SendOtpForgotPass' element={<Mentor_Protected Component={SendOtpForgotPass} />} />
            <Route path='/ForgotOtp' element={<Mentor_Protected Component={ForgotOtp} />} />
            <Route path='/NewPassword' element={<Mentor_Protected Component={NewPassword} />} />
            <Route path='/StudentSignUpOtpVerify' element={<Mentor_Protected Component={StudentSignUpOtpVerify} />} />
            <Route path='/RecruiterSignUpOtpVerify' element={<Mentor_Protected Component={RecruiterSignUpOtpVerify} />} />
            <Route path='/MentorSignUpOtpVerify' element={<Mentor_Protected Component={MentorSignUpOtpVerify} />} />
            <Route path='/Student_SignUp' element={<Mentor_Protected Component={Student_SignUp} />} />
            <Route path='/Recruiter_SignUp' element={<Mentor_Protected Component={Recruiter_SignUp} />} />
            <Route path='/Mentor_SignUp' element={<Mentor_Protected Component={Mentor_SignUp} />} />
            <Route path='/Change_Password' element={<Mentor_Protected Component={Mentor_SignUp} />} />
            </>
         
          )
        }

        {
          role == "4" && (
           <>
            {/* <Route path='/' element={< Recruiter_Protected Component={TemHome}/>} /> */}
            <Route path='/' element={<Recruiter_Protected Component={Home} />} />
            <Route path='/Contact' element={<Recruiter_Protected Component={Contact} />} />
            <Route path='/Mentorship' element={<Recruiter_Protected Component={TrainerMentor} />} />
            <Route path='/Recruiter' element={<Recruiter_Protected Component={RecruiterHiring} />} />
            <Route path='/TrainingPrograms' element={<Recruiter_Protected Component={TrainingPrograms} />} />
            <Route path='/privacy-policy' element={<Recruiter_Protected Component={PrivacyPolicy} />} />
            <Route path='/terms-condition' element={<Recruiter_Protected Component={TermsCondition} />} />
            <Route path='/Jobs' element={<Recruiter_Protected Component={Jobs} />} />
            <Route path='/CoursesDetails/:Course_id' element={<Recruiter_Protected Component={CoursesDetails} />} />
            <Route path='/Profile' element={<Recruiter_Protected Component={Profile} />} />
            <Route path='/Edit_Profile' element={<Recruiter_Protected Component={Edit_Profile} />} />
            <Route path='/Course_List' element={<Recruiter_Protected Component={Course_List} />} />

            {/* <-------- Auth sections ---------------> */}
            <Route path='/Signin' element={<Recruiter_Protected Component={Signin} />} />
            <Route path='/Signup' element={<Recruiter_Protected Component={Signup} />} />
            <Route path='/Otp' element={<Recruiter_Protected Component={Otp} />} />
            <Route path='/ForgotPassword' element={<Recruiter_Protected Component={ForgotPassword} />} />
            <Route path='/SendOtpForgotPass' element={<Recruiter_Protected Component={SendOtpForgotPass} />} />
            <Route path='/ForgotOtp' element={<Recruiter_Protected Component={ForgotOtp} />} />
            <Route path='/NewPassword' element={<Recruiter_Protected Component={NewPassword} />} />
            <Route path='/StudentSignUpOtpVerify' element={<Recruiter_Protected Component={StudentSignUpOtpVerify} />} />
            <Route path='/RecruiterSignUpOtpVerify' element={<Recruiter_Protected Component={RecruiterSignUpOtpVerify} />} />
            <Route path='/MentorSignUpOtpVerify' element={<Recruiter_Protected Component={MentorSignUpOtpVerify} />} />
            <Route path='/Student_SignUp' element={<Recruiter_Protected Component={Student_SignUp} />} />
            <Route path='/Recruiter_SignUp' element={<Mentor_Protected Component={Recruiter_SignUp} />} />
            <Route path='/Mentor_SignUp' element={<Recruiter_Protected Component={Mentor_SignUp} />} />
            <Route path='/Change_Password' element={<Recruiter_Protected Component={Mentor_SignUp} />} />
           </>
          )
        }
        {/* <Route path='/' element={<TemHome />} /> */}
        <Route path='/' element={<Home />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/Mentorship' element={<TrainerMentor />} />
        <Route path='/Recruiter' element={<RecruiterHiring />} />
        <Route path='/TrainingPrograms' element={<TrainingPrograms />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-condition' element={<TermsCondition />} />
        <Route path='/Jobs' element={<Jobs />} />
        <Route path='/CoursesDetails/:Course_id' element={<CoursesDetails />} />
        <Route path='/Profile' element={<Profile />} />
        <Route path='/Edit_Profile' element={<Edit_Profile />} />
        <Route path='/Course_List' element={<Course_List />} />

        <Route path='/Signin' element={<Signin/>} />
            <Route path='/Signup' element={<Signup/>} />
            <Route path='/Otp' element={<Otp/>} />
            <Route path='/ForgotPassword' element={<ForgotPassword/>} />
            <Route path='/SendOtpForgotPass' element={<SendOtpForgotPass/>} />
            <Route path='/ForgotOtp' element={<ForgotOtp/>} />
            <Route path='/NewPassword' element={<NewPassword/>} />
            <Route path='/StudentSignUpOtpVerify' element={<StudentSignUpOtpVerify/>} />
            <Route path='/RecruiterSignUpOtpVerify' element={<RecruiterSignUpOtpVerify />} />
            <Route path='/MentorSignUpOtpVerify' element={<MentorSignUpOtpVerify />} />
            <Route path='/Student_SignUp' element={<Student_SignUp/>} />
            <Route path='/Recruiter_SignUp' element={<Recruiter_SignUp/>} />
            <Route path='/Mentor_SignUp' element={<Mentor_SignUp/>} />
            <Route path='/Change_Password' element={<Mentor_SignUp/>} />

        {/* <----------- Mentors sections here -----------> */}
        <Route path='/mentor_pages/CoachingMentor' element={<Mentor_Protected Component={CoachingMentor} />} />
        <Route path='/mentor_pages/MentorDashboard' element={<Mentor_Protected Component={MentorDashboard} />} />
        <Route path='/mentor_pages/Mentor-Step' element={<Mentor_Protected Component={MentorStep} />} />
        <Route path='/mentor_pages/Mentor_UpComing_Session' element={<Mentor_Protected Component={Mentor_UpComing_Session} />} />
        <Route path='/mentor_pages/Mentor_Student_Profile' element={<Mentor_Protected Component={Mentor_Student_Profile} />} />
        <Route path='/mentor_pages/Mentor_Booking' element={<Mentor_Protected Component={Mentor_Booking} />} />
        <Route path='/mentor_pages/Mentor_Resume_Profile' element={<Mentor_Protected Component={Mentor_Resume_Profile} />} />
        <Route path='/mentor_pages/Mentor_Profile' element={<Mentor_Protected Component={Mentor_Profile} />} />
        <Route path='/mentor_pages/Mentor_Edit_Profile' element={<Mentor_Protected Component={Mentor_Edit_Profile} />} />
        <Route path='/mentor_pages/Mentor_Change_Password' element={<Mentor_Protected Component={Mentor_Change_Password} />} />
        <Route path='/mentor_pages/Mentor_Support' element={<Mentor_Protected Component={Mentor_Support} />} />

        {/* <----------- This is Recuriter Sections ------------------> */}
        <Route path='/recruiter_pages/AllProfile' element={<Recruiter_Protected Component={AllProfile} />} />
        <Route path='/recruiter_pages/RecruiterJobs' element={<Recruiter_Protected Component={RecruiterJobs} />} />
        <Route path='/recruiter_pages/Recruiter_JobPosts' element={<Recruiter_Protected Component={Recruiter_JobPosts} />} />
        <Route path='/recruiter_pages/NewProfile' element={<Recruiter_Protected Component={NewProfile} />} />
        <Route path='/recruiter_pages/Recuriter_Applied_Profile' element={<Recruiter_Protected Component={Recuriter_Applied_Profile} />} />
        <Route path='/recruiter_pages/ProfileRequest' element={<Recruiter_Protected Component={ProfileRequest} />} />
        <Route path='/recruiter_pages/ProfileResume' element={<Recruiter_Protected Component={ProfileResume} />} />
        <Route path='/recruiter_pages/RecruiterInterviewing' element={<Recruiter_Protected Component={RecruiterInterviewing} />} />
        <Route path='/recruiter_pages/RecruiterDashboard' element={<Recruiter_Protected Component={RecruiterDashboard} />} />
        <Route path='/recruiter_pages/RecuriterMyTeam' element={<Recruiter_Protected Component={RecuriterMyTeam} />} />
        <Route path='/recruiter_pages/RecruiterList' element={<Recruiter_Protected Component={RecruiterList} />} />
        <Route path='/recruiter_pages/Recuriter_Rejected' element={<Recruiter_Protected Component={Recuriter_Rejected} />} />
        <Route path='/recruiter_pages/RecruiterStep' element={<Recruiter_Protected Component={RecruiterStep} />} />
        <Route path='/recruiter_pages/Interview_Guide' element={<Recruiter_Protected Component={Interview_Guide} />} />
        <Route path='/recruiter_pages/:Interview_Guide_id' element={<Recruiter_Protected Component={Interview_guide_details} />} />
        <Route path='/recruiter_pages/recruiter-Profile' element={<Recruiter_Protected Component={Recruiter_Profile} />} />
        <Route path='/recruiter_pages/Recuriter_Edit_Profile' element={<Recruiter_Protected Component={Recuriter_Edit_Profile} />} />
        <Route path='/recruiter_pages/Recuriter_Reset_Password' element={<Recruiter_Protected Component={Recuriter_Reset_Password} />} />
        <Route path='/recruiter_pages/Recuriter_Support' element={<Recruiter_Protected Component={Recuriter_Support} />} />

        {/* <------------ Students sections here ------------> */}
        <Route path='/Student_step' element={<Student_Protected Component={Student_step} />} />
        <Route path='/Student/Student_jobs' element={<Student_Protected Component={Student_Jobs} />} />
        <Route path='/Student/Student_Resume' element={<Student_Protected Component={Student_Resume} />} />
        <Route path='/Student/Student_dashBoard' element={<Student_Protected Component={Student_DashBoard} />} />
        <Route path='/Student/Student_Traning_Program' element={<Student_Protected Component={Student_Traning_Program} />} />
        <Route path='/Student/Student_Coaching_Center' element={<Student_Protected Component={Student_Coaching_Center} />} />
        <Route path='/Student/Student_Mentor_List' element={<Student_Protected Component={Student_Mentor_List} />} />
        <Route path='/Student/Student_Upcoming_Mentor_list' element={<Student_Protected Component={Student_Upcoming_Mentor_list} />} />
        <Route path='/Student/Student_Mentor_Complate_session' element={<Student_Protected Component={Student_Mentor_Complate_Session} />} />
        <Route path='/Student/Student_Coaching_Session_Complete/:course_id' element={<Student_Protected Component={Student_Coaching_Session_Complete} />} />
        <Route path='/Student/Student_Profile' element={<Student_Protected Component={Student_Profile} />} />
        <Route path='/Student/Student_Edit_Profile' element={<Student_Protected Component={Student_Edit_Profile} />} />
        <Route path='/Student/Student_Change_Password' element={<Student_Protected Component={Student_Change_Password} />} />
        <Route path='/Student/Student_Applied_Job' element={<Student_Protected Component={Student_Applied_Job} />} />
        <Route path='/Student/Student_Support' element={<Student_Protected Component={Student_Support} />} />
        <Route path='/Student/MyCourses' element={<Student_Protected Component={MyCourses} />} />
        <Route path='/MyCoursesDetails' element={<Student_Protected Component={MyCoursesDetails} />} />
        <Route path='/CourseVideoInfo' element={<Student_Protected Component={CourseVideoInfo} />} />
        <Route path='*' element={<Page_404 />} />
      </Routes>
    </div>
  )
}

export default AllRoutes
