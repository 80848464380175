// import React from 'react';

// const RazorpayPaymentButton = ({ Price, onPaymentSuccess, buttonContent }) => {
//     // Load the Razorpay checkout script
//     const loadRazorpayScript = () => {
//         return new Promise((resolve) => {
//             const script = document.createElement('script');
//             script.src = 'https://checkout.razorpay.com/v1/checkout.js';
//             script.onload = () => {
//                 resolve(true);
//             };
//             script.onerror = () => {
//                 resolve(false);
//             };
//             document.body.appendChild(script);
//         });
//     };

//     // Handle payment when the button is clicked
//     const handlePayment = async () => {
//         console.log("Price_Payment", Price);
//         const res = await loadRazorpayScript(); // Load the Razorpay script
//         if (!res) {
//             alert('Razorpay SDK failed to load. Are you online?');
//             return;
//         }

//         const options = {
//             key: 'rzp_test_pPEb1wAu9J7WUF', // Replace with your test API key
//             // rzp_test_ontnDNBQoN17AN   (old key)
//             amount: Price, // Amount in paise (e.g., 5000 for ₹50)
//             currency: 'INR',
//             description: 'Test Transaction',
//             image: 'https://your-logo-url.com/logo.png', // Your company logo
//             handler: function (response) {
//                 // Payment successful callback
//                 alert(`Payment successful. Payment ID: ${response.razorpay_payment_id}`);
//                 console.log(`Payment successful. Payment ID: ${response.razorpay_payment_id}`);
//                 console.log(`Order ID: ${response.razorpay_order_id}`);
//                 console.log(`Signature: ${response.razorpay_signature}`);

//                 // Call the success handler passed as prop
//                 onPaymentSuccess({
//                     paymentId: response.razorpay_payment_id,
//                     payment_status: 'success',
//                     amount: Price / 100, // Convert paise to actual amount
//                 });
//             },
//             prefill: {
//                 name: 'Freshgrad',
//                 email: 'freshgrad@gmail.com',
//                 contact: '7023088303',
//             },
//             notes: {
//                 address: 'Razorpay Corporate Office',
//             },
//             theme: {
//                 color: '#F37254',
//             },  
//         };

//         // Create and open the payment modal
//         const paymentObject = new window.Razorpay(options);
//         paymentObject.open();
//     };

//     return (
//         <button onClick={handlePayment} className='Make_Payment btn default-btn col-md-12'>
//             {buttonContent ? buttonContent : "Make Payment"}
//         </button>
//     );
// };

// export default RazorpayPaymentButton;







import React from 'react';

const PayPalPaymentButton = ({ Price, onPaymentSuccess, buttonContent }) => {
    const loadPayPalScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://www.paypal.com/sdk/js?client-id=AZyxScUm9JUna-LqmOJkceEkS3h35x_esjPaMZodgNMfdIX5IVA8mUt7vKlTEyaaNaZO5XowE4T-QB8C&currency=USD'; // Replace with your client ID
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const handlePayment = async () => {
        console.log("Price_Payment", Price);
        const res = await loadPayPalScript();
        if (!res) {
            alert('PayPal SDK failed to load. Are you online?');
            return;
        }

        // Render PayPal Buttons using the loaded PayPal SDK
        const container = document.getElementById("paypal-button-container");
        if (container) {
            container.innerHTML = ""; // Clear any existing buttons

            window.paypal.Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: (Price / 100).toFixed(2), // Convert to dollars if Price is in cents
                                },
                            },
                        ],
                    });
                },
                onApprove: (data, actions) => {
                    return actions.order.capture().then((details) => {
                        alert(`Transaction completed by ${details.payer.name.given_name}`);
                        console.log("Transaction details:", details);

                        onPaymentSuccess({
                            paymentId: details.id,
                            payment_status: 'success',
                            amount: Price / 100,
                        });
                    });
                },
                onError: (err) => {
                    console.error("PayPal Checkout onError", err);
                    alert("An error occurred during the transaction. Please try again.");
                }
            }).render(container);
        }
    };

    return (
        <div>
            <button onClick={handlePayment} className='Make_Payment btn default-btn col-md-12'>
                {buttonContent ? buttonContent : "Make Payment"}
            </button>
            <div id="paypal-button-container"></div>
        </div>
    );
};

export default PayPalPaymentButton;

