import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import CommonImageTransport from '../../../../common/images/CommonImages'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
import { GetMentorList } from '../../../../../api/Mentor';

const TopMentors = () => {
      const navigate = useNavigate();
      const [MentorList, setMentorList] = useState([])

      const HandleGetMentorList = async () => {
            const response = await GetMentorList();
            setMentorList(response?.data?.data?.mentor_list
            )
      }

      const AddAllMentors = async (e) => {
            e.preventDefault();
            const role_id = localStorage.getItem("role_id");
            if (role_id === "2") {
                  navigate("/Student/Student_Mentor_List");
            } else if (role_id === "3" || role_id === "4") {
                  toast.error("You Can't access !!!");
                  setTimeout(() => {
                        navigate("/");
                  }, 2000);
            }
            else {
                  toast.error("User Not Sign in here !!!");
                  setTimeout(() => {
                        navigate("/Signin");
                  }, 2000);
            }
      }

      useEffect(() => {
            HandleGetMentorList();
      }, [])
      return (
            <div className="instructors-area py-5">
                  <div className="container">
                        <div className="row align-items-center mb-45">
                              <ToastContainer style={{ marginTop: "100px" }} />
                              <div className="col-lg-8 col-md-9">
                                    <div className="section-title mt-rs-20">
                                          <h2>Meet Our Top Mentors</h2>
                                          <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                incididunt ut
                                                labore et dolore.
                                          </p>
                                    </div>
                              </div>
                              <div className="col-lg-4 col-md-3 text-end">
                                    <Link to="#" onClick={AddAllMentors} className="default-btn">View All Mentors</Link>
                              </div>
                        </div>
                        <div className="row justify-content-center">
                              {
                                    MentorList?.slice(0, 4)?.map((MentorListResult) => {
                                          if(MentorListResult?.status != "1"){
                                                return;
                                          }
                                          return (
                                                <>
                                                      <div className="col-lg-3 col-md-6">
                                                            <div className="instructors-item">
                                                                  <div className='instructors-img'>
                                                                        <Link to="#" onClick={AddAllMentors}>
                                                                              {
                                                                                    MentorListResult?.profileimg != null ? (
                                                                                          <>
                                                                                                <img src={MentorListResult?.profileimg}
                                                                                                      alt="Team Images" style={{ height: "40vh", width: "250px" }} />
                                                                                          </>
                                                                                    ) : (
                                                                                          <>
                                                                                                <img src={CommonImageTransport?.userLogo} style={{ height: "40vh", width: "250px" }} alt="Team Images" />
                                                                                          </>
                                                                                    )
                                                                              }
                                                                        </Link>
                                                                  </div>
                                                                  <div className='content'>
                                                                        <h5>{MentorListResult?.fname}</h5>
                                                                        <p>{MentorListResult?.job_title}</p>
                                                                  </div>

                                                            </div>
                                                      </div>
                                                </>
                                          )
                                    })
                              }
                        </div>
                  </div>
            </div>
      )
}

export default TopMentors