import React, { useState } from 'react'
import Recruiter_Header from '../recruiter_header/Recruiter_Header';

const ProfileResume = () => {
    const [ActiveTab, setActiveTab] = useState(0);

    const HandleActiveTab = (tabs) => {
        setActiveTab(tabs)
    }
    return (
        <div>
            <div className="bg_nav-slid bg-white">
                <Recruiter_Header />
            </div>
            <div className="enrolled-area-two py-5 bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 my-auto">
                            <div className="Dashboard1">
                                <h3>Profile Resume</h3>
                            </div>
                        </div>
                        <div className="col-12 mb-3 mt-3">
                            <div className="col-md-12">
                                <ul className="nav nav-tabs applide_tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${ActiveTab === 0 ? "active" : ""}`}
                                            onClick={() => HandleActiveTab(0)}
                                            id="home-tab" data-bs-toggle="tab"
                                            data-bs-target="#home-tab-pane" type="button" role="tab"
                                            aria-controls="home-tab-pane" aria-selected="true">All</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${ActiveTab === 1 ? "active" : ""}`}
                                            onClick={() => HandleActiveTab(1)} id="contact-tab" data-bs-toggle="tab"
                                            data-bs-target="#contact-tab-pane2" type="button" role="tab"
                                            aria-controls="contact-tab-pane2" aria-selected="false">New
                                            Request</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${ActiveTab === 2 ? "active" : ""}`}
                                            onClick={() => HandleActiveTab(2)} id="contact-tab" data-bs-toggle="tab"
                                            data-bs-target="#contact-tab-pane" type="button" role="tab"
                                            aria-controls="contact-tab-pane" aria-selected="false">Interviewing</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${ActiveTab === 3 ? "active" : ""}`}
                                            onClick={() => HandleActiveTab(3)} id="disabled-tab" data-bs-toggle="tab"
                                            data-bs-target="#disabled-tab-pane" type="button" role="tab"
                                            aria-controls="disabled-tab-pane" aria-selected="false">Hired</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="card widget-flat">
                                <div className="card-body">
                                    <div className="left-slid-filter list-infode-company">
                                        <div className="text-center row">
                                            <div className="tab-content" id="myTabContent">
                                                {/* <------- Show the Active Tabs---------------> */}
                                                <div className={`tab-pane fade ${ActiveTab === 0 ? "show active" : ""}`}
                                                    id="home-tab-pane" role="tabpanel"
                                                    aria-labelledby="home-tab" tabindex="0">
                                                    <div className="d-flex gap-3 mb-1 justify-content-between">
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <label>Show</label>
                                                            <select className="form-select">
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                        </div>
                                                        <div className="form-group d-flex align-items-center gap-3">
                                                            <label>Search</label>
                                                            <input type="text" placeholder="Search" className="form-control"
                                                                required="" />
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-striped reusme-table mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sr.</th>
                                                                    <th>Status</th>
                                                                    <th>Job Title</th>
                                                                    <th>First Name</th>
                                                                    <th>Last Name</th>
                                                                    <th>Rusume</th>
                                                                    <th>Email ID</th>
                                                                    <th>Phone Number</th>
                                                                    <th>Training Program</th>
                                                                    <th>Degree</th>
                                                                    <th>University Name</th>
                                                                    <th>Graduation Date:</th>
                                                                    <th>Location</th>
                                                                    <th>Work Authorization</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>01.</td>
                                                                    <td><span className="badge badge-gradient-success">Hired</span>
                                                                    </td>
                                                                    <td>Cybersecurity engineer</td>
                                                                    <td>Ravi</td>
                                                                    <td>Sharma</td>
                                                                    <td><a href="#">ravi_Sharama_resume.pdf</a></td>
                                                                    <td><a href="#"><u>ravi@gmail.com</u></a></td>
                                                                    <td>+1 789456789</td>
                                                                    <td>Networking</td>
                                                                    <td>PHD</td>
                                                                    <td>University</td>
                                                                    <td>16/12/2024</td>
                                                                    <td>Lorem Ipsum is simply dummy text</td>
                                                                    <td>US Citizen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>02.</td>
                                                                    <td><span
                                                                        className="badge badge-gradient-danger">Rejected</span>
                                                                    </td>
                                                                    <td>Cybersecurity engineer</td>
                                                                    <td>Ravi</td>
                                                                    <td>Sharma</td>
                                                                    <td><a href="#">ravi_Sharama_resume.pdf</a></td>
                                                                    <td><a href="#"><u>ravi@gmail.com</u></a></td>
                                                                    <td>+1 789456789</td>
                                                                    <td>Networking</td>
                                                                    <td>PHD</td>
                                                                    <td>University</td>
                                                                    <td>16/12/2024</td>
                                                                    <td>Lorem Ipsum is simply dummy text</td>
                                                                    <td>US Citizen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>03.</td>
                                                                    <td><span
                                                                        className="badge badge-gradient-warning">Interview</span>
                                                                    </td>
                                                                    <td>Cybersecurity engineer</td>
                                                                    <td>Ravi</td>
                                                                    <td>Sharma</td>
                                                                    <td><a href="#">ravi_Sharama_resume.pdf</a></td>
                                                                    <td><a href="#"><u>ravi@gmail.com</u></a></td>
                                                                    <td>+1 789456789</td>
                                                                    <td>Networking</td>
                                                                    <td>PHD</td>
                                                                    <td>University</td>
                                                                    <td>16/12/2024</td>
                                                                    <td>Lorem Ipsum is simply dummy text</td>
                                                                    <td>US Citizen</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className={`tab-pane fade ${ActiveTab === 1 ? "show active" : ""}`} id="contact-tab-pane2" role="tabpanel"
                                                    aria-labelledby="contact-tab" tabindex="0">
                                                    <div className="d-flex gap-3 mb-1 justify-content-between">
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <label>Show</label>
                                                            <select className="form-select">
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                        </div>
                                                        <div className="form-group d-flex align-items-center gap-3">
                                                            <label>Search</label>
                                                            <input type="text" placeholder="Search" className="form-control"
                                                                required="" />
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-striped reusme-table mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sr.</th>
                                                                    <th>Job Title</th>
                                                                    <th>First Name</th>
                                                                    <th>Last Name</th>
                                                                    <th>Rusume</th>
                                                                    <th>Email ID</th>
                                                                    <th>Phone Number</th>
                                                                    <th>Training Program</th>
                                                                    <th>Degree</th>
                                                                    <th>University Name</th>
                                                                    <th>Graduation Date:</th>
                                                                    <th>Location</th>
                                                                    <th>Work Authorization</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>01.</td>
                                                                    <td>Cybersecurity engineer</td>
                                                                    <td>Ravi</td>
                                                                    <td>Sharma</td>
                                                                    <td><a href="#">ravi_Sharama_resume.pdf</a></td>
                                                                    <td><a href="#"><u>ravi@gmail.com</u></a></td>
                                                                    <td>+1 789456789</td>
                                                                    <td>Networking</td>
                                                                    <td>PHD</td>
                                                                    <td>University</td>
                                                                    <td>16/12/2024</td>
                                                                    <td>Lorem Ipsum is simply dummy text</td>
                                                                    <td>US Citizen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>02.</td>
                                                                    <td>Cybersecurity engineer</td>
                                                                    <td>Ravi</td>
                                                                    <td>Sharma</td>
                                                                    <td><a href="#">ravi_Sharama_resume.pdf</a></td>
                                                                    <td><a href="#"><u>ravi@gmail.com</u></a></td>
                                                                    <td>+1 789456789</td>
                                                                    <td>Networking</td>
                                                                    <td>PHD</td>
                                                                    <td>University</td>
                                                                    <td>16/12/2024</td>
                                                                    <td>Lorem Ipsum is simply dummy text</td>
                                                                    <td>US Citizen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>03.</td>
                                                                    <td>Cybersecurity engineer</td>
                                                                    <td>Ravi</td>
                                                                    <td>Sharma</td>
                                                                    <td><a href="#">ravi_Sharama_resume.pdf</a></td>
                                                                    <td><a href="#"><u>ravi@gmail.com</u></a></td>
                                                                    <td>+1 789456789</td>
                                                                    <td>Networking</td>
                                                                    <td>PHD</td>
                                                                    <td>University</td>
                                                                    <td>16/12/2024</td>
                                                                    <td>Lorem Ipsum is simply dummy text</td>
                                                                    <td>US Citizen</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className={`tab-pane fade ${ActiveTab === 2 ? "show active" : ""}`} id="contact-tab-pane" role="tabpanel"
                                                    aria-labelledby="contact-tab" tabindex="0">
                                                    <div className="d-flex gap-3 mb-1 justify-content-between">
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <label>Show</label>
                                                            <select className="form-select">
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                        </div>
                                                        <div className="form-group d-flex align-items-center gap-3">
                                                            <label>Search</label>
                                                            <input type="text" placeholder="Search" className="form-control"
                                                                required="" />
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-striped reusme-table mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sr.</th>
                                                                    <th>Status</th>
                                                                    <th>Job Title</th>
                                                                    <th>First Name</th>
                                                                    <th>Last Name</th>
                                                                    <th>Rusume</th>
                                                                    <th>Email ID</th>
                                                                    <th>Phone Number</th>
                                                                    <th>Training Program</th>
                                                                    <th>Degree</th>
                                                                    <th>University Name</th>
                                                                    <th>Graduation Date:</th>
                                                                    <th>Location</th>
                                                                    <th>Work Authorization</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>01.</td>
                                                                    <td><span
                                                                        className="badge badge-gradient-warning">Interview</span>
                                                                    </td>
                                                                    <td>Cybersecurity engineer</td>
                                                                    <td>Ravi</td>
                                                                    <td>Sharma</td>
                                                                    <td><a href="#">ravi_Sharama_resume.pdf</a></td>
                                                                    <td><a href="#"><u>ravi@gmail.com</u></a></td>
                                                                    <td>+1 789456789</td>
                                                                    <td>Networking</td>
                                                                    <td>PHD</td>
                                                                    <td>University</td>
                                                                    <td>16/12/2024</td>
                                                                    <td>Lorem Ipsum is simply dummy text</td>
                                                                    <td>US Citizen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>02.</td>
                                                                    <td><span
                                                                        className="badge badge-gradient-warning">Interview</span>
                                                                    </td>
                                                                    <td>Cybersecurity engineer</td>
                                                                    <td>Ravi</td>
                                                                    <td>Sharma</td>
                                                                    <td><a href="#">ravi_Sharama_resume.pdf</a></td>
                                                                    <td><a href="#"><u>ravi@gmail.com</u></a></td>
                                                                    <td>+1 789456789</td>
                                                                    <td>Networking</td>
                                                                    <td>PHD</td>
                                                                    <td>University</td>
                                                                    <td>16/12/2024</td>
                                                                    <td>Lorem Ipsum is simply dummy text</td>
                                                                    <td>US Citizen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>03.</td>
                                                                    <td><span
                                                                        className="badge badge-gradient-warning">Interview</span>
                                                                    </td>
                                                                    <td>Cybersecurity engineer</td>
                                                                    <td>Ravi</td>
                                                                    <td>Sharma</td>
                                                                    <td><a href="#">ravi_Sharama_resume.pdf</a></td>
                                                                    <td><a href="#"><u>ravi@gmail.com</u></a></td>
                                                                    <td>+1 789456789</td>
                                                                    <td>Networking</td>
                                                                    <td>PHD</td>
                                                                    <td>University</td>
                                                                    <td>16/12/2024</td>
                                                                    <td>Lorem Ipsum is simply dummy text</td>
                                                                    <td>US Citizen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>03.</td>
                                                                    <td><span
                                                                        className="badge badge-gradient-warning">Interview</span>
                                                                    </td>
                                                                    <td>Cybersecurity engineer</td>
                                                                    <td>Ravi</td>
                                                                    <td>Sharma</td>
                                                                    <td><a href="#">ravi_Sharama_resume.pdf</a></td>
                                                                    <td><a href="#"><u>ravi@gmail.com</u></a></td>
                                                                    <td>+1 789456789</td>
                                                                    <td>Networking</td>
                                                                    <td>PHD</td>
                                                                    <td>University</td>
                                                                    <td>16/12/2024</td>
                                                                    <td>Lorem Ipsum is simply dummy text</td>
                                                                    <td>US Citizen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>04.</td>
                                                                    <td><span
                                                                        className="badge badge-gradient-warning">Interview</span>
                                                                    </td>
                                                                    <td>Cybersecurity engineer</td>
                                                                    <td>Ravi</td>
                                                                    <td>Sharma</td>
                                                                    <td><a href="#">ravi_Sharama_resume.pdf</a></td>
                                                                    <td><a href="#"><u>ravi@gmail.com</u></a></td>
                                                                    <td>+1 789456789</td>
                                                                    <td>Networking</td>
                                                                    <td>PHD</td>
                                                                    <td>University</td>
                                                                    <td>16/12/2024</td>
                                                                    <td>Lorem Ipsum is simply dummy text</td>
                                                                    <td>US Citizen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>05.</td>
                                                                    <td><span
                                                                        className="badge badge-gradient-warning">Interview</span>
                                                                    </td>
                                                                    <td>Cybersecurity engineer</td>
                                                                    <td>Ravi</td>
                                                                    <td>Sharma</td>
                                                                    <td><a href="#">ravi_Sharama_resume.pdf</a></td>
                                                                    <td><a href="#"><u>ravi@gmail.com</u></a></td>
                                                                    <td>+1 789456789</td>
                                                                    <td>Networking</td>
                                                                    <td>PHD</td>
                                                                    <td>University</td>
                                                                    <td>16/12/2024</td>
                                                                    <td>Lorem Ipsum is simply dummy text</td>
                                                                    <td>US Citizen</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className={`tab-pane fade ${ActiveTab === 3 ? "show active" : ""}`} id="disabled-tab-pane" role="tabpanel"
                                                    aria-labelledby="disabled-tab" tabindex="0">
                                                    <div className="d-flex gap-3 mb-1 justify-content-between">
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <label>Show</label>
                                                            <select className="form-select">
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                        </div>
                                                        <div className="form-group d-flex align-items-center gap-3">
                                                            <label>Search</label>
                                                            <input type="text" placeholder="Search" className="form-control"
                                                                required="" />
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-striped reusme-table mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sr.</th>
                                                                    <th>Status</th>
                                                                    <th>Job Title</th>
                                                                    <th>First Name</th>
                                                                    <th>Last Name</th>
                                                                    <th>Rusume</th>
                                                                    <th>Email ID</th>
                                                                    <th>Phone Number</th>
                                                                    <th>Training Program</th>
                                                                    <th>Degree</th>
                                                                    <th>University Name</th>
                                                                    <th>Graduation Date:</th>
                                                                    <th>Location</th>
                                                                    <th>Work Authorization</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>01.</td>
                                                                    <td><span className="badge badge-gradient-success">Hired</span>
                                                                    </td>
                                                                    <td>Cybersecurity engineer</td>
                                                                    <td>Ravi</td>
                                                                    <td>Sharma</td>
                                                                    <td><a href="#">ravi_Sharama_resume.pdf</a></td>
                                                                    <td><a href="#"><u>ravi@gmail.com</u></a></td>
                                                                    <td>+1 789456789</td>
                                                                    <td>Networking</td>
                                                                    <td>PHD</td>
                                                                    <td>University</td>
                                                                    <td>16/12/2024</td>
                                                                    <td>Lorem Ipsum is simply dummy text</td>
                                                                    <td>US Citizen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>02.</td>
                                                                    <td><span className="badge badge-gradient-success">
                                                                        Hired</span></td>
                                                                    <td>Cybersecurity engineer</td>
                                                                    <td>Ravi</td>
                                                                    <td>Sharma</td>
                                                                    <td><a href="#">ravi_Sharama_resume.pdf</a></td>
                                                                    <td><a href="#"><u>ravi@gmail.com</u></a></td>
                                                                    <td>+1 789456789</td>
                                                                    <td>Networking</td>
                                                                    <td>PHD</td>
                                                                    <td>University</td>
                                                                    <td>16/12/2024</td>
                                                                    <td>Lorem Ipsum is simply dummy text</td>
                                                                    <td>US Citizen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>03.</td>
                                                                    <td><span className="badge badge-gradient-success">
                                                                        Hired</span></td>
                                                                    <td>Cybersecurity engineer</td>
                                                                    <td>Ravi</td>
                                                                    <td>Sharma</td>
                                                                    <td><a href="#">ravi_Sharama_resume.pdf</a></td>
                                                                    <td><a href="#"><u>ravi@gmail.com</u></a></td>
                                                                    <td>+1 789456789</td>
                                                                    <td>Networking</td>
                                                                    <td>PHD</td>
                                                                    <td>University</td>
                                                                    <td>16/12/2024</td>
                                                                    <td>Lorem Ipsum is simply dummy text</td>
                                                                    <td>US Citizen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>04.</td>
                                                                    <td><span className="badge badge-gradient-success">
                                                                        Hired</span></td>
                                                                    <td>Cybersecurity engineer</td>
                                                                    <td>Ravi</td>
                                                                    <td>Sharma</td>
                                                                    <td><a href="#">ravi_Sharama_resume.pdf</a></td>
                                                                    <td><a href="#"><u>ravi@gmail.com</u></a></td>
                                                                    <td>+1 789456789</td>
                                                                    <td>Networking</td>
                                                                    <td>PHD</td>
                                                                    <td>University</td>
                                                                    <td>16/12/2024</td>
                                                                    <td>Lorem Ipsum is simply dummy text</td>
                                                                    <td>US Citizen</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>05.</td>
                                                                    <td><span className="badge badge-gradient-success">
                                                                        Hired</span></td>
                                                                    <td>Cybersecurity engineer</td>
                                                                    <td>Ravi</td>
                                                                    <td>Sharma</td>
                                                                    <td><a href="#">ravi_Sharama_resume.pdf</a></td>
                                                                    <td><a href="#"><u>ravi@gmail.com</u></a></td>
                                                                    <td>+1 789456789</td>
                                                                    <td>Networking</td>
                                                                    <td>PHD</td>
                                                                    <td>University</td>
                                                                    <td>16/12/2024</td>
                                                                    <td>Lorem Ipsum is simply dummy text</td>
                                                                    <td>US Citizen</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileResume