import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { GetCourseList } from '../../../../api/Global';
import Header from '../../../common/header/Header';
import HomeTopSlider from '../home/home_components/Home_Top_Slider';
import TopMentors from '../home/home_components/Top_Mentors';
import BackToTop from '../../../common/back_to_top/Back_To_Top';
import Footer from '../../../common/footer/Footer';
import { ToastContainer } from 'react-toastify';
import CommonImageTransport from '../../../common/images/CommonImages';
import Loader from '../../../../loader/Loader';

const View_All_Traning_Program = () => {
    const navigate = useNavigate();
    const [isLoading,setIsLoading] = useState(false);
    const [CourseList, setCourseList] = useState([]);
    const [Course_id, setCourse_id] = useState("");

    const HandleCourseList = async () => {
      setIsLoading(true)
      const CourseData = {
        Course_id
      }
      try {
        const response = await GetCourseList(CourseData);
        setCourseList(response?.data?.data)
        setIsLoading(false)
      } catch (error) {
          setIsLoading(false)
            console.log("error", error)
        }
    }

    const NavigateCourseDeatils = (Course_id) => {
        setCourse_id(Course_id)
        navigate(`/CoursesDetails/${Course_id}`)
    }

    console.log("CourseList", CourseList)

    useEffect(() => {
        const timeout = setTimeout(() => {
          document.title = 'Training Program';
          document.querySelector('.banner-img')?.classList.add('animate-from-bottom');
          document.querySelector('.banner-content')?.classList.add('animate-from-top');
          // setIsLoading(false);
        }, 1000);
        return () => clearTimeout(timeout);
      }, []);
    useEffect(() => {
        HandleCourseList()
    }, [])

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return (
        <div>
             <Header />
            {
              isLoading ? (
                <Loader/>
              ):(
                <>
                  <div className="courses-area py-5 bg-light">
              <div className="container">
              <ToastContainer style={{marginTop:"100px"}}/>
                <div className="section-title text-center mb-45">
                  <h2>Free Training Program</h2>
                  <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                   incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                  </p>
                </div>
                <div className="row">
                  
                {
                                    CourseList?.map((CourseListResult) => {
                                          return (
                                                <>

                                                      <div style={{cursor:"pointer"}} className="col-lg-4 col-md-6"
                                                            onClick={() => NavigateCourseDeatils(CourseListResult?.id)}>
                                                            <div className="courses-item">

                                                                  <img src={CourseListResult?.course_img} alt="Courses" />

                                                                  <div className="content">
                                                                        <Link to="#" className="tag-btn">{CourseListResult?.category}</Link>
                                                                        <div className="price-text">Free</div>
                                                                        <h3> <Link to="#">{CourseListResult?.title}</Link></h3>
                                                                        <ul className="course-list">

                                                                              <li><i className="ri-vidicon-fill"></i> {CourseListResult?.total_classes} Days</li>
                                                                        </ul>
                                                                        <div className="bottom-content">
                                                                              <Link to="#" className="user-area">
                                                                                    <img src={CourseListResult?.instructor_img} style={{width:"50px",height:"50px",borderRadius:"50%"}} alt="Instructors" />
                                                                                    <h3>{CourseListResult?.instructor_name}</h3>
                                                                              </Link>

                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>

                                                </>
                                          )
                                    })
                              }
                  
                  {/* <div  className="col-lg-12 col-md-12 text-center">
                    <div className="pagination-area">
                      <Link to="courses.html" className="prev page-numbers">
                        <i className="flaticon-left-arrow" />
                      </Link>
                      <span className="page-numbers current" aria-current="page">1</span>
                      <Link to="" className="page-numbers">2</Link>
                      <Link to="courses.html" className="page-numbers">3</Link>
                      <Link to="courses.html" className="next page-numbers">
                        <i className="flaticon-chevron" />
                      </Link>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <TopMentors />
            {/* <Subscribe /> */}
            <BackToTop />
            <Footer />
                </>
              )
            }
          
        </div>
    )
}

export default View_All_Traning_Program