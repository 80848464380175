import AllRoutes from './allroutes/AllRoutes';

function App() {
  return (
    <div className="App">
    <AllRoutes />
    </div>
  );
}

export default App;




