import CommonImageTransport from '../../../common/images/CommonImages';
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Dropdown } from 'react-bootstrap';
import { ProfileSections } from '../../../../api/Auth';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { BsThreeDotsVertical } from "react-icons/bs";
import { GetSetings } from '../../../../api/Public';

const Student_Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("Is_LoggedIn"));
  const [GetUserProfile, SetGetUserProfile] = useState([]);
  const [SettingList, setSettingList] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const user_id = localStorage.getItem("User_id");

  // <------- User Authetication token's ------------->
  const token = localStorage.getItem("User_Token");
  const headers = {
    "Authorization": `Bearer ${token}`
  }

  // <----------- Get the Student Profile Sections -------------->
  const GetStudentSections = async () => {
    const response = await ProfileSections({ user_id, headers })
    SetGetUserProfile(response?.data?.data?.profile)
  }



  // <-------- active Class on the both files(Jobs, Applied Jobs)------------->
  const jobRoutePattern = /^\/Student\/Student_jobs$/;
  const appliedJobRoutePattern = /^\/Student\/Student_Applied_Job$/;

  // <-------- active Class on the both files(All Mentors,Upcoming Mentors, Completed Mentors)------------->
  const AllMentors = /^\/Student\/Student_Mentor_List$/;
  const UpcomingMentors = /^\/Student\/Student_Upcoming_Mentor_list$/;
  const CompletedMentors = /^\/Student\/Student_Mentor_Complate_session$/;

  // <----------------- Student Traning Program's section's Active ----------------->
  const Student_Traning_Program = /^\/Student\/Student_Traning_Program$/;
  const Student_Traning_Program1 = /^\/Student\/Student\/Student_Coaching_Session_Complete\/:course_id$/;


  // <------------- Logout the User ---------------->
  const LogoutUser = () => {
    const User_Token = localStorage.getItem("User_Token");
    if (User_Token) {
      const confirmation = window.confirm("Are you sure you want to logout?");
      if (confirmation) {
        localStorage.removeItem("User_Token");
        localStorage.removeItem("Is_LoggedIn");
        localStorage.removeItem("user_Login_Data");
        localStorage.removeItem("user_Email");
        localStorage.removeItem("User_id");
        localStorage.removeItem("rememberedUser");
        localStorage.removeItem("role_id");
        localStorage.removeItem("Active_Traning_Program");
        navigate('/Signin');
        window.location.reload();
      } else {
        console.log("User canceled the logout");
      }
    }
  };

  const HandleGetSettingList = async () => {
    const response = await GetSetings();
    setSettingList(response?.data?.data);
  };

  useEffect(() => {
    HandleGetSettingList();
  }, []);

  // <----------- Render the Get Student Profile  -------------->
  useEffect(() => {
    GetStudentSections();
  }, [])

  
  return (
    <div>
      <Navbar expand="lg" className="navbar-area ledu-area otherpages-menu">
        <div className='container'>
          <Navbar.Brand as={Link} to="/Student/Student_dashBoard">
            {
              SettingList?.headerlogo != null ? (
                <>
                  <img src={SettingList?.headerlogo} height={50} className="logo-one rounded-pill" alt="logo" />
                </>

              ) : (
                <>
                  <img src={CommonImageTransport?.logoFull} height={50} className="logo-one rounded-pill" alt="logo" />

                </>
              )
            }
          </Navbar.Brand>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="w-100 ms-3">
              <ul className="navbar-nav w-100 ms-3">
                <li className="nav-item">
                  <Link to="/Student/Student_dashBoard"
                    className={location.pathname === '/Student/Student_dashBoard' ? 'active nav-link' : 'nav-link'}>
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/Student/Student_Resume"
                    className={location.pathname === '/Student/Student_Resume' ? 'nav-link active' : 'nav-link'}>
                    Resume
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/Student/Student_Traning_Program"
                    className={location.pathname === '/Student/Student_Traning_Program' ? 'active nav-link' : 'nav-link'}
                  >
                    Training Program
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/Student/Student_jobs"
                    className={
                      jobRoutePattern.test(location.pathname) || appliedJobRoutePattern.test(location.pathname)
                        ? 'active nav-link'
                        : 'nav-link'
                    }>
                    Jobs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/Student/Student_Mentor_List"
                    className={
                      AllMentors.test(location.pathname) || UpcomingMentors.test(location.pathname) || CompletedMentors.test(location.pathname)
                        ? 'active nav-link'
                        : 'nav-link'
                    }>
                    Mentors
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/Student/Student_Support"
                    className={location.pathname === '/Student/Student_Support' ? 'active nav-link' : 'nav-link'}>
                    Support
                  </Link>
                </li>
              </ul>
              <div className='"others-options gap-2 d-flex align-items-center"'>

                <div className="optional-item">
                  {/* <button className="btn btn-primary rounded-pill" onClick={handleShow} type="button"
                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"><i className="ri-notification-2-line">

                    </i></button> */}
                  {/* <---------- Notification sections here --------------> */}
                  {/* <Offcanvas className="offcanvas offcanvas-end"
                    tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" show={show} onHide={handleClose} placement="end">
                    <div className="offcanvas-header">
                      <h5 className="offcanvas-title" id="offcanvasRightLabel">Notification list</h5>
                      <button type="button" className="btn-close" onClick={() => handleClose()} data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body p-0">
                      <div className="right boxshhd">
                        <div className="box shadow-sm rounded bg-white mb-3">
                          <div className="box-title border-bottom p-3">
                            <h6 className="m-0">Recent</h6>
                          </div>
                          <div className="box-body p-0">
                            <div className="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header">
                              <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle"
                                  src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="" />
                              </div>
                              <div className="font-weight-bold mr-3">
                                <div className="text-truncate">DAILY RUNDOWN: WEDNESDAY</div>
                                <div className="small">Income tax sops on the cards, The bias in VC funding, and other top news for you</div>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button">
                                      <i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button">
                                      <BsThreeDotsVertical /> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">3d</div>
                              </span>
                            </div>
                            <div className="p-3 d-flex align-items-center osahan-post-header">
                              <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="" />
                              </div>
                              <div className="font-weight-bold mr-3">
                                <div className="mb-2">We found a job at askbootstrap Ltd that you may be interested in Vivamus imperdiet venenatis est...</div>
                                <button type="button" className="btn btn-outline-success btn-sm">View Jobs</button>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">4d</div>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="box shadow-sm rounded bg-white mb-3">
                          <div className="box-title border-bottom p-3">
                            <h6 className="m-0">Earlier</h6>
                          </div>
                          <div className="box-body p-0">
                            <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div className="dropdown-list-image mr-3 d-flex align-items-center bg-danger justify-content-center rounded-circle text-white">DRM</div>
                              <div className="font-weight-bold mr-3">
                                <div className="text-truncate">DAILY RUNDOWN: MONDAY</div>
                                <div className="small">Nunc purus metus, aliquam vitae venenatis sit amet, porta non est.</div>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right" >
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">3d</div>
                              </span>
                            </div>
                            <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div className="dropdown-list-image mr-3"><img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="" /></div>
                              <div className="font-weight-bold mr-3">
                                <div className="text-truncate">DAILY RUNDOWN: SATURDAY</div>
                                <div className="small">Pellentesque semper ex diam, at tristique ipsum varius sed. Pellentesque non metus ullamcorper</div>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">3d</div>
                              </span>
                            </div>
                            <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="" />
                              </div>
                              <div className="font-weight-bold mr-3">
                                <div className="mb-2"><span className="font-weight-normal">Congratulate Gurdeep Singh Osahan (iamgurdeeposahan)</span> for 5 years at Askbootsrap Pvt.</div>
                                <button type="button" className="btn btn-outline-success btn-sm">Say congrats</button>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">4d</div>
                              </span>
                            </div>
                            <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar4.png" alt="" />
                              </div>
                              <div className="font-weight-bold mr-3">
                                <div>
                                  <span className="font-weight-normal">Congratulate Mnadeep singh (iamgurdeeposahan)</span> for 4 years at Askbootsrap Pvt.
                                  <div className="small text-success"><i className="fa fa-check-circle"></i> You sent Mandeep a message</div>
                                </div>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">4d</div>
                              </span>
                            </div>
                            <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div className="dropdown-list-image mr-3 d-flex align-items-center bg-success justify-content-center rounded-circle text-white">M</div>
                              <div className="font-weight-bold mr-3">
                                <div className="text-truncate">DAILY RUNDOWN: MONDAY</div>
                                <div className="small">Nunc purus metus, aliquam vitae venenatis sit amet, porta non est.</div>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">3d</div>
                              </span>
                            </div>
                            <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div className="dropdown-list-image mr-3"><img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="" /></div>
                              <div className="font-weight-bold mr-3">
                                <div className="text-truncate">DAILY RUNDOWN: SATURDAY</div>
                                <div className="small">Pellentesque semper ex diam, at tristique ipsum varius sed. Pellentesque non metus ullamcorper</div>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">3d</div>
                              </span>
                            </div>
                            <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="" />
                              </div>
                              <div className="font-weight-bold mr-3">
                                <div className="mb-2"><span className="font-weight-normal">Congratulate Gurdeep Singh Osahan (iamgurdeeposahan)</span> for 5 years at Askbootsrap Pvt.</div>
                                <button type="button" className="btn btn-outline-success btn-sm">Say congrats</button>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">4d</div>
                              </span>
                            </div>
                            <div className="p-3 d-flex align-items-center osahan-post-header">
                              <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="" />
                              </div>
                              <div className="font-weight-bold mr-3">
                                <div>
                                  <span className="font-weight-normal">Congratulate Mnadeep singh (iamgurdeeposahan)</span> for 4 years at Askbootsrap Pvt.
                                  <div className="small text-success"><i className="fa fa-check-circle"></i> You sent Mandeep a message</div>
                                </div>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">4d</div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Offcanvas> */}
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
          <Nav>
            {isLoggedIn ? (
              <Dropdown className="others-options">
                <Dropdown.Toggle variant="text-white">
                  {
                    GetUserProfile?.profileimg != null ?
                      <>
                        <img src={GetUserProfile?.profileimg} height={50} width={50}
                          className="rounded-pill" alt="Profile" />
                      </> :
                      <>
                        <img src={CommonImageTransport.userLogo} height={50} width={50}
                          className="rounded-pill" alt="Profile" />
                      </>
                  }

                  {`${GetUserProfile?.fname}  ${GetUserProfile?.lname}`}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Link to="/Student/Student_Profile" className='dropdown-item'><i className="flaticon-user" />Profile</Link>

                  {/* <Link to="/Student/MyCourses"> <i className="ri-bookmark-fill" /> My Courses</Link> */}
                  <li className='dropdown-item' onClick={LogoutUser}>
                    <i className="ri-logout-box-fill" />Logout</li>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <div className="others-options login__signup gap-2 d-flex align-items-center">
                <div className="optional-item">
                  <Link to="/Signin" className={location.pathname === '/Signin' ? 'active default-btn two rounded-pill' : 'rounded-pill'}>Sign In</Link>
                </div>
                <div className="optional-item">
                  <Link to="/Signup" className={location.pathname === '/Signup' ? 'active default-btn two rounded-pill' : 'rounded-pill'}>Sign up</Link>
                </div>
              </div>
            )}
          </Nav>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </div>
      </Navbar>
      <div class="header_space"></div>
    </div>
  )
}

export default Student_Header