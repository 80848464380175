import React, { useState } from "react";
import CommonImageTransport from "../../components/common/images/CommonImages";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/common/header/Header";
import { ForgotPass } from "../../api/Auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [conf_password, setConf_password] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const email = localStorage.getItem("Reset_password_User_Email");
  // <--------- Apply The regex for the vaild Password section's ------------->
  const ValidatePassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{11,}$/;
    setIsValidPassword(regex.test(password));
  };
  /*----------------------Validated Password -------------------*/
  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    ValidatePassword(value);
  };

  //   <--------- Handle Forgot Password ----------->
  const Forgot_Password = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    if (!isValidPassword) {
      toast.error(
        "Password must have at least 11 characters, including uppercase, lowercase, number, and special character."
      );
      setIsLoading(false);
      return;
    }
    if(!password){
      setIsLoading(false)
      toast.error("Please enter the password")
      return
    }
    if (password !== conf_password) {
      setIsLoading(false)
      toast.error("Password Do Not Match !!");
      return;
    }
   else if(!conf_password){
      setIsLoading(false)
      toast.error("Please enter the confirm password")
      return
    }
    try {
      const ForgotPassData = {
        email: email,
        password: password,
      };
     
      const response = await ForgotPass(ForgotPassData);
      if (response.data.statusCode == "200") {
        setIsLoading(false);
        setTimeout(() => {
          toast.success("Successfully Reset Password !!");
        }, 1000);
        setTimeout(() => {
          navigate("/Signin");
        }, 2000);
      } else if (response.data.statusCode == "400") {
        setIsLoading(false);
        setTimeout(() => {
          toast.error(response?.data?.msg);
        }, 1000);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  return (
    <div>
      <div className="login-signup-bg">
        <Header />
        {isLoading ? 
        <>
            <div className="chat-window text-center">
            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                margin: "auto",
              }}
              className="spinner-border"
              role="status"
            >
              <span className="sr-only"></span>
            </div>
          </div>
        </> 
        : 
        <>
        <div className="user-area user-area p-2 p-md-4 p-lg-5 pt-lg-3">
          <div className="container">
            <div className="row">
              <ToastContainer style={{ marginTop: "120px" }} />
              <div className="col-lg-6 p-0">
                <div className="user_login">
                  <img
                    src={CommonImageTransport.loginbg}
                    className="imgk-fluid w-100"
                    alt="login-banner"
                  />
                </div>
              </div>
              <div className="col-lg-6 d-flex p-0">
                <div className="user-all-form userlogin position-relative">
                  <div className="login_bottom">
                    <img
                      src={CommonImageTransport.loginBottom}
                      alt="loginBottom"
                    />
                  </div>
                  <div className="contact-form">
                    <h3 className="user-title">Forgot Password</h3>
                    <p>
                      Enter your email and we'll send you a link to reset you
                      password
                    </p>
                    <form id="contactForms" className="mt-5" onSubmit={Forgot_Password}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-floating form-group">
                            <input
                              type={showPassword ? "text" : "password"}
                              name="password"
                              value={password}
                              onChange={handlePasswordChange}
                              className="form-control"
                              id="floatingInput"
                              placeholder=""
                            />
                            <label htmlFor="floatingInput">Password</label>
                            <span
                              onClick={() => setShowPassword(!showPassword)}
                              className="password-toggle eye"
                            >
                              {showPassword ? <FaEye /> : <FaEyeSlash />}
                            </span>
                          </div>
                        </div>
                        {!isValidPassword && (
                          <p style={{ color: "red" }}>
                            Password must be at least 11 characters long,
                            contain at least one lowercase letter, one uppercase
                            letter, one digit, and one special character
                            (@$!%*?&).
                          </p>
                        )}
                        <div className="col-lg-12">
                          <div className="form-floating form-group">
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              name="conf_password"
                              value={conf_password}
                              onChange={(e) => setConf_password(e.target.value)}
                              className="form-control"
                              id="floatingInput"
                              placeholder=""
                            />
                            <label htmlFor="floatingInput">
                              Confirm Password
                            </label>
                            <span
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              className="password-toggle eye"
                            >
                              {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <button
                            type="submit"
                            className="default-btn w-100 rounded"
                           
                          >
                            Submit
                          </button>

                          <h6 className="mt-4 text-center fw-normal text-muted">
                            Don’t have an account?
                            <Link className="fw-bold" to="/Student_SignUp">
                              Create an account
                            </Link>
                          </h6>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
        }
        
      </div>
    </div>
  );
};

export default ForgotPassword;
