import React from 'react'
import Recruiter_Header from '../recruiter_header/Recruiter_Header'

const ProfileRequest = () => {
    return (
        <div>

            <div className="bg_nav-slid bg-white">
                <Recruiter_Header/>
            </div>
          
            <div className="enrolled-area-two py-5 bg-light">
                <div className="container">

                    <div className="row">

                        <div className="Dashboard1 col-md-12">
                            <h3>Profile Request</h3>
                        </div>
                        <div className="col-12 mb-3">
                            <div className="card widget-flat">
                                <div className="card-body">
                                    <div className="d-flex gap-3 mb-3 justify-content-between">
                                        <div className="d-flex gap-2 align-items-center">
                                            <label>Show</label>
                                            <select className="form-select">
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                        <div className="form-group d-flex align-items-center gap-3">
                                            <label>Search</label>
                                            <input type="text" placeholder="Search" className="form-control"
                                                required="" />
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-centered table-nowrap mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th className="border-0">No.</th>
                                                    <th className="border-0">Training Program</th>
                                                    <th className="border-0">Job Titles</th>
                                                    <th className="border-0">Fees & USD<span className="small">($100 Fixed Fees)</span>
                                                    </th>
                                                    <th className="border-0">Request Confirmation</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        1
                                                    </td>
                                                    <td>
                                                        Networking
                                                    </td>
                                                    <td>
                                                        Network Engineer
                                                    </td>

                                                    <td>
                                                        $500
                                                    </td>

                                                    <td className="table-action">
                                                        <a href="#" className="btn btn-sm btn-success"><i className="ri-check-line"></i>
                                                            Active</a>
                                                        <a href="#" className="btn btn-sm btn-danger"><i
                                                            className="ri-delete-bin-6-line"></i> Inactive</a>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        2
                                                    </td>
                                                    <td>
                                                        Networking
                                                    </td>
                                                    <td>
                                                        Network Engineer
                                                    </td>

                                                    <td>
                                                        $500
                                                    </td>

                                                    <td className="table-action">
                                                        <a href="#" className="btn btn-sm btn-success"><i className="ri-check-line"></i>
                                                            Active</a>
                                                        <a href="#" className="btn btn-sm btn-danger"><i
                                                            className="ri-delete-bin-6-line"></i> Inactive</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        3
                                                    </td>
                                                    <td>
                                                        Networking
                                                    </td>
                                                    <td>
                                                        Network Engineer
                                                    </td>

                                                    <td>
                                                        $500
                                                    </td>

                                                    <td className="table-action">
                                                        <a href="#" className="btn btn-sm btn-success"><i className="ri-check-line"></i>
                                                            Active</a>
                                                        <a href="#" className="btn btn-sm btn-danger"><i
                                                            className="ri-delete-bin-6-line"></i> Inactive</a>
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileRequest