import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import Mentor_Header from '../mentor_header/Mentor_Header';
import { SendOtp, OtpVerify, ChangePassword } from '../../../../api/Auth';
import Mentor_Common_Profile_Section from '../mentor_common_profile_section/Mentor_Common_Profile_Section';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Mentor_Change_Password = () => {
  const navigate = useNavigate();
  const email = localStorage.getItem("user_Email");
  const [otp, setOtp] = useState('');
  const [old_password, setOld_Password] = useState('');
  const [password, setPassword] = useState('');
  const [confpassword, setConfPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  // <----------- Check the password validation's ---------------->
  const [isValidPassword, setIsValidPassword] = useState(true);

  const token = localStorage.getItem("User_Token");
  const headers = {
    "Authorization": `Bearer ${token}`
  }
  // <---------- Forgot Password Function ------------->
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // <---------- OTP VERIFICATION Function ------------->
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // <------------ Send Otp Api call --------------->
  const SendOtpChangePassword = async () => {
    const data = {
      type: "resetpassword",
      email: email,
    }
    const response = await SendOtp(data)
    if (response.data.statusCode == "200") {
      handleShow();
    }
  }
  // <------------ Otp Verify Api Call -------------->
  const OtpVerifyResetPass = async () => {
    try {
      const SendOtpVerifyData = {
        type: "resetpassword",
        email: email,
        otp
      }
      const response = await OtpVerify(SendOtpVerifyData);
      if (response.data.data.isOtpMatch == true) {
        localStorage.removeItem("Student_Register_Email")
        handleShow1();
        handleClose();
      }
      else if(response?.data?.statusCode == "400"){
        toast.error(response?.data?.msg)
        setTimeout(()=>{
          handleClose();
          setOtp("");
        },1000)
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }

   // <--------- Apply The regex for the vaild Password section's ------------->
   const ValidatePassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{11,}$/;
    setIsValidPassword(regex.test(password));
  };

    // <---------- Onchange events on the password section's ---------------->
    const handlePasswordChange = (event) => {
      const { value } = event.target;
      setPassword(value);
      ValidatePassword(value);
    };
  

 // <----------- Change Password section's ---------------->
 const Change_Password = async () => {
  const data = {
    old_password,
    password: password
  };

  if (!password) {
    toast.error("please fill the password to processed !!!")
    return;
  }
  if (!confpassword) {
    toast.error("please fill the confirm password to processed !!!")
    return;
  }
  if (password !== confpassword) {
    toast.error("Password do not match !!!")
    return;
  }
  try {
    const response = await ChangePassword(data, headers);
    if (response?.data?.statusCode == "200") {
      toast.success("Password Change Successfully !!!")
      handleClose1();
      setTimeout(()=>{
        navigate("/mentor_pages/MentorDashboard")
      },1000)
    }
    else if (response?.data?.statusCode == "400") {
      toast.error(response?.data?.msg)
      console.log("Change_Password", response)
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
  return (
    <div className="stepbg">
      <Mentor_Header />
      <div className="client_filter-job py-5">
        <div className="stepsForm">
          <form method="post" id="msform">
            <div className="container">
            <ToastContainer style={{ marginTop: "100px" }} />
              <div className="row">
                <div className="col-md-4">
                  <Mentor_Common_Profile_Section />
                </div>
                {/* <------------ Forgot Password sections --------------> */}
                {/* <------------ Change Password Sections ------------> */}
                <Modal show={show1} onHide={handleClose1}>
                <Modal.Header class="modal-header">
                          <h1 class="modal-title fs-5" id="exampleModalLabel">Change Password</h1>
                          <button type="button" onClick={handleClose1} class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                        </Modal.Header>
                  <div class="col-md-12 d-flex">
              
                    <div class="flexdr position-relative p-3 p-md-4 shadow-lg">
                      <div class="sf-steps-form personal_user_info">
                        <div class="row">
                          <div class="col-lg-12 mt-3">
                            <label>New Password</label>
                            <div class="form-floating form-group">
                              <input type={showConfirmPassword ? "text" : "password"} name='password' value={password}
                                onChange={handlePasswordChange}
                                class="form-control"
                                placeholder="New Password" />
                              <span onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                class="password-toggle eye">
                                {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                              </span>
                            </div>
                          </div>

                          <div class="col-lg-12 mt-3">
                            <label>Confirm New Password</label>
                            <div class="form-floating form-group">
                              <input type={showConfirmNewPassword ? "text" : "password"} name='confpassword' value={confpassword}
                                onChange={(e) => setConfPassword(e.target.value)}
                                class="form-control"
                                placeholder="Confirm New Password" />
                              <span onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                                class="password-toggle eye">
                                {showConfirmNewPassword ? <FaEye /> : <FaEyeSlash />}
                              </span>
                            </div>
                          </div>
                          {!isValidPassword && (
                            <p style={{ color: "red" }}>
                              Password must be at least 11 characters long,
                              contain at least one lowercase letter, one
                              uppercase letter, one digit, and one special
                              character (@$!%*?&).
                            </p>
                          )}
                          <div class="col-lg-12 col-md-12 mt-3">
                            <button type="button" onClick={() => Change_Password()}
                              class="default-btn rounded">
                              Change Password
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                {/* <-------- OTP VERIFICATION Sections --------------> */}
                <div className='modal fade' id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <Modal show={show} onHide={handleClose}>
                      <div className="modal-content">
                        <Modal.Header className="modal-header">
                          <h1 className="modal-title fs-5" id="exampleModalLabel">OTP VERIFICATION</h1>
                          <button type="button" onClick={handleClose} className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                        </Modal.Header>
                        <Modal.Body className="modal-body">
                          <div className="title">
                            <h6 className="info">An otp has been sent to <a href="#">{email}</a></h6>
                            <p className="mb-3">Please enter OTP to verify</p>
                          </div>
                          <div className="form-floating form-group">
                            <div className="otp-input-fields">
                              <OtpInput className="otp__digit otp__field__1"
                                value={otp}
                                onChange={(newOtp) => { setOtp(newOtp) }}
                                numInputs={6}
                                renderInput={(props) =>
                                  <input   {...props} />}/>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer className="modal-footer">
                          <a type="button" onClick={OtpVerifyResetPass} data-bs-target="#exampleModalToggle2"
                            data-bs-toggle="modal" className="default-btn py-2 rounded w-100">Verify</a>
                        </Modal.Footer>
                      </div>
                    </Modal>
                  </div>
                </div>

                <div className="col-md-8 d-flex">
                  <div className="flexdr position-relative p-3 p-md-4 shadow-lg">
                    <div className="sf-steps-form personal_user_info">
                      <h4>Reset Password</h4>
                      <div className="row">
                        <div className="col-12 col-lg-12">
                          <div className="form-groups emailforgot">
                            <label>Email address</label>
                            <input type="email" placeholder="ravi@gmail.com"
                              value={email} disabled />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <a type="button" onClick={() => {
                            SendOtpChangePassword();
                          }} className="default-btn py-2 rounded" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">
                            Send OTP
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Mentor_Change_Password