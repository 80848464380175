import React, { useState, useEffect } from 'react'
import "../../../../assets/css/style.css";
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
import { GetRecuriterjobAmount, RecuriterJobPost } from '../../../../api/Recuriter';
import { useNavigate } from 'react-router-dom';
import Recruiter_Header from '../recruiter_header/Recruiter_Header';
import RazorpayPaymentButton from '../../../../api/payment/Razorpay_Payment_Button';
import Recruiter_Footer from '../recruiter_footer/Recruiter_Footer';
import Loader from '../../../../loader/Loader';

const Recruiter_JobPosts = () => {
  const navigate = useNavigate();
  const [is_Loading, setIs_Loading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [Logo, setLogo] = useState("");
  const [title, setTitle] = useState("");
  const [organization, setorganization] = useState("")
  // const [companyUrl, setcompanyUrl] = useState("")
  const [skills, setskills] = useState([])
  const [expertise, setexpertise] = useState("")
  const [startDate, setstartDate] = useState("")
  const [endDate, setendDate] = useState("")
  const [employmentType, setemploymentType] = useState("")
  const [location, setlocation] = useState("")
  const [currencyType, setcurrencyType] = useState("USD")
  const [salaryPackage, setsalaryPackage] = useState("")
  const [aboutJob, setaboutJob] = useState("")
  const [postion_no, setPostion_no] = useState("")
  const [ErrorMessgae, setErrorMessgae] = useState(false);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [amount, setAmount] = useState("")
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [showPaymentButton, setShowPaymentButton] = useState(false);
  const TotalAmount = Math.round(postion_no * amount * 100);

  // <---------- User Authentication token's ------------>
  const token = localStorage.getItem("User_Token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setLogo(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result); // Set the preview URL
    };
    if (file) {
      reader.readAsDataURL(file); // Read the uploaded file as Data URL
    }
  };

  const GetTheAmount = async () => {
    try {
      const response = await GetRecuriterjobAmount(headers)
      setAmount(response?.data?.data)
    }
    catch (error) {
      console.log("error", error)
    }
  }
  console.log("GetTheAmount", amount)

  const isFormComplete = () => {
    return (
      // salaryPackage &&
      // currencyType &&
      location &&
      employmentType &&
      expertise &&
      skills.length > 0 &&
      // companyUrl &&
      organization &&
      title &&
      endDate &&
      startDate &&
      postion_no &&
      aboutJob
    );
  };



  // Handle the file input change
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to Array
    const newPreviews = [];

    files.forEach((file) => {
      if (file.type.startsWith('image/')) { // Validate image type
        const reader = new FileReader();
        reader.onloadend = () => {
          newPreviews.push(reader.result); // Store preview URL
          if (newPreviews.length === files.length) {
            setSelectedFiles(files);
            setImagePreviews((prev) => [...prev, ...newPreviews]); // Update state with new URLs
          }
        };
        reader.readAsDataURL(file);
      } else {
        alert("Please upload a valid image file.");
      }
    });
  };


  const validateFields = () => {
    const fields = [
      { value: title, message: "Please enter the job title to proceed." },
      { value: organization, message: "Please enter your organization to proceed." },
      // { value: salaryPackage, message: "Please enter the salary package to proceed." },
      // { value: currencyType, message: "Please select a currency type to proceed." },
      { value: expertise, message: "Please select your expertise to proceed." },
      { value: startDate, message: "Please select the application start date." },
      { value: endDate, message: "Please select the application end date." },
      { value: employmentType, message: "Please select your employment type to proceed." },
      { value: postion_no, message: "Please enter the number of positions to proceed." },
      { value: location, message: "Please provide your location to proceed." },
      { value: aboutJob, message: "Please provide About Opportunity to proceed." },
    ];
    for (const field of fields) {
      if (!field.value) {
        toast.error(field.message);
        return false;
      }
    }

    // Check if at least one skill is added
    if (skills.length === 0) {
      toast.error("Please add at least one skill to proceed.");
      return false;
    }

    return true;
  };

  const handlePaymentSuccess = ({ paymentId, payment_status, amount }) => {
    console.log("Payment successful:", { paymentId, payment_status, amount });
    HandleRecuriterJobsPosts({ paymentId, payment_status, amount });
  };

  const handlePostJob = async () => {
    if (validateFields()) {
      // toast.success("All fields validated. Proceeding with payment...");
      setShowPaymentButton(true);
    }
  };




  // Job posting function
  const HandleRecuriterJobsPosts = async ({ paymentId, payment_status, amount }) => {
    setIs_Loading(true);

    const formData = new FormData();
    formData.append("salaryPackage", salaryPackage);
    formData.append("currencyType", currencyType);
    formData.append("location", location);
    formData.append("employmentType", employmentType);
    formData.append("expertise", expertise);
    formData.append("skills", skills);
    formData.append("organization", organization);
    formData.append("title", title);
    formData.append("endDate", endDate);
    formData.append("startDate", startDate);
    formData.append("aboutJob", aboutJob);
    formData.append("paymentId", paymentId);
    formData.append("payment_status", payment_status);
    formData.append("amount", amount);
    formData.append("postion_no", postion_no);
    formData.append("job_image", Logo);

    try {
      const response = await RecuriterJobPost(formData, headers);
      if (response?.data?.statusCode === "200") {
        setIs_Loading(false);
        toast.success("Job Posted Successfully !!!");
        navigate("/recruiter_pages/RecruiterJobs");
      }
    } catch (error) {
      setIs_Loading(false);
      console.error("Error posting job:", error);
      toast.error("Error posting job. Please try again.");
    }
  };

  // <---------- Start Not Grater then to the End Date------------>
  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    const currentDate = new Date().toISOString().split('T')[0];
    if (selectedStartDate < currentDate) {
      setstartDate(currentDate);
    } else if (selectedStartDate > endDate) {
      setstartDate(selectedStartDate);
    } else {
      setstartDate(selectedStartDate);
    }
  };

  // <---------- End Date Not Less then to the Start Date------------>
  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    const currentDate = new Date().toISOString().split('T')[0];
    if (selectedEndDate < startDate || selectedEndDate < currentDate) {
      setendDate(startDate);
    } else if (selectedEndDate < startDate) {
      setendDate(selectedEndDate);
    } else {
      setendDate(selectedEndDate);
    }
  };

  const today = new Date().toISOString().split('T')[0];

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    GetTheAmount()
  }, [])
  return (
    <div>
      {
        is_Loading ? (
          <>
            <Loader />
          </>

        ) : (
          <>
            <div className="bg_nav-slid bg-white">
              <Recruiter_Header />
            </div>

            <div className="stepbg">
              <div className="client_filter-job pt-1 pb-0">
                <div className="stepsForm">
                  <form id="msform" onSubmit={handleFormSubmit}>
                    <div className="container">
                      <ToastContainer style={{ marginTop: "120px" }} />
                      <div className="row">
                        <div className="col-md-8 mx-auto">
                          <div className="flexdr position-relative">
                            <div className="sf-steps-form sf-radius">
                              <ul className="sf-content m-0">
                                <div className="find-com-box p-0">
                                  <div className="professional-sp">
                                    <h4>Basic Details</h4>
                                    <h6>Fill all form field to go to next step</h6>
                                  </div>
                                  <hr />
                                  <div className="professional-sp row">
                                    <div className="col-12 col-md-12">
                                      <div className="form-group">
                                        <label className="fieldlabels">Upload Logo</label>
                                        {/* <section className="bg-diffrent">
                                    <div className="file-upload-contain">
                                      <label htmlFor="file-upload" className="file-input theme-explorer-fas file-input-ajax-new file-thumb-loading">
                                        <div className="file-preview">
                                          <div className="file-drop-zone clickable" tabIndex="-1">
                                            <div className="file-drop-zone-title">
                                              <div className="upload-area">
                                                <i className="ri-upload-cloud-line"></i>
                                                <p>Browse or Drag and Drop .jpg, .png, .gif</p>
                                                <div>
                                                  <a className="default-btn rounded-pill">Browse File</a>
                                                  <input id="file-upload" type="file"
                                                    name='Logo'
                                                    accept=".jpg,.gif,.png"
                                                    onChange={(e) => setLogo(e.target.files[0])}
                                                    multiple hidden />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="clearfix"></div>
                                            <div className="file-preview-status text-center text-success">
                                              {selectedFiles?.length > 0 ? `${selectedFiles?.length} files selected` : 'No files selected'}
                                            </div>
                                            <div className="kv-fileinput-error file-error-message"
                                              style={{ display: "none" }} ></div>
                                          </div>
                                        </div>
                                      </label>
                                    </div>
                                  </section> */}
                                        <section className="bg-diffrent">
                                          <div className="file-upload-contain">
                                            <label htmlFor="file-upload" className="file-input theme-explorer-fas file-input-ajax-new file-thumb-loading">
                                              <div className="file-preview">
                                                <div className="file-drop-zone clickable" tabIndex="-1">
                                                  <div className="file-drop-zone-title">
                                                    <div className="upload-area">
                                                      <i className="ri-upload-cloud-line"></i>
                                                      <p>Browse or Drag and Drop .jpg, .png, .gif</p>
                                                      <div>
                                                        <a className="default-btn rounded-pill">Browse File</a>
                                                        <input
                                                          id="file-upload"
                                                          type="file"
                                                          name="Logo"
                                                          accept=".jpg,.gif,.png"
                                                          onChange={handleImageUpload} // Change handler
                                                          multiple
                                                          hidden
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="clearfix"></div>
                                                  <div className="file-preview-status text-center text-success">
                                                    {selectedFiles.length > 0 ? `${selectedFiles.length} files selected` : 'No files selected'}
                                                  </div>
                                                  <div className="kv-fileinput-error file-error-message" style={{ display: "none" }}></div>
                                                </div>
                                              </div>
                                            </label>
                                          </div>

                                          {/* Section to display the uploaded image previews */}
                                          {imagePreviewUrl && (
                                            <div className="uploaded-image-preview">
                                              <img
                                                src={imagePreviewUrl}
                                                alt="Uploaded Preview"
                                                style={{
                                                  margin: "auto",
                                                  width: "180px", // Set width for the preview image
                                                  height: "180px", // Set height for the preview image
                                                  objectFit: "cover", // Maintain aspect ratio
                                                  marginTop: "20px", // Space above the preview image
                                                  borderRadius: "5px", // Rounded corners
                                                  border: "1px solid #ddd", // Light border
                                                }}
                                              />
                                            </div>
                                          )}
                                        </section>
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-12">
                                      <div className="form-group">
                                        <label className="fieldlabels">Job Title</label>
                                        <input type="text"
                                          name='title'
                                          value={title}
                                          onChange={(e) => setTitle(e.target.value)}
                                          placeholder="Job Title" />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12">
                                      <div className="form-group">
                                        <label className="fieldlabels">Your organization Name *</label>
                                        <input type="text" name="organization"
                                          value={organization}
                                          onChange={(e) => setorganization(e.target.value)}
                                          placeholder="Your organization Name" />
                                      </div>
                                    </div>
                                    {/* <div className="col-12 col-md-12">
                                      <div className="form-group">
                                        <label className="fieldlabels">Website URL </label>
                                        <p className="small">The URL can be your organization's website or an opportunity-related
                                          URL.</p>
                                        <input type="text" name="companyUrl"
                                          onChange={(e) => setcompanyUrl(e.target.value)}
                                          placeholder="Website URL" value={companyUrl} />
                                      </div>
                                    </div> */}
                                    <div className="col-12 col-md-12">
                                      <div className="form-group">
                                        <label className="fieldlabels">skills to be Accessed </label>
                                        <p className="small">List required skills to attract participants with matching abilities or
                                          engage eager individuals seeking to improve these skills.</p>
                                        <TagsInput value={skills} name="skills"
                                          onChange={(skill) => setskills(skill)}
                                          inputProps={{ placeholder: 'Type your skills and press Enter.' }} />
                                        <ul id="tagList">
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <select name='expertise'
                                          onChange={(e) => setexpertise(e.target.value)}>
                                          <option>Select expertise</option>
                                          <option value="Beginner">Beginner</option>
                                          <option value="Intermediate">Intermediate</option>
                                          <option value="Advanced">Advanced</option>
                                          <option value="Expert">Expert</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-12">
                                      <div className="form-group">
                                        <div className="d-flex gap-2">
                                          <div className="w-100">
                                            <label className="fieldlabels">Application Start date</label>
                                            <input
                                              type="date"
                                              name="startDate"
                                              value={startDate}
                                              onChange={handleStartDateChange}
                                              min={today}
                                              placeholder="start date" />
                                          </div>
                                          <div className="w-100">
                                            <label className="fieldlabels">End date</label>
                                            <input
                                              type="date"
                                              name="endDate"
                                              value={endDate}
                                              onChange={handleEndDateChange}
                                              min={startDate || today}
                                              placeholder="end date"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label className="fieldlabels">Employment type</label>
                                        <select className="form-controls" name='employmentType'
                                          onChange={(e) => setemploymentType(e.target.value)}>
                                          <option>Select</option>
                                          <option value="Full time">Full time </option>
                                          <option value="Part-time">Part-time</option>
                                          <option value='Freelance'>Freelance</option>
                                          <option value="Trainee">Trainee </option>
                                        </select>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label className="fieldlabels">Number of Job position *</label>
                                        <input
                                          type="number"
                                          name="postion_no"
                                          value={postion_no}
                                          onChange={(e) => setPostion_no(e.target.value)}
                                          min={startDate || today}
                                          placeholder="Number of Job position"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label className="fieldlabels">location*</label>
                                        <input type="text"
                                          value={location}
                                          onChange={(e) => setlocation(e.target.value)}
                                          name='location'
                                          placeholder="London, united kingdom"
                                          className="form-control" />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12">
                                      <div className="form-group">
                                        <label className="fieldlabels">Salary Range/Year</label>
                                        <div className="d-flex gap-2">
                                          <div>
                                            <select
                                              style={{ width: "100px" }}
                                              name="currencyType"
                                              onChange={(e) => setcurrencyType(e.target.value)}
                                              defaultValue="USD" // USD is selected by default
                                            >
                                              <option value="USD">USD - US Dollar</option> {/* Default selected option */}
                                              <option value="EUR">EUR - Euro</option>
                                              <option value="JPY">JPY - Japanese Yen</option>
                                              <option value="GBP">GBP - British Pound Sterling</option>
                                              <option value="AUD">AUD - Australian Dollar</option>
                                              <option value="CAD">CAD - Canadian Dollar</option>
                                              <option value="CHF">CHF - Swiss Franc</option>
                                              <option value="CNY">CNY - Chinese Yuan</option>
                                              <option value="SEK">SEK - Swedish Krona</option>
                                              <option value="NZD">NZD - New Zealand Dollar</option>
                                              <option value="MXN">MXN - Mexican Peso</option>
                                              <option value="SGD">SGD - Singapore Dollar</option>
                                              <option value="HKD">HKD - Hong Kong Dollar</option>
                                              <option value="NOK">NOK - Norwegian Krone</option>
                                              <option value="KRW">KRW - South Korean Won</option>
                                              <option value="TRY">TRY - Turkish Lira</option>
                                              <option value="RUB">RUB - Russian Ruble</option>
                                              <option value="INR">INR - Indian Rupee</option>
                                              <option value="BRL">BRL - Brazilian Real</option>
                                              <option value="ZAR">ZAR - South African Rand</option>
                                              <option value="AED">AED - United Arab Emirates Dirham</option>
                                              <option value="SAR">SAR - Saudi Riyal</option>
                                              <option value="PLN">PLN - Polish Zloty</option>
                                              <option value="THB">THB - Thai Baht</option>
                                              <option value="IDR">IDR - Indonesian Rupiah</option>
                                              <option value="CZK">CZK - Czech Koruna</option>
                                              <option value="MYR">MYR - Malaysian Ringgit</option>
                                              <option value="PHP">PHP - Philippine Peso</option>
                                              <option value="HUF">HUF - Hungarian Forint</option>
                                              <option value="ILS">ILS - Israeli New Shekel</option>
                                              <option value="DKK">DKK - Danish Krone</option>
                                              <option value="RON">RON - Romanian Leu</option>
                                              <option value="CLP">CLP - Chilean Peso</option>
                                              <option value="PKR">PKR - Pakistani Rupee</option>
                                              <option value="EGP">EGP - Egyptian Pound</option>
                                              <option value="BDT">BDT - Bangladeshi Taka</option>
                                              <option value="VND">VND - Vietnamese Dong</option>
                                              <option value="LKR">LKR - Sri Lankan Rupee</option>
                                              <option value="NGN">NGN - Nigerian Naira</option>
                                              <option value="KES">KES - Kenyan Shilling</option>
                                              <option value="COP">COP - Colombian Peso</option>
                                              <option value="PEN">PEN - Peruvian Sol</option>
                                              <option value="ARS">ARS - Argentine Peso</option>
                                              <option value="MAD">MAD - Moroccan Dirham</option>
                                              <option value="KWD">KWD - Kuwaiti Dinar</option>
                                            </select>


                                          </div>
                                          <input type="number" placeholder="Salary Range/Year"
                                            className="form-control" name="salaryPackage"
                                            value={salaryPackage}
                                            onChange={(e) => setsalaryPackage(e.target.value)} />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12">
                                      <div className="form-group">
                                        <label className="fieldlabels">About Opportunity *</label>
                                        <ReactQuill className="aboutPageEditorContainer"
                                          value={aboutJob}
                                          onChange={(contant) => setaboutJob(contant)} />
                                      </div>
                                    </div>

                                  </div>
                                </div>

                              </ul>
                            </div>

                            <div className="sf-steps-navigation d-flex justify-content-between">
                              {ErrorMessgae && <div className='text-danger'>{ErrorMessgae}</div>}
                              {!showPaymentButton ? (
                                <button
                                  className='btn btn-light col-md-12'
                                  onClick={handlePostJob}
                                >
                                  Post Job
                                </button>
                              ) : (
                                <>
                                  {
                                    amount == "0" ? (
                                      <>
                                      <button
                                  className='btn default-btn col-md-12'
                                  onClick={HandleRecuriterJobsPosts}
                                >
                                  Post Job
                                </button>
                                      </>
                                    ) : (
                                      <>
                                        <RazorpayPaymentButton
                                          Price={TotalAmount}
                                          onPaymentSuccess={handlePaymentSuccess}
                                          buttonContent="Post Job"
                                        />
                                      </>
                                    )
                                  }
                                </>

                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <br />

            <Recruiter_Footer />
          </>
        )
      }

    </div>
  )
}

export default Recruiter_JobPosts