import React, { useState, useEffect } from 'react'
import CommonImageTransport from '../../../common/images/CommonImages'
import { Link } from 'react-router-dom'
import Recruiter_Header from '../recruiter_header/Recruiter_Header';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CreateRecuriterTemplate, DeleteRecuriterTemplate, GetRecuriterTemplateList } from '../../../../api/Recuriter';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Loader from '../../../../loader/Loader';
import Recruiter_Footer from '../recruiter_footer/Recruiter_Footer';

const Interview_Guide = () => {
    const [show, setShow] = useState(false);
    const [is_Loading, setIs_Loading] = useState(false)
    const [showInterviewGuide, setShowInterviewGuide] = useState(false);
    const [title, setTitle] = useState("");
    const [long_description, setLongDescription] = useState("");
    const [guide_image, setInterviewGuideImage] = useState("");
    const [guide_pdf, setInterviewGuidePdf] = useState("");
    const [TemplateList, setTemplateList] = useState([])
    const [TemplateListId, setTemplateListId] = useState("")
    const recruiter_id = localStorage.getItem("User_id");

    // <------- User Authentication token's --------------->
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setInterviewGuideImage(file);
    };

    const handlePdfChange = (e) => {
        const file = e.target.files[0];
        setInterviewGuidePdf(file);
    };

    // <----------- Create the Teamplates ------------>
    const HandleCreateTemplate = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("title", title)
        formData.append("long_description", long_description)
        formData.append("guide_image", guide_image)
        formData.append("guide_pdf", guide_pdf)

        const response = await CreateRecuriterTemplate(formData, headers)
        console.log("HandleCreateTemplate", response)
        if (response?.data?.statusCode == "200") {
            toast.success("Successfully Create the Template !!");
            setTitle("")
            setLongDescription("")
            setInterviewGuidePdf("")
            setInterviewGuideImage("")
            GetTemplateList();
            setTimeout(() => {
                setShowInterviewGuide(false)
            }, 1000)
        }
    }

    // <----------- get the Teamplate List here ------------->
    const GetTemplateList = async () => {
        setIs_Loading(true)
        const GetRecuriterTemplateListdata = {
            recruiter_id
        }
        try {
            const response = await GetRecuriterTemplateList(GetRecuriterTemplateListdata, headers);
            setIs_Loading(false)
            console.log("GetTemplateList", response)
            setTemplateList(response?.data?.data)

        } catch (error) {
            setIs_Loading(false)
        }
    }

    // <----------- Handle Delete Template Id --------------->
    const HandleDeleteTemplateId = (TemplateList_id) => {
        setTemplateListId(TemplateList_id)
    }

    // <------- delete the Teamplate here ----------->
    const DeleteTeamplate = async () => {
        const DeleteRecuriterTemplatedata = {
            id: TemplateListId
        }
        const response = await DeleteRecuriterTemplate(DeleteRecuriterTemplatedata, headers)
        if (response.data.statusCode == "200") {
            GetTemplateList();
            setShow(false)
        }
    }

    // <-------- render the Teamplate list ----------->
    useEffect(() => {
        GetTemplateList();
    }, [])

    return (
        <div>
            {
                is_Loading ? (
                    <>
                        <Loader />
                    </>

                ) : (
                    <>
                        <div className="bg_nav-slid bg-white">
                            <Recruiter_Header />
                        </div>
                        <div className="header_space"></div>
                        <Button variant="primary" className='mt-5 default-btn two' style={{ marginLeft: "15%" }} onClick={() => setShowInterviewGuide(true)}>
                            Create Template
                        </Button>
                        <div className="blog-area pt-5 pb-50">
                            <div className="container">
                                <div className="row">
                                    <ToastContainer style={{ marginTop: "120px" }} />
                                    <div className="col-md-12 mx-auto">
                                        <div className="text-center product-topper mb-45">
                                            <h2>Interview Guide Template</h2>
                                            <p>Discover effective interview tips and tricks that will enhance your <br />chances of acing
                                                the job
                                                interview.</p>
                                        </div>
                                    </div>
                                    {
                                        TemplateList?.map((TemplateListResult) => {
                                            return (
                                                <>
                                                    <div className="col-lg-4 col-md-6">
                                                        <div className="courses-item">
                                                            <Link to={`/recruiter_pages/${TemplateListResult?.id}`} onClick={()=>localStorage.setItem("Interview_Guilde_Title",TemplateListResult?.title)}>
                                                                <img src={TemplateListResult?.guide_image} alt="Courses" style={{ width: "100%",height:"250px" }} />
                                                            </Link>
                                                            <div className="content">
                                                                <div className='d-flex justify-content-between'>
                                                                    <Link to={`/recruiter_pages/${TemplateListResult?.id}`} className="tag-btn">{TemplateListResult?.title}</Link>
                                                                    <button className="btn btn-danger" onClick={() => {
                                                                        HandleDeleteTemplateId(TemplateListResult?.id)
                                                                        setShow(true)
                                                                    }}>Delete</button>
                                                                </div>
                                                                <h3><Link to={`/recruiter_pages/${TemplateListResult?.id}`}>{TemplateListResult?.long_description}</Link></h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>

                        {/* <-------- This is My Interview Guide Modal sections here ------------> */}
                        <Modal size='lg' show={showInterviewGuide} onHide={() => setShowInterviewGuide(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Create Template</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form onSubmit={HandleCreateTemplate}>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <strong><label>Title</label></strong>
                                                <input
                                                    className='form-control'
                                                    name='title'
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    type='text'
                                                    placeholder='Title here...'
                                                    required
                                                />
                                            </div>
                                            <div className='col-md-6'>
                                                <strong><label>Description</label></strong>
                                                <input
                                                    className='form-control'
                                                    name='long_description'
                                                    value={long_description}
                                                    onChange={(e) => setLongDescription(e.target.value)}
                                                    type='text'
                                                    placeholder='Description here...'
                                                    required
                                                />
                                            </div>
                                            <div className='col-md-12'>
                                                <strong><label>Images</label></strong>
                                                <input
                                                    className='form-control'
                                                    name='guide_image'
                                                    onChange={handleImageChange}
                                                    type='file'
                                                    placeholder='Title here...'
                                                    required
                                                />
                                            </div>
                                            <div className='col-md-12'>
                                                <strong><label>PDF</label></strong>
                                                <input
                                                    className='form-control'
                                                    name='guide_pdf'
                                                    onChange={handlePdfChange}
                                                    type='file'
                                                    placeholder='Title here...'
                                                    required
                                                />
                                            </div>
                                            <div className='col-md-12 text-center'>
                                                <button className='mt-5 btn default-btn col-md-12' type='submit'>Create Template</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>

                        {/* <------- Accept Modal here ---------> */}
                        <Modal show={show} onHide={() => setShow(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Template</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are You Sure !!!</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShow(false)}>
                                    Close
                                </Button>
                                <Button variant="danger" onClick={() => DeleteTeamplate()}>
                                    Delete
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                )
            }

        <Recruiter_Footer/>
        </div>
    )
}

export default Interview_Guide