import React, { useState,useEffect } from 'react'
import { StudentSupport, StudentSupportList } from '../../../../api/Student'
import { ProfileSections } from '../../../../api/Auth'
import Recruiter_Header from "../recruiter_header/Recruiter_Header";
import Table from 'react-bootstrap/Table';
import Recruiter_Footer from '../recruiter_footer/Recruiter_Footer';
import { BiSupport } from "react-icons/bi";
const Recuriter_Support = () => {
    const [userProfile,setuserProfile] = useState([])
    const [Query, setQuery] = useState("")
    const [SupportList, setSupportList] = useState([]);
    const user_id = localStorage.getItem("User_id");
    // <------- User Authentication token's ------------>
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };

    const GetProfileSection = async () => {
        const data = {
            user_id: user_id
        }
        try {
            const response = await ProfileSections(data, headers)
            setuserProfile(response?.data.data?.profile)
        } catch (error) {
            console.log("error",error)
        }
    }
    console.log("GetProfileSection",userProfile)

    const HandleSupportSubmit = async (e) => {
        e.preventDefault();
        const data = {
            user_type: "interviewer",              //'interviewer, trainer, student'
            user_query: Query
        }
        try {
            const response = await StudentSupport(data, headers)
            console.log("HandleSupportSubmit", response)
            alert("Succesfully Post the Query")
            setQuery("")
        }
        catch (error) {
            console.log("error", error)
        }
    }

    const GetRecuriterSupportList = async () => {
        try {
            const response = await StudentSupportList(headers)
            setSupportList(response?.data?.data)
        } catch (error) {
            console.log("error", error)
        }
    }
    console.log("GetRecuriterSupportList", SupportList)
    useEffect(() => {
        GetProfileSection();
        GetRecuriterSupportList();
    }, [])
    
    return (
        <>
            <div className="bg_nav-slid bg-white">
                <Recruiter_Header />
            </div>
            <div className='support_sec py-3'>
            <div className='container'>
                <div className='col-md-8 mx-auto shadow-lg'>
                    <div className='supportFormcontainer p-md-4'>
                    <div className='section-title text-center'>
                        <h3> <BiSupport /> Support</h3>
                    </div>

                    <form className='form' onSubmit={HandleSupportSubmit}>
                        <div className='row'>
                        <div className='col-md-6 mt-4'>
                            <label htmlFor="">First Name</label>
                            <div className="form-floating form-group">
                                <input type="text" value={userProfile?.fname} className="form-control"
                                    id="floatingInput" placeholder=""
                                    name="First_name"
                                />
                            </div>
                        </div>
                        <div className='col-md-6 mt-4'>
                            <label htmlFor="floatingInput">Last Name</label>
                            <div className="form-floating form-group">
                                <input type="text" value={userProfile?.lname} className="form-control"
                                    id="floatingInput" placeholder=""
                                    name="Last_name"
                                />
                            </div>
                        </div>
                        <div className='col-md-6 mt-4'>
                            <label htmlFor="floatingInput">Email</label>
                            <div className="form-floating form-group">
                                <input type="text" className="form-control" value={userProfile.email}
                                    id="floatingInput" placeholder=""

                                />
                            </div>
                        </div>
                        <div className='col-md-6 mt-4'>
                            <label htmlFor="floatingInput">Phone</label>
                            <div className="form-floating form-group">
                                <input type="text" className="form-control" value={userProfile?.mobile?.replace(/null/g, '')}
                                    id="floatingInput" placeholder=""
                                />
                            </div>
                        </div>
                        <div className='col-md-12 mt-4'>
                            <div className="form-floating form-group">
                                <textarea rows={5}
                                    cols={60} type="text" value={Query} onChange={(e) => setQuery(e.target.value)} className="textareaStyle" placeholder="Any Query"
                                    name="Query"
                                />
                            </div>
                        </div>
                        <div className='col-md-12 mt-4 mb-3'>
                            <button type='submit' className="default-btn w-100 rounded">
                                Submit
                            </button>
                        </div>
                        </div>
                    </form>


                </div>
                </div>
                {/* <------------- Query List  */}
                {
                    SupportList?.length > 0 && (
                        <div className='mt-5'>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>No of Query</th>
                                        <th>Your Query</th>
                                        <th>Reply</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        SupportList?.map((SupportListResult, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{SupportListResult?.user_query}</td>
                                                        <td>{SupportListResult?.admin_response}</td>

                                                    </tr>
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    )
                }

            </div>
            </div>
            <br/>
            <Recruiter_Footer/>
        </>

    )
}

export default Recuriter_Support