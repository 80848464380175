import React, { useState, useEffect } from 'react'
import { GetRecuriterDetails, RecuriterDetails } from '../../../../api/Recuriter';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const RecruiterStep = () => {
  const navigate = useNavigate();
  const [Opportunity_Title, setOpportunity_Title] = useState("");
  const [Organisation, setOrganisation] = useState("");
  const [Website_URL, setWebsite_URL] = useState("");
  const [Categories, setCategories] = useState("");
  const [Skills, setSkills] = useState("");
  const [About_Opportunity, setAbout_Opportunity] = useState("");
  const [Application_End, setApplication_End] = useState("");
  const [Application_Start, setApplication_Start] = useState("");
  const [Salary_Start, setSalary_Start] = useState("");
  const [Salary_type, setSalary_type] = useState("");
  const [status, setStatus] = useState("")
  const [ActiveTab, setActiveTab] = useState(0);
  const tabs = [0, 1, 2];

  // <---- User Authentication token ------->
  const token = localStorage.getItem("User_Token");
  const headers = {
    "Authorization": `Bearer ${token}`
  };

  // <----------- Handle Previous Pages ------------------>
  const handlePrevious = () => {
    const currentIndex = tabs.indexOf(ActiveTab);
    const previousIndex = (currentIndex - 1 + tabs.length) % tabs.length;
    setActiveTab(tabs[previousIndex]);
  };
  // <----------- Handle Next Pages ------------------>
  const handleNext = () => {
    const currentIndex = tabs.indexOf(ActiveTab);
    const nextIndex = (currentIndex + 1) % tabs.length;
    setActiveTab(tabs[nextIndex]);
  };

  const validateURL = (url) => {
    const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\/?$/;
    return regex.test(url);
  };

  // <----------- Handle Recruiter Salary Range -------------->
  const HandleRecruiterSalaryRange = async (e) => {
    e.preventDefault();
    
    const updaterecruiterInfoData = {
      salary: Salary_Start,
      salary_type: Salary_type,
      application_start_date: Application_Start,
      application_end_date: Application_End,
      opp_title: Opportunity_Title,
      organisation: Organisation,
      website_url: Website_URL,
      categories: Categories,
      skills: Skills,
      about_opportunity: About_Opportunity
    }
    const response = await RecuriterDetails(updaterecruiterInfoData, headers)
    if(response?.data?.statusCode == "200"){
      toast.success("Successfully Update the Recuriter Details !!!")
      setTimeout(()=>{
        navigate("/recruiter_pages/RecruiterDashboard")
      },2000)
    }
    console.log("HandleRecruiterSalaryRange", response)
  }

  // <------ Handle the Active Tabs sections ---------->
  const HandleActiveTab = (index) => {
    setActiveTab(index)
  }

  const today = new Date().toISOString().split('T')[0]; // Define today's date

  // Function to handle start date change
  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
  
    if (selectedStartDate < today) { // Use 'today' instead of 'currentDate'
      // If the selected start date is in the past, set the start date to today
      setApplication_Start(today);
    } else {
      // Otherwise, update the start date
      setApplication_Start(selectedStartDate);
  
      // Reset the end date to the start date if it is earlier than the selected start date
      if (Application_End < selectedStartDate) {
        setApplication_End('');
      }
    }
  };
  
  // Function to handle end date change
  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
  
    if (selectedEndDate < Application_Start) {
      // If the selected end date is earlier than the start date, set it to the start date
      setApplication_End(Application_Start);
    } else {
      // Otherwise, update the end date
      setApplication_End(selectedEndDate);
    }
  };
  


  // <---------- Fetch the User Data ------------>
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetRecuriterDetails({ headers });
      console.log("GetRecuriterDetails", response)
      setOpportunity_Title(response?.data?.data?.opp_title)
      setOrganisation(response?.data?.data?.website_url)
      setCategories(response?.data?.data?.categories)
      setSkills(response?.data?.data?.skills)
      setAbout_Opportunity(response?.data?.data?.about_opportunity)
      setApplication_End(response?.data?.data?.application_end_date)
      setApplication_Start(response?.data?.data?.application_start_date)
      setSalary_Start(response?.data?.data?.salary)
      setSalary_type(response?.data?.data?.salary_type)
      setStatus(response?.data?.data?.status)
    };
    fetchData();
  }, []);
  console.log("status", status)

  // <--------- Render the Recuriter DashBoard Page When Recuriter Step form Completed ------------>
  useEffect(() => {
    if (status === true) {
      navigate("/recruiter_pages/RecruiterDashboard")
    }
  }, [status, navigate]);

  return (
    <div>
      <div className="stepbg">
      <ToastContainer style={{ marginTop: "120px" }} />
        <div className="client_filter-job pt-1 pb-0">
          <div className="stepsForm">
            <form method="post" id="msform">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="sf-steps">
                      <h5 className="default-btn w-100 text-white m-0 py-3 px-4">Recruiter</h5>
                      <div className="left-slid-filter space-box-two shadow-none">
                        <div className="sf-steps-content">
                          <div
                            className={`joblisttitle ${ActiveTab === 0 ? "sf-active" : ""}`} onClick={() => HandleActiveTab(0)}>
                            <span>1</span>
                            <aside className="list-icons">
                              <h5>Basic Details</h5>
                            </aside>
                          </div>
                          <div className={`joblisttitle ${ActiveTab === 1 ? "sf-active" : ""}`} onClick={() => HandleActiveTab(1)}>
                            <span>2</span>
                            <aside className="list-icons">
                              <h5>Application Details</h5>
                            </aside>
                          </div>
                          <div className={`joblisttitle ${ActiveTab === 2 ? "sf-active" : ""}`} onClick={() => HandleActiveTab(2)}>
                            <span>3</span>
                            <aside className="list-icons">
                              <h5>Salary Range/Year</h5>
                            </aside>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 d-flex">
                    <div className="flexdr position-relative">
                      <div className="sf-steps-form sf-radius">
                        {
                          ActiveTab === 0 && (
                            <ul className="sf-content m-0">
                              <div className="find-com-box p-0">
                                <div className="professional-sp">
                                  <h4>Basic Details</h4>
                                  <h6>Fill all form field to go to next step</h6>
                                </div>
                                <hr />
                                <div className="professional-sp row">
                                  <div className="col-12 col-md-12">
                                    <div className="form-group">
                                      <label className="fieldlabels">Opportunity Title</label>
                                      <input type="text" name="Opportunity_Title"
                                        value={Opportunity_Title}
                                        onChange={(e) => setOpportunity_Title(e.target.value)}
                                        placeholder="Title" />
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-12">
                                    <div className="form-group">
                                      <label className="fieldlabels">Enter Your Organisation *</label>
                                      <input type="text" name="Organisation"
                                        value={Organisation}
                                        onChange={(e) => setOrganisation(e.target.value)}
                                        placeholder="Your Organisation" />
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-12">
                                    <div className="form-group">
                                      <label className="fieldlabels">Website URL </label>
                                      <span className="small">The URL can be your organization's website or an opportunity-related
                                        URL.</span>
                                      <input type="text" name="Website_URL" placeholder="Website URL..."
                                        onChange={(e) => setWebsite_URL(e.target.value)}
                                        value={Website_URL} />
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-12">
                                    <div className="form-group">
                                      <label className="fieldlabels">Categories</label>
                                      <select className="form-control" name='Categories'
                                        onChange={(e) => setCategories(e.target.value)}>
                                        <option value="" readonly>Select Categories</option>
                                        <option value="Business Plan">Business Plan</option>
                                        <option value="Campus" className='Camps'>Campus</option>
                                        <option value="Case Study" className='Case Study'>Case Study</option>
                                        <option value="Coding Challenge" className='Coding Challenge'>Coding Challenge</option>
                                        <option value="College Festiva" className='College Festiva'>College Festiva</option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-12">
                                    <div className="form-group">
                                      <label className="fieldlabels">Skills to be Accessed</label>
                                      <input type="text" id="newTag" value={Skills}
                                        name='Skills'
                                        onChange={(e) => setSkills(e.target.value)} />
                                      <ul id="tagList">
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-12">
                                    <div className="form-group">
                                      <label className="fieldlabels">About Opportunity *</label>
                                      <textarea id="text_area" maxlength="1500" name="About_Opportunity"
                                        value={About_Opportunity}
                                        onChange={(e) => setAbout_Opportunity(e.target.value)}
                                        className="form-control mb-0" cols="30" rows="7"
                                        required data-error="Write your message"
                                        placeholder=""
                                        style={{ height: "100px" }}
                                      ></textarea>
                                      <span id="count">100 to 1500</span>
                                    </div>
                                  </div>

                                  <div className="sf-steps-navigation d-flex justify-content-between">
                                    <button onClick={handlePrevious} id="sf-prev" type="button" className="btn btn-light">Previous</button>
                                    <span id="sf-msg" className="sf-msg-error"></span>
                                    <button
        onClick={(e) => {
          e.preventDefault();

          // Validate 'About Opportunity' length
          const characterCount = About_Opportunity.trim().length;
          if (characterCount < 100 || characterCount > 1500) {
            toast.error("The 'About Opportunity' section must contain between 100 and 1500 characters.");
            return;
          }

          // Check for empty required fields
          if (!About_Opportunity || !Skills || !Categories || !Website_URL || !Organisation || !Opportunity_Title) {
            toast.error("Please fill in all required fields.");
            return;
          }

          // Validate the Website URL
          if (!validateURL(Website_URL)) {
            toast.error("Please enter a valid Website URL.");
            return;
          }

          // If all validations pass, move to the next step
          handleNext();
        }}
        id="sf-next"
        type="submit"
        className="btn default-btn"
        disabled={!About_Opportunity || !Skills || !Categories || !Website_URL || !Organisation || !Opportunity_Title}
      >
        Next
      </button>
                                  </div>
                                </div>
                              </div>
                            </ul>
                          )
                        }

                        {
                          ActiveTab === 1 && (
                            <ul className="sf-content m-0">
                              <div className="find-com-box p-0">
                                <div className="professional-sp">
                                  <h4>Application Details</h4>
                                </div>
                                <hr />
                                <div className="professional-sp">
        <div className="form-group">
          <label className="fieldlabels">Application Start Date & Time *</label>
          <input
            type="date"
            name="Application_Start"
            value={Application_Start}
            min={today}
            onChange={handleStartDateChange}
            placeholder="date"
          />
        </div>
      </div>
      <div className="col-12 col-md-12">
        <div className="form-group">
          <label className="fieldlabels">Application End Date & Time *</label>
          <input
            type="date"
            name="Application_End"
            value={Application_End}
            min={Application_Start || today} // Set the min attribute to start date or today
            onChange={handleEndDateChange}
            placeholder="date"
            disabled={!Application_Start} // Disable end date input if start date is not selected
          />
        </div>
      </div>

                                <div className="sf-steps-navigation d-flex justify-content-between">
                                  <button onClick={handlePrevious} id="sf-prev" type="button" className="btn btn-light">Previous</button>
                                  <span id="sf-msg" className="sf-msg-error"></span>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (Application_Start && Application_End) {
                                        handleNext();
                                        // HandleRecruiterApplicationDetails(e);
                                      }
                                    }}
                                    id="sf-next"
                                    type="submit"
                                    className="btn default-btn"
                                    disabled={!Application_Start || !Application_End}>
                                    Next
                                  </button>
                                </div>

                              </div>
                            </ul>
                          )
                        }

                        {
                          ActiveTab === 2 && (
                            <ul className="sf-content m-0">
                              <div className="find-com-box p-0">
                                <div className="professional-sp">
                                  <h4>Salary Range/Year </h4>
                                </div>
                                <hr />
                                <div className="professional-sp row">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <div className="d-flex gap-2">
                                        <div>
                                          <select name="Salary_type" onChange={(e) => setSalary_type(e.target.value)} style={{ width: "100px" }} >
                                            <option value="">Select salary Type</option>
                                            <option value="INR">INR</option>
                                            <option value="USA">USA</option>
                                          </select>
                                        </div>
                                        <input type="number" placeholder="Enter salary"
                                          className="form-control"
                                          name="Salary_Start" value={Salary_Start}
                                          onChange={(e) => setSalary_Start(e.target.value)} />
                                      </div>
                                    </div>

                                  </div>
                                  <div className="sf-steps-navigation d-flex justify-content-between">
                                    <button onClick={handlePrevious} id="sf-prev" type="button" className="btn btn-light">Previous</button>
                                    <span id="sf-msg" className="sf-msg-error"></span>
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (Salary_Start && Salary_type) {
                                          HandleRecruiterSalaryRange(e);
                                        }
                                      }}
                                      id="sf-next"
                                      type="submit"
                                      className="btn default-btn"
                                      disabled={!Salary_type || !Salary_Start}>
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </ul>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecruiterStep