import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { GetSetings } from "../../../api/Public";
import { GetSetings } from "../../../../api/Public";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Student_Footer = () => {
  const navigate = useNavigate();
  const [SettingList, setSettingList] = useState({});

  const HandleGetSettingList = async () => {
    const response = await GetSetings();
    setSettingList(response?.data?.data);
  };

//   const AllMentorList = (e) => {
//     e.preventDefault();
//     const role_id = localStorage.getItem("role_id");
//     if (role_id === "2") {
//       navigate("/Student/Student_Mentor_List");
//     } else if (role_id === "3" || role_id === "4") {
//       navigate("/Signin");
//       setTimeout(() => {
//         toast.error("You are not Student You cannot Access This !!!");
//       }, 2000);
//     } else {
//       navigate("/Signin");
//       setTimeout(() => {
//         toast.error("You are not Sign Here !!!");
//       }, 2000);
//     }
//   };

  //   <-------- Instructor registration ----------->
//   const InstructorRegistration = () => {
//     const Is_LoggedIn = localStorage.getItem("Is_LoggedIn");
//     if (!Is_LoggedIn) {
//       navigate("/Mentor_SignUp");
//     } else {
//       toast.error(
//         "Please log out before proceeding with Instructor Registration."
//       );
//     }
//   };

  useEffect(() => {
    HandleGetSettingList();
  }, []);
  console.log("HandleGetSettingList", SettingList);

  return (
    <footer class="footer-area">
      <div class="container pt-5">
        <div class="row">
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget">
              <p>{SettingList?.shortdesc}</p>
              <ul class="social-link">
                <li class="social-title">Follow Us:</li>
                <li>
                  <Link to={SettingList?.facebook} target="_blank">
                    <i class="ri-facebook-fill"></i>
                  </Link>
                </li>
                <li>
                  <Link to={SettingList?.twitter} target="_blank">
                    <i class="ri-twitter-fill"></i>
                  </Link>
                </li>
                <li>
                  <Link to={SettingList?.instagram} target="_blank">
                    <i class="ri-instagram-line"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget ps-5">
              <h3>About us</h3>
              <ul class="footer-list">
                {/* <li>
                            <a href="#">
                                About Us
                            </a>
                        </li> */}
                <li>
                  <Link to="/Student/Student_dashBoard">Dashboard</Link>
                </li>

                <li>
                  <Link to="/Student/Student_Resume">Resume</Link>
                </li>
                <li>
                  <Link to="/Student/Student_Traning_Program">Training Program</Link>
                </li>
             

              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget ps-5">
              <h3>Resources</h3>
              <ul class="footer-list">
                <li style={{ zIndex: 99 }}>
                  <Link to="/Student/Student_jobs">Jobs</Link>
                </li>
                <li>
                  <Link to="/Student/Student_Mentor_List">Mentors</Link>
                </li>
                <li>
                  <Link to="/Student/Student_Support">Support</Link>
                </li>
                
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget ps-5">
              <h3>Official Info</h3>
              <ul class="footer-contact">
                <li>
                  <i class="ri-map-pin-2-fill"></i>
                  <div class="content">
                    <h4>Location:</h4>
                    <span>{SettingList?.officeaddress}</span>
                  </div>
                </li>
                <li>
                  <i class="ri-mail-fill"></i>
                  <div class="content">
                    <h4>Email:</h4>
                    <span>
                      <Link
                        to={`mailto:${SettingList?.email}?subject=Application%20for%20Job&body=I%20am%20interested%20in%20applying%20for%20a%20job.`}

                      >
                        <span className="__cf_email__">
                          {SettingList?.email}
                        </span>
                      </Link>
                    </span>
                  </div>
                </li>
                <li>
                  <i class="ri-phone-fill"></i>
                  <div class="content">
                    <h4>Phone:</h4>
                    <span>
                      <a href="tel:+11234567890">
                        {SettingList?.countrycode} {SettingList?.mobileno}
                      </a>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright-area">
        <div class="container">
          <div class="copy-right-text text-center">
            <p>
              Copyright @
              <script>document.write(new Date().getFullYear())</script>{" "}
              <b>FreshGard</b> All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Student_Footer;
