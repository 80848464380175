import axios from "axios";
import { ADMIN_API_BASE_URL, API_BASE_URL } from "../base_url/Base_URL";

export const GetSetings = async () => {
    try {
        const response = await axios.get(`${ADMIN_API_BASE_URL}/get-setting`)
        return response;
    } catch (error) {
        return error;
    }
}

export const GetPublicJob = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get-home-jobs`)
        return response;
    } catch (error) {
        return error;
    }
}

export const UserContactForm = async (data) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/post-contactus`,data)
        return response;
    } catch (error) {
        return error;
    }
}

// <-------------- For the Student FAQ's ------------>
export const GetStudentFCQustion = async (data) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/get-home-faqs`,data)
        return response;
    } catch (error) {
        return error;
    }
}

// <-------------- For the Mentor FAQ's ------------>
export const GetMentorFCQustion = async (data) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/get-home-faqs`,data)
        return response;
    } catch (error) {
        return error;
    }
}

// <-------------- For the Mentor FAQ's ------------>
export const GetRecuriterFCQustion = async (data) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/get-home-faqs`,data)
        return response;
    } catch (error) {
        return error;
    }
}
