import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MentorProfileUpdate,
  ProfileSections,
  ProfileUpdate,
} from "../../../../api/Auth";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import "react-tagsinput/react-tagsinput.css";
import "react-phone-number-input/style.css";
import PhoneInput, {
  getCountryCallingCode,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import moment from "moment";
// import Mentor_Common_Profile_Section from "../mentor_common_profile_section/Mentor_Common_Profile_Section";
import "react-tagsinput/react-tagsinput.css";
// import Mentor_Header from "../mentor_header/Mentor_Header";
import Loader from "../../../../loader/Loader";
import {
  GetCountries,
  GetState,
  GetCity,
  GetLanguages, //async functions
} from "react-country-state-city";
import Student_Header from "../student_header/Student_Header";
import Student_Common_Profile_section from "../student_common_profile_section/Student_Common_Profile_section";


const Student_Edit_Profile = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [defaultCountryCode, setDefaultCountryCode] = useState("");
  const [defaultState, setDefaultState] = useState("");
  const [defaultCity, setDefaultCity] = useState("");
  const [dob, setDob] = useState("");
  const [selectedCountryNameObj, setSelectedCountryObj] = useState({});
  const [selectedStateObj, setSelectedStateObj] = useState({ name: '' });
const [selectedCityObj, setSelectedCityObj] = useState({ name: '' });
  const [Countries, setCountries] = useState(0);
  const [LanguageList, setLanguageList] = useState(0);
  const [countryid, setCountryid] = useState(null);
  const [stateid, setStateid] = useState(null); // Fix: Define setStateid correctly
  const [cityid, setCityid] = useState(null);
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [profileImg, setProfileImg] = useState("");
  const [value, setValue] = useState(mobile);
  const [CountryNewName, setCountryNewName] = useState();
  // <---------- Change the DOB Formatted here -------------->
  const [formattedDob, setFormattedDob] = useState("");

  // For previewing the image
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  //   <------------ Show the Country , state , city -------------->
  const [CountryInput, setCountryInput] = useState(true);
  const [StateInput, setStateInput] = useState(true);
  const [CityInput, setCityInput] = useState(true);

  // <---------- Valiadation for the Parameters ------------>
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [isCountryChanged, setIsCountryChanged] = useState(false); // Track if the country is changed
  const [isStateChanged, setIsStateChanged] = useState(false); // Track if the state is changed
  const [isCityChanged, setIsCityChanged] = useState(false); // Track if the city is changed

  useEffect(() => {
    let [firstIndex, ...remaining] = formatPhoneNumberIntl(value).split(" ");
    let joinedRemaining = remaining.join("");
    setCountryCode(firstIndex);
    setPhoneNumber(joinedRemaining);
  }, [value]);

  // useEffect(() => {
  //   console.log("selectedCountryNameObj", country, selectedCountryNameObj);
  // }, [selectedCountryNameObj])

  useEffect(() => {
    GetCountries().then((result) => {
      setCountries(result);
      const filteredData =
        country &&
        result?.filter((data) => {
          return data.iso2 == country;
        });
      setSelectedCountryObj(filteredData[0]);
      filteredData[0] &&
        GetState(filteredData[0].id).then((result) => {
          const filteredState =
            state &&
            result?.filter((data) => {
              return data.name == state;
            });
          filteredState && setSelectedStateObj(filteredState[0]);
          filteredState[0] &&
            GetCity(filteredData[0].id, filteredState[0].id).then((result1) => {
              // setCityList(result);
              const filteredCity =
                city &&
                result1?.filter((data) => {
                  return data.name == city;
                });
              filteredCity && setSelectedCityObj(filteredCity[0]);
            });
        });
    });
    GetLanguages().then((result) => {
      setLanguageList(result);
    });
  }, [country]);

  const fetchCountryStateCity = (countryname, statename, cityname) => {
    GetCountries().then((result) => {
      setCountries(result);
      const filteredData =
        countryname &&
        result?.filter((data) => {
          return data.name == countryname;
        });
      setSelectedCountryObj(filteredData[0]);
      filteredData[0] &&
        GetState(filteredData[0].id).then((result) => {
          const filteredState =
            statename &&
            result?.filter((data) => {
              return data.name == statename;
            });
          filteredState && setSelectedStateObj(filteredState[0]);
          filteredState[0] &&
            GetCity(filteredData[0].id, filteredState[0].id).then((result1) => {
              // setCityList(result);
              const filteredCity =
                cityname &&
                result1?.filter((data) => {
                  return data.name == cityname;
                });
              filteredCity && setSelectedCityObj(filteredCity[0]);
            });
        });
    });
  };

  /*------------------validate name------------*/
  const handleNameChange = (event, setter, setError) => {
    const value = event.target.value;
    setter(value);
    const regex = /^[a-zA-Z\s]+$/;
    if (!regex.test(value)) {
      setError("Name should contain only alphabetical letters");
    } else {
      setError("");
    }
  };

  //   <------- Images Handle ------------>
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setProfileImg(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handlePhoneNumberChange = (value) => {
    if (!value) {
      setMobile(null); // Set to null when the input is cleared
      setMobileError("Phone number is required."); // Set the error for an empty input
      return;
    }

    setMobile(value);

    // Get the current country code from the selected country
    try {
      if (selectedCountryNameObj && selectedCountryNameObj.iso2) {
        const callingCode = getCountryCallingCode(selectedCountryNameObj.iso2);
        setCountryCode(`+${callingCode}`);
      }
    } catch (error) {
      console.error("Invalid country code", error);
    }

    // Validate phone number based on the country code
    if (isValidPhoneNumber(value)) {
      setMobileError(""); // Clear error if valid
    } else {
      setMobileError("Invalid phone number for the selected country.");
    }
  };
  console.log("Mobile+1234", mobile);
  // Function to handle the country change and reset state/city
  const funCountryId = (selectedCountry) => {
    // setMobile(`+${selectedCountry.phone_code}`);
    setDefaultCountryCode(selectedCountry.iso2);
    setCountryid(selectedCountry.id);
    setCountryNewName(selectedCountry.name);

    // Mark that the country has been changed
    setIsCountryChanged(true);

    // Reset state and city when country changes
    setState('');
    setCity('');
  };

  const funStateId = (selectedState) => {
    setDefaultState(selectedState.iso2);
    setStateid(selectedState);

    // Mark that the state has been changed
    setIsStateChanged(true);
  };

  const funCityId = (selectedCity) => {
    setDefaultCity(selectedCity.iso2);
    setCityid(selectedCity);

    // Mark that the city has been changed
    setIsCityChanged(true);
  };

  const user_id = localStorage.getItem("User_id");
  const token = localStorage.getItem("User_Token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // <----------- Get the User Profile --------------->
  const ProfileSections1 = async () => {
    const response = await ProfileSections({ user_id, headers });
    setMobile(
      `${response?.data?.data?.profile?.countrycode}${response?.data?.data?.profile?.mobile}`
    );
    setFname(response?.data?.data?.profile?.fname);
    setLname(response?.data?.data?.profile?.lname);
    setEmail(response?.data?.data?.profile?.email);
    // setMobile(response?.data?.data?.profile?.mobile);
    setCountry(response?.data?.data?.profile?.country);
    setState(response?.data?.data?.profile?.state);
    setCity(response?.data?.data?.profile?.city);
    setDob(response?.data?.data?.student_detail?.dob);
    setCountryCode(response?.data?.data?.profile?.countrycode);
    setImagePreviewUrl(response?.data?.data?.profile?.profileimg);
    fetchCountryStateCity(
      response?.data?.data?.profile?.country,
      response?.data?.data?.profile?.state,
      response?.data?.data?.profile?.city
    );
  };

  // <----------- Edit Profile Sections ------------------>
  const EditProfile = async () => {
    setIsLoading(true);

    // Validate phone number presence and validity
    if (mobileError || !mobile || mobile.length < 6) {
      toast.error(mobileError || "Please enter a valid phone number.");
      setIsLoading(false);
      return;
    }

    // Validate first name and last name
    if (!fname || firstNameError || !lname || lastNameError) {
      toast.error("Name should contain only alphabetical letters.");
      setIsLoading(false);
      return;
    }

    // Check if country, state, or city has been changed
    const countryToSubmit = isCountryChanged ? CountryNewName : selectedCountryNameObj;
    const stateToSubmit = isStateChanged ? state : selectedStateObj?.name;
    const cityToSubmit = isCityChanged ? city : selectedCityObj?.name;

    // Validate country, state, and city
    // if (!countryToSubmit) {
    //   toast.error("Please select a valid country.");
    //   setIsLoading(false);
    //   return;
    // }

    if (!stateToSubmit) {
      toast.error("Please select a valid state.");
      setIsLoading(false);
      return;
    }

    if (!cityToSubmit) {
      toast.error("Please select a valid city.");
      setIsLoading(false);
      return;
    }
    // Proceed with form submission if everything is valid
    const formdata = new FormData();
    formdata.append("countryCode", countryCode);
    formdata.append("dob", dob);
    formdata.append("country", isCountryChanged ? CountryNewName : selectedCountryNameObj?.name);
    formdata.append("state", state);
    formdata.append("city", city);
    formdata.append("mobile", mobile);
    formdata.append("email", email);
    formdata.append("lname", lname);
    formdata.append("fname", fname);
    formdata.append("profileImg", profileImg);

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await ProfileUpdate(formdata, headers);
      if (response?.data?.statusCode === "200") {
        setIsLoading(false);
        toast.success("Profile Updated Successfully!");
        setTimeout(() => navigate("/Student/Student_Profile"), 2000);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to update profile. Please try again.");
    }
  };

  useEffect(() => {
    ProfileSections1();
  }, []);

  // <-------- show the ByDefault Country code here ------------->
  useEffect(() => {
    setValue(defaultCountryCode);
  }, [defaultCountryCode]);

  // <------------ Show the DOB bydeafult Here ---------------->
  useEffect(() => {
    setFormattedDob(formatDateForDisplay(dob));
  }, [dob]);

  // <------------ Show the DOB format Date ---------------->
  const formatDateForDisplay = (dateString) => {
    if (!dateString) return "";
    return moment(dateString, "YYYY-MM-DD").format("YYYY-MM-DD");
  };

  // <---------- Support function ----------------->
  const SupportFunction = () => {
    toast.warn("please raise a supprot case to change your personal information.")
  }
  useEffect(() => {
    const isFirstRender = sessionStorage.getItem('isFirstRender');
  
    if (!isFirstRender) {
      // Set flag in sessionStorage to indicate that the page has been loaded once
      sessionStorage.setItem('isFirstRender', 'true');
  
      // Reload the page once
      window.location.reload();
    }
  }, []);

 
  return (
    <div>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <div className="bg_nav-slid bg-white">
            <Student_Header />
          </div>
          <div className="stepbg">
            <div className="header_space"></div>
            <div className="client_filter-job pt-2 pb-5">
              <div className="stepsForm">
                <form method="post" id="msform">
                  <div className="container">
                    <div className="row">
                      <ToastContainer style={{ marginTop: "100px" }} />
                      <div className="col-md-4">
                        <Student_Common_Profile_section />
                      </div>
                      <div className="col-md-8 d-flex">
                        <div className="flexdr position-relative p-3 p-md-4 shadow-lg">
                          <div className="professional-sp row">
                            <div className="col-12 col-md-12 mb-3 text-center border-bottom pb-3">
                              <div className="form-group">
                                <label className="fieldlabels">
                                  Upload Profile image
                                </label>
                                <div id="profile-upload">
                                  <div className="hvr-profile-img">
                                    <input
                                      type="file"
                                      name="profileImg"
                                      onChange={handleImageUpload}
                                      id="getval"
                                      className="upload w180"
                                      title="Dimensions 180 X 180"
                                    />
                                  </div>
                                  <i className="fa ri-camera-2-fill"></i>

                                  {/* Display the uploaded image preview if available */}
                                  {imagePreviewUrl && (
                                    <div className="image-preview">
                                      <img
                                        src={imagePreviewUrl}
                                        alt="Profile Preview"
                                        style={{
                                          width: "180px",
                                          height: "180px",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label className="fieldlabels">
                                  First Name
                                </label>
                                <input
                                  type="text"
                                  name="fname"
                                  value={fname}
                                  onClick={SupportFunction}
                                  // onChange={(event) =>
                                  //   handleNameChange(
                                  //     event,
                                  //     setFname,
                                  //     setFirstNameError
                                  //   )
                                  // }
                                  placeholder="First Name"
                                />
                                {firstNameError && (
                                  <p style={{ color: "red", fontSize: "14px" }}>
                                    {firstNameError}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label className="fieldlabels">Last Name</label>
                                <input
                                  type="text"
                                  name="uname"
                                  placeholder="Last Name"
                                  value={lname}
                                  // onChange={(event) =>
                                  //   handleNameChange(
                                  //     event,
                                  //     setLname,
                                  //     setLastNameError
                                  //   )
                                  // }
                                  onClick={SupportFunction}
                                />
                                {lastNameError && (
                                  <p style={{ color: "red", fontSize: "14px" }}>
                                    {lastNameError}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-md-12">
                              <div className="form-group">
                                <label className="fieldlabels">
                                  Verify email ID *
                                </label>
                                <input
                                  type="email"
                                  name="uname"
                                  placeholder="Email ID"
                                  value={email}
                                  onClick={SupportFunction}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-12">
                              <div className="form-group mb-3">
                                <label className="fieldlabels">
                                  Verify Phone Number *
                                </label>
                                <div className="combined-input-container">
                                  <div className="phoneinput">
                                    {/* <PhoneInput
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry={defaultCountryCode}
                                  placeholder="Code"
                                  value={countryCode}
                                  onChange={setCountryCode}
                                /> */}
                                    <PhoneInput
                                      international
                                      defaultCountry={selectedCountryNameObj?.iso2}
                                      placeholder="Enter phone number"
                                      value={mobile || ''}  // Use an empty string instead of null for controlled input
                                      onClick={SupportFunction} // Keep if necessary
                                      readOnly
                                    />


                                    {mobileError && (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {mobileError}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`col-12 col-md-12 ${mobileError && `mt-5`
                                }`}
                            >
                              <div className="form-group">
                                <label className="fieldlabels">
                                  Linkedin Profile link{" "}
                                </label>
                                <input
                                  type="text"
                                  name="uname"
                                  placeholder="https://in.linkedin.com/"
                                  value="https://in.linkedin.com/op34w"
                                />
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <h5>Location</h5>
                            </div>
                            {
                              <>
                                {/* <------ Country section's -----------> */}
                                <div className="col-md-4">
                                  <div className="form-floating form-group">
                                    <h6>Country</h6>
                                    <CountrySelect
                                      defaultValue={selectedCountryNameObj} // Pre-populated country from API
                                      name="country"
                                      onChange={(selectedCountry) => {
                                        setCountry(selectedCountry);
                                        funCountryId(selectedCountry);

                                        // Reset state and city when country changes
                                        setSelectedStateObj({ name: '' });
                                        setSelectedCityObj({ name: '' });

                                        // Mark that the country has been changed
                                        setIsCountryChanged(true);

                                        // Update the country code dynamically based on the selected country
                                        const callingCode =
                                          getCountryCallingCode(
                                            selectedCountry.iso2
                                          );
                                        setCountryCode(`+${callingCode}`);
                                      }}
                                      placeHolder="Select Country"
                                    />
                                  </div>
                                </div>

                                {/* <------ State section's -----------> */}
                                <div className="col-md-4">
                                  <h6>State</h6>
                                  <StateSelect
                                    defaultValue={selectedStateObj} // Pre-populated state from API
                                    countryid={countryid}
                                    onChange={(selectedState) => {
                                      funStateId(selectedState.id);
                                      setState(selectedState.name);
                                    }}
                                    placeHolder="Select State"
                                  />
                                </div>

                                {/* <------ City section's -----------> */}
                                <div className="col-md-4">
                                  <h6>City</h6>
                                  <CitySelect
                                    defaultValue={selectedCityObj} // Pre-populated city from API
                                    countryid={countryid}
                                    stateid={stateid}
                                    cityid={cityid}
                                    onChange={(selectedCity) => {
                                      funCityId(selectedCity.id);
                                      setCity(selectedCity.name);
                                    }}
                                    placeHolder="Select City"
                                  />
                                </div>
                              </>
                            }

                             <div className="col-12 col-md-12">
                              <div className="form-group">
                                <label className="fieldlabels">DOB</label>
                                <input
                                  type="date"
                                  name="dob"
                                  placeholder="DOB"
                                  // onChange={(e) => setDob(e.target.value)}
                                  onClick={SupportFunction}
                                  value={formattedDob}
                                />
                              </div>
                            </div> 
                            <hr />
                            <div className="col-12 col-md-12">
                              <div className="form-group">
                                <Link
                                  to="#"
                                  type="button"
                                  onClick={EditProfile}
                                  className="default-btn rounded px-5"
                                >
                                  Submit
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Student_Edit_Profile;
