import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { SendOtp } from "../../api/Auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import CommonImageTransport from "../../components/common/images/CommonImages";
import Header from "../../components/common/header/Header";
import moment from "moment-timezone";
import Modal from "react-bootstrap/Modal";
import {
  parsePhoneNumberFromString,isValidPhoneNumber,} from "libphonenumber-js";
import { PhoneValidLenght } from "../../components/common/phonevalidlength/PhoneValidLenght";
import { PrivacyPolicy, TermsCondtion } from "../../api/Global";
import ReactHtmlParser from "react-html-parser";

const StudentForm = () => {
  const navigate = useNavigate();
  const [ActiveTabs, setAciveTabs] = useState("student");
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState();
  const [defaultCountryCode, setDefaultCountryCode] = useState("US");
  const [firstName, setFirstName] = useState("");
  const [country, setCountry] = useState({
    capital: "Washington",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    emoji: "🇺🇸",
    id: 233,
    iso2: "US",
    iso3: "USA",
    latitude: "38.00000000",
    longitude: "-97.00000000",
    name: "United States",
    native: "United States",
    numeric_code: "840",
    phone_code: 1,
    region: "Americas",
    subregion: "Northern America",
    tld: ".us",
  });
  console.log("country++++", country);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [UniversityMail, setUniversityMail] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [countryCode, setCountryCode] = useState();
  const [error, setError] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [countryid, setCountryid] = useState(country.id);
  const [stateid, setstateid] = useState(0);
  const [cityid, setcityid] = useState(0);

  // <---------- Valiadation for the Parameters ------------>
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");

  // <---------- Show the Popup T&C ------------->
  const [ShowTermsCondition, setShowTermsCondition] = useState(false);

  // <---------- Show the Popup T&C ------------->
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  // <----------- Check the T & C -------------->
  const [isTACChecked, setIsTACChecked] = useState(false);
  
  // <------------ P & P ----------------->
  const [PrivacyPolicyList, setPrivacyPolicyList] = useState({});
  const [TermsConditionList, setTermsConditionList] = useState({});
  
  useEffect(() => {
    if (value && validatePhoneNumber(value)) {
      const parsedPhoneNumber = parsePhoneNumberFromString(
        value,
        defaultCountryCode
      );
      if (parsedPhoneNumber) {
        setCountryCode(parsedPhoneNumber.defaultCountryCode);
        setPhoneNumber(`+${parsedPhoneNumber.countryCallingCode}-${parsedPhoneNumber.nationalNumber}`); 
      }
    }
  }, [value]);

  /*------------------validate name------------*/
  const handleInputChange = (event, setter, setError) => {
    const value = event.target.value;
    setter(value);
    const regex = /^[a-zA-Z\s]+$/;
    if (!regex.test(value)) {
      setError("Name should contain only alphabetical letters");
    } else {
      setError("");
    }
  };

  /*----------------------Validated Email-------------------*/
  const handleChange = (event) => {
    const { value } = event.target;
    setUniversityMail(value);
    validateEmail(value);
  };

  // <--------- Apply The regex for the vaild email Edu  section's ------------->
  const validateEmail = (email) => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[edu]{3}$/;
    if (!regex.test(email)) {
      setIsValidEmail(false);
      setEmailError(
        "It looks like your email is not from a university. Please use a .edu email address."
      );
    } else {
      setIsValidEmail(true);
      setEmailError("");
    }
  };

  // <--------- Apply The regex for the vaild Password section's ------------->
  const ValidatePassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{11,}$/;
    setIsValidPassword(regex.test(password));
  };
  /*----------------------Validated Password -------------------*/
  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    ValidatePassword(value);
  };

  // Phone Number Validation
  const validatePhoneNumber = (phoneNumber) => {
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
    if (!parsedPhoneNumber) {
      setMobileError("Please enter a valid phone number.");
      return false;
    }
    const isValid = isValidPhoneNumber(phoneNumber);
    const countryCode = parsedPhoneNumber.country; 
    

    if (isValid) {
      const phoneNumberLength = parsedPhoneNumber.nationalNumber.length; 
      if (PhoneValidLenght[countryCode] && phoneNumberLength !== PhoneValidLenght[countryCode]) {
        setMobileError(`Please enter a valid ${countryCode} phone number with ${PhoneValidLenght[countryCode]} digits.`);
        return false;
      }
  
      setMobileError("");  
      return true;
    } else {
      setMobileError(`Please enter a valid phone number.`);
      return false;
    }
  };
  
  

  /*-------------------Validate form------------------------*/
  const validateForm = () => {
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      UniversityMail.trim() === "" ||
      password.trim() === "" ||
      confirmPassword.trim() === "" ||
      !value
    ) {
      setError("Please enter all fields");
      return false;
    }
    setError("");
    return true;
  };

  // <---------- Checked the Terms & Condition's ------------>
  const handleCheckboxChange = () => {
    setIsTACChecked(!isTACChecked);
  };

  const funCountryId = (e) => {
    setDefaultCountryCode(e.iso2);
    setCountryid(e.id);
    console.log("funCountryId", e.iso2);
    console.log("Country Name:1234", e.name);
  };

  const funStateId = (e) => {
    setstateid(e);
  };

  const funCityId = (e) => {
    setcityid(e);
  };

  // <--------- Send Otp for Student SignUp --------------->
  const SendOtpStudentSignUp = async () => {
    setIsLoading(true);
    try {
      if (!validateForm()) {
        setIsLoading(false);
        return;
      } else if (password !== confirmPassword) {
        toast.error("Password Do Not Match !!!");
        setIsLoading(false);
        return;
      }
      if (country && state && city) {
        console.log("Form submitted:", { country, state, city });
      } else {
        toast.error("Please select country, state, and city.");
        setIsLoading(false);
        return;
      }
      if (password.length < 10) {
        toast.error("Password must be have 10 digits !!!");
        setIsLoading(false);
        return;
      }
      if (confirmPassword.length < 10) {
        toast.error("Confirm Password must be have 10 digits !!!");
        setIsLoading(false);
        return;
      }
      if (!isValidEmail) {
        toast.error("Please enter a valid univeristy email id (.edu)");
        setIsLoading(false);
        return;
      }
      if (!firstName || firstNameError || !lastName || lastNameError) {
        toast.error("Name should contain only alphabetical letters");
        setIsLoading(false);
        return;
      }

      if (!isValidPassword) {
        toast.error(
          "Password must have at least 11 characters, including uppercase, lowercase, number, and special character."
        );
        setIsLoading(false);
        return;
      }
      if (!validatePhoneNumber(value)) {
        toast.error("Please enter a valid phone number.");
        setIsLoading(false);
        return;
      }

      if (!isTACChecked) {
        toast.error("Please accept the Terms & Conditions and Privacy Policy.");
        setIsLoading(false);
        return;
      }
      const userData = {
        type: "signup",
        email: UniversityMail,
      };

      const StudentData = {
        role_id: 2,
        fname: firstName,
        lname: lastName,
        email: UniversityMail,
        password,
        countryCode: countryCode,
        mobile: phoneNumber,
        // dob: "2024-04-02",
        citizen: country.iso2,
        city: city,
        state: state,
        country: country.name,
        tcCheck: 1,
        time_zone: timeZoneAbbr,
      };

      const response = await SendOtp(userData);
      if (response.data.statusCode === "200") {
        toast.success("Otp sent on your email, please check");
        localStorage.setItem(
          "Student_Register_Email",
          response.data.data.email
        );
        localStorage.setItem(
          "Student_Register_Data",
          JSON.stringify(StudentData)
        );
        setTimeout(() => {
          navigate("/StudentSignUpOtpVerify");
          setIsLoading(false);
        }, 2000);
      } else if (response.data.statusCode == "400") {
        setIsLoading(false);
        setTimeout(() => {
          setIsLoading(false);
          toast.warn(response?.data?.msg);
          window.location.reload(); // Reload the page after 2 seconds
      }, 1000);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error:", error);
    }
  };

  const getRecruiter = localStorage.getItem("HandleRecruiter");
  const getMentorship = localStorage.getItem("HandleMentorship");

  const Student = getRecruiter && getMentorship;
  useEffect(() => {
    document.title = "::Signup::";
  }, []);

  const RemoveLocalStorage = () => {
    localStorage.removeItem("HandleMentorship");
    localStorage.removeItem("HandleRecruiter");
  };

  const StudentSignUpPage = () => {
    navigate("/Student_SignUp");
    setAciveTabs("student");
    localStorage.setItem("ActiveTabs", "student");
  };

  const RecuriterSignUpPage = () => {
    navigate("/Recruiter_SignUp");
    setAciveTabs("Recruiter");
    localStorage.setItem("ActiveTabs", "Recruiter");
  };

  const MentorSignUpPage = () => {
    navigate("/Mentor_SignUp");
    setAciveTabs("Mentor");
    localStorage.setItem("ActiveTabs", "Mentor");
  };
  const DateDataFirst = new Date();
  const timeZone = moment.tz.guess();
  const timeZoneAbbr = moment.tz(DateDataFirst, timeZone).format("z");
  console.log("timeZoneAbbr", timeZoneAbbr);

  // <---------- Get Privacy & ploicy ------------->
  const GetPrivacyPolicy = async()=>{
    try{
  const response = await PrivacyPolicy();
  setPrivacyPolicyList(response?.data?.data)
    }catch(error){
       console.log("error",error)
    }
  }

  // <---------- Get Privacy & ploicy ------------->
  const GetTermsCondtion = async()=>{
    try{
  const response = await TermsCondtion();
  setTermsConditionList(response?.data?.data)
    }catch(error){
       console.log("error",error)
    }
  }

  useEffect(()=>{
    GetPrivacyPolicy();
    GetTermsCondtion();
  },[])
  return (
    <div className="login-signup-bg">
      <Header />
      {isLoading ? (
        <>
          <div className="chat-window text-center">
            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                margin: "auto",
              }}
              className="spinner-border"
              role="status"
            >
              <span className="sr-only"></span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="user-area p-2 p-md-4 p-lg-5 pt-lg-3">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 p-0 d-none d-md-none d-lg-block">
                  <div className="user_login">
                    <img
                      src={CommonImageTransport.loginbg}
                      className="imgk-fluid w-100"
                      alt="login-banner"
                    />
                  </div>
                </div>
                <div className="col-lg-6 d-flex p-0">
                  <div className="user-all-form userlogin position-relative">
                    <div className="login_bottom">
                      <img src={CommonImageTransport.loginBottom} alt="" />
                    </div>
                    <div className="contact-form m-0 py-0">
                      <h3 className="user-title"> Sign up for an account</h3>

                      <ul className="nav tabs_signup justify-content-start gap-2">
                        <li className="nav-item">
                          <a
                            className={`${
                              ActiveTabs == "student"
                                ? "nav-link active"
                                : "nav-link"
                            }`}
                            aria-current="page"
                            onClick={() => {
                              RemoveLocalStorage();
                              StudentSignUpPage();
                            }}
                          >
                            Student
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`${
                              ActiveTabs == "Recruiter"
                                ? "nav-link active"
                                : "nav-link"
                            }`}
                            onClick={() => {
                              RecuriterSignUpPage();
                            }}
                          >
                            Recruiter
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`${
                              ActiveTabs == "Mentor"
                                ? "nav-link active"
                                : "nav-link"
                            }`}
                            onClick={() => {
                              MentorSignUpPage();
                            }}
                          >
                            Mentor
                          </a>
                        </li>
                      </ul>

                      <form
                        id="contactForms"
                        className="mt-2"
                       
                      >
                        <ToastContainer style={{ marginTop: "100px" }} />
                        <div className="row">
                          {/* <div className="col-lg-12 mb-4">
                            <h6>Location</h6>
                          </div> */}
                          <div className="col-md-4">
                            <div className="form-floating form-group">
                              <h6>Country</h6>
                              <CountrySelect
                                defaultValue={country}
                                value={country}
                                name="country"
                                onChange={(selectedCountry) => {
                                  setCountry(selectedCountry);
                                  funCountryId(selectedCountry);
                                }}
                                placeHolder="Select Country"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <h6>State</h6>
                            <StateSelect
                              country={country}
                              value={state}
                              countryid={countryid}
                              onChange={(selectedState) => {
                                funStateId(selectedState.id);
                                setState(selectedState.name);
                              }}
                              placeHolder="Select State"
                            />
                          </div>

                          <div className="col-md-4">
                            <h6>City</h6>
                            <CitySelect
                              country={country}
                              state={state}
                              value={city}
                              countryid={countryid}
                              stateid={stateid}
                              cityid={cityid}
                              onChange={(selectedCity) => {
                                funCityId(selectedCity.id);
                                setCity(selectedCity.name);
                              }}
                              placeHolder="Select City"
                            />
                          </div>

                          <div className="col-12 col-lg-6">
                            <div className="form-floating form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder=""
                                required
                                name="firstName"
                                data-error="Please Enter Your Name"
                                value={firstName}
                                onChange={(event) =>
                                  handleInputChange(
                                    event,
                                    setFirstName,
                                    setFirstNameError
                                  )
                                }
                              />
                              <label htmlFor="floatingInput">First Name</label>
                              {firstNameError && (
                                <p style={{ color: "red", fontSize: "14px" }}>
                                  {firstNameError}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="form-floating form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder=""
                                name="lastName"
                                value={lastName}
                                onChange={(event) =>
                                  handleInputChange(
                                    event,
                                    setLastName,
                                    setLastNameError
                                  )
                                }
                              />
                              <label htmlFor="floatingInput">Last Name</label>
                              {lastNameError && (
                                <p style={{ color: "red", fontSize: "14px" }}>
                                  {lastNameError}
                                </p>
                              )}
                            </div>
                          </div>
                          {/* <div className="col-lg-4">
                                        <div className="form-floating form-group">
                                            <input type="" className="form-control" id="floatingInput" placeholder="DOB" />
                                            <label htmlFor="floatingInput">DOB</label>
                                        </div>
                                    </div> */}
                          <div className="col-lg-12">
                            <div className="form-floating form-group">
                              <input
                                onChange={handleChange}
                                type="email"
                                className="form-control"
                                id="floatingInput"
                                placeholder=""
                              />
                              <label htmlFor="floatingInput">
                                University Email
                              </label>
                              {!emailError && (
                                <p style={{ color: "red" }}>{emailError}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-lg-6 position-relative">
                            <div className="form-floating form-group">
                              <input
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                id="floatingPassword"
                                placeholder="xxxxxxxx"
                                value={password}
                                onChange={(e) => handlePasswordChange(e)}
                              />
                              <label htmlFor="floatingPassword">Password</label>
                            </div>
                            <span
                              onClick={() => setShowPassword(!showPassword)}
                              className="password-toggle eye"
                            >
                              {showPassword ? <FaEye /> : <FaEyeSlash />}
                            </span>
                          </div>
                          <div className="col-12 col-lg-6 position-relative">
                            <div className="form-floating form-group">
                              <input
                                type={showConfirmPassword ? "text" : "password"}
                                className="form-control"
                                id="floatingPassword"
                                placeholder="xxxxxxxx"
                                value={confirmPassword}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                              <label htmlFor="floatingPassword">
                                Confirm password
                              </label>
                            </div>
                            <span
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              className="password-toggle eye"
                            >
                              {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                            </span>
                          </div>
                          <div className="col-12 col-lg-12 position-relative">
                            {!isValidPassword && (
                              <p style={{ color: "red" }}>
                                Password must be at least 11 characters long,
                                contain at least one lowercase letter, one
                                uppercase letter, one digit, and one special
                                character (@$!%*?&).
                              </p>
                            )}
                          </div>
                          <div className="col-lg-12 ">
                            <div className="form-floating form-group">
                              <PhoneInput
                                className="phoneinput form-control"
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry={defaultCountryCode} // Set US as default
                                placeholder="Enter phone number"
                                value={value}
                                onChange={setValue}
                                // onChange={(newValue) => {
                                //   const validPhoneNumber = newValue || "";
                                //   setValue(validPhoneNumber);
                                //   validatePhoneNumber(validPhoneNumber);
                                // }}
                              />
                              {mobileError && (
                                <p style={{ color: "red", fontSize: "14px" }}>
                                  {mobileError}
                                </p>
                              )}

                            
                            </div>
                          </div>

                          <div className="col-lg-12 form-condition">
                            <div className="agree-label">
                              <input
                                type="checkbox"
                                id="chb1"
                                onChange={handleCheckboxChange}
                                checked={isTACChecked}
                                required
                              />
                              <label
                                htmlFor="chb1"
                                className="small"
                                
                              >
                                All your information is collected, stored and
                                processed as per our data processing guidelines.
                                By signing up on FreshGrad, you agree to our{" "}
                                <Link to="#" onClick={(e) => {
                                  e.stopPropagation();
                                  setShowPrivacyPolicy(true);
                                }}>Privacy Policy</Link>{" "}
                                <Link to="#">and</Link>{" "}
                                <Link to="#" onClick={(e) => {
                                  e.stopPropagation();
                                  setShowTermsCondition(true);
                                }}>Terms of Use</Link>
                              </label>
                            </div>
                          </div>
                          {error && (
                            <p style={{ color: "red", fontSize: "14px" }}>
                              {error}
                            </p>
                          )}
                          <div className="col-lg-12 col-md-12">
                            <button
                              type="button"
                              onClick={SendOtpStudentSignUp}
                              className="default-btn w-100 rounded"
                            >
                              Sign Up
                            </button>
                            <h6 className="mt-4 text-center fw-normal text-muted">
                              Already have an account?{" "}
                              <Link className="fw-bold" to="/Signin">
                                Login
                              </Link>
                            </h6>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <--------- T & C Modal section's ------------> */}
          <Modal
            size="lg"
            show={ShowTermsCondition}
            onHide={() => setShowTermsCondition(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Terms & Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row pt-1">
                  <div className="col-lg-12">
                    <div className="single-content">
                      <h3>{TermsConditionList?.title}</h3>
                      <ul>
                        <li>
                          <p>{ReactHtmlParser(TermsConditionList?.desciption)}</p>
                        </li>
                       
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* <--------- Privacy & Policy section's ------------> */}
          <Modal
            size="lg"
            show={showPrivacyPolicy}
            onHide={() => setShowPrivacyPolicy(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Privacy & Policy</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row pt-1">
                  <div className="col-lg-12">
                    <div className="single-content">
                      <h3>{PrivacyPolicyList?.title}</h3>
                      <ul>
                        <li>
                          <p>{ReactHtmlParser(PrivacyPolicyList?.desciption)}</p>
                        </li>
                      </ul>
                     
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
};

export default StudentForm;
