import React, { useEffect, useState } from 'react'
import { RiArrowUpSLine } from 'react-icons/ri';

const BackToTop = () => {
  //---------------------------BackToTop Arrow--------------------------------

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
      const handleScroll = () => {
          const scrolled = window.scrollY;
          if (scrolled > 600) {
              setShowButton(true);
          } else {
              setShowButton(false);
          }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  const scrollToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: 'smooth'
      });
  };

  return (
    <div className={`go-top${showButton ? ' active' : ''}`} onClick={scrollToTop}>
            <RiArrowUpSLine />
        </div>
  )
}

export default BackToTop
